import React from 'react'
import { DateRangePicker } from "materialui-daterange-picker";

const CustomDateRange = () => {
    const [open, setOpen] = React.useState(true);
  const defaultDate = {
    startDate: Date.parse("2010-01-13"),
    endDate: Date.now()
  };
  const [dateRange, setDateRange] = React.useState(defaultDate);

  const toggle = () => setOpen(!open);
  return (
    <>
    
    <DateRangePicker
    open={open}
    toggle={toggle}
    onChange={(range) => {
      setDateRange(range);
    }}
    initialDateRange={dateRange}
  />
    
    </>
  )
}

export default CustomDateRange