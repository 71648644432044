import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import "./ExcelData.css";
import { TbCloudUpload } from "react-icons/tb";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineClose } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import IconButs from "../../common/Controls/IconButs";
import { EXCEL_UPLOAD_DATA } from "../../utilitis/header";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 480,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const ExcelData = ({
  open,
  onModalClose,
  readExcel,
  title,
  fileType,
  message,
  requestId,
}) => {
  const [files, setFiles] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);
  const customStyle = makeStyles();

  useEffect(() => {
    if (message && requestId && fileType) {
      setFileUploaded(true);
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);
    }
  }, [requestId]);

  return (
    <>
      <Modal open={open} onClose={onModalClose}>
        <Box sx={style}>
          <Typography
            variant="h6"
            component="h2"
            className={customStyle.modalHeader}
          >
            {title}
          </Typography>
          <IconButton
            onClick={onModalClose}
            className={customStyle.iconButtonClose}
          >
            <AiOutlineClose />
          </IconButton>
          <Typography className={customStyle.modalSubHeader}>
            Only .xls and .xlsx files are allowed
          </Typography>
          <div>
            <label htmlFor="file-upload" className="custom-file-upload">
              <div className={customStyle.inBox}>
                <TbCloudUpload className={customStyle.uploadIcon} />
                <Typography className={customStyle.modalBodyText}>
                  Browse your excel files here
                </Typography>
              </div>
            </label>
            <input id="file-upload" type="file" onChange={(e) => setFiles(e)} />
          </div>
          <div className={customStyle.uploadStyle}>
            <IconButs
              ibtname={EXCEL_UPLOAD_DATA}
              className={customStyle.salesButton}
              onClick={() => readExcel(files)}
              hidden={fileUploaded ? true : false}
            />
            {fileUploaded ? (
              <div>
                <h6>{message}</h6>
              </div>
            ) : (
              ""
            )}

            {!fileType ? (
              <div>
                <h6>
                  File does not support. You must use .xls or .xlsx files only
                </h6>
              </div>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ExcelData;
