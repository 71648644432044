import React from 'react'

const KwikfixIcon = () => {
  return (
    <>
      <svg width="152" height="49" viewBox="0 0 152 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.6303 6.49442C26.8557 -1.27589 14.2547 -1.27589 6.48434 6.49442C-0.620403 13.597 -1.21043 24.7276 4.67317 32.5252L7.84729 29.4308C6.09151 26.9204 5.053 23.8666 5.053 20.5693C5.053 12.0048 11.9931 5.06282 20.5576 5.06282C29.122 5.06282 36.0619 12.0048 36.0619 20.5693C36.0619 29.1318 29.1218 36.0738 20.5576 36.0738C17.1642 36.0738 14.0336 34.9696 11.4818 33.1197L8.23905 36.3969L20.5576 48.7131L34.6335 34.6401C42.4038 26.8701 42.4038 14.2669 34.6303 6.49442Z" fill="#E94141" />
        <path d="M28.863 28.9296C33.5029 24.2899 33.5029 16.7669 28.863 12.1259C24.2252 7.48627 16.7024 7.48627 12.0614 12.1259C8.04892 16.1374 7.51349 22.3046 10.4411 26.901L14.5475 22.8975C13.6333 20.6034 14.1 17.8799 15.9565 16.0231C17.7698 14.2098 20.4064 13.7229 22.6634 14.5552L19.6035 17.6119C18.5629 18.6526 18.5629 20.3426 19.6035 21.3832C20.6439 22.4236 22.3339 22.4236 23.3746 21.3832L26.4366 18.3263C27.267 20.5849 26.7822 23.2177 24.9687 25.035C23.11 26.8928 20.3884 27.3633 18.0924 26.4422L14.0497 30.5234C18.6501 33.4807 24.8406 32.9553 28.863 28.9296Z" fill="#E94141" />
        <path d="M50.8623 8.48029H55.2345V18.4236H55.4932L63.9789 8.48029H69.2592V8.73899L60.8708 18.3258L70.1332 31.4109V31.6696H64.7892L57.8918 21.7909L55.235 24.8681V31.6696H50.8628V8.48029H50.8623Z" fill="black" />
        <path d="M67.2983 15.8003H72.0594L74.6827 25.7425H74.908L78.0498 15.8003H82.4218L85.5969 25.7425H85.8233L88.4154 15.8003H93.1109L88.0265 31.6693H83.4603L80.2851 21.6614H80.0587L76.8835 31.6693H72.3822L67.2983 15.8003Z" fill="black" />
        <path d="M96.3575 13.6628C95.9906 13.6628 95.6392 13.5921 95.3047 13.4519C94.9704 13.3124 94.6786 13.1173 94.4318 12.869C94.1832 12.6204 93.9884 12.3346 93.8471 12.0112C93.7066 11.6871 93.6379 11.3306 93.6379 10.9414C93.6379 10.5523 93.7066 10.1979 93.8471 9.87351C93.9884 9.54934 94.1834 9.26333 94.4318 9.01497C94.6786 8.76634 94.9704 8.57258 95.3047 8.4321C95.6392 8.29294 95.9906 8.22217 96.3575 8.22217C97.114 8.22217 97.7615 8.48697 98.3009 9.01524C98.8393 9.54351 99.1112 10.1871 99.1112 10.9417C99.1112 11.6974 98.8395 12.3407 98.3009 12.869C97.7615 13.398 97.1142 13.6628 96.3575 13.6628ZM94.2524 31.6694V15.8003H98.4952V31.6694H94.2524V31.6694Z" fill="black" />
        <path d="M100.935 8.48029H105.178V21.1118H105.371L110.684 15.8003H115.931V16.059L110.037 21.7909L116.416 31.4109V31.6696H111.396L107.055 24.7074L105.177 26.5517V31.6696H100.934V8.48029H100.935Z" fill="black" />
        <path d="M124.019 31.6692H119.778V19.4267H116.928V15.8001H119.778V14.2455C119.778 13.2736 119.923 12.4161 120.216 11.6705C120.506 10.9259 120.922 10.2996 121.463 9.79143C122.002 9.28436 122.638 8.89552 123.372 8.62674C124.107 8.35691 124.915 8.22173 125.801 8.22173C126.234 8.22173 126.578 8.24399 126.838 8.2864C127.097 8.32987 127.345 8.39455 127.583 8.48043L127.324 12.4635C127.172 12.3988 127.001 12.3464 126.807 12.3029C126.612 12.2594 126.363 12.2382 126.061 12.2382C125.434 12.2382 124.939 12.4362 124.571 12.8362C124.203 13.2362 124.019 13.7918 124.019 14.504V15.8001H134.255V31.6692H130.013V19.4267H124.019V31.6692V31.6692ZM132.116 13.4999C131.751 13.4999 131.4 13.4302 131.066 13.2887C130.729 13.1492 130.439 12.9542 130.191 12.7058C129.942 12.4593 129.747 12.1735 129.608 11.8494C129.468 11.5249 129.397 11.1684 129.397 10.7796C129.397 10.3918 129.468 10.034 129.608 9.71085C129.747 9.38641 129.942 9.10068 130.191 8.85231C130.439 8.6058 130.729 8.41072 131.066 8.26944C131.4 8.13001 131.751 8.0603 132.116 8.0603C132.873 8.0603 133.52 8.32404 134.061 8.85231C134.6 9.38244 134.87 10.0242 134.87 10.7796C134.87 11.5361 134.6 12.1775 134.061 12.7061C133.52 13.2362 132.873 13.4999 132.116 13.4999Z" fill="#E94141" />
        <path d="M141.409 23.606L135.968 15.8002H140.988L143.87 20.2047H144.097L146.818 15.8002H152L146.428 23.606L152 31.6692H147.012L144.033 27.1345H143.805L140.923 31.6692H135.903L141.409 23.606Z" fill="#E94141" />
        <path d="M54.1613 37.182C55.2099 37.182 56.0382 37.4619 56.6454 38.0225C57.2506 38.5821 57.5546 39.35 57.5546 40.3275V44.9847H56.198V43.9361H56.1365C55.5507 44.7989 54.7688 45.2312 53.7918 45.2312C52.9584 45.2312 52.2624 44.9847 51.7028 44.4906C51.1422 43.9978 50.8623 43.3805 50.8623 42.641C50.8623 41.859 51.1573 41.2377 51.7492 40.7731C52.34 40.3113 53.1291 40.081 54.1152 40.081C54.9586 40.081 55.6525 40.2347 56.198 40.5427V40.2194C56.198 39.7256 56.0021 39.3062 55.6122 38.9616C55.2213 38.6181 54.7627 38.4445 54.2395 38.4445C53.4485 38.4445 52.8203 38.779 52.3575 39.4475L51.1091 38.6616C51.7974 37.674 52.8158 37.182 54.1613 37.182ZM52.3278 42.6715C52.3278 43.042 52.4845 43.3503 52.7975 43.5957C53.1106 43.8433 53.4782 43.9663 53.8997 43.9663C54.4966 43.9663 55.028 43.745 55.4959 43.3036C55.9634 42.861 56.1978 42.3428 56.1978 41.7459C55.7554 41.3973 55.1391 41.2205 54.3471 41.2205C53.7714 41.2205 53.2913 41.361 52.9057 41.6377C52.5197 41.915 52.3278 42.2601 52.3278 42.6715Z" fill="black" />
        <path d="M69.1842 44.9849H67.8265V43.9363H67.765C67.5479 44.3061 67.2166 44.6141 66.77 44.8616C66.3226 45.1081 65.8568 45.2314 65.3739 45.2314C64.4486 45.2314 63.7363 44.9666 63.2383 44.4362C62.7392 43.9079 62.4897 43.1544 62.4897 42.1776V37.4277H63.91V42.0856C63.9394 43.319 64.5615 43.9363 65.776 43.9363C66.3406 43.9363 66.8134 43.7081 67.1943 43.2493C67.5742 42.7915 67.765 42.2452 67.765 41.6067V37.4274H69.1842V44.9849V44.9849Z" fill="black" />
        <path d="M77.2946 45.1079C76.6776 45.1079 76.1673 44.9178 75.7602 44.5372C75.3541 44.1571 75.146 43.6278 75.1349 42.9481V38.7236H73.8096V37.4274H75.1349V35.114H76.5543V37.4274H78.4047V38.7236H76.5543V42.4853C76.5543 42.9906 76.6521 43.332 76.848 43.5119C77.0431 43.6927 77.2652 43.7818 77.5106 43.7818C77.623 43.7818 77.7352 43.7696 77.843 43.7431C77.9501 43.7179 78.0511 43.6834 78.1433 43.6431L78.5908 44.9077C78.2199 45.0403 77.7887 45.1079 77.2946 45.1079Z" fill="black" />
        <path d="M82.7244 41.2057C82.7244 40.045 83.0891 39.0841 83.8193 38.3215C84.5589 37.5608 85.4892 37.182 86.6105 37.182C87.7309 37.182 88.6573 37.5608 89.3857 38.3215C90.127 39.0841 90.4968 40.045 90.4968 41.2057C90.4968 42.3775 90.1268 43.3402 89.3857 44.0898C88.6573 44.8505 87.7309 45.2314 86.6105 45.2314C85.4892 45.2314 84.5589 44.8505 83.8193 44.0898C83.0891 43.3291 82.7244 42.3682 82.7244 41.2057ZM84.1427 41.2057C84.1427 42.0189 84.3802 42.6776 84.8531 43.1806C85.3257 43.6835 85.9117 43.9363 86.6107 43.9363C87.3089 43.9363 87.8949 43.6837 88.3697 43.1806C88.8412 42.6776 89.0787 42.0189 89.0787 41.2057C89.0787 40.4046 88.8412 39.751 88.3697 39.2479C87.8857 38.7326 87.2988 38.4771 86.6107 38.4771C85.9218 38.4771 85.3358 38.7326 84.8531 39.2479C84.3802 39.7507 84.1427 40.4044 84.1427 41.2057Z" fill="black" />
      </svg>
    </>
  )
}

export default KwikfixIcon