import React, { useState } from 'react'
import {
  Typography,
  Paper,
  IconButton,
  Box,
  Grid
} from "@material-ui/core";
import makeStyles from '../../sharedFeatures/sharedClasses';
import { Link } from 'react-router-dom';
import Sub from "../../common/Subheader/Sub";
import { FcStackOfPhotos } from 'react-icons/fc'
import { MdArrowBackIosNew } from "react-icons/md";
import {
  TXT_VEHICLEMANAGEMENT,
  TXT_VEHICLE_PRODUCT_DETAIL
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";


const VehicleProductDetails = () => {
  const viewStyle = makeStyles();
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  return (

    <div className={viewStyle.divStyle}>

      <header className={viewStyle.headerStyle} style={{ backgroundColor: '#FEDD17' }}>
        <h1>{TXT_VEHICLEMANAGEMENT}</h1>
      </header>

      <div className={viewStyle.searchBoxParent}></div>

      <Paper className={viewStyle.parentContainer} elevation="6">
        <div className={viewStyle.subHeaderParent}>
          <Link to="/administrator/vehicle/vehicleDetails/:id">
            <IconButton className={viewStyle.backButtonParent}>
              <MdArrowBackIosNew className={viewStyle.backButton} />
            </IconButton>
          </Link>
          <Sub data={TXT_VEHICLE_PRODUCT_DETAIL} />
        </div>

        <div className={viewStyle.searchBoxParent}  >
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <Grid container alignItems='center'>

                <Grid item xs={4} md={6}>
                  Brand
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    className={viewStyle.formTextBar}
                    label='Type / Search Brand'
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Product
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    className={viewStyle.formTextBar}
                    label='Select Car'
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Seral Number
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Supported Vehicles
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.SelectBox
                    name="vehicles"
                    label='Select Vehicles'
                    className={viewStyle.formSelectBox}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  MRP
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  RCP
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Customer Price
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Dealer Price
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Other Charges
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Description
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.TextArea
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={6}>
              <Grid container alignItems='center'>
                <Grid item xs={4} md={6}>
                  Product Image
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    id="file-upload"
                    type="file"
                    name="Upload Image"
                    className={viewStyle.forms}
                    icon={<FcStackOfPhotos />}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  GST
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  Warranty Details
                </Grid>
                <Grid item xs={4} md={6}>
                  <Controls.TextArea
                    name="Search Varient"
                    className={viewStyle.formTextBar}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </Paper >
    </div >
  )
}

export default VehicleProductDetails;
