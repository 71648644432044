import React from 'react'
import {Box} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const DatePicker = (props) => {

  const { name, label, value, onChange, className } = props

  const convertToDefaultEventPara = (name, value) => ({
    target: {
      name,
      value
    }
  })

  return (
    <Box>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Box sx={{
      display: "flex",
      alignItems: "center",
      bgcolor: "#FFFFFF",
      // pr: 3,
      borderRadius: "8px",
      width:"220px"
    }}>
      <KeyboardDatePicker disableToolbar variant='inline' 
        label={label}
        format="dd/MM/yyyy"
        name={name}
        value={value}
        className={className}
        onChange={(date) => onChange(convertToDefaultEventPara(name, date))}

      />

      </Box>
    </MuiPickersUtilsProvider>
    </Box>
  )
}

export default DatePicker