import React from "react";
import { Card, Box } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import InfoIButton from "../../utilitis/icons/InfoIButton";
import ChartDetailHeader from "./ChartDetailHeader";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";


const COLORS = ["#A155B9", "#F765A3", "#16BFD6", "#1DDD8D","#F94144"];

const TopCatSales = ({data,total=0}) => {
  const dashStyles = useStyles();
  const renderLegendText = (value, entry) => {
    return (
      <span
        style={{
          color: "#4F4F4F",
          fontSize: "12px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          paddingLeft: "8px",
          width:'9rem',
          display:'inline-flex',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        title={value}
      >
        {value}
      </span>
    );
  };

  return (
    <>
      <Card className={dashStyles.salesChartCommonParent}>
        <ChartDetailHeader
          title="Top Categories Sales"
          stat={total}
          update="Daily Update"
        />
        <Box className={dashStyles.iButtonParent}>
          <InfoIButton />
        </Box>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Legend
              align="right"
              verticalAlign="middle"
              iconType="rect"
              iconSize={17}
              layout="vertical"
              formatter={renderLegendText}
              wrapperStyle={{ top: 90 }}
            />
            <Tooltip />
            <Pie
              data={data && data}
              cx={120}
              cy={150}
              labelLine={false}
              outerRadius={90}
              fill="#8884d8"
              dataKey="value"
            >
              {data && data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default TopCatSales;
