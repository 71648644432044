import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import makeStyles from "../../../sharedFeatures/sharedClasses";
import Controls from "../../../common/Controls/Controls";
import useDealersQuery from "../../../data/dealer/useDealersQuery";
import dealerStyles from "../dealerStyle";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import { FiUpload } from "react-icons/fi";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";

import ScrollToTop from "../../../common/ScrollToTop";

const Documents = ({
  submitDocuments,
  handleDocumentsUpload,
  handleShopImage,
  preview,
  previewShopImage,
  handleBack,
  dataLoading,
  imageError,
  shopError,
}) => {
  const dealerClass = dealerStyles();
  const customStyle = makeStyles();
  const { data } = useDealersQuery.get_Input_List();

  const shopImages = data
    ? data &&
      data.data &&
      data.data.results[0] &&
      data.data.results[0].shop_images
    : "";

  const shopImageItems = shopImages ? Object.keys(shopImages) : "";

  useEffect(() => {
    if (previewShopImage != undefined) {
      const data1 = Object.keys(previewShopImage);
      const count = data1.filter(function (e) {
        return e !== "key";
      });
      setKey([
        ...data1.filter(function (e) {
          return e !== "key";
        }),
      ]);
      setCounter(count.length);
    }
  }, [previewShopImage]);

  const [counter, setCounter] = useState(0);
  const [imageKey, setKey] = useState([]);

  const handleIncrement = () => {
    if (imageKey.length < previewShopImagetems.length) {
      setCounter(counter + 1);
      setKey([...imageKey, `key_${counter}`]);
    }
  };

  const previewItems = preview
    ? Object.entries(preview).map(([key, value]) => ({
        key,
        value,
      }))
    : "";

  const previewShopImagetems = previewShopImage
    ? Object.entries(previewShopImage).map(([key, value]) => ({
        key,
        value,
      }))
    : "";

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const findExist = (items) => {
    if (previewItems.length > 0) {
      let stuff = previewItems.filter((it) => it.key === items);
      if (stuff.length > 0) {
        return true;
      } else return false;
    }
  };

  const findFirstShopImageExist = (items) => {
    return true;
  };

  const findShopImageExist = (shopItem) => {
    if (previewShopImagetems.length > 0) {
      let stuff = previewShopImagetems.filter((it) => it.key === shopItem);
      if (stuff.length > 0) {
        return true;
      } else return false;
    }
  };

  const deleteFn = (e, item) => {
    e.preventDefault();

    var removeIndex = previewItems.map((x) => x.key).indexOf(item.key);
    removeIndex && previewItems.splice(removeIndex, 1);
  };

  return (
    <>
      <ScrollToTop />
      <Grid container spacing={{ xs: 0, md: 1 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          style={{ marginTop: "10px" }}
        >
          <Typography className={dealerClass.label}>
            Upload Documents
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <p className={dealerClass.error}>{imageError}</p>
          <Grid container>
            {shopImageItems &&
              shopImageItems.map((items) => {
                return (
                  <Grid
                    item
                    xs={7}
                    md={4}
                    sm={6}
                    lg={4}
                    xl={4}
                    key={items}
                    style={{ marginBottom: { xs: "0px", md: "25px" } }}
                  >
                    {previewItems && previewItems != []
                      ? previewItems
                          .filter((docItem) => docItem.key === items)
                          .map((filteredPerson) => (
                            <>
                              <div className={dealerClass.imgDiv}>
                                <Typography className={dealerClass.labelImg}>
                                  {items.replaceAll("_", " ")}
                                </Typography>
                                <label>
                                  <img
                                    src={
                                      filteredPerson.value.includes("blob")
                                        ? filteredPerson.value
                                        : `${BASE_URL}/${filteredPerson.value}`
                                    }
                                    className={dealerClass.documentImage}
                                  />

                                  <Controls.CustomDocumentInput
                                    name={items}
                                    type="file"
                                    label={items.replaceAll("_", " ")}
                                    onChange={handleDocumentsUpload}
                                    style={{ display: "none" }}
                                  ></Controls.CustomDocumentInput>
                                </label>
                                {/* <button
                                  onClick={(e) => deleteFn(e, filteredPerson)}
                                >
                                  delete
                                </button> */}
                              </div>
                            </>
                          ))
                      : ""}
                    {!findExist(items) && (
                      <Controls.CustomDocumentInput
                        name={items}
                        type="file"
                        id="file-upload"
                        title=" "
                        label={items.replaceAll("_", " ")}
                        onChange={handleDocumentsUpload}
                        className={dealerClass.imageBox}
                        icon={
                          JSON.stringify(previewItems) === "{}" ? (
                            <DoneTwoToneIcon
                              className={dealerClass.imageicon}
                            />
                          ) : (
                            <FiUpload />
                          )
                        }
                      ></Controls.CustomDocumentInput>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 0, md: 1 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          style={{ marginTop: { xs: "-15px", md: "15px" } }}
        >
          <Typography className={dealerClass.label}>Shop Images</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={10}
          className={dealerClass.shopGrid}
        >
          <p className={dealerClass.error}>{shopError}</p>
          <Grid container>
            <Grid item md={4}>
              {previewShopImagetems ? (
                previewShopImagetems &&
                previewShopImagetems
                  .filter((shopItem) => shopItem.key === "key")
                  .map((filteresShopImage) => (
                    <Grid item xs={7} md={4} sm={6} lg={4} xl={4}>
                      <div className={dealerClass.imgDiv}>
                        <Typography className={dealerClass.labelImg}>
                          Image 1
                        </Typography>
                        <label>
                          <img
                            src={
                              filteresShopImage.value.includes("blob")
                                ? filteresShopImage.value
                                : `${BASE_URL}/${filteresShopImage.value}`
                            }
                            height="100px"
                            width="150px"
                            style={{ cursor: "pointer" }}
                          />
                          <Controls.CustomDocumentInput
                            label="Shop Image"
                            name="key"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleShopImage}
                          ></Controls.CustomDocumentInput>
                        </label>
                      </div>

                      {!findFirstShopImageExist("key") && (
                        <Controls.CustomDocumentInput
                          label="Shop Image"
                          name="key"
                          type="file"
                          onChange={handleShopImage}
                          className={dealerClass.imageBox2}
                          // values={}
                          icon={
                            JSON.stringify(previewItems) === "{}" ? (
                              <DoneTwoToneIcon
                                className={dealerClass.imageicon}
                              />
                            ) : (
                              <FiUpload />
                            )
                          }
                        ></Controls.CustomDocumentInput>
                      )}
                    </Grid>
                  ))
              ) : (
                <Controls.CustomDocumentInput
                  name="key"
                  type="file"
                  onChange={handleShopImage}
                  className={dealerClass.imageBox2}
                  // values={}
                  icon={
                    JSON.stringify(previewItems) === "{}" ? (
                      <DoneTwoToneIcon className={dealerClass.imageicon} />
                    ) : (
                      <FiUpload />
                    )
                  }
                ></Controls.CustomDocumentInput>
              )}
            </Grid>
            {counter < 9 ? (
              <IconButton className={dealerClass.addDashedBorder}>
                <AddIcon
                  // fontSize="large"
                  className={dealerClass.addIcon}
                  onClick={() => handleIncrement()}
                />
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <p className={dealerClass.error}>{shopError}</p>
          <Grid container>
            {imageKey &&
              imageKey.map((x, i) => {
                return (
                  <Grid item xs={7} md={4} sm={6} lg={4} xl={4}>
                    {previewShopImagetems
                      ? previewShopImagetems &&
                        previewShopImagetems
                          .filter((shopItem) => shopItem.key === x)
                          .map((filteresShopImage) => (
                            <>
                              <Grid item xs={7} md={4} sm={6} lg={4} xl={4}>
                                <div className={dealerClass.imgDiv}>
                                  <Typography className={dealerClass.labelImg}>
                                    Image{" "}
                                    {previewShopImagetems.some(
                                      (item) => item.key === "key"
                                    )
                                      ? i + 2
                                      : i + 1}
                                  </Typography>
                                  <label>
                                    <img
                                      src={
                                        filteresShopImage.value.includes("blob")
                                          ? filteresShopImage.value
                                          : `${BASE_URL}/${filteresShopImage.value}`
                                      }
                                      height="100px"
                                      width="150px"
                                      margin="20px"
                                      align-items="center"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <Controls.CustomDocumentInput
                                      name={x}
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={handleShopImage}
                                    ></Controls.CustomDocumentInput>
                                  </label>
                                </div>
                              </Grid>
                            </>
                          ))
                      : ""}
                    {!findShopImageExist(x) && (
                      <Controls.CustomDocumentInput
                        id="file-upload"
                        name={x}
                        type="file"
                        label="Shop Image"
                        multiple={true}
                        placeholder="Upload Image"
                        onChange={handleShopImage}
                        className={dealerClass.imageBox2}
                        icon={<FiUpload />}
                      ></Controls.CustomDocumentInput>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: { xs: "-10px", md: "40px" } }}
      >
        {/* <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid container justifyContent="center">
            <IconButs
              ibtname={STEPPER_BACK}
              className={dealerClass.formButtonAdd}
              variant="outlined"
              onClick={handleBack}
            />
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid container justifyContent="center">
            <LoadingButton
              ibtname="Submit"
              className={customStyle.formButtonAdd}
              variant="outlined"
              onClick={submitDocuments}
              loading={dataLoading}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Documents;
