import React, { useState, useEffect } from "react";
import { Paper, IconButton,Box } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import Sub from "../../common/Subheader/Sub";
import axios from "axios";
import {
  TXT_DEALER_VIEW_DOCUMENTS,
  TXT_DEALERMANAGEMENT,
  
  DEALER_LIST_DOC_DOWNLOAD,
  DOC_BANK_PASSBOOK,
  DOC_AADHAR_CARD,
  DOC_CHEQUE,
  DOC_GST,
  DOC_PAN_CARD,
  DOC_SHOP,
} from "../../utilitis/header";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import Cards from "./Cards";
import dealerViewDocStyle from "./dealerViewDocStyle";
import IconButs from "../../common/Controls/IconButs";
import { useParams } from "react-router-dom";
import useDealersQuery from "../../data/dealer/useDealersQuery";
import HeadBar from "../../components/HeadBar/HeadBar";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const DealersViewDocuments = () => {
  const [isActive, setActive] = useState(false);
  const [tasks, setTasks] = useState(true);
  const { id } = useParams();
  const { data: docData } = id ? useDealersQuery.dealers_data(id) : "";

  const styles = makeStyles();
  const dealerStyles = dealerViewDocStyle();

  const shopImageobj = {
    bankPassbook: "true",
    Adhar: "true",
    cheque: "true",
    Gst: "true",
    PAN: "true",
    shopBanner: "true",
  };

  const shopimjitems = Object.keys(shopImageobj);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const banks =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Bank_Passbook;

  const aadharFront =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Aadhar_Front;

  const aadharBack =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Aadhar_Back;

  let adhar = [aadharFront, aadharBack];

  const gst =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Gst_Certificate;

  const pan =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Pan;

  const shops =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].photos;

  const cancelled_cheque =
    docData &&
    docData.data &&
    docData.data.shop_details[0] &&
    docData.data.shop_details[0].documents &&
    docData.data.shop_details[0].documents.Cancelled_Cheque;

  let shopBanners = shops && Object.values(shops);

  const [passImage, setPassImage] = useState(banks);
  const [showDownload, setShowDownload] = useState(true);
  const [buttonState, setButtonState] = useState("bank");
  const [images, setImages] = useState(banks);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleClick = (image, text) => {
    setImages(image);
    if (text === "adhar") {
      const aadharImage = image.filter((item) => item != undefined);
      setPassImage(aadharImage);
      setActive(true);
      setShowDownload(true);
      setButtonState(text);
    } else {
      setPassImage(image);
      setActive(true);
      setShowDownload(true);
      setButtonState(text);
    }
  };

  const buttons = () => {
    if (Array.isArray(passImage)) {
      const images = passImage.filter((item) => item !== undefined);
      images.length == 0 ? setDisabledButton(true) : setDisabledButton(false);
    } else if (passImage === undefined || passImage == null) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    buttons();
  }, [passImage]);

  const downloadImage = () => {
    Array.isArray(passImage)
      ? downloadZip()
      : // passImage.map((item, key) =>
      //     axios
      //       .get(`${BASE_URL}${item}`, {
      //         responseType: "blob",
      //       })
      //       .then((response) => {
      //         if (response) {
      //           var FileSaver = require("file-saver");
      //           FileSaver.saveAs(
      //             new Blob([response.data]),
      //             `doc-${key + 1}.png`
      //           );
      //         }
      //       })
      //   )
      passImage
      ? axios
          .get(`${BASE_URL}${passImage}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response) {
              var FileSaver = require("file-saver");
              FileSaver.saveAs(new Blob([response.data]), `doc.png`);
            }
          })
      : setTasks(false);
  };

  const [files, setFiles] = useState([]);

  //Convert generated URL to File
  const convertUrlToFile = async (url, filename) => {

    const response = await fetch(url);
    const blob = await response.blob();
 
    const file = new File([blob], filename, { type: blob.type });

    return file;
  };

  const downloadZip = async () => {
    const zip = new JSZip();
    const img = zip.folder("images");
    let converted = await Promise.all(
      passImage.map(async (item, idx) => {
        return await convertUrlToFile(`${BASE_URL}${item}`, `img_${idx}`);
      })
    );
    converted.map((item, idx) => {
      img.file(`image_${idx}.webp`, item);
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "images.zip");
    });
  };

  return (
    <>
      <HeadBar title={TXT_DEALERMANAGEMENT} />
      <div className={styles.divStyle}>
        <Paper className={styles.parentContainer}>
          <div className={styles.searchBoxParentOne}>
            <div className={styles.subHeaderParent}>
              <Link to={`/administrator/dealerDetails/${id}`}>
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
              <Sub data={TXT_DEALER_VIEW_DOCUMENTS} />
            </div>

            {showDownload && (
              <IconButs
                ibtname={DEALER_LIST_DOC_DOWNLOAD}
                icon={<FiDownload />}
                className={dealerStyles.dmSalesButton}
                onClick={downloadImage}
                disabled={disabledButton}
              />
            )}
          </div>

          <div className={styles.dmSearchBoxParentTwo}>
            <ul>
              <li
                className={
                  buttonState === "bank"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(banks, "bank")}
              >
                {DOC_BANK_PASSBOOK}
              </li>

              <li
                className={
                  buttonState === "adhar"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(adhar, "adhar")}
              >
                {DOC_AADHAR_CARD}
              </li>

              <li
                className={
                  buttonState === "cheque"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(cancelled_cheque, "cheque")}
              >
                {DOC_CHEQUE}
              </li>

              <li
                className={
                  buttonState === "gsts"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(gst, "gsts")}
              >
                {DOC_GST}
              </li>

              <li
                className={
                  buttonState === "panCard"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(pan, "panCard")}
              >
                {DOC_PAN_CARD}
              </li>

              <li
                className={
                  buttonState === "shopBanner"
                    ? dealerStyles.docViewButtonsClicked
                    : dealerStyles.docViewButtons
                }
                onClick={() => handleClick(shopBanners, "shopBanner")}
              >
                {DOC_SHOP}
              </li>
            </ul>

            <Box
              sx={{
                border: "2px solid #D9D9D9",
                marginLeft: "10px",
                width: "100%",
              }}
            >
              <Cards img={images} />
            </Box>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default DealersViewDocuments;
