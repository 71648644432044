import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {

  Typography,

  TableBody,
  TableRow,
  TableCell,
  IconButton,

  Box,
  Grid,
} from "@material-ui/core";
import { AiOutlineStar } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import { TXT_FINANCEMANAGEMENT } from "../../utilitis/header";

import makeStyles from "../../sharedFeatures/sharedClasses";

import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import DropDown from "../../common/Controls/ListingDropdown";
import { Link } from "react-router-dom";
import financeStyles from "./financeStyles";
import HeadBar from "../../components/HeadBar/HeadBar";

const Finance = () => {
  const customStyle = financeStyles();
  const styles = makeStyles();

  const data = [
    {
      sl_no: 1,
      orderid: "ORD101",
      customer: "Abhay Nair",
      techinician: "Arun Mishra",
      commission: "4",
      paymentstatus: "Paid",
      commissionstatus: "Paid",
    },
    {
      sl_no: 2,
      orderid: "ORD102",
      customer: "Alok Jose",
      techinician: "Sumini Mani",
      commission: "5",
      paymentstatus: "Paid",
      commissionstatus: "Pending",
    },
    {
      sl_no: 3,
      orderid: "ORD103",
      customer: "Abhay Nair",
      techinician: "Arun Mishra",
      commission: "3",
      paymentstatus: "Paid",
      commissionstatus: "Paid",
    },
    {
      sl_no: 4,
      orderid: "ORD104",
      customer: "Alok Jose",
      techinician: "Sumini Mani",
      commission: "1",
      paymentstatus: "Paid",
      commissionstatus: "Pending",
    },
    {
      sl_no: 5,
      orderid: "ORD105",
      customer: "Abhay Nair",
      techinician: "Arun Mishra",
      commission: "4",
      paymentstatus: "Paid",
      commissionstatus: "Paid",
    },
    {
      sl_no: 6,
      orderid: "ORD106",
      customer: "Alok Jose",
      techinician: "Sumini Mani",
      commission: "3",
      paymentstatus: "Paid",
      commissionstatus: "Pending",
    },
  ];

  const selectData = [
    { id: "1", name: "11/8/2022" },
    { id: "2", name: "10/3/2022" },
  ];

  const { TblContainer, TblHead } = useTable(HeadCells.userFinance);
  const [search, setSearch] = useState();
  const [trySearch, setTrySearch] = useState("");

  const searchUser = (e) => {
    setSearch(e.target.value);
  };
  return (
    <>
      <HeadBar title={TXT_FINANCEMANAGEMENT} />
      <div className={customStyle.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => setTrySearch(e.target.value)}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Grid container alignItems="center">
                <Grid item xs={3} md={3}>
                  <Typography
                    className={styles.viewlabel}
                    sx={{ marginTop: "10px" }}
                  >
                    Filter By
                  </Typography>
                </Grid>
                <Grid item xs={9} md={9}>
                  <Controls.SelectBox
                    className={customStyle.formSelectBox}
                    name="date"
                    label="Date"
                    options={selectData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {data.map((item) => {
                return (
                  <TableRow>
                    {/* <TableCell align="center">{item.sl_no}</TableCell> */}
                    <TableCell align="center">{item.orderid} </TableCell>
                    <TableCell align="center">{item.customer}</TableCell>
                    <TableCell align="center">{item.techinician}</TableCell>
                    <TableCell align="center">
                      {item.totalpayment}
                      <Link to="" className={styles.linkColor}>
                        View
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {item.commission}
                      <IconButton>
                        <AiOutlineStar className={styles.iconColor} />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell align="center">{item.paymentstatus}</TableCell>
                      <TableCell align="center">{item.commissionstatus}</TableCell> */}
                    <TableCell>
                      <div className={styles.tableCell}>
                        <div>
                          <Link to="" className={styles.linkColor}>
                            View Invoice
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.tableCell}>
                        <div>
                          <Link to="" className={styles.linkColor}>
                            View
                          </Link>
                        </div>
                        <div>
                          <Link to="" className={styles.linkColorTwo}>
                            Edit
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </Box>

        <Box className={customStyle.pagination}>
          <Box className={customStyle.paginationStyle}>
            <DropDown
              value={"10"}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: "10" },
                { key: 2, value: "20" },
              ]}
            />
            <span className={customStyle.paginationPage}>Per Page</span>
          </Box>
          <Pagination
            count={5}
            // page={currentPage}
            // onChange={currentData}
            variant="outlined"
            className={customStyle.paginationFont}
          />
        </Box>
      </div>
    </>
  );
};
export default Finance;
