import { Grid, IconButton, Paper } from "@material-ui/core";
import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import makeStyles from "../../sharedFeatures/sharedClasses";
import categoryBrandStyles from "./InventoryManagmentStyle";
import Sub from "../../common/Subheader/Sub";

const DealerOrTechnicianView = () => {
    const viewStyle = makeStyles();

    const categoryStyle = categoryBrandStyles();
    return (
        <>
          <HeadBar title="Dealer Techician Deatils" />
          <div className={viewStyle.divStyle}>
            <Paper className={categoryStyle.parentContainer} elevation="6">
              <div className={viewStyle.subHeaderParent}>
              <Link to={`/administrator/inventoryProduct`}>
                  <IconButton className={viewStyle.backButtonParent}>
                    <BackbuttonIcon />
                  </IconButton>
                </Link>
                <Sub data="Product Details" />
              </div>
              <div>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Inventory Id
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="inventoryId"
                            label="inventoryId"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            MRP
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="inventoryId"
                            placeholder="INR 1234"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Inventory Type
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.SelectBox
                            className={categoryStyle.formTextBar2}
                            name="inventoryId"
                            placeholder="INR  123456"
                            // options={categoryType}
                            // onChange={handleInputChange}
                            // value={values.inventoryId}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            RCP
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="inventoryId"
                            placeholder="INR  123456"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* .................... */}
                    {/* {(values.inventoryId == 1 || values.inventoryId == 2) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={categoryStyle.label}>
                                Category
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.SelectBox
                                className={categoryStyle.formTextBar2}
                                name="categrory"
                                placeholder="INR  123456"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                       
                      </>
                    )} */}
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Inventory Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="inventoryNmae"
                            placeholder="Tyre Brand"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ........................ */}
                    {/* {values.inventoryId ==1 && (
                      <>
                       <Grid item xs={12} md={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={categoryStyle.label}>
                                brand
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.SelectBox
                                className={categoryStyle.formTextBar2}
                                name="brand"
                                placeholder="INR  123456"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )} */}
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Customer Price
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="customerPrice"
                            placeholder="INR  123456"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Vehicle Model
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.SelectBox
                            className={categoryStyle.formTextBar2}
                            name="vehiclemodel"
                            label="vehiclemodel"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Dealer Price
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="DealerPrice"
                            placeholder="INR  123456"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Time for Service
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                columnGap: "4px",
                              }}
                            >
                              <Controls.SelectBox
                                name="DealerPrice"
                                placeholder="INR  123456"
                                className={categoryStyle.formTextBar3}
                              />
                              <Controls.SelectBox
                                name="DealerPrice"
                                label="Dealer Price"
                                placeholder="INR  123456"
                                className={categoryStyle.formTextBar3}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            other Changes
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="otherChanges"
                            placeholder="otherChanges"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            GST
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="GST"
                            label="GST"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Description
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.formTextBar2}
                            name="Description"
                            label="Description"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Warranty Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.formTextBar2}
                            name="Warranty"
                            label="Warranty"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Vehicle Category
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.SelectBox
                            className={categoryStyle.formTextBar2}
                            name="VehicleCategory"
                            label="VehicleCategory"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    
                    
                   
                  </Grid>
                </div>
            </Paper>
          </div>
        </>
      );
}

export default DealerOrTechnicianView