import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,

} from "@material-ui/core";
import { BsSearch, BsPersonCircle } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import InputField from "../../common/Controls/InputField";
import { TXT_DEALER_TECHNICIAN_ONBOARD } from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { MdNotifications } from "react-icons/md";
import { FcSettings } from "react-icons/fc";

const DealerTechnicianOnboardered = () => {
  const data = [
    {
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      phone: 960549483,
      address: "Navi Mumbai",
      products: "AC",
    },
    {
      name: "Jenny Wilson",
      email: "sara.cruz@example.com",
      phone: 9605499898,
      address: "3517 W. Gray St. Utica, Pennsylvania 57867",
      products: "Polishing",
    },
    {
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      phone: 960549483,
      address: "Navi Mumbai",
      products: "AC",
    },
    {
      name: "Jenny Wilson",
      email: "sara.cruz@example.com",
      phone: 9605499898,
      address: "3517 W. Gray St. Utica, Pennsylvania 57867",
      products: "Car Washing",
    },
    {
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      phone: 960549483,
      address: "Navi Mumbai",
      products: "AC",
    },
    {
      name: "Jenny Wilson",
      email: "sara.cruz@example.com",
      phone: 9605499898,
      address: "3517 W. Gray St. Utica, Pennsylvania 57867",
      products: "Car Washing",
    },
  ];

  const { TblContainer, TblHead } = useTable(HeadCells.dealerTechDetails);
  const [search, setSearch] = useState();
  const styles = makeStyles();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.divStyle}>
        <header
          className={styles.headerStyle}
          style={{ backgroundColor: "#FEDD17" }}
        >
          <h1>{TXT_DEALER_TECHNICIAN_ONBOARD}</h1>

          <div className={styles.iconStyle}>
            <IconButton>
              <BsPersonCircle className={styles.iconColor} />
            </IconButton>
            Sign In
            <IconButton>
              <FcSettings className={styles.iconColor} />
            </IconButton>
            <IconButton>
              <MdNotifications className={styles.iconColor} />
            </IconButton>
          </div>
        </header>

        <div className={styles.searchBoxParent}>
          <InputField
            value={search}
            placeholder="Type here..."
            className={styles.searchBar}
            size="small"
            name="usersearch"
            onChange={searchUser}
            icon={<BsSearch />}
          />
        </div>

        <Paper className={styles.parentContainerOne} elevation="6">
          <div className={styles.headerParent}>
            <section>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {data.map((item, key) => {
                    key++;

                    return (
                      <TableRow>
                        <TableCell align="center">{key}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">{item.address}</TableCell>
                        <TableCell align="center">{item.products}</TableCell>
                        <TableCell align="center">Pending</TableCell>
                        <TableCell align="center">Interested</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </TblContainer>
            </section>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default DealerTechnicianOnboardered;
