import React from "react";
import { Avatar, Card } from "@material-ui/core";
import useStyles from "./dashboardStyles";

const MiniCard = ({ icon, stat, text }) => {
  const dashStyles = useStyles();
  return (
    <>
      <Card className={dashStyles.greetingsMiniCard}>
        <Avatar
          alt={text}
          src={icon}
          sx={{ width: 75, height: 75, paddingTop: "20px" }}
        />
        <h3 className={dashStyles.greetingsMiniCardStat}>{stat}</h3>
        <p className={dashStyles.greetingsMiniCardText}>{text}</p>
      </Card>
    </>
  );
};

export default MiniCard;
