import { useQuery } from "react-query";
import {
  userRoleDetails,
  userRolePermissions,
  getRoleDetails,
} from "./userRoleUrls";

const getUserRolesList = (qry) => {
  return useQuery(["userroleslist", qry], () => userRoleDetails(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const getUserRolePermissions = () => {
  return useQuery(["userrolepermissionslist"], () => userRolePermissions());
};

const getUserRoleDetails = (qry) => {
  return useQuery(["userroledetails", qry], () => getRoleDetails(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const userRoleQuery = {
  getUserRolesList,
  getUserRolePermissions,
  getUserRoleDetails,
};

export default userRoleQuery;
