import React from "react";
import KwikfixIcon from "../KwikfixIcon";
import "../Sidebar.css";

const UseAppName = () => {
  
  return (
    <>
      <div className="name">
        <KwikfixIcon/>
      </div>
    </>
  );
};

export default UseAppName;
