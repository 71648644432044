import { useQuery } from "react-query";
import {
    getAllReviews,
    reviewDetails
} from "./reviewUrls";

const getReviewList = (qry) => {
  return useQuery(["reviewslist", qry], () => reviewDetails(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};



const reviewQuery = {
    getReviewList,
};

export default reviewQuery;
