export const APP_URLS = {
    //signin
    login: '/auth/login/',
    logout: '/auth/logout/',
    //User List
    getUserManagementList: '/users',
    //Dealer Management List
    getDealerManagementList: `dealers`,
    // Add User
    addUser: 'user/administrator/createUser',
    //Forget password
    forgotPasswrd:'auth/request-reset-email/'
  };
