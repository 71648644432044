import { useQuery } from "react-query";
import {
  getUserCredentialById,
  userCredentialDeatils,
  getUserTypesList,
  getUserTypesListForCreate
} from "./userUrls";

const userCredentailList = (qry) => {
  return useQuery(["usercredential", qry], () => userCredentialDeatils(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const userCredentailById = (id) => {
  return useQuery(["usercredential", id], () => getUserCredentialById(id));
};

const userTypesList = () => {
  return useQuery(["usertypes"], () => getUserTypesList());
};


const userTypesListforCreate = () => {
  return useQuery(["usertypes-create"], () => getUserTypesListForCreate(),{
    // staleTime: 30000,
    // keepPreviousData: true,
    refetchOnWindowFocus: true,
  });
};


const userCredentailQuery = {
  userCredentailList,
  userCredentailById,
  userTypesList,
  userTypesListforCreate
};
export default userCredentailQuery;
