import React from "react";
import { Navigate } from "react-router-dom";
import routes from "./Routes";

const Bouncer = () => {
  const [newRoutes, setNewRoutes] = React.useState([]);

  React.useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("userPermissions"));
    let filteredRoutes = [];
    if (permissions) {
      permissions = permissions.map((item) => item.id);
      permissions && permissions.push(0);
      filteredRoutes = routes.filter((item) => permissions.includes(item.id));
    }
    setNewRoutes([...filteredRoutes]);
  }, []);


  return <Navigate to="/administrator/dealers" />;
};

export default Bouncer;
