import React, { useState } from "react";
import { Box, Card, FormControl, MenuItem, Select } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import ChartHeader from "./ChartHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import UpArrowGreen from "../../utilitis/icons/UpArrowGreen";


const customStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "700",
      color: "#444445",
    },
  },
}));

const NumberOfOrdersChart = ({data}) => {
  const dashStyles = useStyles();




  const changeLegendTextColor = (value, entry) => {
    return (
      <span
        style={{
          color: "#252F40",
          fontFamily: "Montserrat",
          fontWeight: "400",
          fontSize: "14px",
          padding: "0 20px 0 6px",
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <Box>
      <Card className={dashStyles.ordersChartParent}>
        <ChartHeader
          header="Number Of Orders"
          subHeader="4% more this week"
          icon={<UpArrowGreen />}
        />
       
        <ResponsiveContainer width="100%" height="75%">
          <AreaChart
            data={data}
             
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#CB0C9F" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#252F40" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" axisLine={false} />
            <YAxis axisLine={false} />
            <Tooltip cursor={false} />
            <Legend
              align="center"
              verticalAlign="top"
              iconType="square"
              formatter={changeLegendTextColor}
              wrapperStyle={{ left: -50, top: -42 }}
            />
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#CB0C9F"
              fill="url(#colorPv)"
              name="Cancelled"
              strokeLinecap="round"
              strokeWidth={2}
            />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#252F40"
              fill="url(#colorUv)"
              name="Closed"
              strokeLinecap="round"
              strokeWidth={2}
            />
            <Area
              type="monotone"
              dataKey="pw"
              stroke="#F94144"
              fill="url(#colorUv)"
              name="Open"
              strokeLinecap="round"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    </Box>
  );
};

export default NumberOfOrdersChart;
