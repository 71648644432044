import { Paper, IconButton, Box, Typography } from "@material-ui/core";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { TXT_ADVISORYOFICER } from "../../utilitis/header";
import HeadBar from "../../components/HeadBar/HeadBar";
import orderStyles from "./orderStyles";
import styles from "../../sharedFeatures/sharedClasses";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import useTable from "../../sharedFeatures/useTable";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { BsSearch } from "react-icons/bs";
import InputField from "../../common/Controls/InputField";
import Controls from "../../common/Controls/Controls";
import HeadCells from "../../common/Headcells";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import "./order.css";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import CustomLoader from "../../features/CustomLoader";
import useAdvisor from "../../data/orderManegemnet/orderChangeQuery";
import { ToastContainer, toast } from "material-react-toastify";
import useDebounce from "../../utilitis/debounceSearch/useDebounce";
import { FindLink } from "../../sharedFeatures/FindLink";


const AssignToAdviser = () => {
  const orderStyle = orderStyles();
  const customStyle = styles();
  const customStylem = makeStyles();
  const inventory = InventoryManagmentStyle();
  const [search, setSearch] = useState("");
  const pathname = useLocation().pathname.split("/")[2];
  const searchUser = (e) => {
    setSearch(e.target.value);
  };
  const navigate = useNavigate();


  const { TblContainer, TblHead } = useTable(HeadCells.asignToAdvisorHead, 10);
  function createData(Status, name, usertype, location, cuslocation, action) {
    return { Status, name, usertype, location, cuslocation, action };
  }

 

  const notifyAssign = () => {
    toast.success(
      pathname==='reasigntoadvisor'?
      <span style={{ fontFamily: "Montserrat" }}>
        Advisor reassigned successfully.
      </span>:<span style={{ fontFamily: "Montserrat" }}>
        Advisor assigned successfully.
      </span>
    );
    


  };
  const debouncerSearch = useDebounce(search, 500);

  const qry = { page: 1, pageCount: 10, search: debouncerSearch };

  const {
    data: advisorList,
    isLoading:advisorListLoading,
    isError,
    isFetching,
  } = useOrderQuery.get_advisor_List(qry);

  const [checked, setChecked] = useState(false);
  const id = useParams();
  

  const handleChecked = (value) => {
    setChecked(value);
  };

  const {
    mutateAsync: assignAdvisor,
    data: advisorData,
    isSuccess: assignSucess,
    isLoading: assignLoading,
  } = useAdvisor.assignAdvisor();

  const handleAssignAdvisor = () => {
    const advisorData = {
      id: id.id,
      data: {
        advisor_id: checked,
      },
    };
    assignAdvisor(advisorData).then(()=>{
      notifyAssign();
      navigate(link)
    })
    
  };


   



  const isChecked = (itemId) => {
    if (itemId == id.adid && !checked) {
      return true;
    } else if (checked) {
      if (checked === itemId) {
        return true;
      }
    }
  };

  let link = FindLink("/administrator/orders")


  return (
    <>
      <Box>
        <Box sx={{ display: { xs: "none", md: "inline" } }}>
          <HeadBar title={TXT_ADVISORYOFICER} />
        </Box>
        <Box sx={{ display: { xs: "inline", md: "none" } }}>
          <HeadBar title="Assign to Advisor" />
        </Box>
        <div className={customStyle.divStyle}>
          <Paper className={customStyle.parentContainer} elevation="6">
            <Box
              sx={{ display: { xs: "none", md: "inline" } }}
              className={orderStyle.orderSubHeader}
            >
              <Link to={link}>
                <IconButton>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
              <Typography className={orderStyle.orderSubHeading}>
                {pathname === "reasigntoadvisor"
                  ? "Re-assign to Office Advisor"
                  : "Assign to Office Advisor"}
              </Typography>
            </Box>

            <div>
              {/* <Box className={orderStyle.modalStyle}> */}
              <div>
                <div className={customStylem.searchBoxParent}>
                  <InputField
                    value={search}
                    placeholder="Search office advisor"
                    className={inventory.searchBar}
                    size="small"
                    name="usersearch"
                    onChange={searchUser}
                    icon={<BsSearch />}
                  />
                </div>
                <Box className={customStylem.tableBox}>
                  <TblContainer>
                    <Table sx={{ minWidth: 750 }} aria-label="simple table">
                      <TblHead />

                      <TableBody>
                        {advisorListLoading ||isFetching ||assignLoading? (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <CustomLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          advisorList &&
                          advisorList.data &&
                          advisorList.data.data &&
                          advisorList.data.data.map((item) => {
                            if(item.status === 'Active'){
                              return ( <TableRow key={item.name}>
                                
                                <TableCell align="center">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleChecked(item.id)}
                                  >
                                    <Controls.radioButton
                                      value={"radio"}
                                      name={item.name}
                                      checked={isChecked(item.id)}
                                    />
                                    {item.first_name}
                                  </div>
                                </TableCell>
                           
                              </TableRow>)
                            }
                           
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TblContainer>
                </Box>
                {((advisorList &&
                  advisorList.data &&
                  advisorList.data.data &&
                  advisorList.data.data.length === 0) ||
                  isError) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "100px",
                    }}
                  >
                    <lottie-player
                      src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "300px", height: "300px" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                )}
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "30px",
                    gap: "20px",
                  }}
                >
                  <Grid item>
                    <div
                      className={customStylem.formIcon}
                      onClick={handleAssignAdvisor}
                    >
                      <IconButs
                        ibtname="Assign"
                        className={customStylem.formButtonAdd}
                        variant="outlined"
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <Link
                      to={link}
                      className={customStylem.formIcon}
                    >
                      <IconButs
                        ibtname="Cancel"
                        className={customStylem.formButtonAdd}
                        variant="outlined"
                      />
                    </Link>
                  </Grid>
                </Grid>
              </div>
              {/* </Box> */}
            </div>
          </Paper>
        </div>
      </Box>

      
    </>
  );
};

export default AssignToAdviser;
