
import DataService from "../../DataService/DataService";

const getCategoryList = async ({ searchQry, page, perPageItem }) => {
  // const url = `product/category/?page=${pageNo}`;
  let url = ``;
  if (searchQry && page && perPageItem) {
    url = `product/category/?search=${searchQry}&page_size=${perPageItem}&page=${page}`;
  } else if (searchQry && page) {
    url = `product/category/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItem) {
    url = `product/category/?search=${searchQry}&page_size=${perPageItem}`;
  } else if (perPageItem && page) {
    url = `product/category/?page_size=${perPageItem}&page=${page}`;
  } else if (page) {
    url = `product/category/?page=${page}`;
  } else if (perPageItem) {
    url = `product/category/?page_size=${perPageItem}`;
  } else {
    url = `product/category/`;
  }
  const data = await DataService.get(url);
  return data.data;
};

const getCatList_SelectBox = async (pageNo) => {
  const url = `product/getallcategory_with_without_brand/`;
  const data = await DataService.get(url);
  return data.data.data;
};

const addCategory = async (value) => {
  let url = "";
  let data = ``;
  if (value.parent_id) {
    url = `/product/subcategory/`;
    data = await DataService.post(url, value);
  } else {
    url = `/product/category/`;
    data = await DataService.post(url, value);
  }
  return data;
};

const editCategory = async (value) => {
  const { id, ...editData } = value;
  let data = "";
  let url = "";
  if (editData.parent_id) {
    url = `product/subcategory/${id}/`;
    data = await DataService.patch(url, editData);
  } else {
    url = `product/category/${id}/`;
    data = await DataService.put(url, editData);
  }
  return data;
};

const getCategoryData = async (value) => {

  const url = `/product/category/${value}/`;
  const data = await DataService.get(url);
  return data.data;
};

const getSubCategoryListByCategory = async (value) => {
  const url = `/product/subcatbycatadmin/${value}/`;
  const data = await DataService.get(url);
  
  return data.data;
};

const getSubcategoryData = async (id) => {
  const url = `/product/subcategory/${id}/`;
  const data = await DataService.get(url);
  return data.data;
};

const getProductList = async ({ searchQry, page, selected, perPageItems }) => {

  let url = ``;
  if (selected === "All" && searchQry && page && perPageItems) {
    url = `/product/product/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (selected === "All" && searchQry && page) {
    url = `/product/product/?search=${searchQry}&page=${page}`;
  } else if (selected === "All" && searchQry) {
    url = `/product/product/?search=${searchQry}`;
  } else if (selected === "All" && page && perPageItems) {
    url = `/product/product/?page_size=${perPageItems}&page=${page}`;
  } else if (selected === "All" && page) {
    url = `/product/product/?page=${page}`;
  } else if (selected === "All" && perPageItems) {
    url = `/product/product/?page_size=${page}`;
  } else if (selected && perPageItems && page && searchQry) {
    url = `/product/product/?search=${searchQry}&status=${selected}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && perPageItems && page) {
    url = `/product/product/?status=${selected}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && perPageItems) {
    url = `/product/product/?status=${selected}&page_size=${perPageItems}`;
  } else if (selected) {
    url = `/product/product/?status=${selected}`;
  } else if (selected && searchQry) {
    url = `/product/product/?search=${searchQry}&status=${selected}`;
  } else if (searchQry && perPageItems && page) {
    url = `/product/product/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `/product/product/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (searchQry && page) {
    url = `/product/product/?search=${searchQry}&page=${page}`;
  } else if (page) {
    url = `/product/product/?page=${page}`;
  } else if (page && perPageItems) {
    url = `/product/product/?page_size=${perPageItems}&page=${page}`;
  } else if (perPageItems) {
    url = `/product/product/?page_size=${perPageItems}`;
  } else {
    url = `/product/product/`;
  }
  const data = await DataService.get(url);
  return data.data;
};
const getBrandInventory = async () => {
  const url = `/product/brand/`;

  const data = await DataService.get(url);
  return data.data;
};

const getBrandList = async ({ searchQry, page, perPageItem }) => {
  let url = ``;
  if (searchQry && page && perPageItem) {
    url = `/product/brand/?search=${searchQry}&page_size=${perPageItem}&page=${page}`;
  } else if (searchQry && page) {
    url = `/product/brand/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItem) {
    url = `/product/brand/?search=${searchQry}&page_size=${perPageItem}`;
  } else if (page && perPageItem) {
    url = `/product/brand/?page_size=${perPageItem}&page=${page}`;
  } else if (perPageItem) {
    url = `/product/brand/?page_size=${perPageItem}`;
  } else if (page) {
    url = `/product/brand/?&page=${page}`;
  } else {
    url = `/product/brand/`;
  }

  const data = await DataService.get(url);
  return data.data;
};

const editBrand = async (value) => {
  const { id, ...editData } = value;
  const url = `product/brand/${id}/`;
  const data = await DataService.patch(url, editData);

  return data;
};

const getBrandData = async (value) => {
  const url = `/product/brand/${value}/`;
  const data = await DataService.get(url);
  return data.data;
};

const addBrand = async (value) => {
  const url = `/product/brand/`;
  const { ...state } = value;
  
  const data = await DataService.post(url, value);
  return data.data;
};

const addProduct = async (value) => {
  const url = "/product/product/";
  const { ...state } = value;
  const data = await DataService.post(url, value);
  return data;
};

const getProductListById = async (id) => {
  const url = `/product/product/${id}/`;
  const data = await DataService.get(url);
  return data.data;
};

const editProduct = async (value) => {
  const { id, ...editData } = value;
  const url = `/product/product/${id}/`;
  const data = await DataService.put(url, editData);
  return data;
};

const deleteProduct = async (id) => {
  const url = `/product/product/${id}/`;
  const data = await DataService.del(url);
};

const deleteSubCategory = async (value) => {
  const url = `/product/subcategory/${value}/`;
  const data = await DataService.del(url);
  return data;
};

const deleteBrand = async (value) => {
  const url = `/product/brand/${value}/`;

  const data = await DataService.del(url);
  return data;
};

const getBrandByCategory = async (id) => {
  let url = `/product/brandbycategory/?category_id=${id}`;
  const data = await DataService.get(url);
  return data.data;
};

const productImageAdd = async (imageData)=>{
    let url = `/product/upload_product_images/${imageData.id}/`
    const data = await DataService.post(url,imageData.image);
    return data.data;
}
export {
  getCategoryList,
  addCategory,
  getCatList_SelectBox,
  editCategory,
  getCategoryData,
  getProductList,
  getBrandList,
  editBrand,
  getBrandData,
  addBrand,
  addProduct,
  editProduct,
  getSubCategoryListByCategory,
  getSubcategoryData,
  deleteSubCategory,
  deleteBrand,
  getProductListById,
  deleteProduct,
  getBrandInventory,
  getBrandByCategory,
  productImageAdd
};
