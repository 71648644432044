import React, { useEffect, useState } from "react";
import "./Login.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Paper, Box, IconButton } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import makeStyles from "../../sharedFeatures/sharedClasses";
import useLoginQuery from "../../data/auth/useLoginQuery";
import { useForm, Form } from "../../sharedFeatures/useForm";
import Controls from "../../common/Controls/Controls";
import useStyles from "./LoginCss";
import KwikfixIcon from "../../Sidebar/KwikfixIcon";

const Login = () => {
  const customStyle = makeStyles();
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    setValues,
    handleInputChange,
    handleMessageBox,
    messageOpen,
    messageContent,
    resetMessage,
  } = useForm(initialValues);

  const classes = useStyles();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email =
        "email" in fieldValues
          ? (fieldValues.email ? "" : "This field is required.") ||
            (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              fieldValues.email
            )
              ? ""
              : "Email is not valid.")
          : "";
      setTimeout(clearError, 1500);
    } ///^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    return validate()
      ? await login({
          email: values.email,
          password: values.password,
        })
      : "";
  };

  const {
    mutateAsync: login,
    data,
    isLoading: loginLoading,
    isSuccess: addLoginSuccess,
    error,
    isError,
  } = useLoginQuery.login(values);

  useEffect(() => {
    if (error) {
      setErrors({
        genError:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail,
      });
    }
    setTimeout(clearError, 1500);
  }, [error]);

  const clearError = () => {
    setErrors({ email: "", password: "", genError: "" });
  };

  return (
    <div className={classes.loginParent}>
      <Grid>
        <Form onSubmit={handleLogin}>
          {messageOpen ? (
            <>
              <div>
                <Controls.MessageBox message={messageContent} />
              </div>
            </>
          ) : (
            ""
          )}
          <Paper elevation={10} className={classes.paperStyle}>
            <Grid align="center" fontSize="35px">
              <KwikfixIcon />
            </Grid>
            <Grid align="center" fontSize="35px">
              <h3 style={{ fontFamily: "Montserrat" }}>LOGIN</h3>
            </Grid>
            <div className="tf">
              {errors && errors.genError ? (
                <div className={customStyle.rectangle}>
                  <p className={customStyle.h1}>{errors.genError}</p>
                </div>
              ) : (
                ""
              )}
              <Controls.InputField
                style={{ marginRight: "27px" }}
                className={customStyle.inputTextBar}
                placeholder=" example@email.com"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                icon={
                  <IconButton style={{ pointerEvents: "none" }}>
                    <EmailIcon />
                  </IconButton>
                }
              />
              <Controls.InputField
                className={customStyle.inputTextBar}
                name="password"
                type={password ? "text" : "password"}
                value={values.password}
                placeholder="Password"
                onChange={handleInputChange}
                error={errors.password}
                icon={
                  password ? (
                    <IconButton onClick={() => setPassword(false)}>
                      <Visibility />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setPassword(true)}>
                      <VisibilityOff />
                    </IconButton>
                  )
                }
              />
            </div>
            <Box className={classes.loginForgotParent} sx={{ mt: 3, mb: 3 }}>
              <LoadingButton
                className={classes.loginButton}
                type="submit"
                variant="contained"
                loadingIndicator={
                  <CircularProgress color="inherit" size={16} />
                }
                // disabled={loginLoading}
                // loading={loginLoading}
              >
                LOGIN
              </LoadingButton>
            </Box>
            {/* <Grid align="center">
              <Typography>
                <Link to="/forgetpasword">Forgot Password?</Link>
              </Typography>
            </Grid> */}
          </Paper>
        </Form>
      </Grid>
    </div>
  );
};

export default Login;
