import { useQuery } from "react-query";
import { advidorList, multipleDealerList, ordersList, singleDealerList, singleOrder } from "./orderUrls";

const get_order_List = (data) => {
    return useQuery(
      ["getOrdersList",data],
      () => ordersList(data),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );
  };
const get_advisor_List = (data) => {
 
    return useQuery(
      ["getAdvisorList",data],
      () => advidorList(data),
      {
        // staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        
      }
    );
  };

  // const get_single_dealer_list = (qry) => {
  //   console.log(qry)
  //   // return useQuery(["singleDealer", qry], () => singleDealerList(qry), {
  //   //   staleTime: 30000,
  //   //   keepPreviousData: true,
  //   //   refetchOnWindowFocus: false,
  //   //   poll: 500,
  //   // });
  // }

  const get_single_dealer_list = (data) => {
    return useQuery(
      ["singleDealer",data],
      () => singleDealerList(data),
      {
        staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        
      }
    );
  };
  const get_multiple_dealer_list = (data) => {
 
    return useQuery(
      ["multipleDealer",data],
      () => multipleDealerList(data),
      {
        // staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        
      }
    );
  };
const get_order_Detials = (data) => {
 
    return useQuery(
      ["getAdvisorList",data],
      () => singleOrder(data),
      {
        staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        
      }
    );
  };


export const useOrderQuery = {
    get_order_List,
    get_advisor_List,
    get_order_Detials,
    get_single_dealer_list,
    get_multiple_dealer_list
    
}