import { GrStatusGoodSmall } from "react-icons/gr";
import DataService from "../../DataService/DataService";

const SalesReports = async ({
  searchQry,
  page,
  perPageItems,
  first_name,
  last_name,
}) => {
  let url = ``;
  if (searchQry && page && perPageItems && first_name && last_name) {
    // serach & fn , perpage & fn , fn
    url = `/reports/salesreport/?search=${searchQry}&page_size=${perPageItems}&first_name=${first_name}&last_name=${last_name}&page=1`;
  } else if (searchQry && first_name && last_name && page) {
    url = `/reports/salesreport/?search=${searchQry}&first_name=${first_name}&last_name=${last_name}&page=1`;
  } else if (page && perPageItems && first_name && last_name) {
    url = `/reports/salesreport/?page_size=${perPageItems}&first_name=${first_name}&last_name=${last_name}&page=1`;
  } else if (searchQry && page) {
    url = `/reports/salesreport/?search=${searchQry}&page=1`;
  } else if (searchQry && perPageItems) {
    url = `/reports/salesreport/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (page && perPageItems) {
    url = `/reports/salesreport/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `/reports/salesreport/?page=${page}`;
  } else if (perPageItems) {
    url = `/reports/salesreport/?page_size=${perPageItems}`;
  } else if (first_name !== "") {
    url = `/reports/salesreport/?first_name=${first_name}`;
  } else {
    url = `/reports/salesreport/?page=${page}`;
  }
  const data = await DataService.get(
    `/reports/salesreport/?page=${page}&${
      first_name ? "first_name=" + first_name + "&" : ""
    }${searchQry ? "search=" + searchQry + "&" : ""}${
      perPageItems ? "page_size=" + perPageItems : ""
    }`
  );
  return data.data;
};

//   const getAllReviews = async () => {
//     const url = "/product/review_rating/";
//     const data = await DataService.get(url);
//     return data.data;
//   };

const SalesPersonList = async () => {
  const url = "/reports/salespersonlist/";
  const data = await DataService.get(url);
  return data.data;
};

// const DealersListById = async (id) => {
//   const url = `/sales/dealerlistbysalesperson/${id}/`;
//   const data = await DataService.get(url);
//   return data.data;
// };

const DealersListById = async ({
  searchQry,
  page,
  perPageItems,
  id,
  status,
}) => {
  let url = ``;
  if (searchQry && page && perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (searchQry && page && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&status=${status}&page=${page}`;
  } else if (page && perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (searchQry && page && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&status=${status}&page=${page}`;
  } else if (page && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&page=${page}`;
  } else if (page && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?status=${status}&page=${page}`;
  } else if (perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (page) {
    url = `/sales/dealerlistbysalesperson/${id}/?page=${page}`;
  }

  const data = await DataService.get(url);

  return data.data;
};

export { SalesReports, SalesPersonList, DealersListById };
