import { useMutation, useQueryClient } from "react-query";
import { addBrand, deleteBrand, editBrand } from "./inventoryUrls";

const add_brand = () => {
  const queryClient = useQueryClient();
  return useMutation((brand) => addBrand(brand), {
    onMutate: async (newBrand) => {
      await queryClient.cancelQueries("brandList");

      const prev = queryClient.getQueryData("brandList");
      return {
        prev,
        newBrand,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries("brandList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("brandList", context.prev); //rollback the cache to the previous state
    },
  });
};

const edit_brand = () => {
  const queryClient = useQueryClient();
  return useMutation((brand) => editBrand(brand), {
    onMutate: async (brand) => {
      await queryClient.cancelQueries("brandList"); //cancel any in-flight or pending query to the `brand` key
      await queryClient.cancelQueries(["brandList", brand.id]);

      const prev = queryClient.getQueryData("brandList", brand.id);
      queryClient.setQueryData(["brandList", brand.id], brand); //add the new todo to the data
      // also update the whole list to enable smooth navigation
      queryClient.setQueryData("brand", (old = []) => {
        
        const index = old.findIndex((item) => item.id === brand.id);
        return [
          ...old.slice(0, index),
          brand,
          ...old.slice(index + 1, old.length),
        ];
      });
      return {
        prev,
        brand,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries("brandList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("brandList", context.prev); //rollback the cache to the previous state
    },
  });
};

const toggleActive = () => {
  const queryClient = useQueryClient();
  return useMutation((value) => editBrand({ id: value, status: true }), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("brandList");
      const prev = queryClient.getQueryData("brandList");
     
      return {
        prev,
        todo,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries("brandList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("brandList", context.prev); //rollback the cache to the previous state
      return err
    },
  });
};

const del_brand = () => {
  const queryClient = useQueryClient();
  return useMutation((todoId) => deleteBrand(todoId), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("brandList");
      const prev = queryClient.getQueryData("brandList");
     
      return {
        prev,
        todo,
      };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("brandList", context.prev);
      return err
    },
    onSuccess: () => {
      queryClient.invalidateQueries("brandList");
    },
  });
};
const useAddBrand = {
  add_brand,
  edit_brand,
  del_brand,
  toggleActive,
};

export default useAddBrand;
