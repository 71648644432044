import React from "react";
import SmallIcon from "../SmallIcon";
import "../Sidebar.css";

const UseSmallAppName = () => {
  return (
    <>
      <div className="smallname">
        <SmallIcon />
      </div>
    </>
  );
};

export default UseSmallAppName;
