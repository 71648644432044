import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useLoginQuery from "../../../data/auth/useLoginQuery";
import { AppRoutes, EndPoint } from "../../../config/config";

const Logout = () => {
  const navigate = useNavigate();
  const refreshToken = localStorage.getItem("refreshToken");

  const { mutateAsync: logout, data } = useLoginQuery.logout(refreshToken);

  useEffect(() => {
    logout({ refresh: refreshToken });
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.status_code) {
      navigate(AppRoutes.AUTH + EndPoint.LOGIN);
    }
  }, [data]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <p style={{ color: "#E94141", fontWeight: "800" }}>Logging out....</p>
    </Box>
  );
};

export default Logout;
