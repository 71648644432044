import { useQuery } from "react-query";
import { SalesReports,SalesPersonList , DealersListById} from "./reportUrls";

const getReportsList = (qry) => {
  return useQuery(["reportslist", qry], () => SalesReports(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};


const getSalesPersonList = () =>{
    return useQuery(["reportssalespersonlist"], () => SalesPersonList(), {
        staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        poll: 500,
      });
}

const getDealersListById = (id) =>{
    return useQuery(["reportdealersListbyid",id],()=>DealersListById(id),{
     
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // poll: 500,
    })
}



const reportQuery = {
    getReportsList,
    getSalesPersonList,getDealersListById
};

export default reportQuery;
