import React from 'react'

const PlusIcon = ({width,height}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    
    viewBox="0,0,256,256"
    width={width}
    height={height}
    fillRule="nonzero"
  >
    <g
      fill="#b4b4b4"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeLiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"

    >
      <g transform="scale(2,2)">
        <path d="M64,6.05078c-14.85,0 -29.7,5.64922 -41,16.94922c-22.6,22.6 -22.6,59.4 0,82c11.3,11.3 26.2,17 41,17c14.8,0 29.7,-5.7 41,-17c22.6,-22.6 22.6,-59.4 0,-82c-11.3,-11.3 -26.15,-16.94922 -41,-16.94922zM64,12c13.3,0 26.60078,5.09922 36.80078,15.19922c20.2,20.3 20.2,53.30156 0,73.60156c-20.3,20.3 -53.3,20.3 -73.5,0c-20.3,-20.3 -20.30156,-53.30156 -0.10156,-73.60156c10.2,-10.1 23.50078,-15.19922 36.80078,-15.19922zM64,42c-1.7,0 -3,1.3 -3,3v16h-16c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h16v16c0,1.7 1.3,3 3,3c1.7,0 3,-1.3 3,-3v-16h16c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3h-16v-16c0,-1.7 -1.3,-3 -3,-3z"></path>
      </g>
    </g>
  </svg>
  )
}

export default PlusIcon