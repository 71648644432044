import Geocode from 'react-geocode'

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);

const geocodeLocation = async (latitude, longitude) => {
  try {
    const response = await Geocode.fromLatLng(latitude, longitude);
    if (response.status === 'OK') {
      const address = response.results[0].formatted_address;
      return address;
    } else {
      return 'Location not found';
    }
  } catch (error) {
    return 'Error retrieving location';
  }
};

export default geocodeLocation;
