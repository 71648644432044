import DataService from "../../DataService/DataService";

const userCredentialDeatils = async ({
  searchQry,
  page,
  perPageItems,
  filter,
}) => {

  let url = ``;
  if (searchQry && page && perPageItems && filter) {
    url = `auth/usercredential/?search=${searchQry}&page_size=${perPageItems}&page=${page}&groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }`;
  } else if (page && perPageItems && filter) {
    url = `auth/usercredential/?page_size=${perPageItems}&page=${page}&groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }`;
  } else if (searchQry && page) {
    url = `auth/usercredential/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `auth/usercredential/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (searchQry && filter) {
    url = `auth/usercredential/?search=${searchQry}&groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }`;
  } else if (page && perPageItems) {
    url = `auth/usercredential/?page_size=${perPageItems}&page=${page}`;
  } else if (filter && perPageItems) {
    url = `auth/usercredential/?page_size=${perPageItems}&groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }`;
  } else if (page && filter) {
    url = `auth/usercredential/?groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }&page=${page}`;
  } else if (page) {
    url = `auth/usercredential/?page=${page}`;
  } else if (perPageItems) {
    url = `auth/usercredential/?page_size=${perPageItems}`;
  } else if (filter) {
    url = `auth/usercredential/?groups__name=${
      filter === "ALL" ? "" : filter === "All" ? "" : filter
    }`;
  } else {
    url = `auth/usercredential/?page=${page}`;
  }
  const data = await DataService.get(url);
  return data.data;
};

const addUserCredential = async (value) => {
  const url = `auth/usercredential/`;
  const data = await DataService.post(url, value);
  return data;
};

const resetUserCredential = async ({ id, payload }) => {
  const url = `/auth/update_password/${id}/`;
  const data = await DataService.patch(url, payload);
  return data;
};

const getUserCredentialById = async (id) => {
  const url = `auth/usercredential/${id}/`;
  const data = await DataService.get(url);
 
  return data;
};

const getUserTypesList = async () => {
  const url = `auth/all-usertypes/`;
  const data = await DataService.get(url);
  return data;
};

const getUserTypesListForCreate = async () => {
  const url = `auth/usertypes/`;
  const data = await DataService.get(url);
  return data;
};

const updateUserCredentialStatus = async (id) => {
  const url = `auth/usercredentials_statuschange/${id}/`;
  const data = await DataService.patch(url);
  return data;
};

export {
  userCredentialDeatils,
  addUserCredential,
  getUserCredentialById,
  resetUserCredential,
  getUserTypesList,
  updateUserCredentialStatus,
  getUserTypesListForCreate
};
