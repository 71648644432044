import { useEffect, useState } from 'react';

const PageCounter = (data) => {
  const [totalCount, setTotalCount] = useState(0);
  
  useEffect(() => {
    setTotalCount(data)
  }, [data])

  return { totalCount };
}

export default PageCounter;
