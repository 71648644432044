import React from "react";
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    return <Navigate to="/auth/login" />;
  }
  return children;
};

export default ProtectedRoutes;
