import * as React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import "./multipleSelectBox.css";
import { FormControl, FormHelperText } from "@material-ui/core";
import { useState } from "react";
import { Button } from "antd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       // width: 250,
//     },
//   },
// };

const MultipleSelectCheckmarks = ({
  name,
  onChange,
  value,
  label,
  options,
  className,
  error,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  return (
    <FormControl
      state={value}
      variant="outlined"
      style={{ width: "100%" }}
      // className={classes.formControl}
      {...(error && { error: true })}
    >
      <Select
        className={className}
        multiple
        value={value}
        onChange={(e,obj) => {
          if(e.target.value.filter(e=>e===undefined).length > 0){
            return false
          }else{
            onChange(e,obj);
          }
   
        }}
        option={options}
        style={{ position: "relative" }}
        SelectDisplayProps={{}}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        MenuProps={{
          getContentAnchorEl: null,
        }}
        error={error}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            { selected !==undefined && selected.map((value) => {
              if (value !== undefined && value !== null) {
                return (
                  <Chip
                    style={{ marginBottom: "4px" }}
                    key={value}
                    label={
                      <span
                        style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                      >
                        {value}
                      </span>
                    }
                  />
                );
              }
            })}
          </Box>
        )}
      >
        {options &&
          options.map((item) => (
            <MenuItem key={item.id} id={item.id} value={item.name}>
              <Checkbox
                checked={value && value.indexOf(item.name) > -1}
                value={item.id}
                name={item.name}
              />
              <ListItemText
                primary={
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    {item.name}
                  </span>
                }
              />
            </MenuItem>
          ))}
        <div>
          <button role="button" className="ok" onClick={() => setOpen(false)}>
            Ok
          </button>
        </div>
      </Select>
      {error && (
        <FormHelperText style={{ fontFamily: "Montserrat" }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MultipleSelectCheckmarks;
