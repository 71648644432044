import { useQuery } from 'react-query'
import { getCategoryData, getSubcategoryData } from "./inventoryUrls"

function useCategoryList(id) {
  return useQuery(['categoryData', id], () =>  getCategoryData(id))
}

function useSubcategoryList(id) {
  return useQuery(['categoryData', id], () =>  getSubcategoryData(id),{
  })
}

const useCategoryData = {
  useCategoryList,
  useSubcategoryList,
}

export default useCategoryData