import DataService from "../../DataService/DataService";

const walletDetails = async ({ searchQry, page, perPageItems }) => {
    let url = ``;
    if (searchQry && page && perPageItems) {
      url = `customer/wallet/?search=${searchQry}&page_size=${perPageItems}&page=1`;
    } else if (searchQry && page) {
      url = `customer/wallet/?search=${searchQry}&page=1`;
    } else if (searchQry && perPageItems) {
      url = `customer/wallet/?search=${searchQry}&page_size=${perPageItems}`;
    } else if (page && perPageItems) {
      url = `customer/wallet/?page_size=${perPageItems}&page=${page}`;
    } else if (page) {
      url = `customer/wallet/?page=${page}`;
    } else if (perPageItems) {
      url = `customer/wallet/?page_size=${perPageItems}`;
    } else {
      url = `customer/wallet/?page=${page}`;
    }
    const data = await DataService.get(url);
    return data.data;
  };


//   const getAllReviews = async () => {
//     const url = "/customer/wallet/";
//     const data = await DataService.get(url);
//     return data.data;
//   };


  export   {
    walletDetails,
    // getAllReviews
  }