import React from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme, state) => ({
  dialog: {
    "&& .MuiPaper-root.MuiDialog-paper": {
      padding: "20px",
    },
  },
  customBtn: {
    background: "#FEDD17 !important",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "bold !important",
    fontSize: "13px",
  },
}));

export default function ComigSoonDialog({
  open,
  setOpen,
  title,
  content,
  handleClose,
}) {
  const customStyle = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={customStyle.dialog}
    >
      <DialogTitle title={title} />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontFamily: "Montserrat", fontSize: "14px" }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
