import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    errorParent: {
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    errorCard: {
        backgroundColor: '#fff', 
        width: '750px', 
        borderRadius: '12px !important', 
        padding: '20px', 
        boxShadow: '2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important'
    },
    fourzerofour: {
        textAlign: 'center', 
        fontFamily: 'Montserrat', 
        fontSize: '75px', 
        fontWeight: '800'
    },
    errorNote: {
        textAlign: 'center', 
        fontFamily: 'Montserrat', 
        fontSize: '45px', 
        fontWeight: '500', 
        marginTop: '-50px', 
        color: '#E94141'
    },
    errorMsg: {
        textAlign: 'center', 
        fontFamily: 'Montserrat', 
        fontSize: '17px'
    },
    homeButtonParent: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center'
    },
    homeButton: {
        backgroundColor: '#FEDD17', 
        width: '150px', 
        fontWeight: '700', 
        fontFamily: 'Montserrat',
        '&:hover': {
            backgroundColor: '#FEDD17'
        }
    },
})

export default useStyles