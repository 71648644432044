import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import {
  TXT_USERCREDENTIALMANAGEMENT,
  ICON_BUTTON_USERCREDENTIALMANAGEMENT,
} from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import UserCredentialForm from "./UserCredentialAdd";
import { AiOutlinePlus } from "react-icons/ai";
import Controls from "../../common/Controls/Controls";
import { Link } from "react-router-dom";
import HeadBar from "../../components/HeadBar/HeadBar";
import DropDown from "../../common/Controls/ListingDropdown";
import { Pagination } from "@material-ui/lab";
import userCredentailQuery from "../../data/userCredential/useCredentialQuery";
import useAddUserCredential from "../../data/userCredential/useAddUserCredential";
import UserPasswordChange from "./UserPasswordChange";
import UserStatusChange from "./UserStatusChange";
import CustomLoader from "../../features/CustomLoader";
import financeStyles from "../FinanceManagement/financeStyles";
import userCredentialStyle from "./userCredentialStyle";
import PageCounter from "../../common/PageCounter";
import usePagination from "../../common/Controls/usePagination";
import PageDropDown from "../../common/Controls/PageDropDown";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { ToastContainer } from "material-react-toastify";
import userRoleQuery from "../../data/user role/useUserRoleQuery";

import "../DealerManagement/AddEditDealer/toast.css";

const UserCredential = () => {
  const styles = makeStyles();
  const [select, setSelect] = useState("");
  const [id, setId] = useState("");
  const [trySearch, setTrySearch] = useState("");
  const [pageItems, setPageItems] = useState(10);
  const [filter, setFilter] = useState("ALL");
  const [currentpageNo, setcurrentpageNo] = useState(1);

  const [roleList, setRoleList] = useState([]);

  const roleQry = {
    searchQry: trySearch,
    page: currentpageNo,
    perPageItems: 150,
  };
  const searchParams = new URLSearchParams(window.location.search);
  let page = searchParams.get("page");

  const { data: userRoleData, isLoading: rolesLoading } =
    userCredentailQuery.userTypesList();
  const qry = {
    searchQry: trySearch,
    page: page !== null ? page : currentpageNo,
    perPageItems: pageItems,
    filter: filter,
  };

  useEffect(()=>{
    setcurrentpageNo(1)
  },[trySearch,pageItems])
  useEffect(() => {
    if (
      userRoleData &&
      userRoleData.data &&
      userRoleData.data.data.length > 0
    ) {
      let data = userRoleData.data.data.map((item) => ({
        key: item.id,
        value: item.name,
      }));
      data.unshift({ key: "User Role", value: "All" });
      if (data && data.length > 0) {
        setRoleList([...data]);
      }
    }
  }, [userRoleData]);

  const financeStyle = financeStyles();

  const credentialStyle = userCredentialStyle();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  // modal for passwordchange

  const [passwordModal, setPasswordModal] = useState(false);

  const handlePassword = (id) => {
    setId(id);
    setPasswordModal(!passwordModal);
  };

  const handlePasswordClose = () => {
    setPasswordModal(false);
  };

  // modal for statuschange

  const [statusModal, setStatusModal] = useState(false);

  const handleStatusClose = () => {
    setStatusModal(false);
  };

  const {
    data: userCredentail,
    isLoading,
    refetch,
    isError,
    isFetching
  } = userCredentailQuery.userCredentailList(qry);

  const { mutate: updateStatus, isLoading: statusUpdateLoading  } =
    useAddUserCredential.update_usercredential_status();

  const { totalCount } = PageCounter(userCredentail && userCredentail.count);

  const { TblContainer, TblHead } = useTable(
    HeadCells.userCredentialHead,
    totalCount
  );

  const { currentData, currentPage, maxPage, setSearchParams } = usePagination(
    userCredentail !== undefined && userCredentail,
    pageItems
  );

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  //if last page and perpage changes go to first page
  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    setcurrentpageNo(1);
  }, [trySearch]);

  const handleFilter = (event) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setFilter(event.target.value);
  };

  return (
    <>
      <HeadBar title={TXT_USERCREDENTIALMANAGEMENT} />
      <div className={styles.listdivStyle}>
        <div className={styles.searchBoxParent}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Controls.StyledSearch
                placeholder="Search"
                className={credentialStyle.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  setTrySearch(e.target.value);
                }}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Typography className={credentialStyle.label}>
                    Filter By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <DropDown
                    value={filter}
                    handlecreateNewItem={handleFilter}
                    items={roleList}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", md: "end" },
                }}
              >
                <IconButs
                  ibtname={ICON_BUTTON_USERCREDENTIALMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={styles.salesButton}
                  onClick={handleClick}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {(isLoading || statusUpdateLoading||isFetching) ?(
                <TableRow>
                  <TableCell colSpan={8}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              ):
              <>
             { !statusUpdateLoading &&
                userCredentail &&
                userCredentail.data.map((item) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{item.first_name }  {item.last_name}</TableCell>
                      <TableCell align="center">
                        {item.groups[0] && item.groups[0].name}
                      </TableCell>
                      <TableCell align="center">{item.phone}</TableCell>
                      <TableCell align="center">{item.email}</TableCell>
                      {/* <TableCell align="center">{item.username}</TableCell> */}
                      <TableCell align="center">
                        <Link
                          to=""
                          className={styles.linkColor}
                          onClick={() => handlePassword(item.id)}
                        >
                          Reset
                        </Link>
                      </TableCell>
                      <TableCell align="center">{item.status}</TableCell>
                      <TableCell align="center">
                        <IconButton>
                          {item.status === "Active" ? (
                            <BsToggleOn
                              onClick={async () => await updateStatus(item.id)}
                              className="user-icons"
                            />
                          ) : (
                            <BsToggleOff
                              onClick={async () => await updateStatus(item.id)}
                              className="user-icons"
                            />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}</>}
            </TableBody>
          </TblContainer>
        </Box>

        {((userCredentail && userCredentail.data.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}

        <Box className={financeStyle.pagination}>
          <Box className={financeStyle.paginationStyle}>
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 3, value: 30 },
              ]}
              handlePageItems={handlePageItems}
            />
            <span
              className={financeStyle.paginationPage}
              style={{ marginLeft: "13px" }}
            >
              Per Page
            </span>
          </Box>
          <Pagination
            count={maxPage}
            page={Number(page)||currentPage}
            onChange={currentData}
            variant="outlined"
            className={financeStyle.paginationFont}
          />
        </Box>

        {open ? (
          <UserCredentialForm
            open={open}
            setOpen={setOpen}
            handleClose={handleClick}
          />
        ) : null}

        {passwordModal ? (
          <UserPasswordChange
            open={passwordModal}
            handleClose={handlePasswordClose}
            id={id}
          />
        ) : null}

        {statusModal ? (
          <UserStatusChange
            open={statusModal}
            handleClose={handleStatusClose}
            id={id}
          />
        ) : null}
      </div>
      {/* <ToastContainer position="top-right" /> */}
    </>
  );
};

export default UserCredential;
