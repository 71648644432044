import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Grid,
} from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { TXT_DEALER_DETAIL } from "../../utilitis/header";
import Sub from "../../common/Subheader/Sub";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData(
    "Virat Kohli",
    "MRF/CEAT/APPOLO",
    "Delhi",
    "Availiable",
    "Click here"
  ),
  createData(
    "Rohith Sharma",
    "CEAT",
    "Navi Mumbai",
    "Availiable",
    "Click here"
  ),
  createData("Ms Dhoni", "MRF", "Navi Mumbai", "Availiable", "Click here"),
  createData("Yuvraj", "CEAT", "Delhi", "Availiable", "Click here"),
  createData("S Raina", "MRF", "Mumbai", "Availiable", "Click here"),
];

const DealerInventoryDetail = () => {
  const styles = makeStyles();
  
  return (
    <>
      <Paper className={styles.parentContainerOne} elevation="6">
        <div className={styles.headerParent}>
          <Link to="/administrator/dealerDetails/${item.sl_no}">
            <MdArrowBackIosNew className={styles.backButton} />
          </Link>
          <Sub data={TXT_DEALER_DETAIL} />
        </div>

        <Grid>
          <TableContainer sx={{ minWidth: 850 }} >
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dealer Name</TableCell>
                  <TableCell align="right">Brands</TableCell>
                  <TableCell align="right">Location</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </>
  );
};

export default DealerInventoryDetail;
