import React, { useState } from "react";
import useStyles from "./dashboardStyles";
import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";
import { useactiveCustomer } from "../../data/dashboard/dashboardQuery";
import { keyConverter } from "./utils/utils";

const customStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiSelect-select": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: "#444445",
    },
  },
}));


const AppDownloadStats = ({ filter }) => {
  const dashStyles = useStyles();
  const classes = customStyles();
  const [duration, setDuration] = useState("week");

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const { data } = useactiveCustomer({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });

  const activeCustomer =
    data &&
    data.data &&
    data.data.data &&
    data.data.data.map((e) => {
      return {
        name: keyConverter(e, filter),
        pv: e["y-axis"].count,
      };
    });
  return (
    <Box className={dashStyles.appDownloadStatsParent}>
      <h6 className={dashStyles.appDownloadStatsTitle}> Active Customers</h6>
      <Box className={dashStyles.appDownloadStatsSelectParent}>
        <Box>
          <p className={dashStyles.appDownloadStatsSelectTitle}>
            {data && data.data && data.data.total}
          </p>
        </Box>
      </Box>
      <Box className={dashStyles.appDownloadStatsChart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={activeCustomer}
            margin={{
              top: 22,
              right: 22,
              left: -15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              style={{
                fontSize: "10px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                color: "#444445",
              }}
            />
            <YAxis
              axisLine={false}
            
              style={{
                fontSize: "10px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                color: "#444445",
              }}
            />
            <Tooltip cursor={false} />
            <Line
              type="monotone"
              dataKey="pv"
              name="Customers"
              dot={false}
              stroke="#E94141"
              strokeWidth={3}
              activeDot={{ fill: "#FDC727", stroke: "#F39800", r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default AppDownloadStats;
