import { useQuery } from 'react-query'
import { getBrandData } from "./inventoryUrls"

export default function useBrandList(id) {
  return useQuery(['brandData', id], () =>  getBrandData(id), {
    // staleTime: 30000,
    // keepPreviousData: false,
    // refetchOnWindowFocus: false,
    // poll: 500,
    // //refetchOnMount: "always",
  })
}