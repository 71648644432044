import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import useStyles from "./MessageStyle";
import { Box } from "@mui/system";

// const AlertFn = React.forwardRef(function AlertFn(props, ref) {
//   return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const MessageBox = ({ message }) => {
 
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box sx={{ marginTop: { xs: "220px", md: "0px" } }}>
        <Snackbar
          sx={{ marginTop: { xs: "220px", md: "0px" } }}
          bodyStyle={{ height: 200, width: 200, flexGrow: 0 }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.alert,
          }}
          message={
            <span id="message-id">
              <div>{message}</div>
            </span>
          }
        >
          {/* <Alert onClose={handleClose}>{message}</Alert> */}
        </Snackbar>
      </Box>
    </>
  );
};

export default MessageBox;
