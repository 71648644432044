import React from 'react'
import { BsBell } from 'react-icons/bs'

const BellIcon = ({size, color}) => {
    return (
        <div>
            <BsBell size={size} color={color} />
        </div>
    )
}

export default BellIcon