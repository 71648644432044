import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiInputBase-root": {
      color: "#D2D6DA",
      borderColor: "#D2D6DA",
      borderWidth: "1px",
      width: "100%",
      borderStyle: "solid",
      borderRadius: "10px",
      height: "43px",
      justifyContent: "center",
      backgroundColor: "white",
      fontFamily: "Montserrat !important",
      "& :hover": {
        border: "none",
      },
    },

    "& :hover": {
      border: "1px solid #FEDD17",
    },

    // "& :active": {
    //   border: "2px solid yellow",
    // },

    "& .MuiSelect-select.MuiSelect-select": {
      color: "#67748E",
      fontWeight: "lighter",
      fontSize: "12px",
      width: "100%",
      textAlign: "start",
      fontStyle: "Normal",
      fontFamily: "Montserrat",
      paddingLeft: "10px",
    },
  },
  select: {
    width: "100%",
    fontSize: "12px",
    fontFamily: "Montserrat !important",

    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    paddingRight: "10px",
    top: "-1px",
    color: "#67748E",
    fontFamily: "Montserrat !important",
    fontSize: "30px",
    fontWeight: 200,
    cursor: "pointer",
  },
  paper: {
    borderRadius: 10,
    marginTop: 8,
    padding: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
      fontFamily: "Montserrat !important",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#ffff",
    },
    "& li.Mui-selected:hover": {
      background: "#ffff",
    },
  },
}));

const DropDown = ({
  value,
  flag,
  handlecreateNewItem,
  items,
  label,
  className,
  onClick,
  ...others
}) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        flag={flag}
        onClick={onClick}
        onChange={handlecreateNewItem}
        className={className}
        disableUnderline
        displayEmpty
        renderValue={value !== "" ? () => value : () => others.placeholder ? others.placeholder :'Status'}
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
        ite
        {...others}
      >
        {items.map((item) => (
          <MenuItem key={ item.key} value={item.value}>
            { item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
