import React from 'react'
import makeStyles from '../../sharedFeatures/sharedClasses'

const Sub = (props) => {
    const subStyles = makeStyles()
    return (
        <>
            <div>
                <h2 className={subStyles.subHeader}>{props.data}</h2>
            </div>
        </>
    )
}

export default Sub