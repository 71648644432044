import IconButs from "./IconButs";
import InputField from "./InputField";
import SelectBox from "./SelectBox";
import EditSelectBox from "./EditSelecteBox";
import BrandSelectBox from "./BrandSelectBox";
import TextArea from "./TextArea";
import CheckBoxLabel from "./CheckBoxLabel";
import SearchBox from "./SearchBox";
import DatePicker from "./DatePickers";
import { CustomAddressInput, CustomInput } from "./CustomInputField";
import MessageBox from "./MessageBox";
import ExcelData from "./ExcelData";
import DocumentInputField from "./FileInput";
import MultipleSelectCheckmarks from "./MultilpleSelectBox";
import MultipleTwo from "./MultipleTwo";
import MultipleView from "./MultipleView";
import ChipInput from "./ChipInput";
import radioButton from "./RadioButtonBox";

import {
  CustomDocumentInput,
  CustomSearchInput,
  StyledInput,
  StyledSearch,
  FullWidthSearch,
  StyledLocation,
  StyledIconInput,
  DetailsInput,
} from "./InputField";
import CustomDateRange from "./CustomDateRange";

const Controls = {
  IconButs,
  InputField,
  SelectBox,
  TextArea,
  CheckBoxLabel,
  MultipleSelectCheckmarks,
  MultipleTwo,
  MultipleView,
  SearchBox,
  DatePicker,
  MessageBox,
  CustomInput,
  ExcelData,
  CustomAddressInput,
  DocumentInputField,
  CustomDocumentInput,
  CustomSearchInput,
  CustomDateRange,
  StyledInput,
  StyledSearch,
  FullWidthSearch,
  StyledLocation,
  DetailsInput,
  BrandSelectBox,
  ChipInput,
  radioButton,
  StyledIconInput,
  EditSelectBox,
};

export default Controls;
