import { useQuery } from "react-query";
import {
  getSalesManager,
  getSalesPersonById,
  getSalesPersonDetails,
  dealerListBySalesPerson,
} from "./salesUrls";

const salesPerson_list = (qry) => {
  return useQuery(["salesPersonList", qry], () => getSalesPersonDetails(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const dealerlist_bysalesperson = (id) => {
  return useQuery(["salesPersonList", id], () => dealerListBySalesPerson(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const salesManagers_list = () => {
  return useQuery(["salesManagerList"], () => getSalesManager());
};

const salesPerson_By_Id = (id) => {
  return useQuery(["salesPersonById", id], () => getSalesPersonById(id), {
    
    retry:false
  });
};

const useSalesPersonQuery = {
  salesPerson_list,
  salesManagers_list,
  salesPerson_By_Id,
  dealerlist_bysalesperson,
};

export default useSalesPersonQuery;
