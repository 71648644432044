import React, { useState, useEffect } from "react";
import { Grid, IconButton, Paper, Typography, Box } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { Link, useSearchParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import useList from "../../data/inventory/useList";
import useBrandData from "../../data/inventory/useBrandData";
import categoryBrandStyles from "./InventoryManagmentStyle";
import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
  TXT_BRAND,
  TXT_ADD_BRAND,
  TXT_EDIT_BRAND,
  EDIT_BRAND,
} from "../../utilitis/header";
import HeadBar from "../../components/HeadBar/HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import { useParams } from "react-router-dom";
import { useForm } from "../../sharedFeatures/useForm";
import useAddBrand from "../../data/inventory/useAddBrand";
import { useNavigate } from "react-router-dom";
import { AppRoutes, EndPoint } from "../../config/config";
import { toast, ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import "../DealerManagement/AddEditDealer/toast.css";

const AddBrand = () => {
  const navigate = useNavigate();
  const customStyle = makeStyles();
  const categoryStyle = categoryBrandStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [branddirty, setBrandDirty] = useState(false);
  const [searches, setSearches] = useState([]);
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [statusError, setStatusError] = useState("");

  //aimee
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");

  let link = "/administrator/brand";
  link = page !== null ? link + `?page=${page}` : link;
  link = search !== null ? link + `&search=${search}` : link;
  link = itemsno !== null ? link + `&itemsno=${itemsno}` : link;

  const notify = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Brand added Sucessfully.
      </span>
    );
  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Brand updated Successfully.
      </span>
    );
  const {
    mutateAsync: addBrand,
    data: addBrandData,
    isSuccess: addBrandSuccess,
  } = useAddBrand.add_brand();

  const {
    mutateAsync: editBrand,
    data: editBrandData,
    isSuccess: editBrandSuccess,
  } = useAddBrand.edit_brand();

  // useEffect(() => {
  //   if (addBrandData) {
  //     notify();
  //     // handleMessageBox("Brand Added Successfully");
  //     setTimeout(() => {
  //       resetMessage();
  //       // navigate(AppRoutes.ADMIN + EndPoint.BRAND_LIST);
  //       navigate(link)

  //     }, [1500]);
  //   }
  //   if (editBrandData) {
  //     notifyEdit();
  //     // handleMessageBox("Brand Edited Successfully");
  //     setTimeout(() => {
  //       resetMessage();
  //       // navigate(AppRoutes.ADMIN + EndPoint.BRAND_LIST);
  //       navigate(link)
  //     }, [1500]);
  //   }
  // }, [addBrandData, editBrandData]);

  const validate = (fieldValues = values) => {
    let temp = {};
    if ("category_id" in fieldValues)
      temp.category_id = fieldValues.category_id
        ? ""
        : "This field is required.";

    if (fieldValues.sub_category_id === undefined && searches.length > 0) {
      temp.sub_category_id = fieldValues.sub_category_id
        ? ""
        : "This field is required.";
    } else {
      temp.sub_category_id = fieldValues.sub_category_id ? "" : "";
    }

    if ("Name" in fieldValues) {
      temp.Name = fieldValues.name ? "" : "This field is required.";
    }
    if ("features" in fieldValues)
      temp.features = fieldValues.features ? "" : "This field is required.";
    if ("advantages" in fieldValues)
      temp.advantages = fieldValues.advantages ? "" : "This field is required.";
    if ("usp" in fieldValues)
      temp.usp = fieldValues.usp ? "" : "This field is required.";
    if ("country_of_origin" in fieldValues)
      temp.country_of_origin = fieldValues.country_of_origin
        ? ""
        : "This field is required.";
    // if ("status" in fieldValues)
    //   temp.status = fieldValues.status ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if ((fieldValues = values))
      return Object.values(temp).every((x) => x == "");
  };

  const submitData = async (e) => {
    e.preventDefault();

    let err = null;
    if (!values.category_id) {
      setCategoryError("Category type is required");
      err = true;
    }
    if (!values.Name) {
      setNameError("Name of the Brand is required");
      err = true;
    }
    if (!values.country_of_origin) {
      setCountryError("Country of Origin is required");
      err = true;
    }

    if (!err) {
      if (id) {
        await editBrand({
          id: id,
          name: values.Name,
          usp: values.usp,
          features: values.features,
          advantages: values.advantages,
          country_of_origin: values.country_of_origin,
          category_id: values.sub_category_id
            ? values.sub_category_id
            : values.category_id,
          status: values.status,
        })
          .then((res) => {
            if (res && res.status === 200) {
              toast.success("Brand edited");
            }
            navigate("/administrator/brand");
          })
          .catch((err) => {
            if (err && err.response && err.response.status !== 403) {
              toast.error("Something went wrong");
            }
          });
      } else {
         addBrand({
          name: values.Name,
          usp: values.usp,
          features: values.features,
          advantages: values.advantages,
          country_of_origin: values.country_of_origin,
          status: values.status ? values.status : false,
          category_id: values.sub_category_id
            ? values.sub_category_id
            : values.category_id,
          //sub_category_id: values.sub_category_id ? values.sub_category_id : 0,
        })
          .then((res) => {
            if (res && res.status === 200) {
              toast.success("Brand added");
            }
            navigate("/administrator/brand");
          })
          .catch((err) => {
            if (err && err.response && err.response.status !== 403) {
              toast.error("Something went wrong");
            }
          });
      }
    }
  };

  const handleInputChangeBrand = (e) => {
    setSearches([]);
    setBrandDirty(true);

    const newItems = { ...values };
    if (newItems.sub_category_id) {
      if (delete newItems.sub_category_id) {
        // or whichever key you want
        setValues(newItems);
      }
    }

    const { name, value } = e.target;

    selectCatList.map((item, id) => {
      if (item.parent_id === value) {
        setSearches((searches) => [...searches, item]);
      }
    });

    setValues({
      ...newItems,
      [name]: value,
      [id]: value,
    });

    setCategoryError("");
  };

  const handleInputChangeSubCategory = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      [id]: value,
    });
  };

  const { id } = useParams();

  const initialValues = {
    Name: "",
    usp: "",
    features: "",
    advantages: "",
    country_of_origin: "",
    category_id: "",
    sub_category_id: "",
    // status: true,
  };

  const {
    values,
    handleInputChange,
    resetForm,
    handleToggle,
    errors,
    setErrors,
    setValues,
    handleMessageBox,
    handleCheckBox,
    resetMessage,
    messageOpen,
    messageContent,
  } = useForm(initialValues);

  const { data: selectCatList } = useList.cat_List_SelectBox();
  const [limit, setLimit] = useState(false);

  const { data: brandData, isSuccess: brandDataSuccess } = isNaN(id)
    ? ""
    : useBrandData(id);

  useEffect(() => {
    setSearches([]);
    setValues({
      ...values,
      Name: brandData && brandData.name,
      usp: brandData && brandData.usp,
      features: brandData && brandData.features,
      advantages: brandData && brandData.advantages,
      country_of_origin: brandData && brandData.country_of_origin,
      category_id:
        brandData && brandData.category_id.parent_id
          ? brandData && brandData.category_id.parent_id
          : brandData && brandData.category_id.id,
      status: brandData && brandData.status,
      sub_category_id:
        brandData &&
        brandData.category_id.parent_id &&
        brandData.category_id.id,
    });

    if (selectCatList && brandData) {
      selectCatList.map((item, id) => {
        if (
          item.parent_id &&
          brandData.category_id.parent_id &&
          item.parent_id === brandData.category_id.parent_id
        ) {
          setSearches((searches) => [...searches, item]);
        }
      });
    }
  }, [brandData]);

  const handlePress = (event, value) => {
    let text = event.target.value;
    let lines = text.split("\n");
    if (event.which === 13 && lines.length > value) {
      setLimit(true);
    }
  };

  const handleClose = () => {
    // navigate(AppRoutes.ADMIN + EndPoint.BRAND_LIST);
    navigate(link);
  };

  const handleChange = (e) => {
    let targetName = e.target.name;

    let targetValue = e.target.value;
    if (targetName === "Name") {
      if (/^[a-z ,.'-]+$/i.test(targetValue)) {
        handleInputChange(e);
        setNameError("");
      } else {
        setValues({
          ...values,
          Name: targetValue.substring(0, targetValue.length - 1),
        });
      }
    }
    if (targetName === "country_of_origin") {
      handleInputChange(e);
      setCountryError("");
    }
    if (targetName === "status") {
      handleCheckBox(e);
      setStatusError("");
    }
  };
  return (
    <>
      <HeadBar title={TXT_BRAND} />
      <div className={customStyle.divStyle}>
        <Paper className={categoryStyle.parentContainer}>
          <div className={customStyle.searchBoxParentOne}>
            <div
              className={customStyle.subHeaderParent}
              style={{ width: "100%" }}
            >
              <Box sx={{ display: { xs: "none", md: "inline" } }}>
                <Link to={link}>
                  <IconButton className={customStyle.backButtonParent}>
                    <BackbuttonIcon />
                  </IconButton>
                </Link>
              </Box>
              <Typography className={categoryStyle.subHeader}>
                {id ? TXT_EDIT_BRAND : TXT_ADD_BRAND}
              </Typography>
            </div>

            <div
              style={{ width: "100%" }}
              // className={customStyle.headerParentOne}
            >
              <Grid
                container
                alignItems="center"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                lg={9}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Choose a category
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.BrandSelectBox
                    className={categoryStyle.basicSelectBox}
                    name="category_id"
                    label="Category Type"
                    value={values.category_id}
                    options={selectCatList}
                    onChange={handleInputChangeBrand}
                    error={categoryError}
                  />
                </Grid>
                {searches.length > 0 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      className={categoryStyle.categoryLabel}
                    >
                      Choose a sub category
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Controls.SelectBox
                        className={categoryStyle.basicSelectBox}
                        name="sub_category_id"
                        label="Sub Category Type"
                        value={values.sub_category_id}
                        options={searches}
                        onChange={handleInputChangeSubCategory}
                        error={errors.sub_category_id}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Name of the Brand
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    name="Name"
                    placeholder={"Name"}
                    value={values.Name}
                    onChange={handleChange}
                    error={nameError}
                    type="text"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  USP
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.TextArea
                    className={categoryStyle.textArea}
                    name="usp"
                    // minRows={5}
                    value={values.usp}
                    onChange={handleInputChange}
                    error={errors.usp}
                    maxLength="250"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Features
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.TextArea
                    className={categoryStyle.textArea}
                    name="features"
                    value={values.features}
                    onChange={handleInputChange}
                    error={errors.features}
                    onKeyPress={(event) => handlePress(event, 7)}
                    maxLength="250"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Advantages
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.TextArea
                    className={categoryStyle.textArea}
                    name="advantages"
                    value={values.advantages}
                    onChange={handleInputChange}
                    error={errors.advantages}
                    maxLength="250"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Country of Origin
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    name="country_of_origin"
                    value={values.country_of_origin}
                    onChange={handleChange}
                    error={countryError}
                    type="text"
                    inputProps={{ maxLength: 25 }}
                    placeholder="Country of Origin"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={5}
                  className={categoryStyle.categoryLabel}
                >
                  Status
                </Grid>
                <Grid item xs={4} sm={7}>
                  <Controls.CheckBoxLabel
                    checked={values && values.status}
                    label="Active"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    className={categoryStyle.chechBox}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {statusError}
                  </span>
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} sm={6}>
                  <IconButs
                    ibtname={id ? EDIT_BRAND : FORM_BUTTON_MANAGEMENT}
                    className={categoryStyle.formButtonAdd}
                    onClick={submitData}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <IconButs
                    onClick={handleClose}
                    ibtname={FORMONE_BUTTON_MANAGEMENT}
                    className={categoryStyle.cancelButton}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          {/* <form>
            {messageOpen ? (
              <>
                <div>
                  <Controls.MessageBox message={messageContent} />
                </div>
              </>
            ) : (
              ""
            )}
          </form> */}
        </Paper>
      </div>
    </>
  );
};

export default AddBrand;
