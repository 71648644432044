import { useQuery } from "react-query";
import {
    // getAllReviews,
    walletDetails
} from "./walletUrls";

const getWalletList = (qry) => {
  return useQuery(["walletlist", qry], () => walletDetails(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};



const walletQuery = {
    getWalletList,
};

export default walletQuery;
