import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import salesStyle from "../SalesPersonManagement/salesStyle";
import "../SalesPersonManagement/Sales.css";
// import userRoleQuery from "../../data/user role/useUserRoleQuery";
import CustomLoader from "../../features/CustomLoader";

const Comment = ({ open, handleClose, title, commentText }) => {
  const customStyle = makeStyles();
  const salesStyles = salesStyle();

 


function stringStyler(inputString){
  const lines = inputString.split('\n');
  return lines.map( line => (
     <p style={{textAlign:'center'}}>{line}</p>
  ))
}


  return (
    <Modal open={open}>
      <div className="modalWrap" style={{width:'800px',height:'400px',fontFamily: "Montserrat"}}>
        <Box>
          <div
            className={customStyle.iconStyle}
            style={{
              backgroundColor: "#FEDD17",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              className={salesStyles.assignTitle}
              style={{ margin: "5px 0px" }}
            >
              {title}
            </Typography>
          </div>
         <div className="comment-box">
          <Typography style={{fontFamily: "Montserrat"}}>
            {stringStyler(commentText)}
          </Typography>
          <button className="comment-close-button" style={{fontFamily: "Montserrat"}}  onClick={handleClose}>Close</button>
         </div>
          {/* <IconButs
                          onClick={handleClose}
                          ibtname="OK"
                          // className={salesStyles.roleAddStyle2}
                        /> */}
         
        </Box>
      </div>
    </Modal>
  );
};

export default Comment;
