import React from "react";
import useStyles from "./dashboardStyles";
import { Box, Avatar } from "@material-ui/core";
import UserSelectDropdown from "./UserSelectDropdown";

const SelectUser = () => {
  const dashStyles = useStyles();
  return (
    <Box className={dashStyles.selectUserParent}>
      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      <UserSelectDropdown />
    </Box>
  );
};

export default SelectUser;
