import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const StyledTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `17px`,
        height: "50px",
        width: `350px`,
      },
    },
  },
})(TextField);

const CustomInput = ({
  label,
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,

}) => {
  return (
    <div>
      <StyledTextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={className}
        {...(error && { error: true, helperText: error })}
      />
    </div>
  );
};

const StyledAddressField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `17px`,
        height: "50px",
        width: `150px`,
      },
    },
  },
})(TextField);

const CustomAddressInput = ({
  label,
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  ...others
}) => {
  return (
    <div>
      <StyledAddressField
        id="outlined-basic"
        label={label}
        variant="outlined"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={className}
        {...(error && { error: true, helperText: error })}
      />
    </div>
  );
};

export { CustomInput, CustomAddressInput };
