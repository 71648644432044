import React from 'react'

const AppIcon = () => {
    return (
        <div>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4.42188" y="3.5" width="7" height="7" rx="1.5" stroke="#444445" />
                <rect x="4.42188" y="13.5" width="7" height="7" rx="1.5" stroke="#444445" />
                <rect x="14.4219" y="3.5" width="7" height="7" rx="1.5" stroke="#444445" />
                <rect x="14.4219" y="13.5" width="7" height="7" rx="1.5" stroke="#444445" />
            </svg>
        </div>
    )
}

export default AppIcon