import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      margin: "0px",
      backgroundColor: "white",
    },

    "& .MuiFormControl-root": {
      padding: "0px",
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined":
      {
        fontSize: "14px",
        fontFamily: "Montserrat",
        zIndex: "100 !important",
      },

    "& .MuiFormHelperText-root": {
      marginLeft: "-1px",
      fontFamily: "Montserrat",
    },
  },

  //placeholder style

  select: {
    fontSize: "14px",
    fontFamily: "Montserrat !important",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    // left: ".2rem",
    paddingRight: "10px",
    top: "-1px",
    color: "#67748E",
    fontFamily: "Montserrat !important",
    fontSize: "30px",
    fontWeight: 200,
    cursor: "pointer",
  },
  paper: {
    borderRadius: 10,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "14px",
      width: "100%",
      fontFamily: "Montserrat !important",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#ffff",
    },
    "& li.Mui-selected:hover": {
      background: "#ffff",
    },
  },
}));

const EditSelectBox = ({
  name,
  label,
  value,
  error = null,
  onChange,
  options,
  className,
  defaultValue,
  disabled,
  ...other
}) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl
      state={value}
      variant="outlined"
      className={classes.formControl}
      {...(error && { error: true })}
      disabled={disabled}
    >
      <InputLabel
        shrink
        style={{ zIndex: "999999" }}
        className={className}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <MuiSelect
        MenuProps={menuProps}
        className={className}
        label={label}
        notched
        name={name}
        value={value}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
        onChange={onChange}
        {...other}
        option={options}
        defaultValue={defaultValue}
        error={error}
      >
        {options &&
          options.map((item, id) => (
            <MenuItem key={id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export default EditSelectBox;
