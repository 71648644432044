import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    stepperFormTextBar: {
      "& ::placeholder": {
        fontSize: "14px !important",
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input": {
        height: "10px !important",
        fontFamily: "Montserrat !important",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        fontFamily: "Montserrat !important",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
        fontFamily: "Montserrat !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        fontFamily: "Montserrat !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "MuiInputBase-input MuiOutlinedInput-input": {
        height: "10px !important",
        fontFamily: "Montserrat !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `14px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      width: "100% !important",
      // fontFamily: "Montserrat !important",
      // fontStyle: "normal !important",
      // fontWeight: "400 !important",
      // fontSize: "14px !important",
      // lineHeight: "24px !important",
      // color: "#444445 !important",
      borderRadius: "6px !important",
      marginRight: "4px !important",

      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: "50px !important",
        marginBottom: "10px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
        "& input": {
          fontSize: "12px !important",
        },
      },
      [theme.breakpoints.down("xs")]: {
        // width: "46% !important",
        // height: "40px !important",
        // marginRight: "16px !important",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "21vw",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "18px !important",
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      margin: "30px 0px",

      // ["@media (max-width: 450px)"]: {
      //   width: "100%",
      //   height: "40px",
      //   margin: "10px 0 !important",
      //   fontFamily: "Montserrat !important",
      //   fontWeight: "400 !important",
      //   fontStyle: "normal",
      //   fontSize: "18px !important",
      //   lineHeight: "24px",
      //   padding: "12px 20px !important",
      //   fontSize: "15px !important",
      //   background: "#ffffff !important",
      //   border: "1px solid #D9D9D9",
      //   color: "#000000 !important",
      //   textTransform: "capitalize !important",
      //   borderRadius: "6px !important",
      // },
      [theme.breakpoints.down("sm")]: {
        width: "95% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "21vw",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "30px 0px",
        width: "100%",
      },
    },
    inputTextBar: {
      width: "21vw",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        height: "50px",
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
      },

      [theme.breakpoints.down("sm")]: {
        width: "90%",
        margin: "10px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0px 0px",

        "& input": {
          fontSize: "12px",
        },
      },
    },
    formSelectBox: {
      width: "21vw",
      borderRadius: "6px",
      height: "50px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    searchBoxParent: {
      // width: "78%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
      margin: "20px",
      [theme.breakpoints.down("xs")]: {
        margin: "20px",
      },
    },
    searchBar: {
      width: "310px",
      borderRadius: "20px",

      [theme.breakpoints.down("xs")]: {
        "& .MuiFormControl-root": {
          width: "100% !important",
        },
      },
      // "& .MuiFormControl-root":{
      //   ["@media (max-width: 450px)"]: {
      //     width:"100% !important"
      //   }
      // }
    },
    addressDetailsValue: {
      width: "310px",
      borderRadius: "20px",
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0px",
      },

      [theme.breakpoints.down("xs")]: {
        margin: "0px 0px",

        "& .MuiFormControl-root": {
          width: "100% !important",
        },
      },

      // "& .MuiFormControl-root":{
      //   ["@media (max-width: 450px)"]: {
      //     width:"100% !important"
      //   }
      // }
    },
    salesAddressParent: {
      paddingBlock: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "0px 10px",
      },
    },
    visitingCardDetails: {
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
    },
    visitingCardDetails2: {
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      marginInlineStart: "15px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    salesLabel: {
      // used in sales form
      padding: "10px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
        fontSize: "12px",
      },
    },
    detailsInput: {
      width: "100%",
      margin: "10px 0px",
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        height: "40px",
        borderRadius: "6px",
      },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
    },
    detailsInput2: {
      width: "100%",
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        height: "40px",
        borderRadius: "6px",
      },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
    },
    detailsLabel: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    cancelButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "100%",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important", // changed from 12 to 18px
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",

      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
    geolocation: {
      width: "21vw !important",
      height: "50px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "black !important",
      padding: "18.5px 14px !important",
      margin: "2px 0 !important",
      marginBottom: "20px !important",
      borderRadius: "6px !important",

      [theme.breakpoints.down("sm")]: {
        width: "90% !important",
        margin: "0px !important",
        marginBottom: "5px !important",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "8px !important",
        width: "100% !important",
      },
    },
    assignTitle: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
      textAlign: "center !important",
      lineHeight: "24px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    roleAddStyle: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "100%",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },
    },
    roleAddStyle2: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "100%",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "10px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },
    },
  })
);
