import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme, state) => ({
  dialog: {
    "&& .MuiPaper-root.MuiDialog-paper": {
      padding: "20px 0px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .css-qfso29-MuiTypography-root-MuiDialogContentText-root": {
        fontSize: "0.9rem",
      },
      "&& .MuiPaper-root.MuiDialog-paper": {
        padding: "20px 0px",
      },
    },
  },
  deleteBtn: {
    background: "#FEDD17 !important",
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: "bold !important",
    fontSize: "13px !important",
    color: "#000 !important",
  },
}));

export default function DialogBox({
  open,
  setOpen,
  content,
  handleClose,
  handleRemove,
  userId,
}) {
  
  const customStyle = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} className={customStyle.dialog}>
      <DialogContent>
        <DialogContentText
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            whiteSpace: "pre-line",
          }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => handleRemove(userId)}
          className={customStyle.deleteBtn}
          autoFocus
        >
          Ok
        </Button>
        <Button
          onClick={() => setOpen(false)}
          className={customStyle.deleteBtn}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
