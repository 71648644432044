import { Box, IconButton, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OrderManagmentStyle from "./OrderManagmentStyle";
import { Typography } from "antd";
import { AiOutlineClose } from "react-icons/ai";

const OrdersIMageViewer = ({ open, setOpen, data }) => {
  const orderStyle = OrderManagmentStyle();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setImages(Object.values(data));
    }
  }, [data]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={orderStyle.imageViewer}>
        <div className="image-container">
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={orderStyle.assignTitle}>
              Order images
            </Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton onClick={() => setOpen(false)}>
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>

          {images.length > 0 ? (
            <div className="image-section">
              {images.map((items, index) => {
                return (
                  <img
                    key={index}
                    src={`${process.env.REACT_APP_BASE_URL}${items}`}
                    alt="product"
                  />
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px",
                width: "100%",
              }}
            >
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                background="transparent"
                speed="1"
                style={{ width: "200px", height: "200px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OrdersIMageViewer;
