import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";


export default makeStyles((theme) =>
  createStyles({
    orderSubHeader: {
      display: "flex",
      alignItems: "center",
    },
    orderSubHeading: {
      fontSize: "16px !important",
      fontWeight: "700",
    },
    inputStyles: {
      width: "100%",
      borderRadius:'20px'
    },
    label: {
      padding: "5px 10px ",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 8px",
        fontSize: "12px",
      },
    },
    viewLabel: {
      padding: "5px 10px ",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 8px",
        fontSize: "12px",
      },
    },
    assignTitle: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
      textAlign: "center !important",
      lineHeight: "24px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    assignBtn: {
      padding: "10px 20px",
      border: "1px solid #D2D6DA",
      borderRadius: "5px",
      fontSize:'14px',
      color:'#F39800',
     
      textTransform: 'none'
    },

    assignedContainer:{
      width:'100%',
      height:'8rem',
      border:'1px solid #D9D9D9',
      borderRadius:'5px',
      padding:'10px',
      display:'flex',
      flexDirection:'column',
      gap:'10px',
      overflow:'scroll',
      "& div":{
        display:'flex',
        gap:'10px'
      },
      "& button":{
        border:'none',
        outline:'none',
        padding:'0',
        background:'none',
        cursor:'pointer',
        height:'15px',
        color:'#F39800',
        marginLeft:'15px',
        '&:active':{
          color:'#1890ff'
        },
      }

    },
    assignMultipleDealer :{
         display:'flex',
         flexDirection:'column',
         gap:'10x',
         justifyContent:'center',
         marginTop:'20px',
         '& .multiple-btn':{
          display:'flex',
          alignItems:'center',
          gap:'10px',
          fontWeight:'400'
         }
    },
      advisorModalWap:{
        position: 'absolute',
        top: "50%",
        left: "50%",
        width: '600px',
        height:' 650px',
        overflow: 'auto',
        fontFamily: "Montserrat",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        boxShadow: 24,
        borderRadius: "12px",
        overflow: 'hidden scroll',
        scrollbarWidth: 'none',
        ["@media (max-width: 1000px)"]: {
          width: "90%",
        },
        '&::-webkit-scrollbar':{
          width: '0 !important'
        },
        "& .loader":{
          height:' 100%',
          width: '100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            ["@media (max-width: 1000px)"]: {
              width: "90%",
            },
        }
      },
      statusModal:{
        position: 'absolute',
        top: "50%",
        left: "50%",
        width: '600px',
        height:' auto',
        overflow: 'auto',
        fontFamily: "Montserrat",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        boxShadow: 24,
        borderRadius: "12px",
        overflow: 'hidden scroll',
        scrollbarWidth: 'none',
        ["@media (max-width: 1000px)"]: {
          width: "90%",
        },
        '&::-webkit-scrollbar':{
          width: '0 !important'
        },
        "& .status-main-container":{
          height:'100%',
          width:"100%"
        }
      },
      statusSection:{
        width:'100%',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        padding:'1rem',
        "& .status-container":{
        
         
         
         "& .status-sub":{
           width:'100%',
           display:'flex',
           flexWrap:'wrap',
           gap:'20px',
           alignItems:'center',
           justifyContent:'center'
                    },
         "& .status":{
          display:'flex',
          minWidth:'9rem',
          justifyContent:'space-between',
          alignItems:'center',
          gap:".5rem",
          "& p":{
            marginBottom:0,
            fontSize:'16px',
            fontWeight:'600'

          }
         }
        }
      }
    
  })
);
