import * as React from "react";
import { CardMedia, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import makeStyles from "../../sharedFeatures/sharedClasses";
import CardContent from "@mui/material/CardContent";
import { FaImage } from "react-icons/fa";
import { Box } from "@material-ui/core";
import dealerStyle from "./dealerStyle";

const Cards = ({ img }) => {
  const styles = makeStyles();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const docFace = ["Front", "Back"];
  const [check, setCheck] = React.useState(false);

  React.useEffect(() => {
    if (Array.isArray(img)) {
      img.forEach((item) => {
        if (item && item.split("/")[1] === "shop_documents") {
          setCheck(true);
        } else {
          setCheck(false);
        }
      });
    } else {
      setCheck(false);
    }
  }, [img]);

 

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Card>
            <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
              <Box className={dealerStyle.cardStyle}>
                <Grid container spacing={2}>
                  {img ? (
                    Array.isArray(img) ? (
                      img.map((value, key) => {
                        if (img.length == 2 && value) {
                          return (
                            <Grid item sm={6}>
                              {check && (
                                <p className={styles.docTitle}>
                                  {docFace[key]}
                                </p>
                              )}
                              <CardMedia
                                component="img"
                                height="250px"
                                width="600px"
                                sx={{ objectFit: "fill" }}
                                image={`${baseURL}/${value}`}
                              />
                            </Grid>
                          );
                        } else if (img.length == 2 && !value) {
                          return (
                            <Grid item sm={6}>
                              <p
                                className={styles.docTitle}
                                style={{ textAlign: "center" }}
                              >
                                {docFace[key]}
                              </p>
                              <CardMedia
                                sx={{
                                  minWidth: {
                                    xs: "300px",
                                    md: "400px",
                                    lg: "100%",
                                  },
                                  height: "250px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FaImage size="30px" />
                                <p style={{ marginTop: "10px" }}>
                                  Image not uploaded!
                                </p>
                              </CardMedia>
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item sm={4}>
                              <CardMedia
                                component="img"
                                sx={{
                                  // minWidth: { xs: "300px", md: "900px" },
                                  height: "250px",
                                  objectFit: "fill",
                                  // display: "flex",
                                  // flexDirection: "column",
                                  // alignItems: "center",
                                  // justifyContent: "center",
                                }}
                                image={`${baseURL}/${value}`}
                              />
                            </Grid>
                          );
                        }
                      })
                    ) : (
                      <CardMedia
                        sx={{
                          minWidth: { xs: "300px", md: "800px", lg: "100%" },
                          height: "450px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          objectFit: "fill",
                        }}
                        component="img"
                        image={`${baseURL}/${img}`}
                      />
                    )
                  ) : (
                    <CardMedia
                      sx={{
                        minWidth: { xs: "300px", md: "500px", lg: "100%" },
                        height: "250px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaImage size="30px" />
                      <p style={{ marginTop: "10px" }}>Image not uploaded</p>
                    </CardMedia>
                  )}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* })
            } */}
    </>
  );
};

export default Cards;
