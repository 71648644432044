import React from "react";
import { Card, Box } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import InfoIButton from "../../utilitis/icons/InfoIButton";
import ChartDetailHeader from "./ChartDetailHeader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "Mon",
//     uv: 400,
//     pv: 240,
//     amt: 240,
//   },
//   {
//     name: "Tue",
//     uv: 300,
//     pv: 139,
//     amt: 221,
//   },
//   {
//     name: "Wed",
//     uv: 200,
//     pv: 980,
//     amt: 229,
//   },
//   {
//     name: "Thu",
//     uv: 278,
//     pv: 390,
//     amt: 200,
//   },
//   {
//     name: "Fri",
//     uv: 189,
//     pv: 480,
//     amt: 218,
//   },
//   {
//     name: "Sat",
//     uv: 239,
//     pv: 380,
//     amt: 250,
//   },
//   // {
//   //     name: "Sun",
//   //     uv: 349,
//   //     pv: 430,
//   //     amt: 210
//   // }
// ];

const OrderValueChart = ({data,product,total=0}) => {
  const dashStyles = useStyles();
  const changeLegendTextColor = (value, entry) => {
    return (
      <span
        style={{
          color: "#252F40",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          padding: "0 10px 0 5px",
        }}
      >
        {value}
      </span>
    );
  };
  const COLORS = ["#A155B9", "#F765A3", "#16BFD6", "#1DDD8D","#F94144"];
  return (
    <>
      <Card className={dashStyles.orderValueChartParent}>
        <ChartDetailHeader
          title="Order Value"
          stat={`INR ${total !== null ? total : 0 }`}
          update="Offer Products - Top Selling"
        />
        <Box className={dashStyles.iButtonParent}>
          <InfoIButton />
        </Box>
        <ResponsiveContainer width="100%" height="75%">
          <BarChart
            data={data && data}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 40,
            }}
            width={1000}
          >
            <CartesianGrid stroke="#ECECEC" />
            <XAxis dataKey="name" axisLine={false} />
            <YAxis axisLine={false} />
            <Tooltip  cursor={false} />
            <Legend
              formatter={changeLegendTextColor}
              wrapperStyle={{ bottom: 40 }}
            />

            {
              product&&product.map((e,i)=>{
                return(
                  <Bar
                  dataKey={i}
                  width={10}
                  
                  fill={COLORS[i % COLORS.length]}
                  name={
                    e.title !==null ? e.title : e.item_code
                  }
                />
                )
              })
            }
            {/* <Bar
              dataKey="pv"
              fill="#165BAA"
              name={
                'product1'
              }
            />
            <Bar
              dataKey="uv"
              fill="#A155B9"
              name={
               'product1'
              }
            /> */}
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default OrderValueChart;
