import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import HeadCells from "../../common/Headcells";
import {
  AiOutlineEye,
  AiOutlinePlus,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import {
  ICON_BUTTON_INVENTORYMANAGEMENT,
  TXT_INVENTORYMANAGEMENT,
} from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import { Link, useNavigate } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import InventoryManagmentStyle from "./InventoryManagmentStyle";
import { Pagination } from "@material-ui/lab";
import { AppRoutes, EndPoint } from "../../config/config";
import productQuery from "../../data/inventory/useProductList";
import DropDown from "../../common/Controls/ListingDropdown";
import financeStyles from "../FinanceManagement/financeStyles";
import DialogBox from "../../common/Controls/DialogBox";
import useAddProduct from "../../data/inventory/useAddProduct";
import CustomLoader from "../../features/CustomLoader";
import PageCounter from "../../common/PageCounter";
import usePagination from "../../common/Controls/usePagination";
import { useEffect } from "react";
import moment from "moment";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

import "../DealerManagement/AddEditDealer/toast.css";
import NavigateLink from "../../common/Controls/NavigateLink";

const InventoryManagement = () => {
  
  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const [search, setSearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);
  
  // const [search, setSearch] = useState();
  // const [currentpageNo, setcurrentpageNo] = useState(1);
  // const [pageItems, setPageItems] = useState(10);

  const [select, setSelect] = useState("");
  const [deleteOpen, setDeleteOpen] = useState();
  const [userId, setUserId] = useState("");

  const styles = makeStyles();
  const financeStyle = financeStyles();

  const inventoryStyle = InventoryManagmentStyle();

  const navigate = useNavigate();

  const qry = {
    searchQry: search,
    page: page !== null ? page : currentpageNo, //aimee
    selected: select,
    perPageItems: pageItems,
  };

  const handleDelete = (id) => {
    setUserId(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handlecreateNewItem = (e) => {
    setPageItems(e.target.value);
  };
  const {
    data: productList,
    isLoading,
    isFetching,
    isError,
  } = productQuery.ProductList(qry);

  const { currentData, currentPage, maxPage, slNo } = usePagination(
    productList != undefined && productList,
    pageItems
  );
  const { totalCount } = PageCounter(productList && productList.count);
  const { TblContainer, TblHead } = useTable(
    HeadCells.inventryHead,
    totalCount
  );

  useEffect(() => {
    setcurrentpageNo(1);
  }, [search, select]);

  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  const { mutateAsync: remove, isSuccess: deletedSucces } =
    useAddProduct.delete_Product();
  const handleSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleRemove =  () => {
    if (userId) {
       remove(userId).then((res) => {
        if(res.status===200){
          toast.success("Product deleted");
         
        }
        setDeleteOpen(false);
      })
      .catch((err) => {
       
           
          if(err && err.response && err.response.status !== 403){

            toast.error("Something went wrong");
          }
          setDeleteOpen(false);
        
      });
      setDeleteOpen(false)
    }
  };

  // useEffect(() => {
  //   if (deletedSucces) {
  //     toast.success("Product deleted successfully");
  //   }
  // }, [deletedSucces]);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    navigate(AppRoutes.ADMIN + EndPoint.INVENTORY_ADD_PRODUCT);
  };

  const [excelModal, setExcelModal] = useState(false);

  const excelDataModal = () => {
    setExcelModal(true);
  };
  const excelDataModalClose = () => {
    setExcelModal(false);
  };
  
  return (
    <>
      <HeadBar title={TXT_INVENTORYMANAGEMENT} />
      <div className={styles.divStyle}>
        <Box className={styles.searchBoxParent}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Controls.FullWidthSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                icon={
                  <BsSearch style={{ marginLeft: "5px", color: "#67748E" }} />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography className={styles.viewlabel}>
                    Filter By
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <DropDown
                    style={{ width: "100%" }}
                    value={select}
                    handleCreateNewItem={handleSelect}
                    items={[
                      { key: 1, value: "All" },
                      { key: 2, value: "active" },
                      { key: 3, value: "inactive" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "end" },
                }}
              >
                <IconButs
                  ibtname={ICON_BUTTON_INVENTORYMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={inventoryStyle.dmSalesButtonOne}
                  onClick={handleClick}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {isLoading || isFetching ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody >
                {productList &&
                  productList.data.map((item) => {
                    return (
                      <TableRow>
                        <TableCell align="center">{item.item_code ?item.item_code :'Not found'}</TableCell>
                        <TableCell align="center">{item.series ? item.series :'Not found'}</TableCell>
                        <TableCell align="center">{item.model ? item.model : 'Not found'}</TableCell>
                        <TableCell align="center">
                          {item.brand_id.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.brand_id.category_id.parent_id
                            ? item.brand_id.category_id.parent_id.name
                            : item.brand_id.category_id.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.brand_id.category_id.parent_id ? item.brand_id.category_id.name :'Not found'}
                        </TableCell>
                        <TableCell align="center">{item.title ? item.title :'Not found'}</TableCell>
                        <TableCell align="center">
                          {item.vehicle_category ? item.vehicle_category :'Not found'}
                        </TableCell>
                        <TableCell align="center">{item.hsn_code ?item.hsn_code:'Not found'}</TableCell>
                        <TableCell align="center">
                          {item.zoho_item_id ?item.zoho_item_id :'Not found' }
                        </TableCell>
                        <TableCell align="center">
                          {moment(item.updated_at).format(
                            "DD/MM /YYYY, h:mm a"
                          )}
                        </TableCell>
                        <TableCell align="center">{item.updated_by ? item.updated_by :'Not found'}</TableCell>

                        <TableCell align="center">
                          {item.status == true ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Link
                              to={`/administrator/viewProductDetails/${item.id}`}
                            >
                              <IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>
                            </Link> */}

                            <NavigateLink
                              url={`/administrator/viewProductDetails/${item.id}`}
                              styleclass={styles.linkColor}
                              currentPage={currentPage}
                              trySearch={search}
                              pageItems={pageItems}
                              itemid={item.id}
                              selected={select}
                              display={<IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>}
                            />

                            {/* <Link to={`/administrator/${item.id}/editProduct`}>
                              <IconButton>
                                <AiOutlineEdit className="user-icons" />
                              </IconButton>
                            </Link> */}

                            <NavigateLink
                              url={`/administrator/${item.id}/editProduct`}
                              styleclass={styles.linkColor}
                              currentPage={currentPage}
                              trySearch={search}
                              selected={select}
                              pageItems={pageItems}
                              itemid={item.id}
                              display={<IconButton>
                                <AiOutlineEdit className="user-icons" />
                              </IconButton>}
                            />

                            <IconButton onClick={() => handleDelete(item.id)}>
                              <AiOutlineDelete className="user-icons" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </TblContainer>

          {((productList && productList.data.length === 0) || isError) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "100px",
              }}
            >
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </Box>
        {/* {open ? (
            <InventoryModal
              open={open}
              handleClose={handleClick}
              title="ADD INVENTORY"
            />
          ) : null
          } */}

        {/* {excelModal ? (
            <InventoryExcelData
              open={excelModal}
              // onClick={handleClick}
              onModalClose={excelDataModalClose}
              title={INVENTORY_MODAL_HEADER}
            />
          ) : null} */}
        <div className={financeStyle.pagination}>
          <div className={financeStyle.paginationStyle}>
            <DropDown
              value={pageItems}
              handlecreateNewItem={handlecreateNewItem}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 3, value: 30 },
              ]}
            />
            <span className={financeStyle.paginationPage}>Per Page</span>
          </div>

          <Pagination
            page={currentPage}
            onChange={currentData}
            variant="outlined"
            count={maxPage}
            // boundaryCount="4"
            className={financeStyle.paginationFont}
          />
        </div>
        {/* )} */}
        {deleteOpen ? (
          <DialogBox
            open={deleteOpen}
            setOpen={setDeleteOpen}
            content={"Are you sure you want to delete ?"}
            handleClose={handleDeleteClose}
            userId={userId}
            handleRemove={handleRemove}
          />
        ) : (
          ""
        )}
      </div>
     
    </>
  );
};

export default InventoryManagement;
