import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import HeadBar from "../../components/HeadBar/HeadBar";
import { AppRoutes, EndPoint } from "../../config/config";
import useAddProduct from "../../data/inventory/useAddProduct";
import makeStyles from "../../sharedFeatures/sharedClasses";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import {
  DEALER_LIST_DOC,
  FORMONE_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import categoryBrandStyles from "./InventoryManagmentStyle";
import productQuery from "../../data/inventory/useProductList";
import useList from "../../data/inventory/useList";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import InventoryImage from "./inventoryImage";
import { FindLink } from "../../sharedFeatures/FindLink";

const EditInventory = () => {
  const customStyle = makeStyles();

  const categoryStyle = categoryBrandStyles();
  const { id } = useParams();
  const [imageOpen, setImageOPen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [specificationFileds, setSpecificationFields] = useState([]);
  const [brand_id, setBrandID] = useState(null);
  const [series, setSeries] = useState("");
  const [item_code, setItemCode] = useState("");
  const [model, setModel] = useState("");
  const [mrp, setMrp] = useState(null);
  const [rcp, setRcp] = useState(null);
  const [dealer_landing_price, setDealerLandingPrice] = useState(null);
  const [customer_landing_price, setCustomerLandingPrice] = useState(null);
  const [gst_perc, setGstPerc] = useState(null);
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState({});
  const [service_items, setServiceItems] = useState({});
  const [specifications, setSpecifications] = useState({});
  const [warranty, setWarranty] = useState("");
  const [vehicle_category, setVehicleCategory] = useState("");
  const [vehicle_segment, setVehicleSegment] = useState(null);
  const [hsn_code, setHscCode] = useState("");
  const [advantages, setAdvantages] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [zohoId, setZohoId] = useState("");
  const [margin, setMargin] = useState("");
  const [timeForService, setTimeForSErvices] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [mrpError, setMrpError] = useState("");
  const [rcpError, setRcpError] = useState("");
  const [dealerPriceError, setDealerPriceError] = useState("");
  const [customerPriceError, setCustomerPriceError] = useState("");
  const [producttype, setProductType] = useState("");
  const [warrantyField, setWarrantyField] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [imageData, setImageData] = useState();
  const navigate = useNavigate();

  let link = FindLink(`/administrator/inventoryProduct`);

  const vehicleCategoryList = [
    { id: "Premium Large", name: "Premium Large" },
    { id: "Premium Medium", name: "Premium Medium" },
    { id: "Medium Car", name: "Medium Car" },
    { id: "Small Car", name: "Small Car" },
    { id: "Large Car", name: "Large Car" },
    { id: "Medium Sedan", name: "Medium Sedan" },
    { id: "Medium SUV", name: "Medium SUV" },
    { id: "Medium MUV", name: "Medium MUV" },
    { id: "Small MUV", name: "Small MUV" },
  ];

  const vehicleSegmentList = [
    { id: 1, name: "Indian" },
    { id: 2, name: "Imported" },
  ];

  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Product updated successfully.
      </span>
    );

  const qry = {
    searchQry: "",
    page: 0,
    perPageItem: 150,
  };

  const { data: productDetails, isSuccess: detailsFetched } = id
    ? productQuery.ProductDetails(id)
    : "";

  const { mutateAsync: editProduct, isSuccess: productEditSuccess } =
    useAddProduct.edit_product();

  const { data: catList } = useList.cat_list(qry);

  const { data: subCatList, isSuccess: subCatListSuccess } =
    useList.subcat_list_by_category("", selectedCategory);

  const { data: brandList, isSuccess: brandsListSuccess } =
    useList.brand_by_category(
      selectedSubCategory ? selectedSubCategory : selectedCategory
    );

  useEffect(() => {
    if (catList && catList.data && catList.data.length > 0) {
      setCategoryList([...catList.data]);
    }
  }, [catList]);

  useEffect(() => {
    if (subCatList && subCatList.data && subCatList.data.length > 0) {
      setSubCategoriesList([...subCatList.data]);
    } else {
      setSubCategoriesList([]);
    }
  }, [subCatList, subCatListSuccess]);

  useEffect(() => {
    if (brandList && brandList.data && brandList.data.length > 0) {
      setBrandsList([...brandList.data]);
      setBrandID(null);
    } else {
      setBrandsList([]);
      setBrandID(null);
    }
  }, [brandList, brandsListSuccess]);

  // useEffect(() => {
  //   if (selectedCategory) {
  //     let selected = categoryList.find((item) => item.id === selectedCategory);
  //     let fields = [];

  //     if (selected && selected.specification_fields) {
  //       fields = selected.specification_fields["SpecificationFields"] || [];
  //     }

  //     if (fields.length > 0) {
  //       fields = fields.split(",");
  //       setSpecificationFields(
  //         fields.reduce(function (obj, v) {
  //           obj[v] = "";
  //           return obj;
  //         }, {})
  //         );
  //       }

  //   }
  // }, [selectedCategory]);

  const submitData = (e) => {
    e.preventDefault();

    let err = false;

    if (!selectedCategory) {
      setCategoryError("Category is required");
      err = true;
    }

    if (subCategoriesList.length > 0 && !selectedSubCategory) {
      setSubCategoryError("Sub Category is required");
      err = true;
    }

    if (!brand_id || brand_id === "") {
      setBrandError("Brand is required");
      err = true;
    }

    if ((!mrp || mrp === 0) && (!rcp || rcp === 0)) {
      setMrpError("MRP / RCP is required");
      err = true;
    }
    if ((!rcp || rcp === 0) && (!mrp || mrp === 0)) {
      setRcpError("MRP / RCP is required");
      err = true;
    }

    if (!dealer_landing_price || dealer_landing_price === 0) {
      setDealerPriceError("Dealer Price is required");
      err = true;
    }

    if (!customer_landing_price || customer_landing_price === 0) {
      setCustomerPriceError("Customer Price is required");
      err = true;
    }

    if (!err) {
      editProduct({
        id: id,
        series: series,
        item_code: item_code,
        model: model,
        mrp: mrp && parseInt(mrp),
        rcp: rcp && parseInt(rcp),
        dealer_landing_price:
          dealer_landing_price && parseInt(dealer_landing_price),
        customer_landing_price:
          customer_landing_price && parseInt(customer_landing_price),
        gst_perc: gst_perc && parseInt(gst_perc),
        margin: 1,
        description: description,
        features: {
          features: "",
        },
        service_items: {
          service_items: "",
        },
        specifications: specificationFileds,
        warranty: {
          warranty: warranty,
        },
        vehicle_category: vehicle_category,
        unique_key: "",
        vehicle_segment: vehicle_segment,
        hsn_code: hsn_code,
        advantages: advantages,
        brand_id: brand_id,
      })
        .then((res) => {
          toast.success("Product added");
          navigate(link);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.detail
          ) {
            toast.warn(err.response.data.detail);
            navigate(link);
          } else if (err.response && err.response.status !== 500) {
            toast.error("Something went wrong");
          }
        });
    }
  };

  // useEffect(() => {
  //   if (productEditSuccess) {
  //     notifyEdit();
  //     handleClose();
  //   }
  // }, [productEditSuccess]);

  const handleClose = () => {
    // navigate(AppRoutes.ADMIN + EndPoint.INVENTORY_PRODUCT);
    navigate(link);
  };

  const handleCategory = (e) => {
    setCategoryError("");
    setSelectedCategory(e.target.value);
  };

  const handleSubCategory = (e) => {
    setSubCategoryError("");
    setSelectedSubCategory(e.target.value);
  };

  const handleBrand = (e) => {
    setBrandError("");
    setBrandID(e.target.value);
  };

  useEffect(() => {
    if (detailsFetched) {
      if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.parent_id
      ) {
        setSelectedCategory(productDetails.brand_id.category_id.parent_id.id);
      } else if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id
      ) {
        setSelectedCategory(productDetails.brand_id.category_id.id);
      }

      if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.parent_id
      ) {
        setSelectedSubCategory(productDetails.brand_id.category_id.id);
      }

      if (productDetails && productDetails.brand_id) {
        setBrandID(productDetails.brand_id.id);
      }

      if (productDetails && productDetails.vehicle_category) {
        setVehicleCategory(productDetails.vehicle_category);
      }

      if (productDetails && productDetails.vehicle_segment) {
        setVehicleSegment(productDetails.vehicle_segment);
      }

      if (productDetails && productDetails.series) {
        setSeries(productDetails.series);
      }

      if (productDetails && productDetails.item_code) {
        setItemCode(productDetails.item_code);
      }

      if (productDetails && productDetails.model) {
        setModel(productDetails.model);
      }

      if (productDetails && productDetails.hsn_code) {
        setHscCode(productDetails.hsn_code);
      }

      if (productDetails && productDetails.images) {
        setProductImages(productDetails.images);
      }
      if (productDetails && productDetails.mrp) {
        setMrp(productDetails.mrp);
      }

      if (productDetails && productDetails.rcp) {
        setRcp(productDetails.rcp);
      }

      if (productDetails && productDetails.dealer_landing_price) {
        setDealerLandingPrice(productDetails.dealer_landing_price);
      }

      if (productDetails && productDetails.customer_landing_price) {
        setCustomerLandingPrice(productDetails.customer_landing_price);
      }

      if (productDetails && productDetails.gst_perc) {
        setGstPerc(productDetails.gst_perc);
      }

      if (productDetails && productDetails.advantages) {
        setAdvantages(productDetails.advantages);
      }

      if (productDetails && productDetails.description) {
        setDescription(productDetails.description);
      }

      if (productDetails && productDetails.specifications) {
        setSpecificationFields(productDetails.specifications);
      }

      if (productDetails && productDetails.warranty) {
        setWarrantyField(productDetails.warranty);
      }

      if (
        productDetails &&
        productDetails.features &&
        productDetails.features.features
      ) {
        setFeatures(productDetails.features.features);
      }
      if (productDetails && productDetails.service_items) {
        setServiceItems(productDetails.service_items);
      }
      if (productDetails && productDetails.status) {
        setStatus(productDetails.status);
      }
      if (productDetails && productDetails.title) {
        setTitle(productDetails.title);
      }
      if (productDetails && productDetails.zoho_item_id) {
        setZohoId(productDetails.zoho_item_id);
      }
      if (productDetails && productDetails.margin) {
        setMargin(productDetails.margin);
      }
      if (productDetails && productDetails.margin) {
        setTimeForSErvices(productDetails.margin);
      }

      if (productDetails && productDetails.warranty) {
        setWarranty(productDetails.warranty);
      }

      if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.name === "Battery"
      ) {
        setProductType(
          productDetails &&
            productDetails.brand_id &&
            productDetails.brand_id.category_id &&
            productDetails.brand_id.category_id.name
        );
      } else if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.name === "Tyre"
      ) {
        setProductType(
          productDetails &&
            productDetails.brand_id &&
            productDetails.brand_id.category_id &&
            productDetails.brand_id.category_id.name
        );
      } else if (
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.parent_id &&
        productDetails.brand_id.category_id.parent_id.name === "Accessories"
      ) {
        setProductType(
          productDetails &&
            productDetails.brand_id &&
            productDetails.brand_id.category_id &&
            productDetails.brand_id.category_id.parent_id &&
            productDetails.brand_id.category_id.parent_id.name
        );
      } else {
        setProductType("");
      }
    }
  }, [detailsFetched, categoryList, subCatList, brandList]);

  return (
    <>
      <HeadBar title="Edit Inventory" />
      <div className={customStyle.divStyle}>
        <Paper className={categoryStyle.parentContainer}>
          <div>
            <div className={customStyle.subHeaderParent}>
              <Box sx={{ display: { xs: "none", sm: "inline" } }}>
                <Link to={link}>
                  <IconButton className={customStyle.backButtonParent}>
                    <BackbuttonIcon />
                  </IconButton>
                </Link>
              </Box>
              <Typography className={categoryStyle.subHeader}>
                Edit Inventory
              </Typography>
            </div>
            <div>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.EditSelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Category"
                        value={selectedCategory}
                        options={categoryList}
                        onChange={(e) => handleCategory(e)}
                        error={categoryError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {subCategoriesList.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Sub Category
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.EditSelectBox
                          className={categoryStyle.formTextBar2}
                          name="inventoryType"
                          label="Select Sub Category"
                          value={selectedSubCategory}
                          options={subCategoriesList}
                          onChange={(e) => handleSubCategory(e)}
                          error={subCategoryError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {producttype !== "Service" && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Brand
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.EditSelectBox
                          className={categoryStyle.formTextBar2}
                          name="inventoryType"
                          label="Select Brand"
                          options={brandsList}
                          value={brand_id}
                          onChange={(e) => handleBrand(e)}
                          error={brandError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Vehicle Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.EditSelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Vehicle Category"
                        value={vehicle_category}
                        options={vehicleCategoryList}
                        onChange={(e) => setVehicleCategory(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {producttype !== "Services" && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Vehicle Segment
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.EditSelectBox
                          className={categoryStyle.formTextBar2}
                          name="inventoryType"
                          label="Select Vehicle Segment"
                          value={vehicle_segment}
                          options={vehicleSegmentList}
                          onChange={(e) => setVehicleSegment(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Item Code
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="itemcode"
                        placeholder="Item Code"
                        value={item_code}
                        onChange={(e) => setItemCode(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {producttype !== "Services" && (
                  <>
                    {" "}
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Series
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="series"
                            placeholder="Series"
                            value={series}
                            onChange={(e) => setSeries(e.target.value)}
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Model
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="model"
                            placeholder="Model"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Title
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="title"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setModel(e.target.value)}
                        type="text"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="status"
                        placeholder="Status"
                        value={status}
                        onChange={(e) => setModel(e.target.value)}
                        type="text"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Zoho id
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="zohoId"
                        placeholder="Zoho id"
                        value={zohoId}
                        onChange={(e) => setModel(e.target.value)}
                        type="text"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        HSC Code
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="hsn_code"
                        placeholder="HSN Code"
                        value={hsn_code}
                        onChange={(e) => setHscCode(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        GST Perc
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="gst_perc"
                        placeholder="GST Perc"
                        value={gst_perc}
                        onChange={(e) => setGstPerc(e.target.value)}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        MRP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="mrp"
                        placeholder="INR 1234"
                        value={mrp}
                        onChange={(e) => {
                          setMrp(e.target.value);
                          if (e.target.value != 0) {
                            setMrpError("");
                            setRcpError("");
                          }
                        }}
                        type="number"
                        error={mrpError}
                        onKeyDown={() => setMrpError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        RCP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="RCP"
                        placeholder="rcp"
                        value={rcp}
                        onChange={(e) => {
                          setRcp(e.target.value);
                          if (e.target.value != 0) {
                            setRcpError("");
                            setMrpError("");
                          }
                        }}
                        error={rcpError}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Customer Price
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="customer_landing_price"
                        placeholder="INR 1234"
                        value={customer_landing_price}
                        onChange={(e) =>
                          setCustomerLandingPrice(e.target.value)
                        }
                        type="number"
                        error={customerPriceError}
                        onKeyDown={() => setCustomerPriceError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Dealer Price
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="dealer_landing_price"
                        placeholder="INR 1234"
                        value={dealer_landing_price}
                        onChange={(e) => setDealerLandingPrice(e.target.value)}
                        type="number"
                        error={dealerPriceError}
                        onKeyDown={() => setDealerPriceError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Margin
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="margin"
                        placeholder="Margin"
                        value={margin}
                        onChange={(e) => setDealerLandingPrice(e.target.value)}
                        type="number"
                        error={dealerPriceError}
                        onKeyDown={() => setDealerPriceError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="description"
                        value={description}
                        label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Features
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="features"
                        value={features}
                        label="Features"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Advantages
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="advantages"
                        label="Advantages"
                        value={advantages}
                        onChange={(e) => setAdvantages(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {producttype === "Services" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Time for services
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="timeForServices"
                            placeholder="Time for services"
                            value={timeForService}
                            onChange={(e) =>
                              setDealerLandingPrice(e.target.value)
                            }
                            type="text"
                            error={dealerPriceError}
                            onKeyDown={() => setDealerPriceError("")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Services items
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="servicesItems"
                            value={service_items}
                            label="Services Items"
                            onChange={(e) => setWarranty(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Specifications
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="specifications"
                            value={specifications}
                            label="Specifications"
                            onChange={(e) => setWarranty(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {Object.keys(warranty).length > 0 &&
                  Object.keys(warranty).map((item, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography className={categoryStyle.label}>
                              {item}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Controls.InputField
                              className={categoryStyle.formTextBar2}
                              name={item}
                              placeholder={`Enter ${item}`}
                              value={warranty[item]}
                              onChange={(e) =>
                                setSpecificationFields({
                                  ...warranty,
                                  [item]: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                {Object.keys(specificationFileds).length > 0 &&
                  Object.keys(specificationFileds).map((item, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography className={categoryStyle.label}>
                              {item}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Controls.InputField
                              className={categoryStyle.formTextBar2}
                              name={item}
                              placeholder={`Enter ${item}`}
                              value={specificationFileds[item]}
                              onChange={(e) =>
                                setSpecificationFields({
                                  ...specificationFileds,
                                  [item]: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Images
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <IconButs
                        ibtname={"Edit image"}
                        className={categoryStyle.imageButton}
                        onClick={() => setImageOPen(true)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          ibtname={"Update"}
                          className={categoryStyle.formButtonAdd}
                          onClick={submitData}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          onClick={handleClose}
                          ibtname={FORMONE_BUTTON_MANAGEMENT}
                          className={categoryStyle.cancelButton}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </div>
      <InventoryImage
        open={imageOpen}
        setOpen={setImageOPen}
        data={productImages}
        imageData={setImageData}
      />
    </>
  );
};

export default EditInventory;
