import React, { useEffect, useState } from "react";
import { Paper, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box,
} from "@material-ui/core";
import { useForm } from "../../../sharedFeatures/useForm";
import BasicDetails from "./BasicDetails";
import Documents from "./Documents";
import AdvancedDetails from "./AdvancedDetails";
import styles from "../../../sharedFeatures/sharedClasses";
import HeadBar from "../../../components/HeadBar/HeadBar";
import "../../../Sidebar/Sidebar.css";
import "./toast.css";
import {
  TXT_DEALERMANAGEMENT,
  EDIT_DEALERS,
  ADD_DEALER,
} from "../../../utilitis/header";
import { Link } from "react-router-dom";
import useAddDealer from "../../../data/dealer/useAddDealer";
import Controls from "../../../common/Controls/Controls";
import useDealersQuery from "../../../data/dealer/useDealersQuery";
import { useNavigate } from "react-router-dom";
import { AppRoutes, EndPoint } from "../../../config/config";
import dealerStyles from "../dealerStyle";
import BackbuttonIcon from "../../../utilitis/BackbuttonIcon";

import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../../common/ScrollToTop";
import { FindLink } from "../../../sharedFeatures/FindLink";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   fontFamily: "Montserrat",
//   transform: "translate(-50%, -50%)",
//   width: 1200,
//   bgcolor: "#ffffff",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: "10px",
// };

function getSteps() {
  const [activeStep, setActiveStep] = useState(0);
  return ["", "", ""];
}

const AddDealer = () => {
  const dealerClass = dealerStyles();

  const [files, setFiles] = useState({});
  const [shopImage, setShopImage] = useState({});
  const [preview, setPreview] = useState([]);
  const [previewShopImage, setPreviewShopImage] = useState([]);
  const [imageError, setImageError] = useState();
  const [shopError, setShopError] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: docData } = id ? useDealersQuery.dealers_data(id) : "";
  const notify = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Dealer/Technician added successfully.
      </span>
    );
  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Dealer/Technician updated successfully.
      </span>
    );
  const notifyApprove = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Approved Successfully.
      </span>
    );
  const notifyImg = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Files uploaded successfully.
      </span>,
      {}
    );

  const useStyles = makeStyles((theme) => ({
    root: {
      ".MuiStepConnector-line": {
        borderColor: "#757575",
        marginLeft: "-203px",
        // width:'90%'
      },
      ".MuiStepConnector-root": {
        top: 20,
        left: 20,
      },
      "&": {
        marginLeft: "-54px",
        marginTop: "-43px",
      },
      "& .MuiStepIcon-active": {
        "& .MuiStepIcon-line": {
          borderColor: "#784af4",
        },
        borderRadius: "50%",
        color: "yellow",
        fontWeight: "bold",
        border: "1px solid white",
        borderRadius: "50%",
        boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1)",
      },
      "& .MuiStepIcon-text": {
        fill: "black !important",
      },
      "& .MuiStepIcon-completed": {
        color: "#e94141",
        fontWeight: "bold",
        borderRadius: "50%",
        boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1)",
      },
      "& .Mui-disabled .MuiStepIcon-root": {
        color: "white",
        fontWeight: "bold",
        borderRadius: "50%",
        boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1)",
      },
      [theme.breakpoints.down("sm")]: {
        "&": {
          marginLeft: "-14px",
          marginTop: "-10px !important",
        },
      },
    },
    "stepConnectorLine.active": {
      borderColor: "red",
    },
  }));

  const classes = useStyles();
  const customStyle = styles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [checkId, setcheckId] = useState();
  const [documentId, setDocumentId] = useState();
  const [location, setLocation] = useState();
  const [state, setState] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [brandData, setBrandData] = useState();
  const [status, setStatus] = useState();
  const steps = getSteps();

  //aimee
  let link = FindLink("/administrator/dealers");

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    shop_type: "",
    shop_category: "",
    service_category: 1,
    shop_name: "",
    website: "",
    brand_id: "",
    bank_details: [],
    user_type: "",
    city: "",
    address: "",
    address_line_one: "",
    address_line_two: "",
    locality: "",
    pincode: "",
    country: "India",
    gstnumber: "",
    gst_treatment: "",
    account_number: "",
    account_name: "",
    bank_name: "",
    branch: "",
    ifsc: "",
    location: "",
    facilities: [],
    category: [],
    servicesList: [],
    serviceObjects: [],
    serviceName: [],
    brandLoaded: [],
    myData: [{}],
  };

  const {
    values,
    handleInputChange,
    handleSelectChange,
    resetForm,
    handleToggle,
    errors,
    setErrors,
    setValues,
    handleMessageBox,
    resetMessage,
    messageOpen,
    messageContent,
    dirty,
    setDirty,
  } = useForm(initialValues);

  useEffect(() => {
    let cats =
      docData &&
      docData.data &&
      docData.data.brand_id.filter(
        (item) => item && item.category_id && item.category_id.type === 2
      );

    const formatedCats =
      cats &&
      cats.length > 0 &&
      cats.map((item) => {
        return {
          id: item && item.id,
          name: item && item.category_id && item.category_id.name,
        };
      });

    setValues({
      ...values,
      user_type:
        docData &&
        docData.data &&
        docData.data.groups &&
        docData.data.groups[0].name,
      first_name: docData && docData.data && docData.data.first_name,
      last_name: docData && docData.data && docData.data.last_name,
      email: docData && docData.data && docData.data.email,
      phone: docData && docData.data && docData.data.phone,
      shop_type:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].shop_type,
      shop_name:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].shop_name,
      website:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].website,
      shop_category:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].shop_category,
      service_category:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].service_category,
      address:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.address,
      address_line_one:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.address_line_one,
      address_line_two:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.address_line_two,

      locality:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.locality,
      city:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.city,
      state:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.state,
      country:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.country,
      pincode:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.pincode,
      facilities:
        docData && docData.data && docData.data.shop_details[0]
          ? docData.data.shop_details[0].facilities
          : [],
      gstnumber:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].gst_number,
      gst_treatment:
        docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].gst_treatment,
      account_number:
        docData &&
        docData.data &&
        docData.data.bank_details[0] &&
        docData.data.bank_details[0].account_number,
      account_name:
        docData &&
        docData.data &&
        docData.data.bank_details[0] &&
        docData.data.bank_details[0].account_name,
      bank_name:
        docData &&
        docData.data &&
        docData.data.bank_details[0] &&
        docData.data.bank_details[0].bank_name,
      branch:
        docData &&
        docData.data &&
        docData.data.bank_details[0] &&
        docData.data.bank_details[0].branch,
      ifsc:
        docData &&
        docData.data &&
        docData.data.bank_details[0] &&
        docData.data.bank_details[0].ifsc,
      brand_id:
        docData &&
        docData.data &&
        docData.data.brand_id.filter((item) => item.category_id.type === 1),
      brandLoaded:
        docData &&
        docData.data &&
        docData.data.brand_id.filter((item) => item.category_id.type === 1),
      category: docData && docData.data && docData.data.category,
      serviceObjects: formatedCats,
      servicesList: formatedCats,
    });

    setLocation(
      docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.location
    );
    setLatitude(
      docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.geo_location &&
        docData.data.shop_details[0].address.geo_location.latitude
    );
    setLongitude(
      docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].address &&
        docData.data.shop_details[0].address.geo_location &&
        docData.data.shop_details[0].address.geo_location.longitude
    );
    setBrandData(docData && docData.data && docData.data.brand_id);
    setPreview(
      docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].documents
    );
    setPreviewShopImage(
      docData &&
        docData.data &&
        docData.data.shop_details[0] &&
        docData.data.shop_details[0].photos
    );
    setStatus(docData && docData.data && docData.data.status);
  }, [docData, id]);

  const {
    mutateAsync: addDealer,
    data: dealersData,
    isSuccess: addDealersSuccess,
    isLoading: addDealersLoading,
    success,
    error,
    refetch,
  } = useAddDealer.add_dealers();

  const {
    mutateAsync: editDealer,
    data: editdealersData,
    isSuccess: editDealersSuccess,
    isLoading: editDealersLoading,
  } = useAddDealer.edit_dealers();

  const {
    mutateAsync: approveDealer,
    data: approveDealerData,
    isSuccess: approveDealerSuccess,
    isLoading: approveDealerLoading,
  } = useAddDealer.approve_dealers();

  const {
    mutateAsync: addDocuments,
    data: dealerDocuments,
    isLoading: dataLoading,
    isSuccess: documentUploadSuccess,
  } = useAddDealer.doc_upload();

  const { mutate: addShopImage, data: shopDocuments } =
    useAddDealer.shop_Image_Upload();

  const handleLocation = (location) => {
    let item = location && location.split(",");
    item = item && item.length > 0 && item[item.length - 2];
    item && item.replace(/[0-9]/g, "");
    setState(item);
    setLocation(location);
  };

  const handleCordinates = (cords) => {
    setLatitude(cords.lat);

    setLongitude(cords.lng);
  };

  useEffect(() => {
    if (dealersData) {
      let userData = dealersData && dealersData.data;
      setDocumentId(
        userData &&
          userData.data &&
          userData.data.shop_details[0] &&
          userData.data.shop_details[0].id
      );
      // notify();
      setTimeout(() => {
        resetMessage();
      }, [6000]);
    }
  }, [dealersData]);

  useEffect(() => {
    if (editdealersData) {
      let userData = editdealersData && editdealersData.data;
      setDocumentId(userData.shop_details[0].id);
      // notifyEdit();
      setTimeout(() => {
        resetMessage();
      }, [6000]);
      handleNext();
    }
  }, [editdealersData]);

  // useEffect(() => {
  //   if (approveDealerData) {
  //     notifyApprove();
  //     setTimeout(() => {
  //       // navigate(AppRoutes.ADMIN + EndPoint.DEALER_MANAGEMENT);
  //       navigate(link);

  //     }, [2000]);
  //   }
  // }, [error, editdealersData, approveDealerData]);

  useEffect(() => {}, [values.servicesList]);

  useEffect(() => {
    if (dealerDocuments || shopDocuments) {
      notifyImg();
      // handleMessageBox("Files Uploaded SuccessFully");
      setTimeout(() => {
        // navigate(AppRoutes.ADMIN + EndPoint.DEALER_MANAGEMENT);
        navigate(link);
      }, [5000]);
    }
  }, [dealerDocuments, shopDocuments]);

  const approve = async () => {
    approveDealer({
      id: id,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Approved Successfully.");
          navigate("/administrator/dealers");
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.detail
        ) {
          navigate("/administrator/dealers");
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleDocumentsUpload = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageError("Please upload a valid image.");
      setTimeout(() => {
        setImageError("");
      }, [2000]);
    } else {
      reader.onloadend = () => {
        setFiles({ ...files, [e.target.name]: e.target.files[0] });
        setPreview({
          ...preview,
          [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleShopImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setShopError("Please upload a valid image.");
      setTimeout(() => {
        setShopError("");
      }, [2000]);
    } else {
      reader.onloadend = () => {
        setShopImage({ ...shopImage, [e.target.name]: e.target.files[0] });
        setPreviewShopImage({
          ...previewShopImage,
          [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const submitDocuments = async (e) => {
    try {
      e.preventDefault();
      let form_data = null;
      let shop_form_data = null;
      let dummyData = {};
      if (files || shopImage) {
        form_data = new FormData();
        shop_form_data = new FormData();
        for (const key in files) {
          if (files.hasOwnProperty(key)) {
            form_data.append(key, files[key], files[key].name);
            dummyData = { image: form_data };
          }
        }
        for (const key in shopImage) {
          if (shopImage.hasOwnProperty(key)) {
            shop_form_data.append(key, shopImage[key], shopImage[key].name);
            dummyData = { shopImg: shop_form_data };
          }
        }
      }

      if ((JSON.stringify(files) === "{}") === false) {
        await addDocuments({
          shop_id: documentId,
          image: form_data,
        });
      } else {
        setTimeout(() => {
          navigate(AppRoutes.ADMIN + EndPoint.DEALER_MANAGEMENT);
        }, [2000]);
      }
      if ((JSON.stringify(shopImage) === "{}") === false) {
        await addShopImage({
          shop_id: documentId,
          image: shop_form_data,
        });
      } else {
        setTimeout(() => {
          navigate(AppRoutes.ADMIN + EndPoint.DEALER_MANAGEMENT);
        }, [2000]);
      }
    } catch (error) {
      handleMessageBox(error);
    }
    // }
  };

  const submitData = async (e) => {
    if (id && (dirty || values.dirty)) {
      editDealer({
        id: id,
        first_name: values.first_name && values.first_name.trim(),
        last_name: values.last_name && values.last_name.trim(),
        email: values.email && values.email.trim(),
        phone: values.phone && values.phone.trim(),
        brand_id:
          Number(values.service_category) === 1
            ? values.brand_id && values.brand_id.map((item) => item.id)
            : Number(values.service_category) === 2
            ? values.servicesList
            : Number(values.service_category) === 3
            ? [
                ...(values.brand_id && values.brand_id.map((item) => item.id)),
                ...values.servicesList,
              ]
            : null,
        shop_details: [
          {
            shop_type: values.shop_type,
            shop_name: values.shop_name && values.shop_name.trim(),
            website: values.website && values.website.trim(),
            address: {
              address: values.address,
              address_line_one:
                values.address_line_one && values.address_line_one.trim(),
              address_line_two:
                values.address_line_two && values.address_line_two.trim(),
              locality: values.locality && values.locality.trim(),
              city: values.city && values.city.trim(),
              // state: state && state.trim(),
              state: "Maharashtra",
              country: values.country,
              geo_location: {
                latitude: latitude,
                longitude: longitude,
              },
              location: location,
              // latitude: latitude,
              // longitude: longitude,
              pincode: values.pincode && values.pincode.trim(),
            },
            facilities: values.facilities,
            gst_number: values.gstnumber && values.gstnumber.trim(),
            gst_treatment: values.gst_treatment,
            shop_category: values.shop_category,
            service_category: values.service_category,
          },
        ],
        bank_details: [
          {
            account_number:
              values.account_number && values.account_number.trim(),
            account_name: values.account_name && values.account_name.trim(),
            bank_name: values.bank_name && values.bank_name.trim(),
            branch: values.branch && values.branch.trim(),
            ifsc: values.ifsc && values.ifsc.trim(),
          },
        ],
        user_type: values.user_type,
        service_category: values.service_category,
        category: values.category && values.category.join(","),
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Dealer/Technician updated successfully.");
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.detail
          ) {
            navigate("/administrator/dealers");
          } else if (err && err.response && err.response.status !== 403) {
            toast.error("Something went wrong");
          }
        });
    } else {
      setDocumentId(
        docData &&
          docData.data &&
          docData.data.shop_details[0] &&
          docData.data.shop_details[0].id
      );
      handleNext();
    }
    if (!id) {
      await addDealer({
        first_name: values.first_name && values.first_name.trim(),
        last_name: values.last_name && values.last_name.trim(),
        email: values.email && values.email.trim(),
        phone: values.phone && values.phone.trim(),
        brand_id:
          Number(values.service_category) === 1
            ? values.brand_id && values.brand_id.map((item) => item.id)
            : Number(values.service_category) === 2
            ? values.servicesList
            : Number(values.service_category) === 3
            ? [
                ...(values.brand_id && values.brand_id.map((item) => item.id)),
                ...values.servicesList,
              ]
            : null,
        shop_details: [
          {
            shop_type: values.shop_type,
            shop_name: values.shop_name && values.shop_name.trim(),
            website: values.website && values.website.trim(),
            address: {
              address: values.address,
              address_line_one:
                values.address_line_one && values.address_line_one.trim(),
              address_line_two:
                values.address_line_two && values.address_line_two.trim(),
              locality: values.locality && values.locality.trim(),
              city: values.city && values.city.trim(),
              // state: state && state.trim(),
              state: "Maharashtra",
              country: values.country && values.country.trim(),
              geo_location: {
                latitude: latitude,
                longitude: longitude,
              },
              // latitude: latitude,
              // longitude: longitude,
              location: location,
              pincode: values.pincode && values.pincode.trim(),
            },
            facilities: values.facilities,
            gst_number: values.gstnumber && values.gstnumber.trim(""),
            gst_treatment: values.gst_treatment,
            shop_category: values.shop_category,
            service_category: values.service_category,
          },
        ],
        bank_details: [
          {
            account_number:
              values.account_number && values.account_number.trim(),
            account_name: values.account_name && values.account_name.trim(),
            bank_name: values.bank_name && values.bank_name.trim(),
            branch: values.branch && values.branch.trim(),
            ifsc: values.ifsc && values.ifsc.trim(),
          },
        ],
        user_type: values.user_type,
        category: values.category && values.category.join(","),
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Dealer/Technician onboarded successfully.");
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.detail
          ) {
            navigate("/administrator/dealers");
          } else if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.email &&
            err.response.data.error.email[0]
          ) {
            toast.error(err &&
              err.response &&
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.email &&
              err.response.data.error.email[0]);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BasicDetails
            values={values}
            handleNext={handleNext}
            handleInputChange={handleInputChange}
            handlecheckId={setcheckId}
            submitData={submitData}
            activestep={activeStep}
            steps={steps}
            errors={errors}
            id={id}
            handleLocation={handleLocation}
            handleCordinates={handleCordinates}
            location={location}
            setErrors={setErrors}
            setDirty={setDirty}
            setValues={setValues}
          />
          // <AdvancedDetails
          //   values={values}
          //   handleNext={() => handleNext()}
          //   handleSelectChange={handleSelectChange}
          //   handleInputChange={handleInputChange}
          //   brandData={brandData}
          //   handleBack={() => handleBack()}
          //   approve={approve}
          //   id={id}
          //   submitData={submitData}
          //   setValues={setValues}
          //   status={status}
          // />
          // <Documents
          //   values={values}
          //   handleBack={() => handleBack()}
          //   handleShopImage={handleShopImage}
          //   handleDocumentsUpload={handleDocumentsUpload}
          //   submitDocuments={submitDocuments}
          //   preview={preview}
          //   previewShopImage={previewShopImage}
          //   imageError={imageError}
          //   shopError={shopError}
          //   dataLoading={dataLoading}
          // />
        );
      case 1:
        return (
          <AdvancedDetails
            values={values}
            handleNext={() => handleNext()}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            brandData={brandData}
            handleBack={() => handleBack()}
            approve={approve}
            id={id}
            submitData={submitData}
            setValues={setValues}
            status={status}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <Documents
            values={values}
            handleBack={() => handleBack()}
            handleShopImage={handleShopImage}
            handleDocumentsUpload={handleDocumentsUpload}
            submitDocuments={submitDocuments}
            preview={preview}
            previewShopImage={previewShopImage}
            imageError={imageError}
            shopError={shopError}
            dataLoading={dataLoading}
          />
        );
      default:
        return "unknown step";
    }
  }

  return (
    <>
      <Box className={dealerClass.boxStyle}>
        <ScrollToTop />
        <Box sx={{ display: { xs: "none", md: "inline" } }}>
          <HeadBar title={TXT_DEALERMANAGEMENT} />
        </Box>
        <Box sx={{ display: { xs: "inline", md: "none" } }}>
          <HeadBar title={id ? EDIT_DEALERS : ADD_DEALER} />
        </Box>
        <div className={customStyle.divStyle}>
          <Paper className={customStyle.parentContainer} elevation="6">
            <Box
              sx={{ display: { xs: "none", md: "inline" } }}
              className={dealerClass.subHeader}
            >
              {/* <Link to={link}> */}
              <IconButton
                className={dealerClass.backButtonDealer}
                onClick={() => window.history.back()}
              >
                <BackbuttonIcon />
              </IconButton>
              {/* </Link> */}
              <Typography className={dealerClass.addDealerHeading}>
                {id ? EDIT_DEALERS : ADD_DEALER}
              </Typography>
            </Box>

            <Stepper
              alternativeLabel
              activeStep={activeStep}
              className={classes.root}
              connector={
                <StepConnector
                  classes={{
                    root: classes.stepConnector,
                    line: classes.stepConnectorLine,
                  }}
                />
              }
            >
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step {...stepProps} key={index}>
                    <StepLabel {...labelProps}>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <Typography variant="h3" align="center">
                Thank You
              </Typography>
            ) : (
              <>
                <form>
                  {messageOpen ? (
                    <>
                      <div>
                        <Controls.MessageBox message={messageContent} />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {!addDealersLoading &&
                    !editDealersLoading &&
                    !approveDealerLoading &&
                    getStepContent(activeStep)}
                </form>
              </>
            )}
            {(addDealersLoading ||
              editDealersLoading ||
              approveDealerLoading) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  src="https://assets3.lottiefiles.com/packages/lf20_3lg0cewf.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "300px", height: "300px" }}
                  loop
                  autoplay
                ></lottie-player>
              </Box>
            )}
          </Paper>
        </div>
      </Box>
    </>
  );
};

export default AddDealer;
