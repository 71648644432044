import React from "react";
import { FormControl, Radio } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const GreenRadio = withStyles({
    root: {
      color: "#34D834",
      '&$checked': {
        color: "#34D834",
      },
    },
    checked: {},
  })((props) => <Radio   color="default" {...props} />);

const radioButton = ({ handleChecked, value, checked, className ,name,onClick ,disabled}) => {
  return (
    <>
      <FormControl>
        <GreenRadio
        
          onChange={handleChecked}
          value={value}
          checked={checked}
          className={className}
          name={name}
          onClick={onClick}
          disabled={disabled}
          
        />
      </FormControl>
    </>
  );
};

export default radioButton;
