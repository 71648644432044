import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import {
  getUserlist,
  getUserDetail,
  getUserVehicleDetail,
  updateUserDetail,
  deleteCustomer,
} from "./userUrls";

//get the listing of dealers

const users_list = (qry) => {
  return useQuery(["UserList", qry], () => getUserlist(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

//get the detail view of dealer
const users_details = (qry) => {
  return useQuery(["UserDetail", qry], () => getUserDetail(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const users_vehicle_details = (qry) => {
  return useQuery(["UserVehicleDetail", qry], () => getUserVehicleDetail(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const update_user_details = () => {
  const queryClient = useQueryClient();
  return useMutation((user) => updateUserDetail(user), {
    onMutate: async (newUser) => {
      await queryClient.cancelQueries("UserList");

      const prev = queryClient.getQueryData("UserList");
      return {
        prev,
        newUser,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("UserList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("UserList", context.prev);
    },
  });
};

const delete_customer = () => {
  const queryClient = useQueryClient();
  return useMutation((userID) => deleteCustomer(userID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("UserList"); //cancel any in-flight or pending query to the `todos` key

      const prev = queryClient.getQueryData("UserList"); // retrieve the cached data

      queryClient.setQueryData("UserList", (old = []) =>
        old.filter((item) => item.id !== todo.id)
      ); //remove the todo from the previous list

      // return the previous list and the todo
      return {
        prev,
        todo,
      };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("UserList", context.prev); //rollback the cache to the previous state
    },
    onSettled: () => {
      queryClient.invalidateQueries("UserList"); //refetch the collection on the background
    },
  });
};

const useUsersQuery = {
  users_list,
  users_details,
  users_vehicle_details,
  update_user_details,
  delete_customer,
};

export default useUsersQuery;
