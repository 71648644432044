import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({

    dmSalesButton: {
      fontFamily: "Montserrat",
      fontWeight: "700",
      background: "#FEDD17",
      letterSpacing: "1px",
      padding: "10px 8px",
      width: '232px',
      height: "40px",
      fontSize: "18px",
      color: "#252F40",
      textTransform: "capitalize",
      borderRadius: "8px",
      justifyContent: 'space-evenly',
      '&:hover': {
        background: "#FEDD17 !important",
      },
    },
    dmDocButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      // padding: "8px 15px 8px 0 !important",
      width: "199px",
      height: "40px",
      fontSize: "14px !important",
      color: "#444445 !important",
      textTransform: "capitalize !important",
      borderRadius: "6px !important",
      '&:hover': {
        color: " #FFFFFF !important",
        background: "#E94141 !important",
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
      },
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
      },
    },
    dmDocButtonOne: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "199px",
      textAlign: 'left !important',
      height: "40px",
      textTransform: 'capitalize',
      marginTop: '10px',
      fontSize: "14px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      "&:hover": {
        color: " #FFFFFF !important",
        background: "#E94141 !important",
      },
    },
    docViewButtons: {
      width: '200px',
      height: '40px',
      marginTop: '10px',
      paddingLeft: '15px',
      fontFamily: 'montserrat',
      fontWeight: '600',
      lineHeight: '40px',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: '#E94141',
        borderRadius: '6px',
        color: '#fff',
      }
    },
    docViewButtonsClicked: {
      cursor: 'pointer',
      width: '200px',
      height: '40px',
      backgroundColor: '#E94141',
      borderRadius: '6px',
      lineHeight: '40px',
      color: '#fff',
      marginTop: '10px',
      paddingLeft: '15px',
      fontFamily: 'montserrat',
      fontWeight: '600'
    }
  })
)