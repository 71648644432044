import { PageHeader } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NavigateLink = ({
  url,
  styleclass,
  currentPage,
  trySearch,
  pageItems,
  itemid,
  display,
  selected,
  from,
  to

}) => {
  const navigate = useNavigate();
  // console.log("FROM:", from)
  // console.log("TO:", to)

  // console.log("----", currentPage)
  let navigationlink = url + "?page=" + currentPage + "&search=" + trySearch + "&itemsno=" + pageItems 
  navigationlink = (selected !== null && selected !== undefined)? navigationlink + "&selected=" + selected : navigationlink + "&selected=All"
  if(from !== undefined && to !== undefined){
    navigationlink = (from !== null && from !== '2000/01/01')? navigationlink + "&from=" + from : navigationlink
    navigationlink = (to !== null && to !== '2000/01/02')? navigationlink + "&to=" + to : navigationlink
  }


  const hanfleFn = (e, id) => {
    e.preventDefault();
    navigate(navigationlink);
  };
  return (
    <>
      <Link
        to=""
        className={styleclass}
        onClick={(e) => hanfleFn(e, itemid)}
      >
        {display}
      </Link>
    </>
  );
};

export default NavigateLink;
