import React from 'react'

const KwikfixSmallLogo = ({onClick}) => {
    return (
        <div onClick={onClick}>
            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9655 2.91105C13.082 -0.970351 6.78751 -0.970351 2.90611 2.91105C-0.642832 6.45893 -0.937561 12.0188 2.0014 15.9139L3.58693 14.3682C2.70989 13.1142 2.19113 11.5888 2.19113 9.94167C2.19113 5.66359 5.65785 2.19595 9.93593 2.19595C14.214 2.19595 17.6806 5.66359 17.6806 9.94167C17.6806 14.2188 14.2139 17.6865 9.93593 17.6865C8.2409 17.6865 6.67709 17.1349 5.40244 16.2108L3.78262 17.8479L9.93593 24L16.9671 16.9703C20.8485 13.089 20.8485 6.79352 16.9655 2.91105Z" fill="#E94141" />
                <path d="M14.0848 14.1177C16.4025 11.8002 16.4025 8.04228 14.0848 5.72403C11.7681 3.40644 8.01038 3.40644 5.69213 5.72403C3.6878 7.72782 3.42035 10.8085 4.88274 13.1045L6.93394 11.1046C6.47728 9.95869 6.71044 8.59824 7.6378 7.67076C8.54357 6.76499 9.86058 6.52176 10.988 6.93751L9.45953 8.46438C8.93972 8.9842 8.93972 9.82841 9.45953 10.3482C9.97921 10.8679 10.8234 10.8679 11.3432 10.3482L12.8728 8.82121C13.2876 9.94942 13.0454 11.2646 12.1395 12.1723C11.2111 13.1004 9.85158 13.3354 8.7047 12.8753L6.68529 14.9139C8.98328 16.3911 12.0756 16.1287 14.0848 14.1177Z" fill="#E94141" />
            </svg>
        </div>
    )
}

export default KwikfixSmallLogo