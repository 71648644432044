import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import {
  TXT_SALESPERSONMANAGEMENT,
  ICON_BUTTON_SALESPERSONMANAGEMENT,
  EDIT_SALESPERSON,
  ADD_SALESPERSON,
} from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import {
  AiOutlineEye,
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineEdit,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import SalesForm from "./SalesForm";
import SalesVisitingCardDetails from "./SalesVisitingCardDetails";
import SalesAddressDetails from "./SalesAddressDetails";
import HeadBar from "../../components/HeadBar/HeadBar";
import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import DropDown from "../../common/Controls/ListingDropdown";
import useSalesPersonQuery from "../../data/sales/useSalesPersonQuery";
import salesStyle from "./salesStyle";
import DialogBox from "../../common/Controls/DialogBox";
import useAddSalesPerson from "../../data/sales/useAddSalesPerson";
import CustomLoader from "../../features/CustomLoader";
import usePagination from "../../common/Controls/usePagination";
import { toast, ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import financeStyles from "../FinanceManagement/financeStyles";
import "./Sales.css";

import "../DealerManagement/AddEditDealer/toast.css";
import NavigateLink from "../../common/Controls/NavigateLink";

const SalesPersonManagement = () => {
  const { TblContainer, TblHead } = useTable(HeadCells.salesUserDetails);
  const [search, setSearch] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [salesData, setSalesData] = useState("");
  const styles = makeStyles();
  const salesClass = salesStyle();
  const inventoryStyle = InventoryManagmentStyle();
  const financeStyle = financeStyles();

  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  // const [trySearch, setTrySearch] = useState("");
  // const [pageItems, setPageItems] = useState(10);
  // const [currentPageNo, setCurrentPageNo] = useState(1);

  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentPageNo, setCurrentPageNo] = useState(page !== null ? page : 1);

  const [id, setId] = useState("");

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  const notify = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Salesperson added successfully.
      </span>
    );

  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Salesperson updated successfully.
      </span>
    );

  const notifyDelete = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Salesperson deleted successfully
      </span>
    );

  const { isLoading: addLoading } = useAddSalesPerson.add_salesPerson();

  const { isLoading: editLoading } = useAddSalesPerson.edit_SalesPerson();
  const qry = {
    searchQry: trySearch,
    page: trySearch ? currentPageNo :page !== null ? page : currentPageNo, //aimee
    perPageItems: pageItems,
  };

  const handleMessage = (id) => {
    if (id) {
      notifyEdit();
    } else {
      notify();
    }
  };

  // Modal for edit

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    setId("");
    // setOpen(!open);
  };

  const openEditModal = (value) => {
    setId(value);
    setOpen(!open);
  };

  //Modal Address Details
  const [addrModal, setAddrModal] = useState(false);

  const handleAddrModal = (item) => {
    setAddrModal(true);
    setId(item);
  };

  const handleAddrModalClose = () => {
    setAddrModal(false);
  };

  //Modal Visiting Card Details
  const [cardModal, setCardModal] = useState(false);

  const handleCardsModal = (value) => {
    setCardModal(true);
    setId(value);
  };
  const handleCardsModalClose = () => {
    setCardModal(false);
  };

  const { mutate: remove, isSuccess: saleDeleteSuccess } =
    useAddSalesPerson.delete_salesperson();

  // Modal for Deleting
  const handleDelete = (id) => {
    setUserId(id);
    setDeleteOpen(true);
  };
  const handleRemove = async () => {
    if (userId) await remove(userId);
    // setDeleteOpen(false)
    setTimeout(() => {
      notifyDelete();
      setDeleteOpen(false);
    }, [1000]);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const {
    data: salesList,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useSalesPersonQuery.salesPerson_list(qry);

  const { data: manager } = useSalesPersonQuery.salesManagers_list();

  useEffect(() => {
    refetch();
  }, []);

  const managerList =
    manager &&
    manager.map((item) => {
      let { first_name: name, ...rest } = item;
      item = { name, ...rest };
      if(item.status === 'Active'){
        return item;

      }
    }).filter(e => e !== undefined)
  const { currentData, currentPage, maxPage, slNo ,setSearchParams} = usePagination(
    salesList !== undefined && salesList,
    pageItems
  );


  useEffect(() => {
    setId("");
    if (salesList) {
      if (salesList.results) {
        setSalesData(salesList.results);
      } else {
        setSalesData(salesList);
      }
    }
  }, [salesList]);

  useEffect(() => {
    setCurrentPageNo(1);
  }, [trySearch]);

  useEffect(() => {
    setCurrentPageNo(currentPage);
  }, [currentPage]);

  //if last page and perpage changes go to first page
  useEffect(() => {
    if (currentPageNo > maxPage) {
      setCurrentPageNo(maxPage);
    }
  }, [pageItems]);

  useEffect(() => {
    setCurrentPageNo(1);
  }, [saleDeleteSuccess]);
   
  return (
    <>
      <HeadBar title={TXT_SALESPERSONMANAGEMENT} />
      <div className={styles.divStyle}>
        <div className={salesClass.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Controls.StyledSearch
                placeholder="Search"
                className={inventoryStyle.searchBar}
                size="small"
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  searchParams.set("page", "1");
           
                  setSearchParams(searchParams);
                  setTrySearch(e.target.value);
                }}
                icon={<BsSearch />}
                onInput={(event)=>{
                  event.target.value = event.target.value.replace(/[^a-zA-Z0-9,@,. ]/g,'')
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "end" },
                }}
              >
                <IconButs
                  ibtname={ICON_BUTTON_SALESPERSONMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={inventoryStyle.dmSalesButtonOne}
                  onClick={handleClick}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
  
        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {isLoading || isFetching || addLoading || editLoading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {salesList &&
                  salesList.data.map((item, i) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ maxWidth: "130px", wordWrap: "break-word" }}
                        >
                          {item.first_name}  {item.last_name && item.last_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ maxWidth: "130px", wordWrap: "break-word" }}
                        >
                          {item.email}
                        </TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">
                          {item.address &&
                          item.address.location &&
                          item.address.location}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to="#"
                            onClick={() => handleAddrModal(item.id)}
                            className={styles.linkColor}
                          >
                            View
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to="#"
                            onClick={() => handleCardsModal(item.id)}
                            className={styles.linkColor}
                          >
                            View
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                             { item.address && item.address.status ? item.address.status : "Pending"}

                        </TableCell>
                        <TableCell align="center">
                          <div className={styles.iconButton}>
                            {/* <Link
                              to={`/administrator/sales/salesUserDetails/salesDealerTechnicianDetails/${item.id}`}
                            >
                              <IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>
                            </Link> */}
                          <NavigateLink
                          url={`/administrator/sales/salesUserDetails/salesDealerTechnicianDetails/${item.id}`}
                          styleclass={styles.linkColor}
                          currentPage={currentPage}
                          trySearch={trySearch}
                          pageItems={pageItems}
                          itemid={item.id}
                          display={<IconButton>
                            <AiOutlineEye className="user-icons" />
                          </IconButton>}
                        />

                            <IconButton onClick={() => openEditModal(item.id)}>
                              <AiOutlineEdit className="user-icons" />
                            </IconButton>
                            {/* <IconButton onClick={() => handleDelete(item.id)}>
                              <AiOutlineDelete className="user-icons" />
                            </IconButton> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </TblContainer>
        </Box>
        {((salesData && salesData.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
        <div className={financeStyle.pagination}>
          <div className={financeStyle.paginationStyle}>
            <DropDown
              value={pageItems}
              handlecreateNewItem={handlePageItems}
              style={{ width: "120px", borderRadius: "55px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
              ]}
            />
            <span className={financeStyle.paginationPage}>Per Page</span>
          </div>
          <Pagination
            count={maxPage}
            page={Number(page) || currentPage}
            onChange={currentData}
            variant="outlined"
            className={financeStyle.paginationFont}
          />
        </div>

        {open ? (
          <SalesForm
            open={open}
            handleClose={handleClick}
            title={id ? EDIT_SALESPERSON : ADD_SALESPERSON}
            salesManagersData={managerList}
            id={id}
            handleMessage={handleMessage}
          />
        ) : null}
        {addrModal ? (
          <SalesAddressDetails
            open={addrModal}
            handleClose={handleAddrModalClose}
            title={"Address Details"}
            id={id}
          />
        ) : null}
        {cardModal && (
          <SalesVisitingCardDetails
            open={cardModal}
            handleClose={handleCardsModalClose}
            id={id}
          />
        ) }
        {deleteOpen ? (
          <DialogBox
            open={deleteOpen}
            setOpen={setDeleteOpen}
            content={"Are you sure you want to delete ?"}
            handleClose={handleDeleteClose}
            userId={userId}
            handleRemove={handleRemove}
          />
        ) : (
          ""
        )}
      </div>
   
    </>
  );
};

export default SalesPersonManagement;
