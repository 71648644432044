import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    fontSize: "inherit" /* inherit from Typography */,
  },
  myIconSizeMedium: {
    "& > *:first-child": {
      // backgroundColor: "red",
      width: "90px",
      paddingBlock: "1px",
    },
  },
}));

const IconButsUser = (props) => {
  const { variant, type, className, disabled, icon, onClick, ...others } =
    props;
  const classes = useStyles();
  return (
    <>
      <Button
        variant={variant}
        type={props.type}
        className={className}
        classes={{ iconSizeMedium: classes.myIconSizeMedium }}
        endIcon={icon}
        disabled={disabled}
        onClick={onClick}
        size="medium"
        {...others}
      >
        {props.ibtname}
      </Button>
    </>
  );
};

export default IconButsUser;
