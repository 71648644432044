import * as React from "react";
import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CustomLoader from "../../features/CustomLoader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       // width: 250,
//     },
//   },
// };

const MultipleTwo = ({
  name,
  onChange,
  onClick,
  value,
  label,
  options,
  className,
  num,
  loading,
  ...other
}) => {
  const [valueWithSameReference, setValueWithSameReference] = React.useState([
    ...value,
  ]);



  React.useEffect(() => {
    if (options.length === 0) {
      setValueWithSameReference([...value]);
      return;
    } else {
      setValueWithSameReference(
        options.filter((option) =>
          value.some((value) => value.id === option.id)
        )
      );
    }
  }, [value, options]);

  return (
    <Select
      className={className}
      multiple
      value={valueWithSameReference}
      onChange={(e) => onChange(e, num)}
      onClick={onClick}
      onMouseEnter={onClick}
      option={options}
      MenuProps={{
        getContentAnchorEl: null,
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: false,
      }}
      renderValue={(selected) => {
  
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    {value.name}
                  </span>
                }
                style={{ margin: "5px" }}
              />
            ))}
          </Box>
        );
      }}
    >
      {!loading && options && options.length > 0 ? (
        options.map((item, idx) => {
          return (
            <MenuItem key={idx} value={item} id={item.id}>
              <Checkbox
                checked={value && value.findIndex((x) => x.id === item.id) > -1}
                name={item.name}
                value={item.id}
              />
              <ListItemText
                primary={
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    {item.name}
                  </span>
                }
              />
            </MenuItem>
          );
        })
      ) : (
        <MenuItem disabled>
          <div style={{ width: "100%" }}>
            <CustomLoader />
          </div>
        </MenuItem>
      )}
    </Select>
  );
};

export default MultipleTwo;
