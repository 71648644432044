import { useQuery } from "react-query";
import {
  activeCustomerUrl,
  areaUrl,
  countUrl,
  dealerUrl,
  orderUrl,
  productUrl,
  quickUpdateUrl,
  topProductUrl,
} from "./dashboardUrl";

const useCountData = (data) => {
  return useQuery(["countList",data], () => countUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useOrderData = (data) => {
  return useQuery(["orderlist", data], () => orderUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};

const useProductData = (data) => {
  return useQuery(["productlist",data], () => productUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};

const useTopProductData = (data) => {
  return useQuery(["topPproductlist",data], () => topProductUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};

const usedealerData = (data) => {
  return useQuery(["dealerOnList",data], () => dealerUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};
const useareaData = (data) => {
  return useQuery(["areaOnList",data], () => areaUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};
const usequickUpdate = (data) => {
  return useQuery(["quickUpdateOnList",data], () => quickUpdateUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};
const useactiveCustomer = (data) => {
  return useQuery(["activeCustomerList",data], () => activeCustomerUrl(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // poll: 500,
  });
};
export {
  useCountData,
  useOrderData,
  useProductData,
  useTopProductData,
  usedealerData,
  useareaData,
  usequickUpdate,
  useactiveCustomer
};
