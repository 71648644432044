import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  
  TableBody,
  TableRow,
  TableCell,

  Box,
  
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import HeadCells from "../../common/Headcells";
import { BsSearch } from "react-icons/bs";

import makeStyles from "../../sharedFeatures/sharedClasses";
import {
  TXT_ADVISORYOFICER,
  ICON_BUTTON_INVENTORYMANAGEMENT,
} from "../../utilitis/header";
import AssignDealerTechnician from "./AssignDealerTechnician";
import AssignLinkedOrders from "./AssignLinkedOrders";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import { AiOutlinePlus } from "react-icons/ai";
import HeadBar from "../../components/HeadBar/HeadBar";
import { useForm } from "../../sharedFeatures/useForm";
import { Pagination } from "@material-ui/lab";
import DropDown from "../../common/Controls/ListingDropdown";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import OrderManagmentStyle from "./OrderManagmentStyle";
import financeStyles from "../FinanceManagement/financeStyles";

const AssignOfficeAdvisor = () => {
  const data = [
    {
      sl_no: 1,
      order_id: "ORD 101",
      customer: "Sumini",
      order_status: "Pending",
      payment_status: "Partially Paid",
      advisorasigned: "-",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
    {
      sl_no: 2,
      order_id: "ORD 102",
      customer: "Arun",
      order_status: "Assigned",
      payment_status: "Fully Paid",
      advisorasigned: "John P",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
    {
      sl_no: 3,
      order_id: "ORD 103",
      customer: "Sumini",
      order_status: "Pending",
      payment_status: "Partially Paid",
      advisorasigned: "-",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
    {
      sl_no: 4,
      order_id: "ORD 104",
      customer: "Arun",
      order_status: "Assigned",
      payment_status: "Fully Paid",
      advisorasigned: "John P",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
    {
      sl_no: 5,
      order_id: "ORD 105",
      customer: "Sumini",
      order_status: "Pending",
      payment_status: "Partially Paid",
      advisorasigned: "-",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
    {
      sl_no: 6,
      order_id: "ORD 106",
      customer: "Arun",
      order_status: "Assigned",
      payment_status: "Fully Paid",
      advisorasigned: "John P",
      completion_Time: "03:22:22 H",
      linked_orders: "View",
    },
  ];

  const assignDealers = [
    {
      id: "1",
      name: "abc",
    },
    {
      id: "2",
      name: "cde",
    },
    {
      id: "3",
      name: "ghi",
    },
    {
      id: "4",
      name: "lmn",
    },
  ];

 

  const { TblContainer, TblHead } = useTable(HeadCells.asignToOficeAdvisor);
  const [search, setSearch] = useState();
  const inventory = InventoryManagmentStyle();
  const styles = makeStyles();
  const orderStyle = OrderManagmentStyle();
  const customStyle = financeStyles();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const {
    handleOpen,
    open,
    // setOpen,
    // values,
    // handleInputChange,
    errors,
    // setErrors,
  } = useForm();

  //Modal Linked Order Details
  const [ordersModal, setOrdersModal] = useState(false);

  const handleOrdersModal = () => {
    setOrdersModal(true);
  };
  const handleOrdersModalClose = () => {
    setOrdersModal(false);
  };

  return (
    <>
      <HeadBar title={TXT_ADVISORYOFICER} />
      <div className={styles.listdivStyle}>
        <div className={styles.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controls.StyledSearch
                value={search}
                placeholder="Search"
                className={inventory.searchBar}
                size="small"
                name="usersearch"
                onChange={searchUser}
                icon={<BsSearch />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography className={styles.viewlabel}>
                    Filter By
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <DropDown
                    value={"All"}
                    items={[
                      { key: 1, value: "All" },
                      { key: 2, value: "Active" },
                      { key: 3, value: "Inactive" },
                      { key: 4, value: "Pending" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ marginLeft: { xs: "0px", md: "5vw" } }}>
                <IconButs
                  ibtname={ICON_BUTTON_INVENTORYMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={styles.salesButton}
                  // onClick={handleClick}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {data.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center">
                      <Link
                        to={`/administrator/asigntooficeadvisor/assignorderdetails/${item.sl_no}`}
                        className={styles.linkColor}
                      >
                        {item.order_id}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{item.customer}</TableCell>
                    <TableCell align="center">{item.order_status}</TableCell>
                    <TableCell align="center">{item.payment_status}</TableCell>
                    <TableCell align="center">{item.advisorasigned}</TableCell>
                    <TableCell align="center">{item.completion_Time}</TableCell>
                    <TableCell align="center">
                      <Link
                        to="#"
                        onClick={handleOrdersModal}
                        className={styles.linkColor}
                      >
                        View
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <div className={styles.tableCell}>
                        <div>
                          <Link to="#" className={styles.linkColorOne}>
                            Add
                          </Link>
                        </div>

                        <div>
                          <Link
                            to="#"
                            onClick={handleOpen}
                            className={styles.linkColorTwo}
                          >
                            Assign
                          </Link>
                        </div>

                        <div className={orderStyle.reAssign}>
                          <Link to="" className={styles.linkColor}>
                            Re-Assign
                          </Link>
                        </div>

                        <div>
                          <Link to="" className={styles.linkColorThree}>
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </Box>
        <div
          className={customStyle.pagination}

          // className={maxPage}
        >
          <div className={customStyle.paginationStyle}>
            <DropDown
              value={"10 "}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: "10 " },
                { key: 2, value: "20 " },
              ]}
            />
            <span className={customStyle.paginationPage}>Per Page</span>
          </div>
          <Pagination
            className={styles.paginationCompStyle}
            count={5}
            // page={currentPage}
            variant="outlined"
          />
        </div>

        {open ? (
          <AssignDealerTechnician
            open={open}
            handleClose={handleOpen}
            title="Assign Dealer / Technician Details"
            assignDealersData={assignDealers}
          />
        ) : null}

        {ordersModal ? (
          <AssignLinkedOrders
            open={ordersModal}
            onModalClose={handleOrdersModalClose}
            title="Linked Orders"
          />
        ) : null}
      </div>
    </>
  );
};

export default AssignOfficeAdvisor;
