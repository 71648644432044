import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import HeadCells from "../../common/Headcells";
import { TXT_WALLETMANAGEMENT } from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { Link } from "react-router-dom";
import financeStyles from "../FinanceManagement/financeStyles";
import DropDown from "../../common/Controls/ListingDropdown";
import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import { Pagination } from "@material-ui/lab";
import CustomLoader from "../../features/CustomLoader";
import PageDropDown from "../../common/Controls/PageDropDown";
import walletQuery from "../../data/wallet/walletQuery";
import usePagination from "../../common/Controls/usePagination";
// import Comment from "./Comment";
const WalletPage = () => {
  const [search, setSearch] = useState();
  // const [trySearch, setTrySearch] = useState("");
  const [currentpageNo, setcurrentpageNo] = useState(1);
  // const [pageItems, setPageItems] = useState(10);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const styles = makeStyles();
  const customStyle = financeStyles();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  //   const handleDetailsOpen = (review) => {
  //     setSelectedReview(review);
  //     setDetailsOpen(true);
  //   };

  //    const handleDetailsClose = () => {
  //     setDetailsOpen(false);
  //   };

  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");

  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const qry = {
    searchQry: trySearch,
    page: page !== null ? page : currentpageNo,
    perPageItems: pageItems,
  };

  const { data: walletData, isLoading: walletLoading } =
    walletQuery.getWalletList(qry);
  const { TblContainer, TblHead } = useTable(HeadCells.walletManagement);

  const { currentData, currentPage, maxPage, setSearchParams } = usePagination(
    walletData !== undefined && walletData,
    pageItems
  );

  const handlePageItems = (e) => {
    setPageItems(e.target.value);
  };

  //if last page and perpage changes go to first page
  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  return (
    <>
      <HeadBar title={TXT_WALLETMANAGEMENT} />
      <div className={customStyle.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  setTrySearch(e.target.value);
                }}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
              />
            </Grid>
          </Grid>
        </div>
        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {walletLoading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {walletData &&
                walletData &&
                walletData.data.map((item) => {
                  return (
                    <TableRow>
                      {/* <TableCell align="center">{item.sl_no}</TableCell> */}
                  
                      <TableCell align="center">
                        {item.user_id.first_name} {item.user_id.last_name}
                      
                      </TableCell>
                      <TableCell align="center">{item.user_id.phone}</TableCell>
                      <TableCell align="center">
                        {item.current_balance}
                      </TableCell>

                      {/* <TableCell>
                      <div className={styles.tableCell}>
                        <div>
                          <Link to="" className={styles.linkColor}>
                            View
                          </Link>
                        </div>

                        <div>
                          <Link to="" className={styles.linkColorTwo}>
                            Archive
                          </Link>
                        </div>
                      </div>
                    </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
        </Box>

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={Number(page) || currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>
      </div>
    </>
  );
};

export default WalletPage;
