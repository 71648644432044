import React, { useState } from "react";
import { Paper, IconButton, Box, Grid } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { Link, useParams, useSearchParams, useLocation } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import { AiOutlinePlus } from "react-icons/ai";
import useVehicleData  from "../../data/vehicle/useVehicleData";

import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import vehicleStyles from "./VehicleStyle";
import {
  TXT_VEHICLEMANAGEMENT,
  ADD_NEW,
  TXT_VEHICLE_DETAIL,
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import { FindLink } from "../../sharedFeatures/FindLink";

const VehicleDetails = () => {
  const { id } = useParams();
  const viewStyle = makeStyles();
  const vehicleStyle = vehicleStyles();
  const location = useLocation();

  const myParam = new URLSearchParams(location.search).get('myParam');


  const { isLoading, isError, data: vehicleData, error } = useVehicleData(id);

  
  
  let link = FindLink("/administrator/vehicle");
  
  return (
    <>
      <HeadBar title={TXT_VEHICLEMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <div className={viewStyle.searchBoxParent}></div>

        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_VEHICLE_DETAIL} />
          </div>

          <Grid container alignItems="start" spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Vehicle Brand
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    className={vehicleStyle.inputTextBarView}
                    value={vehicleData && vehicleData.make}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Vehicle Model
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    className={vehicleStyle.inputTextBarView}
                    value={vehicleData && vehicleData.model}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Vehicle Variant
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.trim}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Fuel Type
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.fuel_type}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Car Category
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.category}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Car Segment
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.segment === '1' ? 'Indian' : 'Imported'}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Tyre Size
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_size}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Listing Order
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.series}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                 <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}> Tyre Spec - Front</p>
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Width
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Width}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Rim Size
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Rim_Size}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Load Index
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Load_Index}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Aspect Ratio
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Aspect_Ratio}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Speed Rating
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Speed_Rating}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Pressure
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_pressure.Front}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                     <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}>Tyre Spec - Rear</p> 
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Width
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Width_Rear}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Rim Size
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Rim_Size_Rear}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Load Index
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Load_Index_Rear}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Aspect Ratio
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Aspect_Ratio_Rear}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Speed Rating
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_spec_front.Speed_Rating_Rear}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Pressure
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].tyre_pressure.Rear}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                  <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}>  Wiper Blade</p>
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Rear
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].wiper_blade.Rear2}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Passanger
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].wiper_blade.Passanger}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Driver Side
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].wiper_blade.Driver_Side}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={6}>
              <Grid container alignItems="center">
                {/* <Grid item xs={12} md={12} className={vehicleStyle.formLabel}>
                  Vehicle Image
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${vehicleData && vehicleData.imagename}`}
                    className={vehicleStyle.formsImage}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                  <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}>  Oil</p>
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Sump Capacity (L)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].sump_capacity}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Oil Grade 1
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].oil_grade.grade1}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Oil Grade 2
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].oil_grade.grade2}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                   <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}> Battery</p>
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  DIN Number
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.DIN_Number}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Capacity (AH)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.Battery_Capacity}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Amaron GO
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.GO}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Amaron FLO
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.FLO}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Amaron PRO
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.PRO}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Amaron BLACK
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.Black}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Amaron FRESH
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].battery_spec.FRESH}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={vehicleStyle.formLabel}
                >
                  <p style={{textDecoration:'underline',textUnderlineOffset:'10px'}}>  Health Check-Ups</p>
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Oil Change (km)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.Oil_change}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Oil Change (Months)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.oil_change_months}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Service Check Frequency (km)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.service_check_freq}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Service Check Frequency (Months)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.service_check_freq_months}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Break Check Frequency (km)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.break_check_freq}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Break Check Frequency (Months)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.break_check_freq_months}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Tyre Rotation Frequency (km)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.tyre_rotation_freq}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Wheel Alignment Frequency (km)
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.StyledInput
                    name="Search Varient"
                    className={vehicleStyle.inputTextBarView}
                    disabled
                    value={vehicleData && vehicleData.vehicle_spec[0].health_checks.wheel_alignment_freq}
                  />
                </Grid>



                {/* <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Related Services
                </Grid>
                <Grid item xs={12} md={6}>
                  <IconButs
                    ibtname={ADD_NEW}
                    className={vehicleStyle.forms}
                    icon={<AiOutlinePlus />}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.viewlabel}>
                  Related Products
                </Grid>
                <Grid item xs={12} md={6}>
                  <IconButs
                    ibtname={ADD_NEW}
                    className={vehicleStyle.forms}
                    icon={<AiOutlinePlus />}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className={vehicleStyle.formIconCentered}>
              <Link to={link}>
                <IconButs
                  ibtname={"OK"}
                  className={vehicleStyle.formButtonAdd}
                  variant="outlined"
                />
              </Link>
            </Box>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default VehicleDetails;
