import React, { useState } from "react";
import {
  FormControl,
 
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "./dashboardStyles";
import { makeStyles } from "@material-ui/core";

const customStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

const UserSelectDropdown = () => {
  const dashStyles = useStyles();
  const classes = customStyles();
  const [user, setUser] = useState("");

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  return (
    <Box sx={{ minWidth: "60%", marginLeft: "16px" }}>
      <FormControl fullWidth>
        <Select
          variant="outlined"
          IconComponent={KeyboardArrowDownIcon}
          value={user}
          label="username"
          className={classes.root}
          displayEmpty
          onChange={handleUserChange}
        >
          <MenuItem disabled value="">
            User Name
          </MenuItem>
          <MenuItem value={10}>User 1</MenuItem>
          <MenuItem value={20}>User 2</MenuItem>
          <MenuItem value={30}>User 3</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default UserSelectDropdown;
