import React from 'react'

const InfoIButton = () => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#BDBDBD" />
                <rect x="10" y="9.33325" width="3.99999" height="10.6666" rx="2" fill="white" />
                <rect x="10" y="4" width="3.99999" height="3.99999" rx="2" fill="white" />
            </svg>
        </>
    )
}

export default InfoIButton