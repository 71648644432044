import React from "react";
import { Button } from "@material-ui/core";

const IconButs = (props) => {
  const { variant, type, className,disabled, icon, onClick, ...others } = props;
  return (
    <>
      <Button
        variant={variant}
        type={props.type}
        className={className}
        endIcon={icon}
        disabled={disabled}
        onClick={onClick}
        {...others}
      >
        {props.ibtname}
      </Button>
    </>
  );
};

export default IconButs;
