import React from "react";
import {
  TextField,
  makeStyles,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    // width:' 350px',
    // height: 50,
    "& input + fieldset": {
      borderColor: "#D2D6DA",
      fontFamily: "Montserrat",
      fontSize: "12px",
    },
  },
  searchIcon: {
    color: "#67748E",
  },
});

const DocumentInputField = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  ...others
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        InputProps={{
          className: classes.input,
          endAdornment: (
            <InputAdornment>
              <IconButton>{icon}</IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={className}
        {...(error && { error: true, helperText: error })}
        // {...others}
      />
    </>
  );
};

export default DocumentInputField;
