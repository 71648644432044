import React from "react";
import {
  TextField,
  makeStyles,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "antd";

const StyledAddressField = withStyles({
  root: {
    "& .MuiFormControl-root": {
      padding: "0px",
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined":
      {
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `14px`,
        color: `#444445`,
      },
      "& input": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `14px`,
        color: `#444445`,
        lineHeight: `30px`,
      },
      "& ::placeholder": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `14px`,
      },
    },
  },
})(TextField);

const CustomDocumentInput = ({
  label,
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  ...others
}) => {
  return (
    <StyledAddressField
      id="outlined-basic"
      label={label}
      variant="outlined"
      value={value}
      
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      className={className}
      {...others}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <IconButton>{icon}</IconButton>
          </InputAdornment>
        ),
      }}
      {...(error && { error: true, helperText: error })}
    />
  );
};

const StyledSearchField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        height: "59px",
        width: `350px`,
        ["@media (max-width: 1500px)"]: {
          width: "200px",
        },
      },
    },
  },
})(TextField);

const CustomSearchInput = ({
  label,
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  position,
  ...others
}) => {
  return (
    <div>
      <StyledSearchField
        id="outlined-basic"
        label={label}
        variant="outlined"
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position={position}>
              <IconButton>{icon}</IconButton>
            </InputAdornment>
          ),
        }}
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={className}
        {...(error && { error: true, helperText: error })}
      />
    </div>
  );
};

const StyledTextField = withStyles({
  input: {
    width: "100%",
    fontFamily: `Montserrat`,
  },
  root: {
    input: {
      width: "100%",
      fontFamily: `Montserrat`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `8px`,
        width: "100%",
        height: "48px",
        color: "#444445",
      },
      "& input": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `12px`,
        color: `#444445`,
        lineHeight: `30px`,
        padding: `12.5px 14px`,
        outline: `none`,
      },
    },
  },
})(TextField);

const StyledInput = ({
  value,
  onChange,
  placeholder,
  className,
  error,
  name,
  icon,
  type,
  disabled,
  position,
  ...others
}) => {
  return (
    <StyledTextField
      InputProps={{
        endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        borderRadius: "110px",
      }}
      disabled={disabled}
      autoComplete="off"
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      name={name}
      variant="outlined"
      {...(error && {
        error: true,
        helperText: (
          <Typography style={{ fontFamily: "Montserrat", color: "red" }}>
            {" "}
            {error}
          </Typography>
        ),
      })}
      {...others}
    />
  );
};

const StyledLocationField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      width: "100%",
      "& fieldset": {
        borderRadius: `10px`,
        // innerWidth:'20vw',
        height: "52px",
        color: "#444445",
      },
      "& input": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `12px`,
        color: `#444445`,
        lineHeight: `30px`,
        padding: `12.5px 14px`,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        fontSize: "5px",
      },
      "& .MuiOutlinedInput-root": {
        fontSize: "5px",
      },
    },

    ["@media (max-width: 450px)"]: {
      "& .MuiTextField-root": {
        width: "100%",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "10px",
      },
      // "& .MuiOutlinedInput-root":{
      //   width:"367px"
      // }
    },
  },
})(TextField);

const StyledLocation = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  ...others
}) => {
  return (
    <div>
      <StyledLocationField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ fontSize: "5px" }}>
              {icon}
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        autoComplete="off"
        value={value}
        type={type}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        variant="outlined"
        {...others}
      />
    </div>
  );
};

const StyledInputBox = withStyles({
  fontSize: "12px",
  "& .MuiInputBase-root": {
    fontSize: "12px",
  },
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      width: "100%",
      height: "56px",

      "&.Mui-focused fieldset": {
        borderColor: "#FEDD17",
        fontSize: "12px",
      },
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important",
      },
      "&:hover fieldset": {
        borderColor: "#FEDD17",
      },
      "& fieldset": {
        borderRadius: `10px`,
        innerWidth: "18vw",
        color: "#67748E",
        fontWeight: "lighter",
        fontSize: "12px",
        fontStyle: "Normal",
      },
    },
    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "#252F40",
      padding: "14.8px 8px",
      backgroundColor: "white",
      height: "10px",
      borderRadius: "10px",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    "& ::placeholder": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "black",
    },
    ["@media (max-width: 450px)"]: {
      // "& .MuiOutlinedInput-root":{
      //   width:"87vw",
      // }
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
    },
  },
})(TextField);

const StyledIconInput = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  ...others
}) => {
  return (
    <StyledInputBox
      InputProps={{
        endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        borderRadius: "110px",
      }}
      disabled={disabled}
      autoComplete="off"
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      variant="outlined"
      className={className}
    />
  );
};

const StyledSearchBox = withStyles({
  fontSize: "12px",
  "& .MuiInputBase-root": {
    fontSize: "12px",
  },
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      borderRadius: "8px",
      width: "100%",

      "&.Mui-focused fieldset": {
        borderColor: "#FEDD17",
        fontSize: "12px",
      },
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important",
      },
      "&:hover fieldset": {
        borderColor: "#FEDD17",
      },
      "& fieldset": {
        borderRadius: `10px`,
        innerWidth: "18vw",
        height: "46px",
        color: "#67748E",
        fontWeight: "lighter",
        fontSize: "12px",
        fontStyle: "Normal",
      },
    },
    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "#252F40",
      padding: "14.8px 8px",
      backgroundColor: "white",
      height: "10px",
      borderRadius: "10px",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    "& ::placeholder": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "black",
    },
    ["@media (max-width: 450px)"]: {
      // "& .MuiOutlinedInput-root":{
      //   width:"87vw",
      // }
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
    },
  },
})(TextField);

const StyledSearch = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  onInput,
  others
}) => {
  return (
    <StyledSearchBox
      InputProps={{
        endAdornment: <InputAdornment   position="end">{icon}</InputAdornment>,
        borderRadius: "110px",
      }}
      disabled={disabled}
      autoComplete="off"
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      onInput={onInput}
      name={name}
      variant="outlined"
      className={className}
      {...others}
      inputProps={{maxLength:200}}
    />
  );
};

const DealerStyledSearchBox = withStyles({
  fontSize: "12px",
  "& .MuiInputBase-root": {
    fontSize: "12px",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      fontSize: "12px",
      borderRadius: "8px",
    },
    borderRadius: "8px",
    width: "100%",
    "& $notchedOutline": {
      borderColor: "green",
    },
    "&:hover $notchedOutline": {
      borderColor: "red",
    },
    "&$focused $notchedOutline": {
      borderColor: "purple",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      borderRadius: "8px",
      "&.Mui-focused fieldset": {
        borderColor: "#FEDD17",
      },
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important",
      },
      "&:hover fieldset": {
        borderColor: "#FEDD17",
      },
      "& fieldset": {
        borderRadius: `10px`,
        innerWidth: "18vw",
        height: "46px",
        color: "#67748E",
        fontWeight: "lighter",
        fontSize: "12px",
        fontStyle: "Normal",
      },
    },
    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "#252F40",
      padding: "14.8px 8px",
      backgroundColor: "white",
      height: "10px",
      borderRadius: "10px",
      fontFamily: "Montserrat",
      fontSize: "12px",
    },
    "& ::placeholder": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "black",
    },
    ["@media (max-width: 500px)"]: {
      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontSize: "12px",
      },
    },
  },
})(TextField);

const FullWidthSearch = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  ...others
}) => {
  return (
    <DealerStyledSearchBox
      InputProps={{
        endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        borderRadius: "110px",
      }}
      disabled={disabled}
      autoComplete="off"
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      variant="outlined"
      inputProps={{maxLength:200}}
    />
  );
};

const DetailsInputBox = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `8px`,
        width: "100%",
        height: "48px",
        color: "#444445",
      },
      "& input": {
        fontFamily: `Montserrat`,
        fontStyle: `Normal`,
        fontWeight: `400`,
        fontSize: `12px`,
        color: `#444445`,
        lineHeight: `30px`,
        padding: `12.5px 14px`,
        outline: `none`,
      },
      ["@media (max-width: 450px)"]: {
        width: "100%",
      },
    },
  },
})(TextField);

const DetailsInput = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  ...others
}) => {
  return (
    <DetailsInputBox
      InputProps={{
        borderRadius: "110px",
      }}
      disabled={true}
      autoComplete="off"
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      name={name}
      variant="outlined"
      // {...others}
    />
  );
};

const useStyles = makeStyles({
  input: {
    width: "350px",
    height: "40px",
    fontFamily: "Montserrat !important",

    "& input + fieldset": {
      borderColor: "#D2D6DA",
      borderRadius: "30",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Montserrat !important",
    },

    "& input + fieldset": {
      borderColor: "#D2D6DA",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,

      "& input + fieldset": {
        borderColor: "#D2D6DA",
        borderRadius: "30",
      },
    },
    searchIcon: {
      color: "#67748E",
    },
  },
  root: {
    "& .MuiFormHelperText-root": {
      position: "absolute",
    },
    "& .Mui-error": {
      position: "absolute",
    },
  },

  // "& .MuiOutlinedInput-inputAdornedEnd":{
  //   fontFamily:"Montserrat"
  // }
});

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "red",
    fontFamily: "Montserrat",
    marginLeft: "-1px",
  },
}));

const InputField = ({
  value,
  onChange,
  placeholder,
  className,
  error = null,
  name,
  icon,
  type,
  disabled,
  position,
  onKeyDown,
  inputProps,
  readOnly=false,
  ...others
}) => {
  const helperTextStyles = useHelperTextStyles();
  const classes = useStyles();
  return (
    <>
      <TextField
        InputProps={{
          endAdornment: <InputAdornment  position="end">{icon}</InputAdornment>,
          borderRadius: "110px",
          fontFamily: "Montserrat !important",
          readOnly:readOnly
        }}
        disabled={disabled}
        
        variant="outlined"
        autoComplete="off"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={className}
        onKeyDown={onKeyDown}
        inputProps={inputProps}
        {...others}
        {...(error && { error: true, helperText: error })}
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.root,
          },
        }}
        style={{ fontFamily: "Montserrat" }}
      />
    </>
  );
};

export {
  CustomDocumentInput,
  CustomSearchInput,
  StyledInput,
  StyledSearch,
  FullWidthSearch,
  StyledLocation,
  DetailsInput,
  StyledIconInput,
};

export default InputField;
