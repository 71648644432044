import React from 'react'
import { Link } from "react-router-dom"
import { MdArrowBackIosNew } from 'react-icons/md'
import makeStyles from "../../sharedFeatures/sharedClasses";
import { TXT_DEALER_DETAIL } from "../../utilitis/header";
import Sub from '../../common/Subheader/Sub';
import { Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom'


const DealerBankDetails = () => {
  const styles = makeStyles();
  const { id } = useParams();

  return (
    <>
      <Paper className={styles.parentContainerOne}>


        <div className={styles.headerParent}>
          <Link to="/administrator/dealerDetails/${item.sl_no}">
            <MdArrowBackIosNew className={styles.backButton} />
          </Link>
          <Sub data={TXT_DEALER_DETAIL} />


        </div>
        DealerBankDetails

      </Paper>
    </>
  )
}

export default DealerBankDetails