import React from 'react'

const UpArrowGreen = () => {
    return (
        <>
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99472 11.0073L6.99472 3.42132L9.28772 5.71432C9.5388 5.97428 9.9106 6.07854 10.2602 5.98702C10.6099 5.89551 10.8829 5.62246 10.9744 5.27283C11.0659 4.9232 10.9617 4.5514 10.7017 4.30032L6.70172 0.300324C6.51411 0.112241 6.25938 0.00653648 5.99372 0.00653648C5.72807 0.00653648 5.47333 0.112241 5.28572 0.300324L1.28572 4.30032C0.895255 4.69134 0.895703 5.32486 1.28672 5.71532C1.67774 6.10579 2.31125 6.10534 2.70172 5.71432L4.99472 3.42132L4.99472 11.0073C4.99472 11.5596 5.44244 12.0073 5.99472 12.0073C6.54701 12.0073 6.99472 11.5596 6.99472 11.0073Z" fill="#82D616" />
            </svg>
        </>
    )
}

export default UpArrowGreen