import React, { useState } from "react";
import { Paper, IconButton, Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  TXT_ASSIGNEMERGENCY,
  TXT_ADD_EMERGENCY_REQUEST,
  ADD_EMERGENCY_REQUEST,
} from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import HeadBar from "../../components/HeadBar/HeadBar";
import Controls from "../../common/Controls/Controls";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import Sub from "../../common/Subheader/Sub";
import LocationIcon from "../../utilitis/Location";
import IconButs from "../../common/Controls/IconButs";

const AssignEmergency = () => {
  const [search, setSearch] = useState();
  const styles = makeStyles();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const brand = [
    {
      id: "1",
      name: "Honda",
    },
    {
      id: "2",
      name: "Maruthi",
    },
    {
      id: "3",
      name: "Toyota",
    },
  ];
  const model = [
    {
      id: "1",
      name: "city",
    },
    {
      id: "1",
      name: "swift",
    },
    {
      id: "1",
      name: "Glanza",
    },
  ];
  const makeYear = [
    {
      id: "1",
      name: "2020",
    },
    {
      id: "2",
      name: "2021",
    },
    {
      id: "3",
      name: "2022",
    },
  ];

  return (
    <>
      <HeadBar title={TXT_ASSIGNEMERGENCY} />

      <div className={styles.divStyle}>
        <Paper className={styles.parentContainer} elevation="6">
          <div className={styles.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to="/administrator/asigntodealer">
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_ADD_EMERGENCY_REQUEST} />
          </div>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className={styles.addlabel}>
                      Customer Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controls.InputField
                      name="name"
                      placeholder="Name"
                      fullWidth
                      className={styles.stepperFormTextBar}
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className={styles.addlabel}>
                      Mobile Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controls.InputField
                      name="name"
                      placeholder="Mobile Number"
                      fullWidth
                      className={styles.stepperFormTextBar}
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className={styles.addlabel}>
                      Choose Emergency Service
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controls.SelectBox
                      name="name"
                      label="Select Emergency Service"
                      fullWidth
                      className={styles.basicSelectBox}
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className={styles.addlabel}>
                      Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controls.InputField
                      name="name"
                      placeholder="Select a location"
                      fullWidth
                      className={styles.stepperFormTextBar}
                      inputProps={{ style: { textAlign: "left" } }}
                      icon={<LocationIcon />}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography className={styles.addlabel}>
                      Select Car
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Controls.SelectBox
                      label={"Brand"}
                      options={brand}
                      className={styles.basicSelectBox}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Controls.SelectBox
                      label={"Model"}
                      options={model}
                      className={styles.basicSelectBox}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Controls.SelectBox
                      label={"makeyear"}
                      options={makeYear}
                      className={styles.basicSelectBox}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className={styles.addlabel}>
                      Total Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controls.InputField
                      name="name"
                      placeholder="Price"
                      fullWidth
                      className={styles.stepperFormTextBar}
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Box className={styles.tableBox}></Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className={styles.formIconCentered}>
              <Link to="/administrator/asigntodealer">
                <IconButs
                  ibtname={ADD_EMERGENCY_REQUEST}
                  className={styles.formButtonAdd}
                  variant="outlined"
                />
              </Link>
            </div>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default AssignEmergency;
