import * as React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Modal,
  Typography,
  CardActionArea,
  Grid,
  IconButton,
} from "@material-ui/core";
import KwikfixIcon from "../../Sidebar/KwikfixIcon";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail, AiFillHome, AiOutlineClose } from "react-icons/ai";
import CustomLoader from "../../features/CustomLoader";
import useSalesPersonQuery from "../../data/sales/useSalesPersonQuery";
import salesStyle from "./salesStyle";
import { toast } from "material-react-toastify";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  // width: 900,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  ["@media (max-width: 600px)"]: {
    width: "90vw",
  },
};

const SalesVisitingCardDetails = ({ open, handleClose, id }) => {
  const viewStyle = makeStyles();
  const salesStyles = salesStyle();

  const {
    isLoading,
    data: salesPerson,
    isFetching,
    error,
    isError
  } = useSalesPersonQuery.salesPerson_By_Id(id);
  useEffect(()=>{
    if(error &&
      error.response &&
      error.response.data &&
      error.response.data.detail){
         toast.warn(error &&
          error.response &&
          error.response.data &&
          error.response.data.detail)
      }
  },[isError])
  if (isLoading) {
    return <CustomLoader />;
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className={viewStyle.closeBtn}>
            <IconButton onClick={handleClose}>
              <AiOutlineClose />
            </IconButton>
          </div>
          <Card sx={{ maxWidth: 400 }}>
            <div>
              <CardMedia className={viewStyle.cardMedia}>
                <KwikfixIcon />
              </CardMedia>
              <CardContent style={{ padding: "0px" }}>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "10px",
                    marginTop: "40px",
                  }}
                >
                  {/* <Typography>Sumini Mani</Typography> */}
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    style={{ marginBottom: "25px" }}
                  >
                    <Grid item xs={2} md={6}>
                      <IconButton>
                        <BsFillTelephoneFill />
                        <Typography
                          className={salesStyles.visitingCardDetails2}
                        >
                          Mobile Number
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Typography className={salesStyles.visitingCardDetails}>
                        {salesPerson && salesPerson.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={6}>
                      <IconButton>
                        <AiFillMail />
                        <Typography
                          className={salesStyles.visitingCardDetails2}
                        >
                          Email
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Typography className={salesStyles.visitingCardDetails}>
                        {salesPerson && salesPerson.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={6}>
                      <IconButton>
                        <AiFillHome />
                        <Typography
                          className={salesStyles.visitingCardDetails2}
                        >
                          Address
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Typography className={salesStyles.visitingCardDetails}>
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.address_line_one}
                        ,
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.address_line_two}
                        ,
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.locality}
                        ,
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.city}
                        ,
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.country}
                        ,
                        {salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.pincode}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </div>
          </Card>
        </Box>
      </Modal>
    </div>
  );
};

export default SalesVisitingCardDetails;
