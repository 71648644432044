import { useMutation, useQueryClient } from "react-query";
import { addUserRole, editUserRole } from "./userRoleUrls";

//add a dealer custom query-function here

const add_user_role = () => {
  const queryClient = useQueryClient();
  return useMutation((role) => addUserRole(role), {
    onMutate: async (newRole) => {
      await queryClient.cancelQueries("userroleslist");
      const prev = queryClient.getQueryData("userroleslist");
      queryClient.invalidateQueries("usertypes-create");
      return {
        prev,
        newRole,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("userroleslist");
      queryClient.invalidateQueries("usertypes-create");//refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("userroleslist", context.prev); //rollback the cache to the previous state
    },
  });
};

const edit_user_role = () => {
  const queryClient = useQueryClient();
  return useMutation((role) => editUserRole(role), {
    onMutate: async (newRole) => {
      await queryClient.cancelQueries("userroleslist");

      const prev = queryClient.getQueryData("userroleslist");
      return {
        prev,
        newRole,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("userroleslist"); //refetch the collection on the background

      queryClient.invalidateQueries("userroledetails");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("userroleslist", context.prev); //rollback the cache to the previous state
    },
  });
};

const useAddRole = {
  add_user_role,
  edit_user_role,
};

export default useAddRole;
