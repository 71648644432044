import { useMutation, useQueryClient } from "react-query";
import { addCoupon,updateCouponStatus } from "./couponUrls";


const add_coupon = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addCoupon(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("couponslist")

      const prev = queryClient.getQueryData("couponslist")

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
  
      queryClient.invalidateQueries("couponslist");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("couponslist", context.prev)
    },
  });
};

// const coupon_status = () =>{
//   const queryClient = useQueryClient()
//   return useMutation((todoId)=>deleteCoupon(todoId),{
//     onMutate:async (todo)=>{
//       await queryClient.cancelQueries("couponslist")


//       const prev = queryClient.getQueryData("couponslist")

//       queryClient.setQueryData("couponslist",(old=[])=> {} )
      
//       // old.results.filter((item)=>item.id!==todo.id)
    

//       return {
//         prev,
//         todo
//       }

//     },
//     onError:(err,_,context)=>{
//       queryClient.setQueryData("salesPersonList",context.prev)
//     },
//     onSettled:(todo)=>{
//      queryClient.invalidateQueries("salesPersonList")
//     }
//   })
// }



const update_coupon_status = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => updateCouponStatus(id), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("couponslist");

      const prev = queryClient.getQueryData("couponslist");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("couponslist");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("couponslist", context.prev);
    },
  });
};

const useAddCouponQuery ={
    add_coupon,
    update_coupon_status
}

export default useAddCouponQuery