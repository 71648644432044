import * as React from "react";
import {
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";

const CheckBoxLabel = ({
  name,
  label,
  value,
  onChange,
  checked,
  className,
}) => {


  return (
    <FormGroup className={className}>
      <FormControlLabel
        className={className}
        control={
          <MuiCheckbox
            color="primary"
            checked={checked ? true : false}
            name={name}
            onChange={onChange}
            className={className}
          />
        }
        label={
          <Typography style={{ fontSize: "14px", fontFamily: "Montserrat" }}>
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default CheckBoxLabel;
