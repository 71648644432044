import React, { useState } from "react";
import { Paper, IconButton, Typography, Box } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import makeStyles from "../../sharedFeatures/sharedClasses";
import Sub from "../../common/Subheader/Sub";
import Chip from "@material-ui/core/Chip";
import ComigSoonDialog from "../../components/ComingSoonDialog";
import {
  TXT_DEALERMANAGEMENT,
  ASSIGN_DEALER_TECHNICIAN,
  DEALER_LIST_DOC,
  TXT_DEALER_VIEW,
} from "../../utilitis/header";
import LocationIcon from "../../utilitis/Location";
import { Link } from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import useDealersQuery from "../../data/dealer/useDealersQuery";
import { EndPoint } from "../../config/config";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import dealerStyles from "../DealerManagement/dealerStyle";
import DealerBankDetailsModal from "./DealerBankDetailPage";
import DealerTechnicianOnboard from "./DealerTechnicianOnboard";
import { FindLink } from "../../sharedFeatures/FindLink";

const DealerDetails = () => {
  const typeofshop = [
    { id: 1, name: "Big" },
    { id: 2, name: "Medium" },
    { id: 3, name: "Small" },
  ];

  const shopcategory = [
    { id: 1, name: "Premium workshop" },
    { id: 2, name: "Standard vehicle workshop" },
    { id: 3, name: "Small workshop" },
  ];

  const servicecategory = [
    { id: 1, name: "Trading" },
    { id: 2, name: "Services" },
    { id: 3, name: "Services & Trading" },
  ];

  const gstTreatments = [
    { id: 1, name: "Registered Business - Regular" },
    { id: 2, name: "Registered Business - Composition" },
    { id: 3, name: "Unregistered Business" },
    { id: 4, name: "Overseas" },
    { id: 5, name: "Special Economic Zone" },
    { id: 6, name: "Deemed Export" },
    { id: 7, name: "Tax Deductor" },
    { id: 8, name: "SEZ Developer" },
  ];

  const [status, setStatus] = useState(false);
  const [technical, setTechnical] = useState(false);
  const [title, setTitle] = useState("This is a alert");
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  
  //aimee
 let link = FindLink("/administrator/dealers");
  
  const { data: dealersData } = id ? useDealersQuery.dealers_data(id) : "";

  const { data: dealerTechnicianData, isLoading: detailsLoading } = id
    ? useDealersQuery.get_dealer_technician_by_dealer(id)
    : "";

  const acNumber =
    dealersData &&
    dealersData.data.bank_details.map((item) => {
      return item.account_number;
    });

  const acName =
    dealersData &&
    dealersData.data.bank_details.map((item) => {
      return item.account_name;
    });

  const bnkName =
    dealersData &&
    dealersData.data.bank_details.map((item) => {
      return item.bank_name;
    });

  const branch =
    dealersData &&
    dealersData.data.bank_details.map((item) => {
      return item.branch;
    });

  const ifsc =
    dealersData &&
    dealersData.data.bank_details.map((item) => {
      return item.ifsc;
    });

  const country =
    dealersData &&
    dealersData.data.brand_id.map((item) => {
      return item.country_of_origin;
    });

  const styles = makeStyles();
  const dealerClass = dealerStyles();
  const serviceCategory =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].service_category;
  const shopCategory =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].shop_category;
  const productCategory = dealersData && dealersData.data.brand_id;
  const shopType =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].shop_type;
  const facility =
    dealersData &&
    dealersData.data &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].facilities.facilities
      ? dealersData.data.shop_details[0].facilities.facilities
      : dealersData &&
        dealersData.data &&
        dealersData.data.shop_details[0] &&
        dealersData.data.shop_details[0].facilities;

  const addr =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.locality;
  const lctn =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.location;

  const addr_lane_one =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.address_line_one;
  const addr_lane_two =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.address_line_two;
  const locality =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.locality;
  const city =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.city;
  const pincode =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].address &&
    dealersData.data.shop_details[0].address.pincode;

  const gstNumber =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].gst_number;

  const companyName =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].shop_name;

  const website =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].website;

  const treatmentID =
    dealersData &&
    dealersData.data.shop_details[0] &&
    dealersData.data.shop_details[0].gst_treatment;

  const gstTreatment = treatmentID && gstTreatments[treatmentID - 1].name;

  let productDetails =
    dealersData &&
    dealersData.data.brand_id &&
    dealersData.data.brand_id.map((item) => {
      if (item.category_id.type === 1) {
        return item.name;
      }
    });

  productDetails =
    productDetails &&
    productDetails.filter(function (element) {
      return element !== undefined;
    });

  let serviceDetails =
    dealersData &&
    dealersData.data.brand_id &&
    dealersData.data.brand_id.map((item) => {
      if (item.category_id.type === 2) {
        return item.category_id.name;
      }
    });

  serviceDetails =
    serviceDetails &&
    serviceDetails.filter(function (element) {
      return element !== undefined;
    });

  

  return (
    <>
      <HeadBar title={TXT_DEALERMANAGEMENT} />
      <div className={styles.divStyle}>
        <Paper className={styles.parentContainer} elevation="6">
          <div className={styles.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={'/administrator/dealers'}>
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>

            <Sub data={TXT_DEALER_VIEW} />
          </div>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    First Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Controls.StyledInput
                    name="name"
                    placeholder="name"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={dealersData && dealersData.data.first_name}
                    inputProps={{ style: { textAlign: "left" } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledInput
                    name="name"
                    placeholder="name"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={dealersData && dealersData.data.last_name}
                    inputProps={{ style: { textAlign: "left" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "none", xs: "none", md: "block" }}
              md={6}
              lg={6}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Service Category
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Controls.StyledInput
                    name="service_category"
                    placeholder="service_category"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={
                      serviceCategory == 1
                        ? "Trading"
                        : serviceCategory == 2
                        ? "Services"
                        : "Services & Trading"
                    }
                    inputProps={{ style: { textAlign: "left" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Contact Email
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledInput
                    name="email"
                    placeholder=" example@email.com"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={dealersData && dealersData.data.email}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "none", xs: "none", md: "block" }}
              md={6}
              lg={6}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Products
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <div className={dealerClass.inputBox} name="brand">
                    {productDetails &&
                      productDetails.length > 0 &&
                      productDetails.map((item) => {
                        return (
                          <Chip
                            key={item}
                            label={
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </span>
                            }
                            style={{ margin: "5px" }}
                          />
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Contact Mobile
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledInput
                    name="mobile"
                    placeholder="mobile"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={dealersData && dealersData.data.phone}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "none", xs: "none", md: "block" }}
              md={6}
              lg={6}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Services
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <div className={dealerClass.inputBox} name="brand">
                    {serviceDetails &&
                      serviceDetails.length > 0 &&
                      serviceDetails.map((item) => {
                        return (
                          <Chip
                            key={item}
                            label={
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </span>
                            }
                            style={{ margin: "5px" }}
                          />
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Company Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledInput
                    name="companyName"
                    placeholder="companyName"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={companyName}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Website
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Controls.StyledInput
                    name="website"
                    placeholder="website"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={website}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "block", xs: "block", md: "none" }}
              sm={12}
              xs={12}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Service Category
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Controls.StyledInput
                    name="service_category"
                    placeholder="service_category"
                    fullWidth
                    className={dealerClass.formTextBar}
                    value={
                      serviceCategory == 1
                        ? "Trading"
                        : serviceCategory == 2
                        ? "Services"
                        : "Services & Trading"
                    }
                    inputProps={{ style: { textAlign: "left" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "block", xs: "block", md: "none" }}
              sm={12}
              xs={12}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Products
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <div className={dealerClass.inputBox} name="brand">
                    {productDetails &&
                      productDetails.length > 0 &&
                      productDetails.map((item) => {
                        return (
                          <Chip
                            key={item}
                            label={
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </span>
                            }
                            style={{ margin: "5px" }}
                          />
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display={{ sm: "block", xs: "block", md: "none" }}
              sm={12}
              xs={12}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Services
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <div className={dealerClass.inputBox} name="brand">
                    {serviceDetails &&
                      serviceDetails.length > 0 &&
                      serviceDetails.map((item) => {
                        return (
                          <Chip
                            key={item}
                            label={
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </span>
                            }
                            style={{ margin: "5px" }}
                          />
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledLocation
                    placeholder="Location"
                    className={dealerClass.locationfield}
                    fullWidth
                    icon={<LocationIcon style={{ fontSize: "5px" }} />}
                    value={lctn}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Bank Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Link>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={dealerClass.listButton}
                      fullWidth
                      onClick={handleClick}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Address
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "5px",
                  }}
                >
                  <Controls.InputField
                    name="address_line_one"
                    placeholder="Address Line 1"
                    value={addr_lane_one}
                    className={dealerClass.addressBar2}
                  />
                  <Controls.InputField
                    name="address_line_two"
                    placeholder="Address Line 2"
                    className={dealerClass.addressBar2}
                    value={addr_lane_two}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "5px",
                  }}
                >
                  <Controls.InputField
                    name="locality"
                    type="text"
                    placeholder="Locality"
                    className={dealerClass.addressBar2}
                    value={locality}
                  />

                  <Controls.InputField
                    name="city"
                    placeholder="City"
                    value={city}
                    className={dealerClass.addressBar2}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      marginTop: "10px",
                    }}
                  >
                    <Typography className={dealerClass.viewlabel}>
                      Country & Zip Code
                    </Typography>
                  </Box>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <Typography className={dealerClass.viewlabel}>
                      Country
                      <br /> & Zip Code
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "5px",
                  }}
                >
                  <Controls.InputField
                    name="country"
                    placeholder="Country"
                    disabled
                    value="India"
                    className={dealerClass.addressBar2}
                  />

                  <Controls.InputField
                    name="pincode"
                    type="number"
                    placeholder="Pin Code"
                    value={pincode}
                    className={dealerClass.addressBar2}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} alignItems="center">
              <Grid
                container
                alignItems="center"
                className={dealerClass.gstGridStyle}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={4}
                  sx={{ marginBottom: { xs: "0px", md: "25px" } }}
                >
                  <Typography className={dealerClass.viewlabel}>
                    GST Treatment
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Box sx={{ marginBottom: { xs: "1.875rem", md: "25px" } }}>
                    <Controls.DetailsInput
                      name="GST Treatment"
                      placeholder="GST Treatment"
                      fullWidth
                      className={dealerClass.formTextBar}
                      value={gstTreatment}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    GST Number
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Box sx={{ marginBottom: { xs: "1.875rem", md: "0px" } }}>
                    <Controls.DetailsInput
                      name="GST Number"
                      placeholder="GST Number"
                      fullWidth
                      className={dealerClass.formTextBar}
                      value={gstNumber}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Inventory Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Link>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={dealerClass.listButton}
                      fullWidth
                      onClick={() => {
                        setTitle("This feature is coming soon");
                        setStatus(true);
                      }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Techncian Onboarded
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Link>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={dealerClass.listButton}
                      fullWidth
                      onClick={() => {
                        setTechnical(true);
                        setTitle("This feature is coming soon");
                      }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Type of Shop
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Controls.DetailsInput
                    name="shop_type"
                    fullWidth
                    placeholder="shop type"
                    variant="outlined"
                    options={typeofshop}
                    value={
                      (shopType == 1 && "A+") ||
                      (shopType === 2 && "A") ||
                      (shopType === 3 && "B+") ||
                      (shopType === 4 && "B") ||
                      (shopType === 5 && "C")
                    }
                    className={dealerClass.formTextBar}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Documents
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Link
                    to={`/administrator/dealerDetails/dealersViewDocuments/${id}`}
                  >
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={dealerClass.listButton}
                      fullWidth
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Facilities
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  <Grid item xs={12} sm={12}>
                    <div className={dealerClass.inputBox} name="brand">
                      {(typeof(facility) === "string")? 
                       <Chip
                       key={facility}
                       label={
                         <span
                           style={{
                             fontFamily: "Montserrat",
                             fontSize: "12px",
                           }}
                         >
                           {facility}
                         </span>
                       }
                       style={{ margin: "5px" }}
                     />
                     :
                     facility &&
                        facility.length > 0 &&
                        facility.map((item) => {
                          return (
                            <Chip
                              key={item}
                              label={
                                <span
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item}
                                </span>
                              }
                              style={{ margin: "5px" }}
                            />
                          );

                        })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Typography className={dealerClass.viewlabel}>
                    Shop Category
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Controls.StyledInput
                    name="shop_category"
                    placeholder="Premium"
                    fullWidth
                    variant="outlined"
                    options={shopcategory}
                    value={
                      shopCategory == 1
                        ? "Premium workshop"
                        : shopCategory == 2
                        ? "Standard vehicle workshop"
                        : "Small workshop"
                    }
                    className={dealerClass.formTextBar}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <Typography className={dealerClass.viewlabel}>
                    Onboarding Sales Person
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8}>
                  {/* <Link>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={dealerClass.listButton}
                      fullWidth
                      onClick={() => {
                        setStatus(true);
                        setTitle("This feature is coming soon");
                      }}
                    />
                  </Link> */}
                  <Controls.DetailsInput
                    fullWidth
                    variant="outlined"
                    value={
                      dealersData &&
                      dealersData.data &&
                      dealersData.data.shop_details[0] &&
                      dealersData.data.shop_details[0].created_by &&
                      dealersData.data.shop_details[0].created_by.first_name
                    }
                    className={dealerClass.formTextBar}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className={styles.formIconCentered} >
              {/* <Link to={link}> */}
                <IconButs
                onClick={()=>window.history.back()}
                  ibtname={ASSIGN_DEALER_TECHNICIAN}
                  className={styles.formButtonAdd}
                  variant="outlined"
                />
              {/* </Link> */}

            </Box>
          </Grid>
          {status ? (
            <ComigSoonDialog
              open={status}
              content={title}
              handleClose={() => setStatus(false)}
            />
          ) : (
            ""
          )}

          {technical ? (
            <DealerTechnicianOnboard
              open={technical}
              handleClose={() => setTechnical(false)}
              data={dealerTechnicianData}
              loading={detailsLoading}
            />
          ) : null}

          {open ? (
            <DealerBankDetailsModal
              acountName={acName}
              bankName={bnkName}
              ifsCode={ifsc}
              braNch={branch}
              acountNumber={acNumber}
              open={open}
              handleClose={handleClick}
            />
          ) : null}
        </Paper>
      </div>
    </>
  );
};

export default DealerDetails;
