import DataService from "../../DataService/DataService";

const couponDetails = async ({ searchQry, page, perPageItems }) => {
  let url = ``;
  if (searchQry && page && perPageItems) {
    url = `coupon/coupon/?search=${searchQry}&page_size=${perPageItems}&page=1`;
  } else if (searchQry && page) {
    url = `coupon/coupon/?search=${searchQry}&page=1`;
  } else if (searchQry && perPageItems) {
    url = `coupon/coupon/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (page && perPageItems) {
    url = `coupon/coupon/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `coupon/coupon/?page=${page}`;
  } else if (perPageItems) {
    url = `coupon/coupon/?page_size=${perPageItems}`;
  } else {
    url = `coupon/coupon/?page=${page}`;
  }
  const data = await DataService.get(url);
  return data.data;
};


const addCoupon = async (value) => {
  const url = "coupon/coupon/";
  const { ...state } = value;
  const data = await DataService.post(url, value);
  return data;
};

// const deleteCoupon = async (value) => {
//   const url = "coupon/coupon/";
//   const { ...state } = value;
//   const data = await DataService.delete(url, value);
//   return data;
// };

const updateCouponStatus = async (id) => {
  const url = `coupon/coupon-status-change/${id}/`;
  const data = await DataService.patch(url);
  return data;
};

export {
  updateCouponStatus,
    addCoupon,
    couponDetails
    // deleteCoupon
  };