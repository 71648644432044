import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import HeadCells from "../../common/Headcells";
import { BsSearch } from "react-icons/bs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import {
  TXT_ADVISORYOFICER,
  ICON_BUTTON_INVENTORYMANAGEMENT,
} from "../../utilitis/header";
import moment from "moment";
import AssignLinkedOrders from "./AssignLinkedOrders";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import { AiOutlinePlus } from "react-icons/ai";
import HeadBar from "../../components/HeadBar/HeadBar";
import { useForm } from "../../sharedFeatures/useForm";
import { Pagination } from "@material-ui/lab";
import DropDown from "../../common/Controls/ListingDropdown";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import OrderManagmentStyle from "./OrderManagmentStyle";
import financeStyles from "../FinanceManagement/financeStyles";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import CustomLoader from "../../features/CustomLoader";
import usePagination from "../../common/Controls/usePagination";
import useDebounce from "../../utilitis/debounceSearch/useDebounce";
import AssignDealerTechnicianModal from "./AssignDealerTechnicianModal";
import ChangeStatusModal from "./ChangeStatusModal";
import NavigateLink from "../../common/Controls/NavigateLink";
import { SearchOffSharp } from "@mui/icons-material";

const Orders = () => {

  const location = useLocation();
  let backQry = location.state && location.state.qxy || null;

  const { TblContainer, TblHead } = useTable(HeadCells.asignToOficeAdvisor);
  const inventory = InventoryManagmentStyle();
  const styles = makeStyles();
  const orderStyle = OrderManagmentStyle();
  const customStyle = financeStyles();
  const [data, setData] = useState("");
  // const [search, setSearch] = useState();
  // const [pageItems, setPageItems] = useState(10);
  // const [currPage, setCurrPage] = useState(1);
  
  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const selected = searchParams.get("selected");
  
  
  const [filter, setFIlter] = useState(selected !== null? selected: "");
  const [search, setSearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currPage, setCurrPage] = useState(page !== null ? page : 1);
  

  const searchUser = (e) => {
    setSearch(e.target.value);
  };
  const handlePageitems = (e) => {
    setPageItems(e.target.value);
  };

  

  // const handleOver = (e) => {
  //   // console.log('event', e)
  //   // if (backQry && backQry.page){
  //   //   console.log('herereeer')
  //   //   backQry = null;
  //     setCurrPage(currentPage);
  //   //} 
  // };

  const {
    handleOpen,
    open,
    setOpen,
    values,
    handleInputChange,
    errors,
    setErrors,
  } = useForm();
  const debounceSearch = useDebounce(search, 500);
  const qry = {
    page: page !== null ? page :currPage,
    pageCount: pageItems,
    search: debounceSearch,
    filter: filter,
  };

  // useEffect(() => {
  //   const referringURL = document.referrer;
  //   console.log('Referring URL:', referringURL);
  //   let backQry = location.state && location.state.qxy || null;
  //   qry.page = backQry.page;
  //     qry.pageCount = backQry.pageCount;
  //     backQry = null
  // }, []);

  useEffect(() => {
    if (backQry && backQry.page) {
      qry.page = backQry.page;
      qry.pageCount = backQry.pageCount;
      backQry = null
    }
  }, []); // empty dependency array


  const {
    data: orderList,
    isLoading,
    isError,
    isFetching,
  } = useOrderQuery.get_order_List(qry);

  const { currentData, currentPage, maxPage, slNo } = usePagination(
    orderList !== undefined && orderList.data,
    pageItems
  );

  useEffect(() => {
    setCurrPage(currentPage);
  }, [currentPage]);
  

  useEffect(() => {
    if (currPage > maxPage) {
      setCurrPage(maxPage);
    }
  }, [pageItems]);

  useEffect(() => {
    setCurrPage(page !== null ? page :1);
  }, [debounceSearch, pageItems]);

  const [ordersModal, setOrdersModal] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [orderDetials, setOrderDetials] = useState("");

  const handleOrdersModal = () => {
    setOrdersModal(true);
  };
  const handleOrdersModalClose = () => {
    setOrdersModal(false);
  };
  const isDealerAssigned = (items) => {
    return items.filter((item) => {
      return item.dealer_id !== null;
    }).length > 0
      ? true
      : false;
  };

  
  return (
    <>
      <HeadBar title={TXT_ADVISORYOFICER} />
      <div className={styles.listdivStyle}>
        <div className={styles.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controls.StyledSearch
                value={search}
                placeholder="Search"
                className={inventory.searchBar}
                size="small"
                name="usersearch"
                onChange={searchUser}
                icon={<BsSearch />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography className={styles.viewlabel}>
                    Filter By 
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <DropDown
                    value={filter}
                    placeholder='Order Status'
                    items={[
                      { key: 1, value: "All" },
                      { key: 2, value: "New" },
                      { key: 3, value: "Confirmed" },
                      { key: 4, value: "Inprogress" },
                      { key: 5, value: "Completed" },
                      { key: 6, value: "Delivered" },
                      { key: 7, value: "Canceled" },
                    ]}
                    handlecreateNewItem={(e) => setFIlter(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Box sx={{ marginLeft: { xs: "0px", md: "5vw" } }}>
                <IconButs
                  ibtname={ICON_BUTTON_INVENTORYMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={styles.salesButton}
                  // onClick={handleClick}
                />
              </Box>
            </Grid> */}
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {isLoading || isFetching ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              ) : (
                orderList &&
                orderList.data &&
                orderList.data.data &&
                orderList.data.data.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell align="center">
                        <Link
                          to={`/administrator/asigntooficeadvisor/assignorderdetails/${item.id}`}
                          state={{ qry: qry }}
                          className={styles.linkColor}
                        >
                          {item.order_no}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {item.user_id.first_name}
                      </TableCell>
                      <TableCell align="center">
                        {item.status === 1 && "New"}
                        {item.status === 2 && "Confirmed"}
                        {item.status === 3 && "Inprogress"}
                        {item.status === 4 && "Completed"}
                        {item.status === 5 && "Delivered"}
                        {item.status === 6 && "Canceled"}
                      </TableCell>
                      <TableCell align="center">
                        {item.payment_status === 1 && "Paid"}
                        {item.payment_status === 0 && "Pending"}
                      </TableCell>
                      <TableCell align="center">
                        {item.advisor_id.email === ""
                          ? "Not assigned"
                          : item.advisor_id.first_name}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.created_at).format("DD/MM/YYYY, h:mm a")}
                      </TableCell>

                      <TableCell align="center">
                        <div className={styles.tableCell}>
                          {/* <div>
                            <Link to="#" className={styles.linkColorOne}>
                              Add
                            </Link>
                          </div> */}

                          {item.advisor_id.email !== "" &&
                            item.status !== 6 &&
                            item.status !== 5 && (
                              <div>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setStatusOpen(true);
                                    setOrderDetials(item);
                                    setData(item);
                                  }}
                                  className={styles.linkColorOne}
                                >
                                  Change Status
                                </Link>
                              </div>
                            )}

                          {!item.advisor_id.email && (
                            <div>
                              <Link
                                to={`/administrator/asigntoadvisor/${item.id}`}
                                className={styles.linkColorTwo}
                              >
                                Assign Advisor
                              </Link>
                            </div>
                          )}

                          {(item.advisor_id.email && item.status < 5) && (
                            <div className={styles.linkColorTwo}>

                              {/* <Link
                                to={`/administrator/reasigntoadvisor/${item.id}/${item.advisor_id.id}`}
                                className={styles.linkColor}
                              >
                                Re-Assign Advisor
                              </Link> */}
                              <NavigateLink
                              url={`/administrator/reasigntoadvisor/${item.id}/${item.advisor_id.id}`}
                              styleclass={styles.linkColor}
                              currentPage={currPage}
                              trySearch={search}
                              pageItems={pageItems}
                              selected={filter}
                              itemid={item.id}                              
                              display={<p className={styles.linkColor}>Re-Assign Advisor</p>}
                            />



                            </div>
                          )}
                          {(item.status >= 2 && item.status < 4) && (
                            <div className={orderStyle.reAssign}>
                              <div
                                className={styles.linkColor}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOrderDetials(item);
                                  setOpen(true);
                                }}
                              >
                                Assign Dealer
                              </div>
                            </div>
                          )}

                          {/* <div>
                            <Link to="" className={styles.linkColorThree}>
                              Cancel
                            </Link>
                          </div> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </TblContainer>
        </Box>
        {((orderList &&
          orderList.data &&
          orderList.data.data &&
          orderList.data.data.length === 0) ||
          isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
        <div
          className={customStyle.pagination}

          // className={maxPage}
        >
          <div className={customStyle.paginationStyle}>
            <DropDown
              value={pageItems}
              style={{ width: "90px", height: "34px" }}
              handlecreateNewItem={handlePageitems}
              items={[
                { key: 1, value: "10 " },
                { key: 2, value: "20 " },
              ]}
            />
            <span className={customStyle.paginationPage}>Per Page</span>
          </div>
          <Pagination
            className={styles.paginationCompStyle}
            count={maxPage}
            page={currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>

        {open ? (
          <AssignDealerTechnicianModal
            open={open}
            handleClose={handleOpen}
            title="Order Details"
            orderDetials={orderDetials && orderDetials}
            // assignDealersData={assignDealers}
          />
        ) : null}

        {ordersModal ? (
          <AssignLinkedOrders
            open={ordersModal}
            onModalClose={handleOrdersModalClose}
            title="Linked Orders"
          />
        ) : null}
        {statusOpen ? (
          <ChangeStatusModal
            open={statusOpen}
            handleClose={setStatusOpen}
            title="Change Status"
            orderDetials={orderDetials}
            data={data}
          />
        ) : null}
      </div>
    </>
  );
};

export default Orders;
