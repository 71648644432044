import { useQuery } from "react-query";
import { getVehicleList } from "./vehicleUrls";

export default function useVehicleList(qry) {
  return useQuery(["vehicleList", qry], () => getVehicleList(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
}
