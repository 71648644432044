import { useMutation, useQueryClient } from "react-query";
import {
  addDealer,
  docUpload,
  editDealer,
  approveDealer,
  emailCheck,
  deleteDealer,
  shopImageUpload,
  phoneCheck,
} from "./dealerUrls";

//add a dealer custom query-function here

const add_dealers = () => {
  const queryClient = useQueryClient();
  return useMutation((dealer) => addDealer(dealer), {
    onMutate: async (newDealer) => {
      await queryClient.cancelQueries("dealersList");

      const prev = queryClient.getQueryData("dealersList");
      return {
        prev,
        newDealer,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("dealersList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("dealersList", context.prev); //rollback the cache to the previous state
    },
  });
};

//Backend Validation customhook for Already existed Email

const email_exist_not = () => {
  const queryClient = useQueryClient();
  return useMutation((emailExist) => emailCheck(emailExist), {
    onMutate: async (newDealer) => {
      await queryClient.cancelQueries("dealersList");

      // const prev = queryClient.getQueryData('dealersList')
      // return {
      //     prev, newDealer
      // }
    },
    onSettled: () => {
      // queryClient.invalidateQueries('dealersList') //refetch the collection on the background
    },
    onError: (err, _, context) => {
      return err.response.data;
      // queryClient.setQueryData('dealersList', context.prev) //rollback the cache to the previous state
    },
  });
};

//Backend Validation customhook for Already existed Phone Number

const phone_exist_not = () => {
  const queryClient = useQueryClient();
  return useMutation((phoneExist) => phoneCheck(phoneExist), {
    onMutate: async (newDealer) => {
      await queryClient.cancelQueries("dealersList");

      // const prev = queryClient.getQueryData('dealersList')
      // return {
      //     prev, newDealer
      // }
    },
    onSettled: () => {
      // queryClient.invalidateQueries('dealersList') //refetch the collection on the background
    },
    onError: (err, _, context) => {
      return err.response.data;
      // queryClient.setQueryData('dealersList', context.prev) //rollback the cache to the previous state
    },
  });
};

//Edit an dealer custom query-function here

const edit_dealers = () => {
  const queryClient = useQueryClient();
  return useMutation((dealer) => editDealer(dealer), {
    onMutate: async (newDealer) => {
      await queryClient.cancelQueries("dealersList");

      const prev = queryClient.getQueryData("dealersList");
      return {
        prev,
        newDealer,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("dealersList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("dealersList", context.prev); //rollback the cache to the previous state
    },
  });
};

//aprove a dealer custom query-function here

const approve_dealers = () => {
  const queryClient = useQueryClient();
  return useMutation((dealer) => approveDealer(dealer), {
    onMutate: async (newDealer) => {
      await queryClient.cancelQueries("dealersList");

      const prev = queryClient.getQueryData("dealersList");
      return {
        prev,
        newDealer,
      };
    },
    onSettled: () => {
      // queryClient.invalidateQueries('dealersList') //refetch the collection on the background
    },
    onError: (err, _, context) => {
      // queryClient.setQueryData('dealersList', context.prev) //rollback the cache to the previous state
    },
  });
};

// custom query-function here

const doc_upload = () => {
  const queryClient = useQueryClient();
  return useMutation((documents) => docUpload(documents), {
    onMutate: async (newDocuments) => {
      // await queryClient.cancelQueries('dealersList');
      // const prev = queryClient.getQueryData('dealersList')
      // return {
      //     prev, newDocuments
      // }
    },
    onSettled: () => {
      // queryClient.invalidateQueries('dealersList') //refetch the collection on the background
    },
    onError: (err, _, context) => {
      // queryClient.setQueryData('dealersList', context.prev) //rollback the cache to the previous state
    },
  });
};

const shop_Image_Upload = () => {
  const queryClient = useQueryClient();
  return useMutation((documents) => shopImageUpload(documents), {
    onSettled: () => {
      queryClient.invalidateQueries("dealersList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("dealersList", context.prev); //rollback the cache to the previous state
    },
  });
};

// custom query-function for Delete a dealer from here

const delete_Dealer = () => {
  const queryClient = useQueryClient();
  return useMutation((todoId) => deleteDealer(todoId), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("dealersList"); //cancel any in-flight or pending query to the `todos` key

      const prev = queryClient.getQueryData("dealersList"); // retrieve the cached data

      queryClient.setQueryData("dealersList", (old = []) =>
        old.filter((item) => item.id !== todo.id)
      ); //remove the todo from the previous list

      // return the previous list and the todo
      return {
        prev,
        todo,
      };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("dealersList", context.prev); //rollback the cache to the previous state
    },
    onSettled: (todo) => {
      queryClient.invalidateQueries("dealersList"); //refetch the collection on the background
    },
  });
};

const useAddDealer = {
  add_dealers,
  doc_upload,
  shop_Image_Upload,
  edit_dealers,
  approve_dealers,
  email_exist_not,
  delete_Dealer,
  phone_exist_not,
};

export default useAddDealer;
