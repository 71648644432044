import React from "react";
import { Box, Card, Avatar } from "@material-ui/core";
import useStyles from "./ErrorStyle";
import IconButs from "../../common/Controls/IconButs";
import errorImage from "../ErrorPage/KfaErrorPageImage.svg";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/administrator/dashboard");
  };

  return (
    <Box className={classes.errorParent}>
      <Card className={classes.errorCard}>
        <Avatar
          src={errorImage}
          variant="square"
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }}
          sx={{ width: 80, height: 100 }}
        />
        <h2 className={classes.fourzerofour}>404!</h2>
        <h4 className={classes.errorNote}>Oops! Page Not Found!</h4>
        <p className={classes.errorMsg}>
          The page you are looking for might have been removed, had its name{" "}
          <br /> changed or is temporarily unavailable.
        </p>
        <Box className={classes.homeButtonParent}>
          <IconButs
            ibtname="Home"
            variant="contained"
            onClick={navigateHome}
            className={classes.homeButton}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default Error;
