import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import useTable from "../../sharedFeatures/useTable";
import Modal from "@material-ui/core/Modal";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineClose } from "react-icons/ai";
import HeadCells from "../../common/Headcells";
import CustomLoader from "../../features/CustomLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  width: 900,
  backgroundColor: "#ffffff",
  boxShadow: 24,
  borderRadius: "10px",
  ["@media (max-width: 360px)"]: {
    width: "95%",
  },
};

const OnboardedTable = ({ open, handleClose, data, loading }) => {
  const customStyle = makeStyles();

  const { TblContainer, TblHead } = useTable(HeadCells.dealerTechnicalHead, 10);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <header className={customStyle.headerStyleOne}>
          <Box></Box>
          <span
            style={{
              marginTop: "15px",
              marginLeft: "30px",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Dealer/Technician Onboarded
          </span>
          <div
            className={customStyle.iconStyle}
            style={{ marginRight: "20px" }}
          >
            <IconButton onClick={handleClose}>
              <AiOutlineClose />
            </IconButton>
          </div>
        </header>
        <div style={{ marginBottom: "15px" }}>
          <Box className={customStyle.tableBox}>
            <TblContainer>
              <TblHead />
              {loading && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              )}
              {data && data.data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <lottie-player
                        src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                        background="transparent"
                        speed="1"
                        style={{ width: "300px", height: "300px" }}
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <TableBody>
                {data &&
                  data.data.map((item) => {
                    return (
                      <TableRow>
                        <TableCell align="center">
                          {item.first_name.charAt(0).toUpperCase() +
                            item.first_name.slice(1)}
                        </TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">
                          {item.shop_details &&
                            item.shop_details[0] &&
                            item.shop_details[0].address &&
                            item.shop_details[0].address.location &&
                            item.shop_details[0].address.location.split(",")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {item.groups && item.groups[0] && item.groups[0].name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </TblContainer>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default OnboardedTable;
