import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    padding: 20,
    height: "auto",
    maxWidth: 550,
    margin: "20px auto",
    borderRadius: "6px",
    color: "#990000",
    backgroundColor: "rgb(245 242 242)",
    // backgroundColor: 'green',
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      // margin: "20px 0"
    },
  },
  loginForgotParent: {
    display: "flex",
    justifyContent: "center",
  },
  loginButton: {
    padding: "20px !important",
    backgroundColor: "#E94141 !important",
    color: "white",
    width: "300px",
    fontFamily: "Montserrat !important",
    fontSize: "14px",
  },
  forgetButton: {
    padding: "20px",
    backgroundColor: "#E94141 !important",
    width: "300px",
  },
  loginParent: {
    backgroundImage: "linear-gradient(#a3c2c2, #d1d1e0 )",
    backgroundRepeat: "initial",
    minHeight: "100vh",
    display: "flex",
    // flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  forgetParent: {
    backgroundImage: "linear-gradient(#a3c2c2, #d1d1e0 )",
    backgroundRepeat: "initial",
    minHeight: "110vh",
    display: "flex",
    // flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export default useStyles;
