import React from "react";
import { Modal, Grid, IconButton, Box } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { ImLocation } from "react-icons/im";
import { MdArrowBackIosNew } from "react-icons/md";
import IconButs from "../../common/Controls/IconButs";
import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import { Link } from "react-router-dom";
import Controls from "../../common/Controls/Controls";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const SalesDealerForm = ({ open, handleClose, title, salesManagersData }) => {
  const customStyle = makeStyles();

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <main>
            <form>
              <Grid>
                <div
                  className={customStyle.iconStyle}
                  style={{ backgroundColor: "#FEDD17" }}
                >
                  <Link to="/administrator/sales/salesUserDetails/${item.sl_no}">
                    <IconButton onClick={handleClose}>
                      <MdArrowBackIosNew />
                    </IconButton>
                  </Link>
                  <h1>{title}</h1>
                  <div></div>
                </div>

                <div className={customStyle.headerParentOne}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} md={6}>
                      Name of the Sales Person
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.InputField
                        placeholder="Jenny Wilson"
                        className={customStyle.inputTextBar}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Mobile Number
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.InputField
                        placeholder="9605499898"
                        className={customStyle.inputTextBar}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Email address
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.InputField
                        placeholder="sara.cruz@example.com"
                        className={customStyle.inputTextBar}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Location
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.InputField
                        placeholder="Austin"
                        className={customStyle.inputTextBar}
                        icon={<ImLocation />}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Address
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.InputField
                        placeholder="3517 W. Gray St. Utica, Pennsylvania 57867"
                        className={customStyle.inputTextBar}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Status
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.SelectBox
                        className={customStyle.formSelectBox}
                        name="salesManagers"
                        label="Status"
                        options={salesManagersData}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      Comment
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <Controls.SelectBox
                        className={customStyle.formSelectBox}
                        name="salesManagers"
                        label="Comment"
                        // label='Status'
                        options={salesManagersData}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <div className={customStyle.formIcon}>
                        <IconButs
                          ibtname={FORM_BUTTON_MANAGEMENT}
                          className={customStyle.formButtonAdd}
                        />
                        <IconButs
                          onClick={handleClose}
                          ibtname={FORMONE_BUTTON_MANAGEMENT}
                          className={customStyle.formButtonAdd}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </form>
          </main>
        </Box>
      </Modal>
    </div>
  );
};

export default SalesDealerForm;
