import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import HeadBar from "../../components/HeadBar/HeadBar";
import { AppRoutes, EndPoint } from "../../config/config";
import useAddProduct from "../../data/inventory/useAddProduct";
import makeStyles from "../../sharedFeatures/sharedClasses";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import {
  FORMONE_BUTTON_MANAGEMENT,
  FORM_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import categoryBrandStyles from "./InventoryManagmentStyle";

import useList from "../../data/inventory/useList";

import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { SetMealSharp } from "@mui/icons-material";
import InventoryImage from "./inventoryImage";
import { isNull } from "lodash";
import { object } from "yup";
import { FindLink } from "../../sharedFeatures/FindLink";

const AddInventory = () => {
  const customStyle = makeStyles();
  const { mutate: addProductImage } = useAddProduct.addImage();
  const categoryStyle = categoryBrandStyles();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [specificationFileds, setSpecificationFields] = useState([]);
  const [imageOpen, setImageOPen] = useState(false);
  const [brand_id, setBrandID] = useState("");
  const [series, setSeries] = useState("");
  const [item_code, setItemCode] = useState("");
  const [model, setModel] = useState("");
  const [mrp, setMrp] = useState("");
  const [rcp, setRcp] = useState("");
  const [dealer_landing_price, setDealerLandingPrice] = useState("");
  const [customer_landing_price, setCustomerLandingPrice] = useState("");
  const [gst_perc, setGstPerc] = useState("");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState("");
  const [service_items, setServiceItems] = useState("");
  const [timeForService, setTimeForSErvices] = useState("");
  const [dealerExchangePrice, setdealerExchangePrice] = useState("");
  const [customerExchangePrice, setcustomerExchangePrice] = useState("");
  const [warranty, setWarranty] = useState({});
  const [vehicle_category, setVehicleCategory] = useState("");
  const [vehicle_segment, setVehicleSegment] = useState("");
  const [hsn_code, setHscCode] = useState("");
  const [advantages, setAdvantages] = useState("");
  const [margin, setMargin] = useState(null);
  const [catType, setCatType] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [mrpError, setMrpError] = useState("");
  const [dealerPriceError, setDealerPriceError] = useState("");
  const [customerPriceError, setCustomerPriceError] = useState("");
  const [rcpError, setRcpError] = useState("");
  const [specsError, setSpecError] = useState({});
  const [warrentError, setWarrentError] = useState({});
  const [imageData, setImageData] = useState();
  const navigate = useNavigate();
  const formData = new FormData();
  const vehicleCategoryList = [
    { id: "Premium Large", name: "Premium Large" },
    { id: "Premium Medium", name: "Premium Medium" },
    { id: "Medium Car", name: "Medium Car" },
    { id: "Small Car", name: "Small Car" },
    { id: "Large Car", name: "Large Car" },
    { id: "Medium Sedan", name: "Medium Sedan" },
    { id: "Medium SUV", name: "Medium SUV" },
    { id: "Medium MUV", name: "Medium MUV" },
    { id: "Small MUV", name: "Small MUV" },
  ];

  const vehicleSegmentList = [
    { id: 1, name: "Indian" },
    { id: 2, name: "Imported" },
  ];

  let link = FindLink(`/administrator/inventoryProduct`);

  const notifyAdd = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Product added successfully.
      </span>
    );

  const qry = {
    searchQry: "",
    page: 0,
    perPageItem: 150,
  };

  const { mutateAsync: addProduct, isSuccess: productAddSuccess } =
    useAddProduct.add_product();
  const { mutate: addImage } = useAddProduct.addImage();

  const { data: catList } = useList.cat_list(qry);

  const {
    data: subCatList,
    isSuccess: subCatListSuccess,
    isLoading: subLoading,
  } = useList.subcat_list_by_category("", selectedCategory);

  const { data: brandList, isSuccess: brandsListSuccess } =
    useList.brand_by_category(
      selectedSubCategory ? selectedSubCategory : selectedCategory
    );

  useEffect(() => {
    setSelectedSubCategory(null);
    setBrandsList([]);
  }, [selectedCategory]);

  useEffect(() => {
    let catogery;
    if (categoryList && categoryList.length > 0 && selectedCategory) {
      catogery =
        categoryList && categoryList.find((e) => e.id === selectedCategory);

      if (catogery && catogery.type === 1) {
        setCatType("product");
      } else {
        setCatType("service");
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (catList && catList.data && catList.data.length > 0) {
      setCategoryList([...catList.data]);
    }
  }, [catList]);

  useEffect(() => {
    if (subCatList && subCatList.data && subCatList.data.length > 0) {
      setSubCategoriesList([...subCatList.data]);
    } else {
      setSubCategoriesList([]);
    }
  }, [subCatList, subCatListSuccess]);

  useEffect(() => {
    if (brandList && brandList.data && brandList.data.length > 0) {
      setBrandsList([...brandList.data]);
      setBrandID(null);
    } else {
      setBrandsList([]);
      setBrandID(null);
    }
  }, [brandList, brandsListSuccess]);

  useEffect(() => {
    if (selectedCategory) {
      let selected;
      if (subCategoriesList && subCategoriesList.length > 0) {
        selected = subCategoriesList.find((item) => {
          return item.id === selectedSubCategory;
        });
      } else {
        selected = categoryList.find((item) => {
          return item.id === selectedCategory;
        });
      }

      let fields = [];
      if (
        selected &&
        selected.specification_fields &&
        selected.specification_fields !== null
      ) {
        fields =
          selected &&
          selected.specification_fields &&
          selected.specification_fields["SpecificationFields"];
      } else {
        fields = [];
      }
      if (fields.length > 0) {
        fields = fields
          .split(",")
          .map((e) => e.trim())
          .filter((e) => e !== "null");
        setSpecificationFields(
          fields.reduce(function (obj, v) {
            obj[v] = "";
            return obj;
          }, {})
        );
      } else {
        setSpecificationFields([]);
      }
    }
  }, [selectedSubCategory, subCategoriesList]);

  const submitData = async (e) => {
    e.preventDefault();

    let err = false;

    if (!selectedCategory) {
      setCategoryError("Category is required");
      err = true;
    }

    if (subCategoriesList.length > 0 && !selectedSubCategory) {
      setSubCategoryError("Sub Category is required");
      err = true;
    }

    if (!brand_id || brand_id === "") {
      setBrandError("Brand is required");
      err = true;
    }
    if ((!mrp || mrp === 0) && (!rcp || rcp === 0)) {
      setMrpError("MRP / RCP is required");
      err = true;
    }
    if ((!rcp || rcp === 0) && (!mrp || mrp === 0)) {
      setRcpError("MRP / RCP is required");
      err = true;
    }
    if (!dealer_landing_price || dealer_landing_price === 0) {
      setDealerPriceError("Dealer Price is required");
      err = true;
    }

    if (!customer_landing_price || customer_landing_price === 0) {
      setCustomerPriceError("Customer Price is required");
      err = true;
    }
    Object.keys(specificationFileds).forEach((e) => {
      setSpecError((prev) => {
        if (specificationFileds[e] === "") {
          err = true;
          return { ...prev, [e]: "This field is required" };
        } else return { ...prev, [e]: "" };
      });
    });

    Object.keys(warranty).forEach((e) => {
      setWarrentError((prev) => {
        return { ...prev, [e]: "" };
      });
    });

    if (!err) {
      addProduct({
        series: series,
        item_code: item_code,
        model: model,
        mrp: mrp && parseInt(mrp),
        rcp: rcp && parseInt(rcp),
        dealer_landing_price:
          dealer_landing_price && parseInt(dealer_landing_price),
        customer_landing_price:
          customer_landing_price && parseInt(customer_landing_price),
        gst_perc: gst_perc && parseInt(gst_perc),
        margin: 1,
        description: description,
        customer_price_with_exchange:
          customerExchangePrice !== ""
            ? customerExchangePrice && parseInt(customerExchangePrice)
            : 0,
        dealer_price_with_exchange:
          dealerExchangePrice !== ""
            ? dealerExchangePrice && parseInt(dealerExchangePrice)
            : 0,
        features: { features },
        service_items: { service_items },
        specifications: specificationFileds,
        warranty: warranty,
        vehicle_category: vehicle_category,
        unique_key: "",
        vehicle_segment: vehicle_segment,
        hsn_code: hsn_code,
        advantages: advantages,
        brand_id: brand_id,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Product added");
            navigate(link);
            const id = res && res.data && res.data.data && res.data.data.id;
            Object.keys(imageData).forEach((e, i) => {
              formData.append(`image${i + 1}`, imageData[e]);
            });
            addImage({ id: id, image: formData });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            navigate(link);
          }
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.item_code
          ) {
            toast.error(error.response.data.error.item_code[0]);
          }
        });
    }
  };

  // useEffect(() => {
  //   if (productAddSuccess) {
  //     notifyAdd();
  //     handleClose();
  //   }
  // }, [productAddSuccess]);

  const handleClose = () => {
    // navigate(AppRoutes.ADMIN + EndPoint.INVENTORY_PRODUCT);
    navigate(link);
  };

  const handleCategory = (e) => {
    setCategoryError("");
    setSelectedCategory(e.target.value);
  };

  const handleSubCategory = (e) => {
    setSubCategoryError("");
    setSelectedSubCategory(e.target.value);
  };

  const handleBrand = (e) => {
    setBrandError("");
    setBrandID(e.target.value);
  };

  const brandData = () => {
    if (selectedCategory === null && catList !== undefined) {
      return [{ name: "Please select catogery", id: 1, disable: true }];
    } else if (selectedSubCategory === null && subCategoriesList.length > 0) {
      return [{ name: "Please select subcatogery", id: 1, disable: true }];
    } else {
      return brandsList;
    }
  };

  return (
    <>
      <HeadBar title="Add Inventory" />
      <div className={customStyle.divStyle}>
        <Paper className={categoryStyle.parentContainer}>
          <div>
            <div className={customStyle.subHeaderParent}>
              <Box sx={{ display: { xs: "none", sm: "inline" } }}>
                <Link to={link}>
                  <IconButton className={customStyle.backButtonParent}>
                    <BackbuttonIcon />
                  </IconButton>
                </Link>
              </Box>
              <Typography className={categoryStyle.subHeader}>
                Add Inventory
              </Typography>
            </div>
            <div>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.SelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Category"
                        options={categoryList}
                        onChange={(e) => handleCategory(e)}
                        error={categoryError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {subCategoriesList.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Sub Category
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.SelectBox
                          className={categoryStyle.formTextBar2}
                          name="inventoryType"
                          label="Select Sub Category"
                          options={subCategoriesList}
                          onChange={(e) => handleSubCategory(e)}
                          error={subCategoryError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Brand
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.SelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Brand"
                        options={brandData()}
                        onChange={(e) => handleBrand(e)}
                        error={brandError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Vehicle Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.SelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Vehicle Category"
                        options={vehicleCategoryList}
                        onChange={(e) => setVehicleCategory(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Vehicle Segment
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.SelectBox
                        className={categoryStyle.formTextBar2}
                        name="inventoryType"
                        label="Select Vehicle Segment"
                        options={vehicleSegmentList}
                        onChange={(e) => setVehicleSegment(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Series
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="series"
                        placeholder="Series"
                        onChange={(e) => setSeries(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Item Code
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="itemcode"
                        placeholder="Item Code"
                        onChange={(e) => setItemCode(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Model
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="model"
                        placeholder="Model"
                        onChange={(e) => setModel(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        HSC Code
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="hsn_code"
                        placeholder="HSN Code"
                        onChange={(e) => setHscCode(e.target.value)}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        MRP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="mrp"
                        placeholder="INR 1234"
                        onChange={(e) => {
                          setMrp(e.target.value);
                          if (e.target.value != 0) {
                            setMrpError("");
                            setRcpError("");
                          }
                        }}
                        type="number"
                        error={mrpError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        RCP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="RCP"
                        placeholder="rcp"
                        error={rcpError}
                        onChange={(e) => {
                          setRcp(e.target.value);
                          if (e.target.value != 0) {
                            setRcpError("");
                            setMrpError("");
                          }
                        }}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Dealer Price
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="dealer_landing_price"
                        placeholder="INR 1234"
                        onChange={(e) => setDealerLandingPrice(e.target.value)}
                        type="number"
                        error={dealerPriceError}
                        onKeyDown={() => setDealerPriceError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Customer Price
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="customer_landing_price"
                        placeholder="INR 1234"
                        onChange={(e) =>
                          setCustomerLandingPrice(e.target.value)
                        }
                        type="number"
                        error={customerPriceError}
                        onKeyDown={() => setCustomerPriceError("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {selectedCategory === 57 && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Customer price with exchange
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="customer_price_with_exchange"
                            placeholder="Customer price with exchange"
                            onChange={(e) =>
                              setcustomerExchangePrice(e.target.value)
                            }
                            type="text"
                            error={dealerPriceError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Dealer price with exchange
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="dealer_price_with_exchange"
                            label="Dealer price with exchange"
                            onChange={(e) =>
                              setdealerExchangePrice(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        GST Perc
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="gst_perc"
                        placeholder="GST Perc"
                        onChange={(e) => setGstPerc(e.target.value)}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {Object.keys(specificationFileds).length > 0 &&
                  Object.keys(specificationFileds).map((item, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography className={categoryStyle.label}>
                              {item[0].toUpperCase() + item.slice(1)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Controls.InputField
                              className={categoryStyle.formTextBar2}
                              name={item}
                              placeholder={`Enter ${item}`}
                              error={specsError[item]}
                              onChange={(e) =>
                                setSpecificationFields({
                                  ...specificationFileds,
                                  [item]: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Features
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="features"
                        placeholder="Features"
                        onChange={(e) => setFeatures(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Advantages
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="advantages"
                        placeholder="Advantages"
                        value={advantages}
                        onChange={(e) => setAdvantages(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="description"
                        value={description}
                        placeholder="Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Specifications
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="specifications"
                        value={specifications}
                        label="Specifications"
                        onChange={(e) => setSpecifications(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                {catType === "service" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Time for services
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="timeForServices"
                            placeholder="Time for services"
                            value={timeForService}
                            onChange={(e) => setTimeForSErvices(e.target.value)}
                            type="text"
                            error={dealerPriceError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Services items
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="servicesItems"
                            value={service_items}
                            label="Services Items"
                            onChange={(e) => setWarranty(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {selectedCategory === 54 && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Warranty Type
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Warranty type"
                            placeholder="Warranty type"
                            error={
                              warrentError["Warranty type"]
                                ? ""
                                : "This field is required"
                            }
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Warranty Years
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Warranty Years"
                            placeholder="Warranty Years"
                            error={
                              warrentError["Warranty Years"]
                                ? ""
                                : "This field is required"
                            }
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Manufacturing Defect Warranty Years
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Manufacturing Defect Warranty Years"
                            placeholder="Manufacturing Defect Warranty Years"
                            error={
                              warrentError[
                                "Manufacturing Defect Warranty Years"
                              ]
                                ? ""
                                : "This field is required"
                            }
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Warranty Duration
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Warranty Duration"
                            error={
                              warrentError["Warranty Duration"]
                                ? ""
                                : "This field is required"
                            }
                            placeholder="Warranty Duration"
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {selectedCategory === 53 && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Free Warranty
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.TextArea
                          className={categoryStyle.textarea2}
                          name="accessorieslist"
                          placeholder="Warrent"
                          onChange={(e) => {
                            setWarranty({
                              ...warranty,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {selectedCategory === 57 && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Free Warranty
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Free Warranty"
                            error={
                              warrentError["Free Warranty"]
                                ? ""
                                : "This field is required"
                            }
                            placeholder="Free Warranty"
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Prorata Warranty
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Prorata Warranty"
                            error={
                              warrentError["Prorata Warranty"]
                                ? ""
                                : "This field is required"
                            }
                            placeholder="Prorata Warranty"
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Total Warranty
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Total Warranty"
                            placeholder="Total Warranty"
                            error={
                              warrentError["Total Warranty"]
                                ? ""
                                : "This field is required"
                            }
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Warranty Duration
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="Warranty Duration"
                            placeholder="Warranty Duration"
                            error={warrentError["Warranty Duration"]}
                            onChange={(e) => {
                              setWarranty({
                                ...warranty,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Images
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <IconButs
                        ibtname={"Add image"}
                        className={categoryStyle.imageButton}
                        onClick={() => setImageOPen(true)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          ibtname={FORM_BUTTON_MANAGEMENT}
                          className={categoryStyle.formButtonAdd}
                          onClick={submitData}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          onClick={handleClose}
                          ibtname={FORMONE_BUTTON_MANAGEMENT}
                          className={categoryStyle.cancelButton}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </div>
      <InventoryImage
        open={imageOpen}
        setOpen={setImageOPen}
        imageData={setImageData}
        // data={data && data.data && data.data.vehicle_images}
      />
    </>
  );
};

export default AddInventory;
