import { Box, IconButton, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Typography } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import PlusIcon from "../../utilitis/icons/PlusIcon";
import "./InventoryExcel.css";
import useAddProduct from "../../data/inventory/useAddProduct";
import { object } from "yup";
const InventoryImage = ({ open, setOpen, data, isView, imageData }) => {
  const orderStyle = OrderManagmentStyle();
  const [images, setImages] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setImages(Object.values(data));
    }
  }, [data]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={orderStyle.imageViewer}>
        <div className="image-container">
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={orderStyle.assignTitle}>
              Product images
            </Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton onClick={() => setOpen(false)}>
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>

          {images.length > 0 ||
          imgUrl.length > 0 ||
          (data !== undefined && data && data[0] && data[0].length > 0) ? (
            <div className="image-section-inventory">
              {imgUrl.map((items, index) => {
                return <img key={index} src={items} alt="product" />;
              })}
              {data !== undefined &&
                data &&
                data[0] &&
                data[0].map((items, index) => {
                  return (
                    <img
                      key={index}
                      src={process.env.REACT_APP_BASE_URL + data[1] + items}
                      alt="product"
                    />
                  );
                })}
              {!isView && imgUrl.length <= 4 && data === undefined && (
                <div className="imageAdd">
                  <label htmlFor="product_image">
                    <PlusIcon width="30px" height="30px" />
                  </label>
                  <input
                    type="file"
                    name="productImages"
                    id="product_image"
                    hidden
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      let file = e.target.files;
                      Object.keys(file).forEach((e) => {
                        let url = URL.createObjectURL(file[e]);
                        setImgUrl((prev) => [...prev, url]);
                      });
                      imageData(file);
                    }}
                  />
                </div>
              )}
              {!isView &&
                imgUrl.length !== data &&
                data !== undefined &&
                data[0] &&
                data[0].length &&
                imgUrl.length <= 4 &&
                data !== undefined &&
                data &&
                data[0] &&
                data[0].length + imgUrl.length < 4 && (
                  <div className="imageAdd">
                    <label htmlFor="product_image">
                      <PlusIcon width="30px" height="30px" />
                    </label>
                    <input
                      type="file"
                      name="productImages"
                      id="product_image"
                      hidden
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        let file = e.target.files;
                        Object.keys(file).forEach((e) => {
                          let url = URL.createObjectURL(file[e]);
                          setImgUrl((prev) => [...prev, url]);
                        });
                        imageData(file);
                      }}
                    />
                  </div>
                )}
            </div>
          ) : !isView ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "100px",
                width: "100%",
                height: "90%",
              }}
            >
              <div className={orderStyle.addIcon}>
                <label htmlFor="product_image">
                  <PlusIcon width="50px" height="50px" />
                  <p>Add Image</p>
                </label>
                <input
                  type="file"
                  name="productImages"
                  id="product_image"
                  hidden
                  multiple
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    let file = e.target.files;
                    Object.keys(file).forEach((e) => {
                      let url = URL.createObjectURL(file[e]);
                      setImgUrl((prev) => [...prev, url]);
                    });
                    imageData(file);
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "100px",
              }}
            >
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InventoryImage;
