import DataService from "../../DataService/DataService";

const getDealerlist = async ({
  searchQry,
  page,
  selected,
  from_date,
  to_date,
  perPageItems,
  created_by,
}) => {
  let url = ``;
  if (
    (selected === "All" &&
      searchQry &&
      from_date &&
      to_date &&
      page &&
      perPageItems,
    created_by)
  ) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&created_by=${created_by}&page=${page}`;
  } else if (selected === "All" && searchQry && from_date && to_date && page) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (
    selected === "All" &&
    searchQry &&
    from_date &&
    to_date &&
    perPageItems
  ) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (selected === "All" && searchQry && from_date && to_date) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}`;
  } else if (selected === "All" && searchQry && page && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (selected === "All" && searchQry && page) {
    url = `dealers/dealer/?search=${searchQry}&page=${page}`;
  } else if (selected === "All" && searchQry && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (selected === "All" && searchQry) {
    url = `dealers/dealer/?search=${searchQry}`;
  } else if (
    selected === "All" &&
    from_date &&
    to_date &&
    page &&
    perPageItems
  ) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&page=${page}`;
  } else if (selected === "All" && from_date && to_date && page) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (selected === "All" && from_date && to_date && perPageItems) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (selected === "All" && from_date && to_date) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}`;
  } else if (selected === "All" && page && perPageItems) {
    url = `dealers/dealer/?page_size=${perPageItems}&page=${page}`;
  } else if (selected === "All" && page) {
    url = `dealers/dealer/?page=${page}`;
  } else if (selected === "All" && perPageItems) {
    url = `dealers/dealer/?page_size=${perPageItems}`;
  } else if (selected === "All") {
    url = `dealers/dealer/?page=${page}`;
  } else if (
    selected &&
    searchQry &&
    from_date &&
    to_date &&
    page &&
    perPageItems
  ) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && searchQry && from_date && to_date && page) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (selected && searchQry && from_date && to_date && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (selected && searchQry && from_date && to_date) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}`;
  } else if (selected && searchQry && page && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && searchQry && page) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&page=${page}`;
  } else if (selected && searchQry && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}&page_size=${perPageItems}`;
  } else if (selected && searchQry) {
    url = `dealers/dealer/?search=${searchQry}&status=${selected}`;
  } else if (selected && from_date && to_date && page && perPageItems) {
    url = `dealers/dealer/?status=${selected}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && from_date && to_date && page) {
    url = `dealers/dealer/?status=${selected}&created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (selected && from_date && to_date && perPageItems) {
    url = `dealers/dealer/?status=${selected}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (selected && from_date && to_date) {
    url = `dealers/dealer/?status=${selected}&created_at__date__range=${from_date},${to_date}`;
  } else if (selected && page && perPageItems) {
    url = `dealers/dealer/?status=${selected}&page_size=${perPageItems}&page=${page}`;
  } else if (selected && page) {
    url = `dealers/dealer/?status=${selected}&page=${page}`;
  } else if (selected && perPageItems) {
    url = `dealers/dealer/?status=${selected}&page_size=${perPageItems}`;
  } else if (selected) {
    url = `dealers/dealer/?status=${selected}`;
  } else if (searchQry && from_date && to_date && page && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && from_date && to_date && page) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (searchQry && from_date && to_date && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (searchQry && from_date && to_date) {
    url = `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}`;
  } else if (searchQry && page && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page) {
    url = `dealers/dealer/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `dealers/dealer/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (searchQry) {
    url = `dealers/dealer/?search=${searchQry}`;
  } else if (from_date && to_date && page && perPageItems) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}&page=${page}`;
  } else if (from_date && to_date && page) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page=${page}`;
  } else if (from_date && to_date && perPageItems) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page_size=${perPageItems}`;
  } else if (from_date && to_date) {
    url = `dealers/dealer/?created_at__date__range=${from_date},${to_date}`;
  } else if (page && perPageItems) {
    url = `dealers/dealer/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `dealers/dealer/?page=${page}`;
  } else if (perPageItems) {
    url = `dealers/dealer/?page_size=${perPageItems}`;
  } else if (created_by) {
    url = `dealers/dealer/?created_by=${created_by}&page_size=${perPageItems}`;
  } else {
    url = `dealers/dealer/?page=${page}`;
  }

  //  else {
  //   url =
  //     searchQry && selected && from_date && to_date
  //       ? `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}`
  //       : searchQry && from_date && to_date
  //       ? `dealers/dealer/?search=${searchQry}&created_at__date__range=${from_date},${to_date}`
  //       : searchQry
  //       ? `dealers/dealer/?search=${searchQry}`
  //       : selected && from_date && to_date
  //       ? `dealers/dealer/?search=${searchQry}&status=${selected}&created_at__date__range=${from_date},${to_date}`
  //       : selected
  //       ? `dealers/dealer/?status=${selected}&page=${page}`
  //       : from_date || to_date
  //       ? `dealers/dealer/?created_at__date__range=${from_date},${to_date}&page=${page}`
  //       : perPageItems
  //       ? `dealers/dealer/?page_size=${perPageItems}&page=${page}`
  //       : `dealers/dealer/?page=${page}`;
  // }

  const data = await DataService.get(
    `dealers/dealer/?${created_by ? "created_by=" + created_by + "&" : ""}${
      selected === "All"
        ? ""
        : selected !== "All"
        ? "status=" + selected + "&"
        : ""
    }${searchQry ? "search=" + searchQry + "&" : ""}${
      from_date && to_date
        ? "created_at__date__range=" + from_date + "," + to_date + "&"
        : ""
    }${page != 0 ? "page=" + page + "&" : ""}${
      perPageItems ? "page_size=" + perPageItems : ""
    }`
  );

  return data.data;
};

const getDealerDetail = async (id) => {
  const url = `dealers/dealer/${id}/`;
  const data = await DataService.get(url);
  return data;
};

const emailCheck = async (value) => {
  const { email } = value;
  const url = `dealers/userexistcheck/${email}/`;
  const data = await DataService.get(url);
  return data.data;
};

const phoneCheck = async (value) => {
  const { phone } = value;
  const url = `dealers/userexistcheckbyphone/${phone}/`;
  const data = await DataService.get(url);

  return data.data;
};

const addDealer = async (value) => {
  const url = "dealers/dealer/";
  const { ...state } = value;
  const data = await DataService.post(url, value);
  return data;
};

const editDealer = async (value) => {
  const { id, ...editData } = value;
  const url = `dealers/dealer/${id}/`;
  const data = await DataService.put(url, value);
  return data;
};

const approveDealer = async (value) => {
  const { id } = value;
  const url = `dealers/dealer/approval/${id}/`;
  const data = await DataService.patch(url);
  return data;
};

const getInputListForImage = async () => {
  const url = `dealers/config/`;
  const data = await DataService.get(url);
  return data;
};

const docUpload = async (value) => {
  const { shop_id, ...image } = value;
  const url = `dealers/upload_doc/${shop_id}/`;
  const data = await DataService.form_post(url, image && image.image);
  return data;
};

const shopImageUpload = async (value) => {
  const { shop_id, ...image } = value;
  const url = `dealers/upload_shopimage/${shop_id}/`;
  const data = await DataService.form_post(url, image && image.image);
  return data;
};

const deleteDealer = async (id) => {
  const url = `/dealers/dealer/${id}/`;
  const data = await DataService.del(url);
  return data;
};

const getProductType = async () => {
  const url = `product/getproductcategory/`;
  const data = await DataService.get(url);
  return data.data.data;
};

const getServicetype = async () => {
  try {
    const url = `product/getservicecategory/`;
    const data = await DataService.get(url);
    return data.data.data;
  } catch (error) {
    throw error
  }

};

const getallcategory = async () => {
  const url = `product/getallcategory/`;
  const data = await DataService.get(url);
  return data.data.data;
};

const getBrandByCategory = async (categoryId) => {
  const url = `product/brandbycategory/?category_id=${categoryId}`;
  const data = await DataService.get(url);
  return data.data.data;
};

const getBothByCat = async (catID) => {
  const url = `product/categorybytype/${catID}/`;
  const data = await DataService.get(url);
  return data.data.data;
};

const getTradingAndServices = async () => {
  const url = `product/getallcategory/`;
  const data = await DataService.get(url);
  return data.data.data;
};
const getFecilites = async () => {
  const url = `/dealers/facilities/`;
  const data = await DataService.get(url);
  return data.data;
};

const getDealerTechnicianData = async (id) => {
  const url = `dealers/dealertechnicianbydealer/${id}/`;
  const data = await DataService.get(url);
  return data.data.data;
};

export {
  getDealerlist,
  deleteDealer,
  shopImageUpload,
  getDealerDetail,
  addDealer,
  getProductType,
  getServicetype,
  getallcategory,
  getBrandByCategory,
  docUpload,
  getInputListForImage,
  emailCheck,
  phoneCheck,
  editDealer,
  approveDealer,
  getBothByCat,
  getTradingAndServices,
  getDealerTechnicianData,
  getFecilites,
};
