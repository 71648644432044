import * as React from "react";
import { useState } from "react";
import { styled } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  CardHeader,
  Grid,
  Typography,
 
  IconButton,

  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
} from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { Link } from "react-router-dom";

import { TXT_USERMANAGEMENT } from "../../utilitis/header";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const UserCarDetails = () => {
  const [expanded, setExpanded] = React.useState(false);
  const carStyle = makeStyles();
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={carStyle.divStyle}>
      <header
        className={carStyle.headerStyle}
        style={{ backgroundColor: "#FEDD17" }}
      >
        <h1>{TXT_USERMANAGEMENT}</h1>
      </header>

      <div className={carStyle.searchBoxParent}>
        {/* <InputField
          value={search}
          placeholder='Type here...'
          className={carStyle.searchBar}
          size='small'
          name='usersearch'
          onChange={searchUser}
          icon={<BsSearch />}
        /> */}
      </div>

      <div className={carStyle.carDetailsContainer}>
        <Card sx={{ maxWidth: 550 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "black" }} aria-label="recipe">
                HC
              </Avatar>
            }
            title="HYUNDAI CRETA"
            subheader="Launched May 3, 2022."
          />
          <CardMedia
            component="img"
            height="350"
            image="https://imgd.aeplcdn.com/1056x594/n/cw/ec/41564/hyundai-creta-right-front-three-quarter9.jpeg?q=75&wm=1"
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              The Hyundai Creta has 1 Diesel Engine and 2 Petrol Engine on
              offer. The Diesel engine is 1493 cc while the Petrol engine is
              1497 cc and 1353 cc . It is available with Manual & Automatic
              transmission. Depending upon the variant and fuel type the Creta
              has a mileage of 16.8 to 21.4 kmpl . The Creta is a 5 seater 4
              cylinder car and has length of 4300, width of 1790 and a wheelbase
              of 2610.
            </Typography>
          </CardContent>

          <CardActions disableSpacing>
            <Link to="/admin/users/usersDetails/${item.sl_no}">
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </Link>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="h6">
                Key Specifications of HYUNDAI CRETA
              </Typography>

              <Grid container spacing={1} style={{ marginTop: "5px" }}>
                <Grid item xs={6}>
                  ARAI Mileage
                </Grid>
                <Grid item xs={6}>
                  18.5 kmpl
                </Grid>

                <Grid item xs={6}>
                  Engine Displacement (cc)
                </Grid>
                <Grid item xs={6}>
                  1493
                </Grid>

                <Grid item xs={6}>
                  Seating Capacity
                </Grid>
                <Grid item xs={6}>
                  5
                </Grid>

                <Grid item xs={6}>
                  Fuel Type
                </Grid>
                <Grid item xs={6}>
                  Diesel
                </Grid>

                <Grid item xs={6}>
                  No. of cylinder
                </Grid>
                <Grid item xs={6}>
                  4
                </Grid>

                <Grid item xs={6}>
                  TransmissionType
                </Grid>
                <Grid item xs={6}>
                  Automatic
                </Grid>

                <Grid item xs={6}>
                  Max Power (bhp@rpm)
                </Grid>
                <Grid item xs={6}>
                  113.45bhp@4000rpm
                </Grid>

                <Grid item xs={6}>
                  Body Type
                </Grid>
                <Grid item xs={6}>
                  SUV
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </div>
  );
};

export default UserCarDetails;
