import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import useStyles from "./dashboardStyles";
import { Progress } from "antd";
import { usequickUpdate } from "../../data/dashboard/dashboardQuery";

const QuickUpdates = ({ filter }) => {
  const dashStyles = useStyles();

  const theme = createTheme({
    palette: {
      success: {
        main: "#34D834",
      },
    },
  });

  const { data } = usequickUpdate({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });
  // console.log(data);

  const updateData = (type) =>
    data &&
    data.data &&
    data.data.data &&
    data.data.data.filter((e) => {
      if (type === "battery" && e.category === "Battery") {
        return e;
      } else if (type === "emergency" && e.category === "Emergency Services") {
        return e;
      }
    });
  const [updateType, setUpdateType] = useState("battery");

  return (
    <ThemeProvider theme={theme}>
      <Box className={dashStyles.quickUpdatesParent}>
        <span className={dashStyles.quickUpdatesHeader}>Quick Updates</span>
        <h6 className={dashStyles.quickUpdatesSubHeader}>
          Top Selling Products
        </h6>
        <Box className={dashStyles.quickUpdatesRadialProgressParent}>
          <Progress
            type="circle"
            percent={
              updateData(updateType) &&
              updateData(updateType)[0] &&
              updateData(updateType)[0].percentage
            }
            width={150}
            format={(percent) => {
              return (
                <>
                  <span
                    style={{
                      color: "#85DE85",
                      fontStyle: "normal",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "24px",
                    }}
                  >
                    {percent}%
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#444445",
                      fontStyle: "normal",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "10px",
                    }}
                  >
                    Sale Status
                  </span>
                </>
              );
            }}
            status="normal"
            strokeColor="#34D834"
            trailColor="#EEEEEE"
          />
        </Box>
        <h6 className={dashStyles.quickUpdatesSubHeaderTwo}>
          Product Categories
        </h6>
        <FormControl>
          <RadioGroup defaultValue="battery" name="radio-buttons-group">
            <div
              className={dashStyles.quickUpdatesRadioParent}
              style={{ padding: "0 60px 0 20px" }}
            >
              <FormControlLabel
                value="battery"
                control={<Radio color="success" />}
                label="Battery Sales"
                className={dashStyles.radioButtonsText}
                onChange={(e) => setUpdateType(e.target.value)}
              />
            </div>
            <div
              className={dashStyles.quickUpdatesRadioParent}
              style={{ padding: "0 20px", marginTop: "10px" }}
            >
              <FormControlLabel
                value="emergency"
                control={<Radio color="success" />}
                label="Emergency Repair"
                className={dashStyles.radioButtonsText}
                onChange={(e) => setUpdateType(e.target.value)}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};

export default QuickUpdates;
