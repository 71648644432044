import { useQuery } from "react-query";
import { getProductList, getProductListById } from "./inventoryUrls";

const ProductList = (qry) => {
  return useQuery(["productList", qry], () => getProductList(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const ProductDetails = (id) => {
  return useQuery(["productDetails", id], () => getProductListById(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const productListById = (id) => {
  return useQuery(["productList", id], () => getProductListById(id));
};

const productQuery = {
  ProductList,
  productListById,
  ProductDetails,
};

export default productQuery;
