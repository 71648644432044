import React, { useState } from "react";

import { Grid, IconButton, Paper } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { Link } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import InputField from "../../common/Controls/InputField";
import { BsSearch } from "react-icons/bs";
import {
  TXT_USERMANAGEMENT,
  TXT_USER_ORDER_DETAIL,
  ASSIGN_DEALER_TECHNICIAN,
} from "../../utilitis/header";
import { MdArrowBackIosNew } from "react-icons/md";
import Controls from "../../common/Controls/Controls";

const UserOrderDetails = () => {
  const orderStyle = makeStyles();
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className={orderStyle.divStyle}>
      <header
        className={orderStyle.headerStyle}
        style={{ backgroundColor: "#FEDD17" }}
      >
        <h1>{TXT_USERMANAGEMENT}</h1>
      </header>

      <div className={orderStyle.searchBoxParent}>
        <InputField
          value={search}
          placeholder="Type here..."
          className={orderStyle.searchBar}
          size="small"
          name="usersearch"
          onChange={searchUser}
          icon={<BsSearch />}
        />
      </div>

      <Paper className={orderStyle.parentContainer} elevation="6">
        <div className={orderStyle.subHeaderParent}>
          <Link to="/admin/users/usersDetails/${item.sl_no}">
            <IconButton className={orderStyle.backButtonParent}>
              <MdArrowBackIosNew className={orderStyle.backButton} />
            </IconButton>
          </Link>
          <Sub data={TXT_USER_ORDER_DETAIL} />
        </div>

        <Grid container spacing={1}>
          <Grid item xs={4} md={6}>
            Name of the User
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder="Jenny Wilson"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            Customer Order
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder="Sumini Mani V"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            Customer Mobile
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder="9605494838"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            Address
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder="3517 W. Gray St. Utica, Pennsylvania 57867"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={6}>
            Order Status
          </Grid>
          <Grid item xs={6}>
            <Controls.InputField
              placeholder=" On-Going"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            Scheduled Date
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.DatePicker className={orderStyle.formSelectBox} />
          </Grid>
          <Grid item xs={4} md={6}>
            Scheduled Time
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.DatePicker className={orderStyle.formSelectBox} />
          </Grid>
          <Grid item xs={4} md={6}>
            Cost
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder="INR 5000"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            Payment Status
          </Grid>
          <Grid item xs={4} md={6}>
            <Controls.InputField
              placeholder=" Fully Paid"
              variant="outlined"
              className={orderStyle.inputTextBar}
            />
          </Grid>
          <Grid item xs={4} md={12}>
            <div className={orderStyle.formIcon}>
              <Link to="/admin/users">
                <IconButs
                  ibtname={ASSIGN_DEALER_TECHNICIAN}
                  className={orderStyle.formButtonAdd}
                  variant="outlined"
                />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default UserOrderDetails;
