import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
const theme = createTheme();

export default makeStyles((theme) =>
  createStyles({
    formContainer: {
      paddingInline: "10px",
    },
    viewlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      fontFamily: "Montserrat !important",
    },
    // viewlabel: {
    //   fontWeight: "400",
    //   fontStyle: "normal",
    //   fontSize: "12px",
    //   lineHeight: "24px",
    //   color: "#444445",
    //   fontFamily: "Montserrat !important",
    //   marginTop: "10px",
    // },
    searchBoxParent: {
      display: "flex",
      margin: "20px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
      ["@media (max-width: 412px)"]: {
        justifyContent: "center",
        gap: "10px",
      },
    },
    formLabel: {
      padding: "18px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      whiteSpace: "nowrap",
      ["@media (max-width: 412px)"]: {
        padding: "5px !important",
      },
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        padding: "0 !important",
        marginBottom: "10px !important",
        marginTop: "16px !important",
      },
    },
    formLabelWithMT: {
      padding: "18px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      marginTop: "25px !important",
      lineHeight: "24px !important",
      ["@media (max-width: 412px)"]: {
        padding: "5px !important",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 !important",
        marginBottom: "0px !important",
        marginTop: "16px !important",
      },
    },
    viewBtnlabel: {
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      color: "#444445",
      fontFamily: "Montserrat !important",
      marginTop: "30px",
      ["@media (max-width: 412px)"]: {
        marginTop: "20px !important",
      },
    },
    inputTextBar: {
      width: "320px",
      height: "40px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "100% !important",
      },
      ["@media (max-width: 400px)"]: {
        width: "94% !important",
      },
      ["@media (max-width: 360px)"]: {
        width: "100% !important",
      },
    },
    inputTextBarView: {
      width: "320px",
      height: "40px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "100% !important",
      },
      ["@media (max-width: 400px)"]: {
        width: "94% !important",
      },
      ["@media (max-width: 360px)"]: {
        width: "100% !important",
      },
    },
    inputTextBarSmall: {
      width: "260px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "100% !important",
      },
      ["@media (max-width: 400px)"]: {
        width: "94% !important",
      },
    },
    inputTextBarSmallView: {
      width: "260px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "100% !important",
      },
      ["@media (max-width: 400px)"]: {
        width: "94% !important",
      },
    },
    iconSizeMedium: {
      "& > *:first-child": {
        width: "90px",
        paddingBlock: "1px",
      },
    },
    addressBar: {
      width: "100% !important",
      ["@media (max-width: 400px)"]: {
        width: "90% !important",
      },
      //hide input arrows
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none !important",
          margin: "0px !important",
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "320px",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "18px !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      ["@media (max-width: 450px)"]: {
        marginTop: "10px",
        width: "100%",
        height: "40px",
        fontFamily: "Montserrat !important",
        fontWeight: "400 !important",
        fontStyle: "normal",
        fontSize: "12px !important",
        lineHeight: "24px",
        fontSize: "15px !important",
        background: "#ffffff !important",
        border: "1px solid #D9D9D9",
        color: "#000000 !important",
        textTransform: "capitalize !important",
        borderRadius: "6px !important",
        ["@media (max-width: 450px)"]: {
          width: "90vw",
        },
      },
    },
    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "14px !important",
      color: "#252F40 !important",
      width: "300px",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },
      ["@media (max-width: 600px)"]: {
        width: "300px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    forms: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      padding: "12px 20px !important",
      fontSize: "15px !important",
      width: "350px !important ",
      marginTop: "30px",
      width: "361px",
      height: "40px",
      background: "#fff !important",
      border: "2px solid #FEDD17",
      textTransform: "capitalize !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      ["@media (max-width: 460px)"]: {
        width: "94% !important",
        marginBottom: "20px",
      },
    },

    formsUpload: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      padding: "12px 20px !important",
      fontSize: "15px !important",
      width: "350px !important ",
      width: "361px",
      height: "150px",
      background: "#fff !important",
      border: "2px solid #FEDD17",
      textTransform: "capitalize !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ["@media (max-width: 460px)"]: {
        width: "94% !important",
        marginBottom: "20px",
        marginTop: "20px",
      },
    },
    formsImage: {
      padding: "12px 20px !important",
      width: "350px !important ",
      height: "200px",
      background: "#fff !important",
      border: "2px solid #FEDD17",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ["@media (max-width: 460px)"]: {
        width: "94% !important",
        marginBottom: "20px",
        marginTop: "20px",
      },
      ["@media (max-width: 360px)"]: {
        width: "100% !important",
        marginBottom: "20px",
        marginTop: "20px",
      },
    },
    smallFieldLabel: {
      ["@media (max-width: 460px)"]: {
        marginBottom: "20px",
      },
    },
    smallFieldLabelView: {
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      ["@media (max-width: 460px)"]: {
        marginBottom: "20px",
      },
    },
    formIconCentered: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "30px",
    },
  })
);
