import { Box, Grid, Modal, Typography, IconButton } from "@material-ui/core";
import React from "react";
import Controls from "../../common/Controls/Controls";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButs from "../../common/Controls/IconButs";
import userCredentailQuery from "../../data/userCredential/useCredentialQuery";
import useAddUserCredential from "../../data/userCredential/useAddUserCredential";
import makeStyles from "../../sharedFeatures/sharedClasses";
import UserCredentialStyle from "./userCredentialStyle";
import CustomLoader from "../../features/CustomLoader";
import { toast } from "material-react-toastify";
import salesStyle from "../SalesPersonManagement/salesStyle";

const UserPasswordChange = ({ open, handleClose, id }) => {
  const customStyle = makeStyles();
  const credentialStyle = UserCredentialStyle();
  const salesStyles = salesStyle();
  const [showPass, setShowPass] = React.useState(false);
  const [showConf, setShowConf] = React.useState(false);

  const { data: userById } = userCredentailQuery.userCredentailById(id);

  const {
    mutate: resetCredential,
    isLoading: resetLoading,
    isSuccess: resetSuccess,
  } = useAddUserCredential.reset_userCredential();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = undefined;
    if (password === "") {
      setPasswordError("Password is required");
      err = true;
      return;
    }
    if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(password)
    ) {
      setPasswordError(
        "Password should have minimum 6 character, atleast one number and one special character"
      );
      err = true;
    }

    if (password === confirmPassword) {
      if (!err) handleReset();
    } else {
      setConfirmPasswordError("Password does not match");
      err = true;
    }
  };

  const handleReset = async () => {
    await resetCredential({
      id: id,
      payload: {
        new_password: password.trim(),
      },
    });
  };

  React.useEffect(() => {
    if (resetSuccess) {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          User password reset successfully
        </span>
      );
      handleClose();
    }
  }, [resetSuccess]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={credentialStyle.resetRootContainer}>
        <div
          className={customStyle.iconStyle}
          style={{ backgroundColor: "#FEDD17" }}
        >
          <h1>Reset Password</h1>
          <div></div>
        </div>
        {!resetLoading ? (
          <Grid className={credentialStyle.resetContainer}>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className={credentialStyle.label}>
                    New Password
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    placeholder="Enter new password"
                    className={credentialStyle.inputTextBar}
                    type={showPass ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                    inputProps={{ maxLength: 12 }}
                    onKeyDown={(e) => {
                      setPasswordError("");
                    }}
                    icon={
                      showPass ? (
                        <IconButton onClick={() => setShowPass(false)}>
                          <Visibility />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setShowPass(true)}>
                          <VisibilityOff />
                        </IconButton>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={credentialStyle.label}>
                    Confirm password
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    placeholder="Confirm password"
                    type={showConf ? "text" : "password"}
                    className={credentialStyle.inputTextBar}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                      setConfirmPasswordError("");
                    }}
                    error={confirmPasswordError}
                    inputProps={{ maxLength: 12 }}
                    icon={
                      showConf ? (
                        <IconButton onClick={() => setShowConf(false)}>
                          <Visibility />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setShowConf(true)}>
                          <VisibilityOff />
                        </IconButton>
                      )
                    }
                  />
                </Grid>

                <Grid container className={credentialStyle.formButtonContainer}>
                  <Grid item>
                    <div className={credentialStyle.formIcon}>
                      <IconButs
                        ibtname="OK"
                        className={credentialStyle.formButtonAdd}
                        variant="outlined"
                        onClick={handleSubmit}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={credentialStyle.formIcon}>
                      <IconButs
                        ibtname="Cancel"
                        className={salesStyles.formButton}
                        variant="outlined"
                        onClick={handleClose}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : (
          <CustomLoader />
        )}
      </Box>
    </Modal>
  );
};

export default UserPasswordChange;
