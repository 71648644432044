import React from 'react';
import { Link } from 'react-router-dom';

const OrderDetails = ({data}) => {
    // data.map((each) => {
    //   console.log(each.order_no)
    // })
    return ( 
        <>
        <ul>
        {data.map((each) => (
            <li key={each.id}>
              <Link to={`/administrator/asigntooficeadvisor/assignorderdetails/${each.id}`}>
              {each.order_no}
              </Link>
            </li>
            ))}              
        </ul>
        </>
     );
}
 
export default OrderDetails;