import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "./dashboardStyles";
import NumberOfOrdersChart from "./NumberOfOrdersChart";
import { useOrderData } from "../../data/dashboard/dashboardQuery";
import { keyConverter } from "./utils/utils";

const Orders = ({ filter }) => {
  const dashStyles = useStyles();
  const { data } = useOrderData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });

  const orderData =
    data &&
    data.data &&
    data.data.data.map((e) => {
      return {
        name: keyConverter(e, filter),
        uv: e["y-axis"].cancelled,
        pv: e["y-axis"].closed,
        pw: e["y-axis"].open,
      };
    });

  return (
    <Box className={dashStyles.ordersSectionParent}>
      <NumberOfOrdersChart data={orderData} />
    </Box>
  );
};

export default Orders;
