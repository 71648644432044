import React from 'react'

const ForwardIcon = () => {
  return (
    <>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z" stroke="#444445" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.74 15.53L14.26 12L10.74 8.46997" stroke="#444445" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
    </>
  )
}

export default ForwardIcon