import { useQuery } from 'react-query'
import { getVehicleData } from "./vehicleUrls"

export default function useVehicleData(id) {
  return useQuery(['vehicleData', id], () =>  getVehicleData(id), {
    staleTime: 30000,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    poll: 500,
    //refetchOnMount: "always",
  })
}