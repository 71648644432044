import React, { useEffect, useState } from "react";
import HeadBar from "../../components/HeadBar/HeadBar";
import { TXT_DASHBOARD } from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { Box } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import Greetings from "./Greetings";
import Dealers from "./Dealers";
import Orders from "./Orders";
import Sales from "./Sales";
import SelectUser from "./SelectUser";
import AppDownloadStats from "./AppDownloadStats";
import QuickUpdates from "./QuickUpdates";

const Dashboard = () => {
  const styles = makeStyles();
  const dashStyles = useStyles();
  const [filter, setFilter] = useState({
    time: "",
    start_date: "",
    end_date: "",
  });

  return (
    <>
      <HeadBar title={TXT_DASHBOARD} />
      <div className={styles.listdivStyle}>
        <Box>
          <Greetings setFilter={setFilter} filter={filter} />
          <Dealers filter={filter} />
          <Orders filter={filter} />
          <Sales filter={filter} />
        </Box>
        <Box className={dashStyles.dbRightParent}>
          <SelectUser />
          <AppDownloadStats filter={filter}/>
          <QuickUpdates filter={filter} />
        </Box>
      </div>
    </>
  );
};

export default Dashboard;
