import { useMutation, useQueryClient } from "react-query";
import {
  addUserCredential,
  resetUserCredential,
  updateUserCredentialStatus,
} from "./userUrls";

const add_userCredential = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addUserCredential(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("usercredential");

      const prev = queryClient.getQueryData("usercredential");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("usercredential");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("usercredential", context.prev);
    },
  });
};

const update_usercredential_status = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => updateUserCredentialStatus(id), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("usercredential");

      const prev = queryClient.getQueryData("usercredential");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("usercredential");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("usercredential", context.prev);
    },
  });
};

const reset_userCredential = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => resetUserCredential(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("usercredential");

      const prev = queryClient.getQueryData("usercredential");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("usercredential");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("usercredential", context.prev);
    },
  });
};

const useAddUserCredential = {
  add_userCredential,
  reset_userCredential,
  update_usercredential_status,
};

export default useAddUserCredential;
