import React, { useEffect } from "react";
import {
  Modal,
  IconButton,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import IconButs from "../../common/Controls/IconButs";
import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
  EDIT_BRAND,
} from "../../utilitis/header";
import { AiOutlineClose } from "react-icons/ai";
import Controls from "../../common/Controls/Controls";

import salesStyle from "./salesStyle";
import { useForm } from "../../sharedFeatures/useForm";
import useAddSalesPerson from "../../data/sales/useAddSalesPerson";
import useSalesPersonQuery from "../../data/sales/useSalesPersonQuery";
import { useState } from "react";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import "./Sales.css";
import Autocomplete from "react-google-autocomplete";
import { Grid } from "@mui/material";
import useAddDealer from "../../data/dealer/useAddDealer";
import CustomLoader from "../../features/CustomLoader";

const SalesForm = ({
  open,
  handleClose,
  title,
  salesManagersData,
  id,
  handleMessage,
}) => {
  const {
    data: salesData,
    isSuccess: dataSucces,
    error: salesError,
    isError,
  } = id ? useSalesPersonQuery.salesPerson_By_Id(id) : "";

  // useEffect(() => {
  //   if (
  //     salesError &&
  //     salesError.response &&
  //     salesError.response.data &&
  //     salesError.response.data.detail
  //   ) {
  //     toast.warn(
  //       salesError &&
  //         salesError.response &&
  //         salesError.response.data &&
  //         salesError.response.data.detail
  //     );
  //     handleClose()
  //   }
  // }, [isError]);

  const url = "AIzaSyCeViu0eoIfgK2TWKWUXCt2p_YWvFbtc7c";

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressOneError, setAddressOneError] = useState("");
  const [addressTwoError, setAddressTwoError] = useState("");
  const [localityError, setLocalityError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [salesManagerError, setSalesManagerError] = useState("");
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const initialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    address: "",
    address_line_one: "",
    address_line_two: "",
    locality: "",
    pincode: "",
    country: "India",
    status: "",
    city: "",
    salesManagers: "",
  };

  const { values, handleInputChange, setValues } = useForm(initialValues);

  const {
    mutate: emailCheck,
    data: emailExistData,
    error: errorData,
    isLoading: mobileLoading,
  } = useAddDealer.email_exist_not();

  const {
    mutate: phoneCheck,
    data: phoneExistData,
    error: errorphnData,
    isLoading: emailLoading,
  } = useAddDealer.phone_exist_not();

  const phoneExistOrnot = async (e) => {
    await phoneCheck({
      phone: values.mobileNumber,
    });
  };

  const emailExistOrnot = async (e) => {
    await emailCheck({
      email: values.email,
    });
  };

  React.useEffect(() => {
    if (
      errorData &&
      errorData.response &&
      errorData.response.data &&
      errorData.response.data.success === "True"
    ) {
      setEmailError(
        errorData &&
          errorData.response &&
          errorData.response.data &&
          errorData.response.data.message
      );
    } else if (
      errorphnData &&
      errorphnData.response &&
      errorphnData.response.data &&
      errorphnData.response.data.success === "True"
    ) {
      setMobileError(
        errorphnData &&
          errorphnData.response &&
          errorphnData.response.data &&
          errorphnData.response.data.message
      );
    } else if (
      emailExistData &&
      emailExistData.success === "False" &&
      phoneExistData &&
      phoneExistData.success === "False"
    ) {
      submitData();
    }
  }, [emailExistData, phoneExistData, errorData, errorphnData]);

  const status = [
    {
      id: "Active",
      name: "Active",
    },
    {
      id: "Inactive",
      name: "Inactive",
    },
    // {
    //   id: "Pending",
    //   name: "Pending",
    // },
  ];

  const orderStyle = OrderManagmentStyle();
  const salesStyles = salesStyle();

  const notifyAdd = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Salesperson added successfully.
      </span>
    );
  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Salesperson updated successfully.
      </span>
    );
  useEffect(() => {
    if (dataSucces) {
      setValues({
        ...values,
        firstName: salesData && salesData.first_name,
        lastName: salesData && salesData.last_name,
        mobileNumber: salesData && salesData.phone,
        email: salesData && salesData.email,
        location: salesData && salesData.address && salesData.address.location,
        address_line_one:
          salesData && salesData.address && salesData.address.address_line_one,
        address_line_two:
          salesData && salesData.address && salesData.address.address_line_two,
        locality: salesData && salesData.address && salesData.address.locality,
        pincode: salesData && salesData.address && salesData.address.pincode,

        city: salesData && salesData.address && salesData.address.city,
        salesManagers:
          salesData && salesData.parent_id && salesData.parent_id.id,
        status: salesData && salesData.status,
      });
      setLocation(salesData && salesData.address && salesData.address.location);
    }
  }, [salesData, dataSucces]);

  const {
    mutateAsync: addData,
    isSuccess: addSuccess,
    isLoading: addLoading,
  } = useAddSalesPerson.add_salesPerson();

  const {
    mutateAsync: editSalesPerson,
    isSuccess: editSuccess,
    isLoading: editLoading,
  } = useAddSalesPerson.edit_SalesPerson();

  const handleCordinates = (cords) => {
    setLatitude(cords.lat);

    setLongitude(cords.lng);
  };

  const submitFormData = async (e) => {
    e.preventDefault();

    let err = false;
    if (!values.firstName) {
      setFirstNameError("First name is required");
      err = true;
    }
    if (!values.lastName) {
      setLastNameError("Last name is required");
      err = true;
    }
    if (!values.mobileNumber) {
      setMobileError("Phone Number is required");
      err = true;
    }
    if (values.mobileNumber.length < 10) {
      setMobileError("Invalid Phone Number");
      err = true;
    }
    if (!values.email) {
      setEmailError("Email is required");
      err = true;
    }

    if (
      !/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i.test(
        values.email
      )
    ) {
      setEmailError("Enter a valid email address");
      err = true;
    }

    if (!values.address_line_one) {
      setAddressOneError("Address is required");
      err = true;
    }

    if (!values.address_line_two) {
      setAddressTwoError("Address is required");
      err = true;
    }
    if (!values.locality) {
      setLocalityError("Locality is required");
      err = true;
    }
    if (!location) {
      setLocationError("Location is required");
      err = true;
    }
    if (!values.pincode) {
      setPincodeError("Pincode is required");
      err = true;
    }
    if (!values.city) {
      setCityError("City is required");
      err = true;
    }
    if (!values.salesManagers) {
      setSalesManagerError("Sales Manager is required");
      err = true;
    }

    if (!values.status && id) {
      setStatusError("status is required");
      err = true;
    }

    if (!err) {
      if (id) {
        await editSalesPerson({
          id: id,
          first_name: values.firstName && values.firstName.trim(),
          last_name: values.lastName && values.lastName.trim(),
          email: values.email,
          phone: values.mobileNumber,
          address: {
            address_line_one:
              values.address_line_one && values.address_line_one.trim(),
            address_line_two:
              values.address_line_two && values.address_line_two.trim(),
            locality: values.locality && values.locality.trim(),
            city: values.city && values.city.trim(),
            location: location,
            geo_location: {
              latitude: latitude,
              longitude: longitude,
            },
            // state: state && state.trim(),
            country: values.country && values.country.trim(),
            status: values.status,

            // location: values.location,
            pincode: values.pincode,
          },
          parent_id: values.salesManagers,
          status: values.status,
        })
          .then((res) => {
            if (res.status === 200) {
              toast.success(
                <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                  Salesperson updated successfully.
                </span>
              );
            }
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status !== 403) {
              if (err.response.data.email) {
                toast.error(
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    {err.response.data.email[0]}{" "}
                  </span>
                );
              } else if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error
              ) {
                toast.error(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.error
                );
              }else if (err.response.data.phone) {
                toast.error(
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    {err.response.data.phone[0]}
                  </span>
                );
              } else {
                toast.error("Something went wrong");
              }
            } else {
              if (err.response.data.detail) {
                toast.warn(err.response.data.detail);
              }
            }
            handleClose();
          });
      } else {
        handleValidate();
      }
    }
  };

  const handleValidate = () => {
    if (
      firstNameError === "" &&
      lastNameError === "" &&
      mobileError === "" &&
      emailError === "" &&
      addressOneError === "" &&
      addressTwoError === "" &&
      localityError === "" &&
      cityError === "" &&
      pincodeError === "" &&
      statusError === "" &&
      salesManagerError === "" &&
      locationError === ""
    ) {
      emailExistOrnot();
      phoneExistOrnot();
    }
  };

  const submitData = async () => {
    await addData({
      first_name: values.firstName && values.firstName.trim(),
      last_name: values.lastName && values.lastName.trim(),
      email: values.email,
      phone: values.mobileNumber,
      address: {
        address_line_one:
          values.address_line_one && values.address_line_one.trim(),
        address_line_two:
          values.address_line_two && values.address_line_two.trim(),
        locality: values.locality && values.locality.trim(),
        city: values.city && values.city.trim(),
        location: location,
        geo_location: {
          latitude: latitude,
          longitude: longitude,
        },
        status: values.status,
        // state: state && state.trim(),
        country: values.country && values.country.trim(),
        pincode: values.pincode,
      },
      parent_id: values.salesManagers,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
              Salesperson added successfully.
            </span>
          );
        }
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status !== 403) {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            toast.error(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.error
            );
          } else {
            toast.error("Something went wrong");
          }
        } else {
          if (err.response.data.detail) {
            toast.warn(err.response.data.detail);
          }
        }
        handleClose();
      });
  };

  // React.useEffect(() => {
  //   if (addSuccess) {
  //     notifyAdd();
  //     handleClose();
  //   }
  //   if (editSuccess) {
  //     notifyEdit();
  //     handleClose();
  //   }
  // }, [addSuccess, editSuccess]);

  const handleChange = (e) => {
    let targetName = e.target.name;

    let targetValue = e.target.value;
    if (targetName === "firstName") {
      if (/^[a-zA-Zs]+$/i.test(targetValue)) {
        handleInputChange(e);
        setFirstNameError("");
      } else {
        setValues({
          ...values,
          firstName: targetValue.substring(0, targetValue.length - 1),
        });
      }
    } else if (targetName === "lastName") {
      if (/^[a-zA-Z\s]+$/i.test(targetValue)) {
        handleInputChange(e);
        setLastNameError("");
      } else {
        setValues({
          ...values,
          lastName: targetValue.substring(0, targetValue.length - 1),
        });
      }
    } else if (targetName === "mobileNumber") {
      if (/^[0-9]+$/.test(targetValue)) {
        if (targetValue.length < 11) {
          handleInputChange(e);
          setMobileError("");
        }
      } else {
        setValues({
          ...values,
          mobileNumber: targetValue.substring(0, targetValue.length - 1),
        });
      }
    } else if (targetName === "email") {
      // handleInputChange(e)
      if (/^[a-zA-Z0-9@._]*$/.test(targetValue)) {
        handleInputChange(e);
        setEmailError("");
      } else {
        setValues({
          ...values,
          email: targetValue.substring(0, targetValue.length - 1),
        });
      }
    } else if (targetName === "address_line_one") {
      if (/^[0-9a-zA-Z ,#-/.&\b]+$/.test(e.target.value)) {
        handleInputChange(e);
        setAddressOneError("");
      } else {
        return setValues({
          ...values,
          address_line_one: e.target.value.substring(
            0,
            e.target.value.length - 1
          ),
        });
      }
    } else if (targetName === "address_line_two") {
      if (/^[0-9a-zA-Z ,#-/.&\b]+$/.test(e.target.value)) {
        handleInputChange(e);
        setAddressTwoError("");
      } else {
        return setValues({
          ...values,
          address_line_two: e.target.value.substring(
            0,
            e.target.value.length - 1
          ),
        });
      }
    } else if (targetName === "pincode") {
      if (/^[0-9]+$/.test(e.target.value)) {
        if (e.target.value.length < 7) {
          handleInputChange(e);
          setPincodeError("");
        }
      } else {
        setValues({
          ...values,
          pincode: e.target.value.substring(0, e.target.value.length - 1),
        });
      }
    } else if (targetName === "city") {
      if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
        handleInputChange(e);
        setCityError("");
      } else {
        setValues({
          ...values,
          city: e.target.value.substring(0, e.target.value.length - 1),
        });
      }
    } else if (targetName === "locality") {
      if (/^[0-9a-zA-Z ,#-.&\b]+$/.test(e.target.value)) {
        handleInputChange(e);
        setLocalityError("");
      } else {
        setValues({
          ...values,
          locality: e.target.value.substring(0, e.target.value.length - 1),
        });
      }
    } else if (targetName === "salesManagers") {
      handleInputChange(e);
      setSalesManagerError("");
    } else if (targetName === "status") {
      handleInputChange(e);
      setStatusError("");
    }
  };

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;

  const alphaWithSpace = (e) => {
    let value = e.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal open={open}>
        <div className="modalWrapSale">
          <Box>
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={salesStyles.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={handleClose}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>

            <div style={{ width: "100%", padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    First Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    name="firstName"
                    placeholder="First Name"
                    className={salesStyles.inputTextBar}
                    value={values.firstName}
                    onChange={handleChange}
                    error={firstNameError}
                    inputProps={{ maxLength: 45 }}
                    // onKeyPress={alphaWithSpace}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Last Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    name="lastName"
                    placeholder="Last Name"
                    className={salesStyles.inputTextBar}
                    value={values.lastName}
                    onChange={handleChange}
                    error={lastNameError}
                    inputProps={{ maxLength: 45 }}
                    // onKeyPress={alphaWithSpace}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Mobile Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    name="mobileNumber"
                    placeholder="Phone Number"
                    className={salesStyles.inputTextBar}
                    value={values.mobileNumber}
                    onChange={handleChange}
                    error={mobileError}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Email
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    name="email"
                    placeholder="example@gmail.com"
                    className={salesStyles.inputTextBar}
                    value={values.email}
                    onChange={handleChange}
                    error={emailError}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Location
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Autocomplete
                    className={salesStyles.geolocation}
                    apiKey={url}
                    // style={{
                    //   borderColor: locationError.length > 0 ? "red" : "gray",
                    //   borderWidth: 1,
                    //   borderStyle: "solid",
                    // }}
                    options={{
                      types: [
                        "neighborhood",
                        "locality",
                        "sublocality",
                        "postal_code",
                        "point_of_interest",
                        // "ALL",
                      ],
                      componentRestrictions: { country: "in" },
                      fields: [
                        "address_components",
                        "geometry",
                        "icon",
                        "name",
                        "place_id",
                        "formatted_address",
                        // "ALL",
                      ],
                    }}
                    name="location"
                    onPlaceSelected={(place) => {
                      let cords = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      };
                      setLocationError("");
                      setLocation(place.formatted_address);
                      handleCordinates(cords);
                    }}
                    // onKeyPress={preventSpecial}
                    onChange={(place) => {
                      if (place.formatted_address === undefined) {
                        setLocationError("Invalid Location");
                      }
                      setLocation(place.formatted_address);
                    }}
                    value={location}
                  />
                  <br></br>
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {locationError}
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Address
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ width: { xs: "100%", sm: "90%", md: "21vw" } }}>
                    <Grid
                      container
                      rowSpacing={{ xs: 2, sm: 3 }}
                      columnSpacing={1}
                    >
                      <Grid item xs={12}>
                        <Controls.TextArea
                          name="address_line_one"
                          placeholder=" Address Line 1"
                          type="text"
                          inputProps={{ maxLength: 10 }}
                          className={salesStyles.stepperFormTextBar}
                          value={values.address_line_one}
                          onChange={handleChange}
                          error={addressOneError}
                          maxRows={4}
                          height={"75px"}
                          padding={"10px"}
                        />
                        {addressOneError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Address is required
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Controls.TextArea
                          name="address_line_two"
                          type="text"
                          placeholder=" Address Line 2"
                          inputProps={{ maxLength: 200 }}
                          className={salesStyles.stepperFormTextBar}
                          value={values.address_line_two}
                          onChange={handleChange}
                          error={addressTwoError}
                          maxRows={4}
                          height={"75px"}
                          padding={"10px"}
                        />
                        {addressTwoError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Address is required
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Controls.InputField
                          name="locality"
                          placeholder="Locality"
                          type="text"
                          inputProps={{ maxLength: 200 }}
                          className={salesStyles.stepperFormTextBar}
                          value={values.locality}
                          onChange={handleChange}
                          error={localityError}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controls.InputField
                          name="city"
                          placeholder="City"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          className={salesStyles.stepperFormTextBar}
                          value={values.city}
                          onChange={handleChange}
                          error={cityError}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Country & Zip Code
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ width: { xs: "100%", sm: "90%", md: "21vw" } }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controls.InputField
                          name="country"
                          placeholder="country"
                          type="text"
                          inputProps={{ maxLength: 45 }}
                          className={salesStyles.stepperFormTextBar}
                          value="India"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controls.InputField
                          name="pincode"
                          type="text"
                          placeholder="pincode"
                          inputProps={{ maxLength: 45 }}
                          className={salesStyles.stepperFormTextBar}
                          value={values.pincode}
                          onChange={handleChange}
                          error={pincodeError}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography className={salesStyles.salesLabel}>
                    Reporting Manager
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.SelectBox
                    className={salesStyles.formSelectBox}
                    name="salesManagers"
                    label="Reporting"
                    options={salesManagersData}
                    value={values.salesManagers}
                    onChange={handleChange}
                    error={salesManagerError}
                  />
                </Grid>
                {id && (
                  <>
                    {" "}
                    <Grid item xs={12} sm={5}>
                      <Typography className={salesStyles.salesLabel}>
                        Status
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Controls.SelectBox
                        className={salesStyles.formSelectBox}
                        name="status"
                        label={
                          values.status === "Pending" ? "Pending" : "Status"
                        }
                        options={status}
                        value={values.status !== "Pending" ? values.status : ""}
                        onChange={handleChange}
                        error={statusError}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          ibtname={
                            addLoading ||
                            editLoading ||
                            mobileLoading ||
                            emailLoading ? (
                              <CircularProgress color="black" size={24} />
                            ) : id ? (
                              EDIT_BRAND
                            ) : (
                              FORM_BUTTON_MANAGEMENT
                            )
                          }
                          disabled={
                            addLoading ||
                            editLoading ||
                            mobileLoading ||
                            emailLoading
                              ? true
                              : false
                          }
                          className={salesStyles.formButtonAdd}
                          onClick={submitFormData}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: { xs: "inline", sm: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButs
                          onClick={handleClose}
                          ibtname={FORMONE_BUTTON_MANAGEMENT}
                          className={salesStyles.formButton}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default SalesForm;
