import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineClose } from "react-icons/ai";
import Controls from "../../common/Controls/Controls";
import { Typography } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#ffffff",
  boxShadow: 24,
  borderRadius: "10px",
  ["@media (max-width: 820px)"]: {
    width: "95vw",
  },
  ["@media (max-width: 450px)"]: {
    // left: "46%",
  },
  ["@media (max-width: 400px)"]: {
    // left: "41%",
  },
  ["@media (max-width: 360px)"]: {
    width: "90vw",
    // left: "40%",
  },
};

const DealerBankDetailsModal = ({
  open,
  handleClose,
  acountNumber,
  acountName,
  bankName,
  ifsCode,
  braNch,
}) => {
  // const { id } = useParams();
  const customStyle = makeStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <main>
            <form>
              <Grid>
                <header className={customStyle.headerStyleOne}>
                  <h1 style={{ marginTop: "15px", marginLeft: "30px" }}>
                    Bank Details
                  </h1>
                  <div
                    className={customStyle.iconStyle}
                    style={{ marginRight: "20px" }}
                  >
                    <IconButton onClick={handleClose}>
                      <AiOutlineClose />
                    </IconButton>
                  </div>
                </header>
                <div className={customStyle.divStyleOne}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>Bank Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controls.StyledInput
                        // className={dealerClass.formTextBar}
                        fullWidth
                        name="products"
                        value={bankName}
                        // options={selectData}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>Account Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controls.StyledInput
                        fullWidth
                        value={acountName}
                        // label="User Role"

                        // options={userCredentialManagersData}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>Account Number</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controls.StyledInput
                        // className={dealerClass.formTextBar}
                        name="products"
                        value={acountNumber}
                        fullWidth
                        // options={selectData}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>Branch</Typography>
                    </Grid>
                    <Grid item xs={12} jy md={6}>
                      <Controls.StyledInput
                        // className={dealerClass.formTextBar}
                        fullWidth
                        name="products"
                        value={braNch}

                        // options={selectData}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>IFSC CODE</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controls.StyledInput
                        // className={dealerClass.formTextBar}
                        fullWidth
                        name="ifsc"
                        value={ifsCode}

                        // options={selectData}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </form>
          </main>
        </Box>
      </Modal>
    </div>
  );
};

export default DealerBankDetailsModal;
