import * as React from "react";
import Select from "@material-ui/core/Select";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       // width: 250,
//     },
//   },
// };

const MultipleView = ({
 
  value,
  
  className,
  // ...other
}) => {
 
  return (
    <Select
      className={className}
      multiple
      value={value}
      disabled
      InputLabelProps={{
        shrink: false,
      }}
      //   renderValue={(selected) => (
      //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      //       {selected.map((value) => (
      //         <Chip key={value.name} label={value.name} />
      //       ))}
      //     </Box>
      //   )}
    ></Select>
  );
};

export default MultipleView;
