import DataService from "../../DataService/DataService";

const countUrl = async ({ filter_type, date_range }) => {
  const url = `dashboard/counts/?${
    filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
  }${date_range !== "" ? "date_range=" + date_range : ""}`;
  const data = await DataService.get(url);
  return data;
};

const orderUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/order-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

const productUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/order-product-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

const topProductUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/top-product-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

const dealerUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/delears-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

const areaUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/order-area-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

const quickUpdateUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/category-percentage-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};
const activeCustomerUrl = async ({ filter_type, date_range }) => {
  try {
    const url = `dashboard/active-customer-graph/?${
      filter_type !== "" ? "filter_type=" + filter_type + "&" : ""
    }${date_range !== "" ? "date_range=" + date_range : ""}`;
    const data = await DataService.get(url);
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  countUrl,
  orderUrl,
  productUrl,
  topProductUrl,
  dealerUrl,
  areaUrl,
  quickUpdateUrl,
  activeCustomerUrl,
};
