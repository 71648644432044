import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const SearchBox = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: `90px`,
          height:`50px`,
          width:`350px`
        },
      },
    },
})(TextField);

const SearchInput = ({label}) => {

    return (
      <div>
        <SearchBox id="outlined-basic" label={label} variant="outlined" />
      </div>
    )
}

export default SearchInput;