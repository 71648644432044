import { Autocomplete } from "@material-ui/lab";
import { TextField, Chip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import "./styles.css";

export default function ChipInput({ updateChip }) {
  //   const [receivers, setReceivers] = React.useState([]);

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      defaultValue={[]}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          updateChip(value);
    
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => <TextField {...params} label="Facilities" />}
    />
  );
}
