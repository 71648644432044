import React from 'react'
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import useStyles from '../LoginCss';
import { LoadingButton } from "@mui/lab";

import makeStyles from "../../../sharedFeatures/sharedClasses";
import {  Form } from "../../../sharedFeatures/useForm";
import Controls from "../../../common/Controls/Controls";
import { Link } from 'react-router-dom'


const ResetPassword = () => {
  const classes = useStyles();
  const customStyle = makeStyles();
  return (
    <>

      <div className={classes.loginParent}>
        <Grid>
          <Form>
            <Paper elevation={10} className={classes.paperStyle}>
              <Grid align="center" fontSize="35px">
                <h3>Reset Password</h3>
              </Grid>
              <div className="tf">
                <Controls.InputField
                  className={customStyle.inputTextBar}
                  name="email"
                  placeholder='Enter New Password'
                />

              </div>
              <div className="tf">
                <Controls.InputField
                  className={customStyle.inputTextBar}
                  name="email"
                  placeholder='Confirm New Password'
                />

              </div>
              <Box className={classes.loginForgotParent} sx={{ mt: 3, mb: 3 }}>
                <Link to='/resetpasword'><LoadingButton
                  className={classes.loginButton}
                  type="submit"
                  variant="contained"
                  // fullWidth
                  loadingIndicator="Loading..."

                >
                  RESET PASSWORD
                </LoadingButton> </Link>
              </Box>

              <Grid align="center">
                <Typography><Link to='/forgetpasword'>Go Back</Link></Typography> </Grid>
            </Paper>
          </Form>
        </Grid>
      </div>
    </>
  )
}

export default ResetPassword