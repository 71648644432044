import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    headbar: {
      position: "sticky",
      top: "0",
      width: "100%",
      height: "80px",
      backgroundColor: "#ffffff",
      zIndex: "1000",
    },
    headerStyle: {
      color: "#252F40",
      position: "sticky",
      top: "0",
      width: "100%",
      height: "80px",
      display: "flex",
      padding: "0 40px",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "white",
      zIndex: "100",
      fontFamily: "Montserrat",
    },
    mobileHeaderStyle: {
      color: "#252F40",
      position: "sticky",
      top: "0",
      width: "100%",
      height: "80px",
      display: "flex",
      padding: "0 !important",
      alignItems: "center",
      justifyContent: "space-evenly",
      backgroundColor: "#FFFFFF",
      zIndex: "100",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-around",
      },
    },
    headerLeftIcons: {
      display: "flex",
      justifyContent: "space-between",
      width: "55px",
      height: "0px",
      marginTop: "-25px",
      marginRight: "13px",
      marginLeft: "-9px",
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      color: "#252F40",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "-0.442553px",
    },
    iconColor: {
      color: "#67748E",
    },
    iconStyle: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    userType: {
      paddingRight: "13px",
      paddingLeft: "5px",
      fontFamily: "Montserrat",
      fontSize: "14px",
      marginTop: "14px",
      color: "#67748E",
      fontWeight: "600",
      letterSpacing: "-0.387234px",
    },
  })
);
