import  { useEffect, useState } from 'react'

const useDebounce = (value,delay) => {
    const [debounceValue,setDebounceValue]=useState(value)
    useEffect(()=>{
        const handleTimeOut = setTimeout(() => {
              setDebounceValue(value)
        }, delay);

        return ()=>{
            clearTimeout(handleTimeOut)
        }
    },[value,delay])
 
  return debounceValue
}

export default useDebounce