import { useMutation, useQueryClient } from "react-query";
import { addProduct, deleteProduct, editProduct, productImageAdd } from "./inventoryUrls";

const add_product = () => {
  const queryClient = useQueryClient();

  return useMutation((product) => addProduct(product), {
    onMutate: async (newProduct) => {
      await queryClient.cancelQueries("productList");

      const prev = queryClient.getQueryData("productList");

      return {
        prev,
        newProduct,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("productList", context.prev);
    },
  });
};
const addImage = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => productImageAdd(data), {
    onSuccess: async (newProduct) => {
      queryClient.invalidateQueries("productList");;

      return newProduct;
     
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("productList", context.prev);
    },
  });
};


const edit_product = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => editProduct(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("productList");
      const prev = queryClient.getQueryData("productList");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("productList", context.prev);
    },
  });
};
const delete_Product = () =>{
    const queryClient = useQueryClient()
    return useMutation((todoId)=>deleteProduct(todoId),{
      onMutate:async (todo)=>{
        await queryClient.cancelQueries("productList")
  
  
        const prev = queryClient.getQueryData("productList")
  
        queryClient.setQueryData("productList",(old=[])=>{
          
        })
       
        // old.results.filter((item)=>item.id!==todo.id) 
  
      
        return {
          prev,
          todo
        }
  
      },
      onError:(err,_,context)=>{
        queryClient.setQueryData("productList",context.prev)
      },
      onSettled:(todo)=>{
       queryClient.invalidateQueries("productList")
      }
    })
  }
const useAddProduct = {
  add_product,
  edit_product,
  delete_Product,
  addImage
};

export default useAddProduct;
