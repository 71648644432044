import React from "react";
import { Paper, TableBody, TableRow, TableCell } from "@material-ui/core";
import { TXT_PRODUCT, ICON_BUTTON_PRODUCT } from "../../utilitis/header";
import useTable from "../../sharedFeatures/useTable";
import IconButs from "../../common/Controls/IconButs";
import HeadCells from "../../common/Headcells";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import HeadBar from "../../components/HeadBar/HeadBar";
import makeStyles from "../../sharedFeatures/sharedClasses";
import InputField from "../../common/Controls/InputField";
import { BsSearch } from "react-icons/bs";
import useProductList from "../../data/inventory/useProductList";
import { PAGE_SIZE } from "../../utilitis/variable";
import PageCounter from "../../common/PageCounter";

const Product = () => {
  const styles = makeStyles();

  const { data: prodList, isError, isLoading } = useProductList();
  const { totalCount } = PageCounter(prodList && prodList.count);
  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(
    HeadCells.productHead,
    totalCount
  );

  let serialNo = PAGE_SIZE * slNo;

  return (
    <>
      <div className={styles.divStyle}>
        <HeadBar title={TXT_PRODUCT} />

        <div className={styles.searchBoxParent}>
          <InputField
            placeholder="Type here..."
            className={styles.searchBar}
            size="small"
            name="usersearch"
            icon={<BsSearch />}
          />
          <div className={styles.excelData}>
            <Link to={`/admin/addProduct/`}>
              <IconButs
                ibtname={ICON_BUTTON_PRODUCT}
                icon={<AiOutlinePlus />}
                className={styles.salesButton}
              />
            </Link>
          </div>
        </div>

        <Paper className={styles.parentContainerOne} elevation="6">
          <div className={styles.headerParent}>
            <section>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {prodList &&
                    prodList.results.map((item, key) => {
                      serialNo++;
                      return (
                        <TableRow key={key}>
                          <TableCell align="center">{serialNo}</TableCell>
                          <TableCell align="center">{item.item_code}</TableCell>
                          <TableCell align="center">{item.model}</TableCell>
                          <TableCell align="center">{item.gst_perc}</TableCell>
                          <TableCell align="center">{item.margin}</TableCell>
                          <TableCell align="center">
                            {item.dealer_landing_price}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </TblContainer>
            </section>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Product;
