import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import UseAppName from "../../Sidebar/UseAppname/UseAppName";
import routes from "../../Routes/Routes";
import { NavLink, useLocation } from "react-router-dom";
import "../../Sidebar/Sidebar.css";
import useLoginQuery from "../../data/auth/useLoginQuery";

const SidebarDrawer = ({ drawerOpen, toggleDrawer }) => {
  const location = useLocation();

  const { isSuccess: permissionsSuccess, refetch: permissionsFetch } =
    useLoginQuery.permissions_list();

  const [newRoutes, setNewRoutes] = useState([]);

  useEffect(() => {
    permissionsFetch();
  }, []);

  useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("userPermissions"));
    let filteredRoutes = [];
    if (permissions) {
      permissions = permissions.map((item) => item.id);
      permissions && permissions.push(0);
      filteredRoutes = routes.filter((item) => permissions.includes(item.id));
    }
    setNewRoutes([...filteredRoutes]);
  }, [permissionsSuccess]);

  return (
    <div style={{ height: "100vh", position: "fixed", top: "0" }}>
      <Drawer
        anchor={window.innerWidth < 600 ? "right" : "left"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        style={{ height: "100vh" }}
      >
        <ProSidebar
          style={{ height: "100vh", position: "sticky", top: "0", left: "0" }}
        >
          <div className="sidebar-header">
            <SidebarHeader>
              <UseAppName />
            </SidebarHeader>
          </div>
          <div>
            <SidebarContent>
              <Menu>
                {newRoutes && newRoutes.map((route, key) =>
                  route.collapse === true && route.submenu !== true ? (
                    <MenuItem
                      key={route.name}
                      icon={route.icon}
                      title={route.name}
                      style={{
                        color:
                          location.pathname === route.layout + route.path
                            ? "#FDC727"
                            : "",
                        border:
                          location.pathname === route.layout + route.path
                            ? "1px solid rgba(233, 65, 65, 0.13)"
                            : "",
                        borderRadius:
                          location.pathname === route.layout + route.path
                            ? "12px"
                            : "",
                        boxShadow:
                          location.pathname === route.layout + route.path
                            ? "2px 4px 24px 3px rgba(0, 0, 0, 0.1)"
                            : "",
                        backgroundColor:
                          location.pathname === route.layout + route.path
                            ? "#FFFFFF"
                            : "",
                      }}
                    >
                      <NavLink to={route.layout + route.path}>
                        {route.name}
                      </NavLink>
                    </MenuItem>
                  ) : route.collapse === true && route.submenu === true ? (
                    <SubMenu
                    key={route.name}
                    icon={route.icon}
                    title={route.name}
                    className="link"
                    style={{
                      color: route.path
                        .toLowerCase()
                        .includes(location.pathname.split("/")[2].slice(0, 5))
                        ? "#FDC727"
                        : "",
                      border: route.path
                        .toLowerCase()
                        .includes(location.pathname.split("/")[2].slice(0, 5))
                        ? "1px solid rgba(233, 65, 65, 0.13)"
                        : "",
                      borderRadius: route.path
                        .toLowerCase()
                        .includes(location.pathname.split("/")[2].slice(0, 5))
                        ? "12px"
                        : "",
                      boxShadow: route.path
                        .toLowerCase()
                        .includes(location.pathname.split("/")[2].slice(0, 5))
                        ? "2px 4px 24px 3px rgba(0, 0, 0, 0.1)"
                        : "",
                      backgroundColor: route.path
                        .toLowerCase()
                        .includes(location.pathname.split("/")[2].slice(0, 5))
                        ? "#FFFFFF"
                        : "",
                    }}
                  >
                    {route &&
                      route.views.map((subItem, key) => {
                        {
                          return (
                            <MenuItem
                              key={subItem.name}
                              icon={subItem.icon}
                              title={subItem.name}
                              className="subinmenu"
                              style={{
                                borderBottom:
                                  location.pathname === subItem.layout + subItem.path
                                    ? "1px solid #E94141"
                                    : "",
                              }}
                            >
                              {
                                subItem.Orders && subItem.Orders.views.length > 0 ?
                                <SubMenu
                                  key={subItem.Orders.name}
                                  // icon={subItem.Orders.icon}
                                  title={subItem.Orders.name}
                                  
                                  style={{
                                    
                                    color: subItem.Orders.path
                                      .toLowerCase()
                                      .includes(
                                        location.pathname.split("/")[2].slice(0, 5)
                                      )
                                      ? "#FDC727"
                                      : "",
                                   
                                    borderRadius: subItem.Orders.path
                                      .toLowerCase()
                                      .includes(
                                        location.pathname.split("/")[2].slice(0, 5)
                                      )
                                      ? "12px"
                                      : "",
                                    boxShadow: subItem.Orders.path
                                      .toLowerCase()
                                      .includes(
                                        location.pathname.split("/")[2].slice(0, 5)
                                      )
                                      ? "2px 4px 24px 3px rgba(0, 0, 0, 0.1)"
                                      : "",
                                    backgroundColor: subItem.Orders.path
                                      .toLowerCase()
                                      .includes(
                                        location.pathname.split("/")[2].slice(0, 5)
                                      )
                                      ? "#FFFFFF"
                                      : "",
                                  }}
                                >
                                  {subItem.Orders &&
                                    subItem.Orders.views.length > 0 &&
                                    subItem.Orders.views.map((sub) => {
                                      return (
                                        <MenuItem
                                          key={sub.name}
                                          icon={sub.icon}
                                          title={sub.name}
                                          style={{
                                           
                                            padding:"0px !important",
                                            borderBottom:
                                              location.pathname ===
                                              sub.layout + sub.path
                                                ? "1px solid #E94141"
                                                : "",
                                          }}
                                        >
                                          <NavLink
                                            to={sub.layout + sub.path}
                                             style={{ marginLeft:"30px",}}
                                            className={({ isActive }) =>
                                              isActive ? "active" : "link"
                                            }
                                          >
                                            {sub.name}
                                          </NavLink>
                                        </MenuItem>
                                      );
                                    })}
                                </SubMenu>:
                              
        
                              <NavLink
                                to={subItem.layout + subItem.path}
                                style={{ marginLeft:"10px" }}
                                className={({ isActive }) =>
                                  isActive ? "active" : "link"
                                }
                              >
                                {subItem.name}
                              </NavLink>
                              }
                            </MenuItem>
                          );
                        }
                      })}
                  </SubMenu>
                  ) : null
                )}
              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </Drawer>
    </div>
  );
};

export default SidebarDrawer;
