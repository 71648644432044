import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { TXT_REPORTING } from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import HeadBar from "../../components/HeadBar/HeadBar";
import ReportingPageCarIcon from "../../utilitis/icons/ReportingPageCarIcon";
import Controls from "../../common/Controls/Controls";
import { BsSearch } from "react-icons/bs";
import DropDown from "../../common/Controls/ListingDropdown";

import useTable from "../../sharedFeatures/useTable";
import { Link } from "react-router-dom";
import HeadCells from "../../common/Headcells";
import PageDropDown from "../../common/Controls/PageDropDown";
import { Pagination } from "@material-ui/lab";
import financeStyles from "../FinanceManagement/financeStyles";
import usePagination from "../../common/Controls/usePagination";
import reportQuery from "../../data/report/reportQuery";
import CustomLoader from "../../features/CustomLoader";
import ReportView from "./ReportView";

const Reporting = () => {
  const [currentpageNo, setcurrentpageNo] = useState(1);
  const styles = makeStyles();
  // const dropData = [
  //   {
  //     key: "sdf",
  //     value: "dfs",
  //   },
  // ];

  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [filterBy, setFilterBy] = useState("");
  const qry = {
    searchQry: trySearch,
    first_name: filterBy.split(" ")[0],
    last_name: "",
    page: page !== null ? page : currentpageNo,
    perPageItems: pageItems,
  };
  const { data: reportSalesPersonData, isLoading: reportSalesLoading } =
    reportQuery.getSalesPersonList();
  const filterData =
    reportSalesPersonData &&
    reportSalesPersonData.data &&
    reportSalesPersonData.data.map((e) => {
      return {
        key: e.id,
        value: e.first_name + " " + e.last_name,
      };
    });

  const { data: reportData, isLoading: reportLoading,isFetching } =
    reportQuery.getReportsList(qry);
  const { TblContainer, TblHead } = useTable(HeadCells.reports);

  const { currentData, currentPage, maxPage, slNo, setSearchParams } =
    usePagination(reportData !== undefined && reportData, pageItems);
  console.log(filterBy);

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  let serialNo = pageItems * slNo;

  const handleFilterBySalesPerson = (e) => {
    setFilterBy(e.target.value);
    console.log(e);
  };
  const financeStyle = financeStyles();
  return (
    <>
      <HeadBar title={TXT_REPORTING} />
      <div className={styles.divStyle}>
        <div className={styles.searchBoxParent}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={4}>
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  setTrySearch(e.target.value);
                }}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography className={styles.viewlabel}>
                    Filter By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <DropDown
                    style={{ width: "100%" }}
                    value={filterBy}
                    handlecreateNewItem={handleFilterBySalesPerson}
                    items={filterData || []}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {reportLoading ||isFetching ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ):
            <TableBody>
              {reportData &&
                reportData.data &&
                reportData.data.map((item) => {
                  serialNo++;
                  return (
                    <TableRow key={item.id}>
                      <TableCell align="center">{serialNo}</TableCell>
                      <TableCell align="center">{item.first_name}</TableCell>
                      <TableCell align="center">
                        {item.dealer_onboarded}
                      </TableCell>
                      <TableCell align="center">
                        {item.dealer_approved}
                      </TableCell>
                      <TableCell align="center">
                        {item.dealer_pending_approval}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                        to={`view/${item.id}`}
                          // to={{
                          //   pathname: `/view/${item.id}`,
                          //   state: { someData: 'data you want to pass' },
                          //   }}
                            state={{ qry: item }}
                          className={styles.linkColor}
                          // onClick={() => handlePassword(item.id)}
                  
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>}
          </TblContainer>
        </Box>

        <Box className={financeStyle.pagination}>
          <Box className={financeStyle.paginationStyle}>
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 3, value: 30 },
              ]}
              handlePageItems={handlePageItems}
            />
            <span
              className={financeStyle.paginationPage}
              style={{ marginLeft: "13px" }}
            >
              Per Page
            </span>
          </Box>
          {/* {console.log("maxpage:"+maxPage+ page)} */}
          <Pagination
            count={maxPage}
            className={financeStyle.paginationFont}
            page={Number(page) || currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </Box>
      </div>
    </>
  );
};

export default Reporting;
