import moment from "moment";

export const keyConverter = (data, filter) => {
  if (filter && filter.time === "today") {
    return data["x-axis"].date;
  } else if (filter && filter.time === "last_seven_days") {
    return data["x-axis"].weekday;
  } else if (filter && filter.time === "last_month") {
    return data["x-axis"].day;
  } else if (filter && filter.time === "last_three_month") {
    return data["x-axis"].month_name;
  } else if (filter && filter.time === "last_year") {
    return data["x-axis"].year + " - " + data["x-axis"].month_name;
  } else if (
    (filter.start_date !== "") 
  ) {
    if (moment(filter.start_date).month() === moment(filter.end_date).month()) {
      return data["x-axis"].day;
    } else if(moment(filter.start_date).month() !== moment(filter.end_date).month()) {
      return data["x-axis"].month_name;
    }
  } else {
    return data["x-axis"].weekday;
  }
};
