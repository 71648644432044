import DataService from "../../DataService/DataService";

const userRoleDetails = async ({ searchQry, page, perPageItems }) => {
  
  let url = ``;
  if (searchQry && page && perPageItems) {
    url = `auth/userrolepermissions/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page) {
    url = `auth/userrolepermissions/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `auth/userrolepermissions/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (page && perPageItems) {
    url = `auth/userrolepermissions/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `auth/userrolepermissions/?page=${page}`;
  } else if (perPageItems) {
    url = `auth/userrolepermissions/?page_size=${perPageItems}`;
  } else {
    url = `auth/userrolepermissions/?page=${page}`;
  }
  const data = await DataService.get(url);
  return data.data;
};

const userRolePermissions = async () => {
  const url = "auth/permissions/";
  const data = await DataService.get(url);
  return data.data;
};

const addUserRole = async (value) => {
  const url = "auth/userrolepermissions/";
  const { ...state } = value;
  const data = await DataService.post(url, value);
  return data;
};

const editUserRole = async (value) => {
  const { id, ...editData } = value;
  const url = `auth/userrolepermissions/${id}/`;
  const data = await DataService.patch(url, editData.payload);
  return data;
};

const getRoleDetails = async (value) => {
  const { id } = value;
  const url = `auth/userrolepermissions/${id}/`;
  const data = await DataService.get(url);
  return data.data;
};

export {
  userRoleDetails,
  userRolePermissions,
  addUserRole,
  editUserRole,
  getRoleDetails,
};
