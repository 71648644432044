import React from "react";
import { Card, Box } from "@material-ui/core";
import InfoIButton from "../../utilitis/icons/InfoIButton";
import useStyles from "./dashboardStyles";
import ChartDetailHeader from "./ChartDetailHeader";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "Mon",
//     uv: 59,
//     amt: 140,
//     cnt: 49,
//   },
//   {
//     name: "Tue",
//     uv: 86,
//     amt: 150,
//     cnt: 59,
//   },
//   {
//     name: "Wed",
//     uv: 139,
//     amt: -168,
//     cnt: 35,
//   },
//   {
//     name: "Thu",
//     uv: 148,
//     amt: 122,
//     cnt: 48,
//   },
//   {
//     name: "Fri",
//     uv: -152,
//     amt: 110,
//     cnt: -46,
//   },
//   {
//     name: "Sat",
//     uv: 140,
//     amt: 170,
//     cnt: 38,
//   },
//   {
//     name: "Sun",
//     uv: 139,
//     amt: -168,
//     cnt: 35,
//   },
// ];

const SalesAreaWise = ({data,total=0}) => {
  const dashStyles = useStyles();
 
  const changeLegendTextColor = (value, entry) => {
    return (
      <span
        style={{
          color: "#252F40",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          padding: "0 10px 0 5px",
        }}
      >
        {value}
      </span>
    );
  };
  return (
    <>
      <Card className={dashStyles.salesChartCommonParent}>
        <ChartDetailHeader
          title="Sales - Area wise"
          stat={total}
          update="Daily Update"
        />
        <Box className={dashStyles.iButtonParent}>
          <InfoIButton />
        </Box>
        <ResponsiveContainer width="100%" height="75%">
          <ComposedChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 40,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" axisLine={false} />
            <YAxis axisLine={false} />
            <Tooltip cursor={false} />
            <Legend
              align="center"
              verticalAlign="bottom"
              iconType="square"
              formatter={changeLegendTextColor}
            />
          
            <Line
              type="linear"
              dataKey="navi_Mumbai"
              stroke="#165BAA"
              strokeWidth={2}
              name="Navi Mumbai"
              dot={{ stroke: "#165BAA", fill: "#165BAA", strokeWidth: 2 }}
            />
            <Line
              type="linear"
              dataKey="mumbai"
              stroke="#A155B9"
              strokeWidth={2}
              name="Mumbai"
              dot={{ stroke: "#A155B9", fill: "#A155B9", strokeWidth: 2 }}
            />
            <Line
              type="linear"
              dataKey="thane"
              strokeDasharray="5 5"
              stroke="#F765A3"
              name="Thane"
              strokeWidth={2}
              dot={{ stroke: "#F765A3", fill: "#F765A3", strokeWidth: 2 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default SalesAreaWise;
