import axios from "axios";
import Axios from "axios";
import { toast } from "material-react-toastify";

let accessToken = localStorage.getItem("accessToken");

//................................................................

export const http = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Accept: "application/json" },
});
export const httpBase = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { Accept: "application/json" },
});
http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});
http.interceptors.response.use(
  async (responce) => {
    return responce;
  },
  async (error) => {
    const originalConfig = error.config;

    if (
      error.response.status == 401 &&
      error.response.data.code == "user_not_found"
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userType");
      localStorage.removeItem("userPermissions");
      window.history.pushState("/login");
    }
    // Access Token was expired
    switch (error.response.status) {
      case 500:
        toast.warn("We are facing some issue, please try after sometimes");
        originalConfig._retry = false;
        return Promise.reject(error);

      default:
        return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
let flag = false
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code === "token_not_valid" &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages[0] &&
        error.response.data.messages[0].token_type === "access"
      ) {
        const originalConfig = error.config;
        originalConfig._retry = true;

        try {
          const refreshTokens = localStorage.getItem("refreshToken");

          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/auth/token/refresh/",
            {
              refresh: refreshTokens,
            }
          );

          let accessToken = response.data.access;

          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
          }
          originalConfig._retry = false;
          return http(originalConfig);
        } catch (_error) {
          if (
            _error &&
            _error.response &&
            _error.response.data &&
            !_error.response.data.messages
          ) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.replace("/");
          }
          return Promise.reject(_error);
        }
      } else {
        return Promise.reject("Refresh token not valid");
      }
    } else if (error && error.response && error.response.status === 403) {
  
      if(!flag){

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.detail
      ) {
        const originalConfig = error.config;
       toast.warn( error.response.data.detail) 
       originalConfig._retry = false;
       flag=true
      }
      setTimeout(() => {
        flag=false
      }, 2000);
    }
    } if(
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code === "user_cannot_access" &&
      error &&
      error.response &&
      error.response.status === 401
    ){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.replace("/");
    }

    // else if (error?.response?.status == 502) {
    //   console.log(error?.response);
    //   console.log("Failed");
    // } else if (error?.response?.status == 403) {
    //   return Promise.reject("refresh_not_valid"); // for disabling login from multiple devices
    // }
    else {
      return Promise.reject(error);
    }
  }
);

//................................................................

// export const instance = Axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: { Accept: "application/json" },
// });
// instance.interceptors.request.use(async (config) => {
//   let accessToken = localStorage.getItem("accessToken");
//   config.headers["authorization"] = `Bearer ${accessToken}`;
//   return config;
// });

//................................................................

export const formData_instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
formData_instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});

//................................................................

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   async (error) => {
//     return Promise.reject(error);
//   }
// );

formData_instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

//.................................................................
