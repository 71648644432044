import React, { useEffect, useState } from "react";
import {
  Paper,
  IconButton,
  Grid,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import Sub from "../../common/Subheader/Sub";
import {
  TXT_ASSIGN_ORDER_DETAIL,
  ASSIGN_DEALER_TECHNICIAN,
  DEALER_LIST_DOC_DOWNLOAD,
  DEALER_LIST_DOC,
} from "../../utilitis/header";
import { Link, useParams, useLocation } from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import OrderManagmentStyle from "./OrderManagmentStyle";
import geocodeLocation from "../../utilitis/locationDecode/geocodeUtils";
// values,HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import AssignLinkedOrders from "./AssignLinkedOrders";
import CustomLoader from "../../features/CustomLoader";
import OrdersIMageViewer from "./OrdersIMageViewer";
import { invoice } from "../../data/orderManegemnet/orderUrls";
import { toast } from "material-react-toastify";
import { Popover } from "@mui/material";
import axios from "axios";
import DataService from "../../DataService/DataService";
import moment from "moment";

const AssignOrderDetails = () => {
  const viewStyle = makeStyles();
  const orderStyle = OrderManagmentStyle();
  const [search, setSearch] = useState();
  const [linkedOrder, setLinkedOrder] = useState(false);
  const [imageOpen, setImageOPen] = useState(false);
  const [advisorPop, setAdvisorPop] = useState(null);
  const popId = advisorPop ? "sdvisor-popover" : undefined;
  const advisorOpen = Boolean(advisorPop);
  const handleAdvisorClick = (event) => {
    setAdvisorPop(event.currentTarget);
  };
  const handleClose = () => {
    setAdvisorPop(null);
  };

  const searchUser = (e) => {
    setSearch(e.target.value);
  };
  const id = useParams().id;

  const { data, isLoading, isFetching } = useOrderQuery.get_order_Detials(id);
  const [images, setImages] = useState([]);
  const [orderlocation, setOrderlocation] = useState("");
  const location = useLocation();
  const qryParams = (location.state && location.state.qry) || null;
  const [qxy, setQxy] = useState(qryParams);
  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.vehicle_images !== null &&
      data &&
      data.data &&
      data.data.vehicle_images !== undefined
    ) {
      setImages(Object.values(data && data.data && data.data.vehicle_images));
    }
  }, [data]);

  useEffect(() => {
    const fetchGeocodeData = async () => {
      if (
        data &&
        data.data &&
        data.data.service_details &&
        data.data.service_details.location &&
        data.data.service_details.location.location_string
      ) {
        const address = data.data.service_details.location.location_string;
        setOrderlocation(address);
      } else if (
        data &&
        data.data &&
        data.data.service_details &&
        data.data.service_details.location
      ) {
        const { latitude, longitude } = data.data.service_details.location;
        const address = await geocodeLocation(latitude, longitude);
        setOrderlocation(address);
      }
    };

    fetchGeocodeData();
  }, [data]);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const address = () => {
    return `${
      data &&
      data.data &&
      data.data.order_item &&
      data.data.order_item[0] &&
      data.data.order_item[0].user_id &&
      data.data.order_item[0].user_id.address &&
      data.data.order_item[0].user_id.address.addressLine
    } ${
      data &&
      data.data &&
      data.data.order_item &&
      data.data.order_item[0] &&
      data.data.order_item[0].user_id &&
      data.data.order_item[0].user_id.address &&
      data.data.order_item[0].user_id.address.city
    }  ${
      data &&
      data.data &&
      data.data.order_item &&
      data.data.order_item[0] &&
      data.data.order_item[0].user_id &&
      data.data.order_item[0].user_id.address &&
      data.data.order_item[0].user_id.address.land_mark
    }, ${
      data &&
      data.data &&
      data.data.order_item &&
      data.data.order_item[0] &&
      data.data.order_item[0].user_id &&
      data.data.order_item[0].user_id.address &&
      data.data.order_item[0].user_id.address.state
    }, ${
      data &&
      data.data &&
      data.data.order_item[0] &&
      data.data.order_item[0].user_id &&
      data.data.order_item[0].user_id.address &&
      data.data.order_item[0].user_id.address.pin_code
    }`;
  };
  const isDealerAssigned = (items) => {
    const dealerItems = items.filter((item) => {
      return item.dealer_id !== null;
    });

    return dealerItems.length === items.length ? true : false;
  };

  // const isCustomerGroup = data && data.data && data.data.group === 'Customer';
  // console.log('group',data.data.group)

  return (
    <>
      <HeadBar title={TXT_ASSIGN_ORDER_DETAIL} />
      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to="/administrator/orders">
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon className={viewStyle.backButton} />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_ASSIGN_ORDER_DETAIL} />
          </div>

          {isLoading || isFetching ? (
            <CustomLoader />
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      {" "}
                      {" "}
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={data && data.data && data.data.order_no}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Advisor Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Controls.InputField
                      value={
                        data && data.data && data.data.advisor_id.email !== ""
                          ? "Assigned"
                          : "Not assigned"
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                  {data && data.data && data.data.advisor_id.email !== "" && (
                    <Grid item xs={6} sm={4}>
                      <Box
                        sx={{
                          display: "flex",
                          color: "#F39800",
                          alignItems: "center",
                          marginLeft: "12px",
                          width: "11rem",
                        }}
                      >
                        <Typography
                          className={orderStyle.detailviewlabel}
                          onClick={handleAdvisorClick}
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                        </Typography>

                        <IconButton
                          aria-describedby={advisorPop}
                          variant="contained"
                          onClick={handleAdvisorClick}
                        >
                          <ArrowForwardIosRoundedIcon
                            sx={{ color: "#F39800" }}
                          />
                        </IconButton>

                        <Popover
                          id={popId}
                          open={advisorOpen}
                          onClose={handleClose}
                          anchorEl={advisorPop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <div className={orderStyle.advisorDetials}>
                            <div className="detials-container">
                              <h2 className="head">Name</h2>
                              <p className="detials">
                                {data &&
                                  data.data &&
                                  data.data.advisor_id &&
                                  data.data.advisor_id.first_name}
                              </p>
                            </div>
                            <div className="detials-container">
                              <h2 className="head">Phone</h2>
                              <p className="detials">
                                {data &&
                                  data.data &&
                                  data.data.advisor_id &&
                                  data.data.advisor_id.phone}
                              </p>
                            </div>
                            <div className="detials-container">
                              <h2 className="head">Email</h2>
                              <p className="detials">
                                {data &&
                                  data.data &&
                                  data.data.advisor_id &&
                                  data.data.advisor_id.email}
                              </p>
                            </div>
                          </div>
                        </Popover>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Customer Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        data &&
                        data.data &&
                        data.data.user_id &&
                        `${data.data.user_id.first_name} ${
                          data.data.user_id.last_name
                            ? data.data.user_id.last_name
                            : ""
                        }`
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Customer Mobile
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={data && data.data && data.data.user_id.phone}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={address()}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Order Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={orderlocation}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {data &&
                data.data &&
                data.data.group &&
                data.data.group.includes("Customer") && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={orderStyle.viewlabel}>
                            Vechicle Reg.No
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            value={
                              data &&
                              data.data &&
                              data.data.customer_vehicle_id &&
                              data.data.customer_vehicle_id.basic_details
                                .register_number
                                ? data.data.customer_vehicle_id.basic_details
                                    .register_number
                                : ""
                            }
                            variant="outlined"
                            className={orderStyle.inputTextBar}
                            readOnly={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={orderStyle.viewlabel}>
                            Vechicle Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            value={
                              data &&
                              data.data &&
                              `${data.data.vehicle_id.make} ${data.data.vehicle_id.model} ${data.data.vehicle_id.trim} ${data.data.vehicle_id.fuel_type} ${data.data.vehicle_id.category}`
                            }
                            variant="outlined"
                            className={orderStyle.inputTextBar}
                            readOnly={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Order Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        (data &&
                          data.data &&
                          data.data.status === 1 &&
                          "New") ||
                        (data &&
                          data.data &&
                          data.data.status === 2 &&
                          "Confirmed") ||
                        (data &&
                          data.data &&
                          data.data.status === 3 &&
                          "Inprogress") ||
                        (data &&
                          data.data &&
                          data.data.status === 4 &&
                          "Completed") ||
                        (data &&
                          data.data &&
                          data.data.status === 5 &&
                          "Delivered") ||
                        (data &&
                          data.data &&
                          data.data.status === 6 &&
                          "Canceled")
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Scheduled Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={moment(
                        data &&
                          data.data &&
                          data.data.order_payment &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0].order_id &&
                          data.data.order_payment[0].order_id.service_details &&
                          data.data.order_payment[0].order_id.service_details
                            .date_slot
                      ).format("D MMMM YYYY")}
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Scheduled Time
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        (data &&
                          data.data &&
                          data.data.order_payment &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0].order_id &&
                          data.data.order_payment[0].order_id.service_details &&
                          data.data.order_payment[0].order_id.service_details
                            .time_slot_from) ||
                        (data &&
                          data.data &&
                          data.data.order_payment &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0] &&
                          data.data.order_payment[0].order_id &&
                          data.data.order_payment[0].order_id.service_details &&
                          data.data.order_payment[0].order_id.service_details
                            .time_slot)
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Service Method
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        data &&
                        data.data &&
                        data.data.service_details &&
                        data.data.service_details.service_method
                          ? data.data.service_details.service_method
                          : ""
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={`INR  ${
                        data &&
                        data.data &&
                        data.data.grand_total_after_discount !== null &&
                        data &&
                        data.data &&
                        data.data.grand_total_after_discount !== 0
                          ? data &&
                            data.data &&
                            data.data.grand_total_after_discount
                          : data && data.data && data.data.grand_total
                      }`}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Discount Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={`INR  ${
                        data &&
                        data.data &&
                        data.data.discount_amount !== null &&
                        data &&
                        data.data &&
                        data.data.discount_amount !== 0
                          ? data && data.data && data.data.discount_amount
                          : 0
                      }`}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Amount Paid
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={`INR  ${
                        data && data.data && data.data.amount_paid
                      }`}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Payment Status
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        (data &&
                          data.data &&
                          data.data.payment_status === 1 &&
                          "Paid") ||
                        (data &&
                          data.data &&
                          data.data.payment_status === 0 &&
                          "Pending")
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      User Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={data && data.data && data.data.group}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Created at
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={moment(
                        data && data.data && data.data.created_at
                      ).format("DD/MM/YYYY, h:mm A")}
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Images
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div className={viewStyle.docStyle}>
                      <IconButs
                        ibtname={DEALER_LIST_DOC}
                        className={orderStyle.formButton}
                        onClick={() => setImageOPen(true)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Order items
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={orderStyle.formButton}
                      onClick={() => setLinkedOrder(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Invoice Status
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      value={
                        data && data.data && data.data.invoice_id !== null
                          ? "Generated"
                          : "Not generated"
                      }
                      variant="outlined"
                      className={orderStyle.inputTextBar}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={orderStyle.viewlabel}>
                      Invoice
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div className={viewStyle.docStyle}>
                      <IconButs
                        ibtname={DEALER_LIST_DOC}
                        className={orderStyle.formButton}
                        disabled={
                          data && data.data && data.data.invoice_id === null
                            ? true
                            : false
                        }
                        onClick={() => {
                          toast.info("Generating invoice", { delay: 3 });
                          invoice({
                            invoice_id:
                              data && data.data && data.data.invoice_id,
                            order_id: data && data.data && data.data.id,
                          })
                            .then((res) => {
                              const link = document.createElement("a");
                              toast.success("Generated");
                              link.href =
                                process.env.REACT_APP_BASE_URL + res.data.path;
                              link.target = "_blank";
                              link.click();
                            })
                            .catch((err) => {
                              toast.error("Failed");
                            });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  className={viewStyle.formIcon}
                >
                  <Link to="/administrator/orders" state={{ qxy: qxy }}>
                    <IconButs
                      ibtname={ASSIGN_DEALER_TECHNICIAN}
                      className={viewStyle.formButtonAdd}
                      variant="outlined"
                    />
                  </Link>
                </div>
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
      <AssignLinkedOrders
        open={linkedOrder}
        data={data && data.data && data.data.order_item}
        onModalClose={setLinkedOrder}
        title="Order Items"
      />
      <OrdersIMageViewer
        open={imageOpen}
        setOpen={setImageOPen}
        data={data && data.data && data.data.vehicle_images}
      />
    </>
  );
};

export default AssignOrderDetails;
