import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import { TXT_OFFERMANAGEMENT } from "../../utilitis/header";
import { ICON_BUTTON_OFFERMANAGEMENT } from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import HeadBar from "../../components/HeadBar/HeadBar";
import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import financeStyles from "../FinanceManagement/financeStyles";
import PageDropDown from "../../common/Controls/PageDropDown";
import { useForm } from "../../sharedFeatures/useForm";
import OfferAdd from "./OfferAdd";
import usePagination from "../../common/Controls/usePagination";
import useCouponQuery from "../../data/coupon/useCouponQuery";
import CustomLoader from "../../features/CustomLoader";
import { toast } from "material-react-toastify";
import useAddCouponQuery from "../../data/coupon/useAddCouponQuery";
import DialogBox from "../../common/Controls/DialogBox";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
const Offer = () => {
  const data = [
    {
      sl_no: 1,
      couponName: "Diwali Offer",
      couponCode: "AXZYN",
      minimumCartValue: 5000,
      discountAmount:1000
    },
    {
      sl_no: 2,
      couponName: "Diwali Offer",
      couponCode: "AXZYN",
      minimumCartValue: 5000,
      discountAmount:1000
    },
    {
      sl_no: 3,
      couponName: "Diwali Offer",
      couponCode: "AXZYN",
      minimumCartValue: 5000,
      discountAmount:1000
    },
    {
      sl_no: 4,
      couponName: "Diwali Offer",
      couponCode: "AXZYN",
      minimumCartValue: 5000,
      discountAmount:1000
    },
    {
      sl_no: 5,
      couponName: "Diwali Offer",
      couponCode: "AXZYN",
      minimumCartValue: 5000,
      discountAmount:1000
    },
 
  ];

  const { TblContainer, TblHead } = useTable(HeadCells.userOffer);
  const [search, setSearch] = useState();
  const styles = makeStyles();
  const customStyle = financeStyles();

  const [trySearch, setTrySearch] = useState("");
  const [currentpageNo, setcurrentpageNo] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const searchUser = (e) => {
    setSearch(e.target.value);
  };



  const qry = {
    searchQry: trySearch,
    page: currentpageNo,
    perPageItems: pageItems,
  }; 


  const { data: couponData, isLoading: couponLoading } =
  useCouponQuery.getCouponsList(qry);
 

  const { currentData, currentPage, maxPage } = usePagination(
    couponData !== undefined && couponData,
    pageItems
  );


  const handlePageItems = (e) => {
    setPageItems(e.target.value);
  };


  const notifyDelete = () =>
  toast.success(
    <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
      Coupon deleted successfully
    </span>
  );

  const { mutate: updateStatus, isLoading: statusUpdateLoading } =
  useAddCouponQuery.update_coupon_status();

  // const handleDelete = (id) => {
  //   setUserId(id);
  //   setDeleteOpen(true);
  // };
  // const handleRemove = async () => {
  //   if (userId) await remove(userId);
  //   // setDeleteOpen(false)
  //   setTimeout(() => {
  //     notifyDelete();
  //     setDeleteOpen(false);
  //   }, [1000]);
  // };

  // const handleDeleteClose = () => {
  //   setDeleteOpen(false);
  // };


  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  const {
    handleOpen,
    open,
    setOpen,
    values,
    handleInputChange,
    errors,
    setErrors,
  } = useForm();

  return (
    <>
      <HeadBar title={TXT_OFFERMANAGEMENT} />
      <div className={customStyle.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={9}>
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => setTrySearch(e.target.value)}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
                onInput={(event)=>{
                  event.target.value = event.target.value.replace(/[^a-zA-Z0-9 ]/g,'')
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <div className={styles.excelData}>
                <IconButs
                  ibtname={ICON_BUTTON_OFFERMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={styles.salesButton}
                  onClick={handleOpen}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            { (couponLoading || statusUpdateLoading) &&(
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {!statusUpdateLoading && couponData &&
                couponData.data &&
                couponData.data.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.coupon_code}</TableCell>
                    <TableCell align="center">
                      {item.min_cart_value}
                    </TableCell>
                    <TableCell align="center">{item.coupon_amount}</TableCell>
                    <TableCell align="center">
                        <IconButton>
                          {item.status  ? (
                            <BsToggleOn
                              onClick={async () => await updateStatus(item.id)}
                              className="user-icons"
                            />
                          ) : (
                            <BsToggleOff
                              onClick={async () => await updateStatus(item.id)}
                              className="user-icons"
                            />
                          )}
                        </IconButton>
                      </TableCell>
                    {/* <TableCell align="center"> <IconButton  onClick={() => handleDelete(item.id)}>
                              <AiOutlineDelete className="user-icons" />
                            </IconButton></TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </Box>

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems} 
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 2, value: 30 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>
      </div>
      {open ? (
        <OfferAdd open={open} handleClose={handleOpen} title="Add Coupon" />
      ) : null}
          {/* {deleteOpen ? (
          <DialogBox
            open={deleteOpen}
            setOpen={setDeleteOpen}
            content={"Are you sure you want to delete ?"}
            handleClose={handleDeleteClose}
            userId={userId}
            handleRemove={handleRemove}
          />
        ) : (
          ""
        )} */}
    </>
  );
};

export default Offer;
