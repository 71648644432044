import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import salesStyle from "../SalesPersonManagement/salesStyle";
import "../SalesPersonManagement/Sales.css";
import userRoleQuery from "../../data/user role/useUserRoleQuery";
import CustomLoader from "../../features/CustomLoader";

const UserRoleDetails = ({ open, handleClose, title, id }) => {
  const customStyle = makeStyles();
  const salesStyles = salesStyle();

  const { data: userRolePermissionsData, isLoading: permissionsLoading } =
    userRoleQuery.getUserRolePermissions();

  const {
    data: userRoleDetailsData,
    isSuccess: detailsSuccess,
    isLoading: detailsLoading,
  } = id ? userRoleQuery.getUserRoleDetails({ id }) : "";

  const [roleName, setRoleName] = React.useState("");
  const [rolesList, setRolesList] = React.useState({});

  useEffect(() => {
    setRoleName(
      userRoleDetailsData &&
        userRoleDetailsData.group_id &&
        userRoleDetailsData.group_id.name
    );
    let roles = userRoleDetailsData && userRoleDetailsData.menu_id;
    let data = {};
    if (roles && roles.length > 0) {
      roles.forEach((item) => {
        data[item.name] = true;
      });
      setRolesList(data);
    }
  }, [detailsSuccess]);

  return (
    <Modal open={open}>
      <div className="modalWrap" style={{height:'500px'}}>
        <Box>
          <div
            className={customStyle.iconStyle}
            style={{
              backgroundColor: "#FEDD17",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              className={salesStyles.assignTitle}
              style={{ margin: "5px 0px" }}
            >
              {title}
            </Typography>
          </div>

          <div className={customStyle.divStyleTwo}>
            <Grid container alignItems="center">
              {!detailsLoading && !permissionsLoading ? (
                <>
                  <Grid
                    container
                    spacing={1}
                    alignItems="top"
                    style={{ margin: "10px 0px" }}
                  >
                    {userRolePermissionsData &&
                      userRolePermissionsData.data &&
                      userRolePermissionsData.data.map((item, idx) => {
                        return (
                          <Grid item xs={12} sm={6} key={idx}>
                            <Controls.CheckBoxLabel
                              name={item.name}
                              label={item.name}
                              disabled
                              value={item.name}
                              checked={rolesList[item.name]}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>

                  {permissionsLoading ||
                    (detailsLoading && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CustomLoader />
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} sm={6}>
                        <IconButs
                          onClick={handleClose}
                          ibtname="OK"
                          className={salesStyles.roleAddStyle2}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <CustomLoader />
                </Grid>
              )}
            </Grid>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default UserRoleDetails;
