import DataService from "../../DataService/DataService";

const reviewDetails = async ({ searchQry, page, perPageItems }) => {
    let url = ``;
    if (searchQry && page && perPageItems) {
      url = `product/review_rating/?search=${searchQry}&page_size=${perPageItems}&page=1`;
    } else if (searchQry && page) {
      url = `product/review_rating/?search=${searchQry}&page=1`;
    } else if (searchQry && perPageItems) {
      url = `product/review_rating/?search=${searchQry}&page_size=${perPageItems}`;
    } else if (page && perPageItems) {
      url = `product/review_rating/?page_size=${perPageItems}&page=${page}`;
    } else if (page) {
      url = `product/review_rating/?page=${page}`;
    } else if (perPageItems) {
      url = `product/review_rating/?page_size=${perPageItems}`;
    } else {
      url = `product/review_rating/?page=${page}`;
    }
    const data = await DataService.get(url);
    return data.data;
  };


  const getAllReviews = async () => {
    const url = "/product/review_rating/";
    const data = await DataService.get(url);
    return data.data;
  };


  export   {
    reviewDetails,
    getAllReviews
  }