import React from "react";
import {
  Typography,
  Paper,
  IconButton,
  Box,
  Grid,
  Button,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import IconButsUser from "../../common/Controls/iconButsUser";
import { Link, useSearchParams } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import { TXT_USERMANAGEMENT, TXT_USER_DETAIL } from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import ForwardIcon from "../../utilitis/icons/ForwardIcon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import useUsersQuery from "../../data/user/useUsersQuery";

import CustomLoader from "../../features/CustomLoader";

import makeStyles from "../../sharedFeatures/sharedClasses";
import userStyles from "./userStyles";
import { useEffect } from "react";
import { FindLink } from "../../sharedFeatures/FindLink";
import OrderDetails from "./OrderDetails";
import axios from 'axios';
import { useState } from "react";
const API_URL = process.env.REACT_APP_API_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const viewStyle = makeStyles();
  const styles = makeStyles();
  const userClass = userStyles();

  const [activeChild, setActiveChild] = React.useState(0);

  const [value, setValue] = React.useState(0);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { id, view } = useParams();

 

  useEffect(() => {
    if (view) {
      setValue(0);
    }
  }, [view]);

  const { data: usersData } = id ? useUsersQuery.users_details(id) : "";

  const { data: userVehilceData, isLoading: usersVehicleDataLoading } = id
    ? useUsersQuery.users_vehicle_details(id)
    : "";

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  //aimee
  const [orderdetails, setOrderDetails] = useState([])
  let accessToken = localStorage.getItem("accessToken");
  const userid = usersData && usersData.data && usersData.data.id
  const getOrderDetails = async () => {
    let url = `${API_URL}/order/get-orders-by-userid/${userid}/`
    try {
      const response = await axios.get(url,{
            headers: { Authorization: `Bearer ${accessToken}` },
      }
        );
      // Handle successful response
      // console.log('Response:', response.data.data);
      setOrderDetails(response.data.data)
    } catch (error) {
      // Handle error
      console.error('Error:', error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 2){
      getOrderDetails()
    }
  };

  const changeChild = React.useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) =>
          a - 1 < 0 ? userVehilceData.data.data.length - 1 : a - 1
        );
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) =>
          a + 1 > userVehilceData.data.data.length - 1 ? 0 : a + 1
        );
      }
    },
    [userVehilceData && userVehilceData.data && userVehilceData.data.data]
  );

  // Set and cleanup the event listener
  React.useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });

  //aimee
  let link = FindLink("/administrator/users");
  
  return (
    <>
      <HeadBar title={TXT_USERMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_USER_DETAIL} />
          </div>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid item sm={12} md={6}>
              <Grid container sm={12}>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    First Name
                  </Typography>
                </Grid>

                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    value={
                      usersData && usersData.data && usersData.data.first_name
                    }
                    disabled
                    variant="outlined"
                    className={userClass.inputTextBar}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    Last Name
                  </Typography>
                </Grid>

                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    value={
                      usersData && usersData.data && usersData.data.last_name
                    }
                    disabled
                    variant="outlined"
                    className={userClass.inputTextBar}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    Email address
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    value={usersData && usersData.data && usersData.data.email}
                    variant="outlined"
                    disabled
                    className={userClass.inputTextBar}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    Mobile number
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    value={usersData && usersData.data && usersData.data.phone}
                    variant="outlined"
                    disabled
                    className={userClass.inputTextBar}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    GST number
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    variant="outlined"
                    disabled
                    value={
                      usersData && usersData.data && usersData.data.gst_number
                    }
                    placeholder={"GST Number"}
                    className={userClass.inputTextBar}
                  />
                </Grid>
                <Grid iitem xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controls.StyledInput
                    value={
                      usersData &&
                      usersData.data &&
                      usersData.data.address &&
                      usersData.data.address.land_mark
                    }
                    disabled
                    className={userClass.inputTextBar}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewlabel}>
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} spacing={3}>
                  <Grid
                    container
                    xs={12}
                    spacing={isSmall ? 0 : 3}
                    style={{ marginBottom: isSmall ? "5px" : "" }}
                  >
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        name="address_line_one"
                        disabled
                        value={
                          usersData &&
                          usersData.data &&
                          usersData.data.address &&
                          usersData.data.address.addressLine
                        }
                        className={userClass.addressBar}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        disabled
                        className={userClass.addressBar}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    spacing={isSmall ? 0 : 3}
                    style={{ marginBottom: isSmall ? "5px" : "" }}
                  >
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        name="locality"
                        type="text"
                        disabled
                        value={
                          usersData &&
                          usersData.data &&
                          usersData.data.address &&
                          usersData.data.address.state
                        }
                        className={userClass.addressBar}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        name="city"
                        disabled
                        value={
                          usersData &&
                          usersData.data &&
                          usersData.data.address &&
                          usersData.data.address.city
                        }
                        className={userClass.addressBar}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={3}
                  lg={3}
                  style={{ marginTop: "12px" }}
                >
                  <Typography className={userClass.viewlabel}>
                    Country & Zip Code
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  spacing={3}
                  style={{ marginTop: "12px" }}
                >
                  <Grid
                    container
                    xs={12}
                    spacing={isSmall ? 0 : 3}
                    style={{ marginBottom: isSmall ? "5px" : "" }}
                  >
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        name="country"
                        placeholder="Country"
                        disabled
                        value="India"
                        className={userClass.addressBar}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.StyledInput
                        name="pincode"
                        type="number"
                        disabled
                        value={
                          usersData &&
                          usersData.data &&
                          usersData.data.address &&
                          usersData.data.address.pin_code
                        }
                        className={userClass.addressBar}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewBtnlabel}>
                    Car Details
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {value === 0 ? (
                    <div className={userClass.userButtonContainer2}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(0)}
                      ></Button>
                    </div>
                  ) : (
                    <div className={userClass.userButtonContainer}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(0)}
                      ></Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewBtnlabel}>
                    Images
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {value === 1 ? (
                    <div className={userClass.userButtonContainer2}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(1)}
                      ></Button>
                    </div>
                  ) : (
                    <div className={userClass.userButtonContainer}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(1)}
                      ></Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={3}>
                  <Typography className={userClass.viewBtnlabel}>
                    Order Details
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {value === 2 ? (
                    <div className={userClass.userButtonContainer2}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(2)}
                      ></Button>
                    </div>
                  ) : (
                    <div className={userClass.userButtonContainer}>
                      <div
                        style={{
                          marginBlock: "auto",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#252F40 !important",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Click to View
                      </div>
                      <Button
                        variant="outlined"
                        className={userClass.userButton2}
                        endIcon={<ForwardIcon />}
                        classes={{ iconSizeMedium: userClass.iconSizeMedium }}
                        size="medium"
                        onClick={() => setValue(2)}
                      ></Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={6} className={userClass.tabParent}>
              <Card sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  style={{ paddingInline: "30px" }}
                  TabIndicatorProps={{ style: { background: "#FEDD17" } }}
                >
                  <Tab
                    label="Your Cars"
                    className={userClass.tabLabel}
                    style={{ color: value === 0 ? "#FEDD17" : "black" }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Images"
                    className={userClass.tabLabel}
                    style={{ color: value === 1 ? "#FEDD17" : "black" }}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Order Details"
                    className={userClass.tabLabel}
                    style={{ color: value === 2 ? "#FEDD17" : "black" }}
                    {...a11yProps(2)}
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Carousel
                    index={activeChild}
                    autoPlay={false}
                    navButtonsAlwaysVisible={
                      userVehilceData &&
                      userVehilceData.data &&
                      userVehilceData.data.data.length > 1
                    }
                    indicators={false}
                    swipe={true}
                    navButtonsProps={{
                      style: {
                        backgroundColor: "#FFFFFF",
                        borderRadius: 0,
                        color: "red",
                        cursor: "pointer",
                        zIndex: 0,
                      },
                    }}
                  > 
                    {userVehilceData &&
                      userVehilceData.data &&
                      userVehilceData.data.data.map((_, idx) => {
                        return (
                          <Grid
                            item
                            sm={12}
                            md={12}
                            key={idx}
                            style={{ width: "100%", zIndex: 0 }}
                          >
                            <CardMedia
                              component="img"
                              width="400px"
                              height="250px"
                              objectFit="cover"
                              image={`${BASE_URL}media/models/${_.imagename}`}
                              alt="Sorry No image !"
                            />

                            <CardContent>
                              <div className={userClass.cardTitle}>
                                <div>
                                  <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "#444445",
                                    }}
                                  >
                                    {_.vehicle_id.make +
                                      " " +
                                      _.vehicle_id.model}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      color: "#989898",
                                    }}
                                  >
                                    {_.vehicle_id.fuel_type}
                                  </Typography>
                                </div>
                                /
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "30px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "170%",
                                  }}
                                >
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                    }}
                                  >
                                    Reg. Year
                                  </Typography>

                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.basic_details &&
                                      _.basic_details.register_year}
                                  </Typography>
                                </div>
                                <div className={userClass.regNoDiv}>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                    }}
                                  >
                                    Reg No
                                  </Typography>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.basic_details &&
                                      _.basic_details.register_number}
                                  </Typography>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "20px",
                                }}
                              >
                                <div>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                    }}
                                  >
                                    Fuel
                                  </Typography>

                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.vehicle_id.fuel_type}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                    }}
                                  >
                                    Insurance Expiry
                                  </Typography>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.basic_details &&
                                      _.basic_details.insurance_date}
                                  </Typography>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "20px",
                                }}
                              >
                                <div>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    PUC Expiry Date
                                  </Typography>

                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.basic_details && _.basic_details.puc}
                                  </Typography>
                                </div>
                                {/* <div>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#444445",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    PUC Expiry Date
                                  </Typography>
                                  <Typography
                                    align="start"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#989898",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {_.basic_details && _.basic_details.puc}
                                  </Typography>
                                </div> */}
                              </div>
                            </CardContent>
                          </Grid>
                        );
                      })}
                  </Carousel>
                  {userVehilceData &&
                    userVehilceData.data &&
                    userVehilceData.data.data &&
                    userVehilceData.data.data.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <lottie-player
                          src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "300px", height: "300px" }}
                          loop
                          autoplay
                        ></lottie-player>
                      </div>
                    )}

                  {usersVehicleDataLoading && <CustomLoader />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container>
                    {userVehilceData &&
                    userVehilceData.data &&
                    userVehilceData.data.data && 
                    userVehilceData.data.data.map((eachdata) => {
                      // console.log("********", eachdata.imagename)

                    })}
                    {userVehilceData &&
                    userVehilceData.data &&
                    userVehilceData.data.data && 
                    userVehilceData.data.data.map((_) => {
                      return (
                        <Grid item xs={12} md={6}>
                          <CardMedia
                            component="img"
                            width="400px"
                            image={`${BASE_URL}media/models/${_.imagename}`}
                            // image={require(`${BASE_URL}media/models/${_.imagename}`)}
                            alt="Sorry No image !"
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={2}
                  style={{ textAlign: "center" }}
                >
                  {orderdetails.length === 0 && 
                  <span style={{ fontFamily: "Montserrat", fontSize: "14px" }}>
                    Loading...
                  </span>
                  }
                  {orderdetails.length !== 0 && 
                    <OrderDetails data={orderdetails}/>
                  }
                  
                </TabPanel>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: isSmall ? "" : "40px",
              marginTop: "40px",
            }}
          >
            <Grid item>
              <div className={viewStyle.formIcon}>
                <Link to={link}>
                  <IconButsUser
                    ibtname="OK"
                    className={userClass.formButtonAdd}
                    variant="outlined"
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default UserDetails;
