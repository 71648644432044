import DataService from "../../DataService/DataService";

const getSalesPersonDetails = async ({ searchQry, page, perPageItems }) => {
  
  let url = ``;
  if (searchQry && page && perPageItems) {
    url = `sales/salesperson/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page) {
    url = `sales/salesperson/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `sales/salesperson/?search=${searchQry}&page=${page}`;
  } else if (perPageItems && page) {
    url = `sales/salesperson/?page_size=${perPageItems}&page=${page}`;
  } else if (perPageItems) {
    url = `sales/salesperson/?page_size=${perPageItems}`;
  } else if (page) {
    url = `sales/salesperson/?page=${page}`;
  } else if (searchQry) {
    url = `sales/salesperson/?search=${searchQry}`;
  } else {
    url = `sales/salesperson/?page=${page}`;
  }


  const data = await DataService.get(url);

  return data.data;
};

const getSalesManager = async () => {
  const url = "/sales/salesmanager/";
  const data = await DataService.get(url);
  return data.data.data;
};

const addSalesPerson = async (value) => {
  const url = `/sales/salesperson/`;
  const data = await DataService.post(url, value);
  return data;
};

const editSalesPerson = async (value) => {
  const { id, ...editData } = value;
  const url = `/sales/salesperson/${id}/`;
  const data = await DataService.put(url, editData);

  return data;
};
const getSalesPersonById = async (id) => {
  const url = `/sales/salesperson/${id}/`;
  const data = await DataService.get(url);
  return data.data;
};

const deleteSalesPerson = async (id) => {
  const url = `/sales/salesperson/${id}/`;
  const data = await DataService.del(url);
  
  return data;
};

const dealerListBySalesPerson = async (id) => {
  const url = `/sales/dealerlistbysalesperson/${id}/`;
  const data = await DataService.get(url);
  return data.data;
};

export {
  getSalesPersonDetails,
  addSalesPerson,
  getSalesManager,
  getSalesPersonById,
  deleteSalesPerson,
  editSalesPerson,
  dealerListBySalesPerson,
};
