import React from "react";
import {
  Typography,
  Paper,
  IconButton,
  Box,
  Grid,
  Button,
} from "@material-ui/core";
import IconButsUser from "../../common/Controls/iconButsUser";
import { Link, useSearchParams } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import Carousel from "react-material-ui-carousel";
import { TXT_USERMANAGEMENT, TXT_USER_EDIT } from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import ForwardIcon from "../../utilitis/icons/ForwardIcon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import useUsersQuery from "../../data/user/useUsersQuery";

import CustomLoader from "../../features/CustomLoader";

import makeStyles from "../../sharedFeatures/sharedClasses";
import userStyles from "./userStyles";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "material-react-toastify";

import "../DealerManagement/AddEditDealer/toast.css";
import { FindLink } from "../../sharedFeatures/FindLink";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserEdit = () => {
  const viewStyle = makeStyles();
  const styles = makeStyles();
  const userClass = userStyles();
  const navigate = useNavigate();
  const url = "AIzaSyCeViu0eoIfgK2TWKWUXCt2p_YWvFbtc7c";

  const [activeChild, setActiveChild] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { id, view } = useParams();

  useEffect(() => {
    if (view) {
      setValue(2);
    }
  }, [view]);

  const { data: usersData } = id ? useUsersQuery.users_details(id) : "";

  const { data: userVehilceData, isLoading: usersVehicleDataLoading } = id
    ? useUsersQuery.users_vehicle_details(id)
    : "";

  const { mutate: editUser, isSuccess: editUserSuccess } =
    useUsersQuery.update_user_details();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [address_line_one, setAddress_line_one] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip_code, setZip_code] = React.useState("");
  const [gst, setGst] = React.useState("");

  const [firstnameError, setFirstNameError] = React.useState("");
  const [lastnameError, setLastNameError] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [locationError, setLocationError] = React.useState("");
  const [address_line_oneError, setAddress_line_oneError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [stateError, setStateError] = React.useState("");
  const [zip_codeError, setZip_codeError] = React.useState("");
  const [gstError, setGstError] = React.useState("");

  React.useEffect(() => {
    if (usersData && usersData.data) {
      setFirstName(usersData.data.first_name);
      setLastName(usersData.data.last_name);

      setEmail(usersData.data.email);
      setLocation(usersData.data.address && usersData.data.address.land_mark);
      setAddress_line_one(
        usersData.data.address && usersData.data.address.addressLine
      );
      setCity(usersData.data.address && usersData.data.address.city);
      setState(usersData.data.address && usersData.data.address.state);
      setZip_code(usersData.data.address && usersData.data.address.pin_code);
      setGst(usersData.data.gst_number);
    }
  }, [usersData]);

  const handleClickSubmit = async () => {
    let error = false;
    if (firstname === "") {
      setFirstNameError("First Name is required");
      error = true;
    } else if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(firstname)) {
      setFirstNameError("First Name not valid");
      error = true;
    } else {
      setFirstNameError("");
    }

    if (lastname === "") {
      setLastNameError("Last Name is required");
      error = true;
    } else if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(lastname)) {
      setLastNameError("Last Name not valid");
      error = true;
    } else {
      setLastNameError("");
    }

    if (email === "") {
      setEmailError("Email is required");
      error = true;
    } else if (
      !/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i.test(
        email
      )
    ) {
      setEmailError("Enter a valid email address");
      error = true;
    } else {
      setEmailError("");
    }

    // if (
    //   gst !== "" &&
    //   !/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/i.test(
    //     gst
    //   )
    // ) {
    //   setGstError("Invalid GST Number");
    //   error = true;
    // } else {
    //   setGstError("");
    // }

    if (location === "") {
      setLocationError("Location is required");
      error = true;
    } else {
      setLocationError("");
    }
    if (address_line_one === "") {
      setAddress_line_oneError("Address is required");
      error = true;
    } else {
      setAddress_line_oneError("");
    }
    if (city === "") {
      setCityError("City is required");
      error = true;
    } else {
      setCityError("");
    }
    if (state === "") {
      setStateError("State is required");
      error = true;
    } else {
      setStateError("");
    }
    if (zip_code === "") {
      setZip_codeError("Zip code is required");
      error = true;
    } else {
      setZip_codeError("");
    }
    if (error) {
    
      return;
    }

    await editUser({
      id: id,
      first_name: firstname,
      last_name: lastname,

      email: email,
      address: {
        addressLine: address_line_one,
        city: city,
        state: state,
        pin_code: zip_code,
        land_mark: location,
      },
      gst_number: gst,
    });
  };
   //aimee
  let link = FindLink("/administrator/users");
  
  React.useEffect(() => {
    if (editUserSuccess) {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          User updated successfully.
        </span>
      );
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(link);
      }, 3000);
    }
  }, [editUserSuccess]);

  const BLOCK_SPECIAL_REGEX = /^[0-9a-zA-Z \b]+$/;

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;

  const alphaWithSpace = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const preventSpecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !BLOCK_SPECIAL_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const changeChild = React.useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        setActiveChild((a) =>
          a - 1 < 0 ? userVehilceData.data.data.length - 1 : a - 1
        );
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) =>
          a + 1 > userVehilceData.data.data.length - 1 ? 0 : a + 1
        );
      }
    },
    [userVehilceData && userVehilceData.data && userVehilceData.data.data]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });

  const ALPHA_NUMERIC_NO_SPACE = /^[a-zA-Z0-9]*$/;

  return (
    <>
      <HeadBar title={TXT_USERMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_USER_EDIT} />
          </div>
          {!loading ? (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid item sm={12} md={6}>
                  <Grid container sm={12}>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        First Name
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={firstname}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        inputProps={{ maxLength: 45 }}
                        onKeyPress={alphaWithSpace}
                        error={firstnameError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Last Name
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        inputProps={{ maxLength: 45 }}
                        onKeyPress={alphaWithSpace}
                        error={lastnameError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Email address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        error={emailError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        GST number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        variant="outlined"
                        placeholder={"GST Number"}
                        className={userClass.inputTextBar}
                        value={gst}
                        // onChange={(e) => setGst(e.target.value)}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (
                            value !== "" &&
                            !ALPHA_NUMERIC_NO_SPACE.test(value)
                          ) {
                            return;
                          }
                          setGstError("");
                          setGst(event.target.value);
                        }}
                        error={gstError}
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid iitem xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {/* <Controls.StyledInput
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className={userClass.inputTextBar}
                  /> */}
                      <Autocomplete
                        className={userClass.geolocation}
                        apiKey={url}
                        style={{
                          borderColor:
                            locationError.length > 0 ? "red" : "gray",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                        options={{
                          types: [
                            "neighborhood",
                            "locality",
                            "sublocality",
                            "postal_code",
                            "point_of_interest",
                            // "ALL",
                          ],
                          componentRestrictions: { country: "in" },
                          fields: [
                            "address_components",
                            "geometry",
                            "icon",
                            "name",
                            "place_id",
                            "formatted_address",
                            // "ALL",
                          ],
                        }}
                        name="location"
                        onPlaceSelected={(place) => {
                     
                          setLocationError("");
                          setLocation(place.formatted_address);
                        }}
                        onKeyPress={preventSpecial}
                        onChange={(place) => {
                          if (place.formatted_address === undefined) {
                            setLocationError("Invalid Location");
                          }
                          setLocation(place.formatted_address);
                        }}
                        value={location}
                      />
                      {
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {locationError}
                        </span>
                      }
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} spacing={3}>
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="address_line_one"
                            type="text"
                            value={address_line_one}
                            onChange={(e) =>
                              setAddress_line_one(e.target.value)
                            }
                            className={userClass.addressBar}
                            error={address_line_oneError}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            className={userClass.addressBar}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            type="text"
                            className={userClass.addressBar}
                            error={stateError}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="city"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className={userClass.addressBar}
                            error={cityError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={3}
                      lg={3}
                      style={{ marginTop: "12px" }}
                    >
                      <Typography className={userClass.viewlabel}>
                        Country & Zip Code
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      spacing={3}
                      style={{ marginTop: "12px" }}
                    >
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="country"
                            placeholder="Country"
                            value="India"
                            disabled
                            className={userClass.addressBar}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="pincode"
                            type="number"
                            value={zip_code}
                            onChange={(e) => setZip_code(e.target.value)}
                            className={userClass.addressBar}
                            error={zip_codeError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Car Details
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 0 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(0)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(0)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Images
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 1 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(1)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(1)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Order Details
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 2 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(2)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => setValue(2)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6} className={userClass.tabParent}>
                  <Card sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                      style={{ paddingInline: "30px" }}
                      TabIndicatorProps={{ style: { background: "#FEDD17" } }}
                    >
                      <Tab
                        label="Your Cars"
                        className={userClass.tabLabel}
                        style={{ color: value === 0 ? "#FEDD17" : "black" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Images"
                        className={userClass.tabLabel}
                        style={{ color: value === 1 ? "#FEDD17" : "black" }}
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="Order Details"
                        className={userClass.tabLabel}
                        style={{ color: value === 2 ? "#FEDD17" : "black" }}
                        {...a11yProps(2)}
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Carousel
                        index={activeChild}
                        navButtonsAlwaysVisible={
                          userVehilceData &&
                          userVehilceData.data &&
                          userVehilceData.data.data.length > 1
                        }
                        autoPlay={false}
                        indicators={false}
                        swipe={true}
                        navButtonsProps={{
                          style: {
                            backgroundColor: "#FFFFFF",
                            borderRadius: 0,
                            color: "black",
                            cursor: "pointer",
                            zIndex: 9999999,
                          },
                        }}
                      >
                        {userVehilceData &&
                          userVehilceData.data &&
                          userVehilceData.data.data.map((_, idx) => {
                            return (
                              <Grid item sm={12} md={12}>
                                <CardMedia
                                  component="img"
                                  height="220px"
                                  objectFit="scale-down"
                                  image={`${BASE_URL}media/models/${_.imagename}`}
                                  alt="Sorry No image !"
                                />

                                <CardContent>
                                  <div className={userClass.cardTitle}>
                                    <div>
                                      <Typography
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          color: "#444445",
                                        }}
                                      >
                                        {_.vehicle_id.make +
                                          " " +
                                          _.vehicle_id.model}
                                      </Typography>
                                      <Typography
                                        align="center"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "700",
                                          color: "#989898",
                                        }}
                                      >
                                        {_.vehicle_id.fuel_type}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "30px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "170%",
                                      }}
                                    >
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          textAlign: "start",
                                        }}
                                      >
                                        Reg. Year
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                          textAlign: "start",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        --
                                      </Typography>
                                    </div>
                                    <div className={userClass.regNoDiv}>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          textAlign: "start",
                                        }}
                                      >
                                        Reg No
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        --
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                        }}
                                      >
                                        Fuel
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.vehicle_id.fuel_type}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                        }}
                                      >
                                        Insurance Expiry
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        --
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        PUC Expiry Date
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        --
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        PUC Expiry Date
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        --
                                      </Typography>
                                    </div>
                                  </div>
                                </CardContent>
                              </Grid>
                            );
                          })}
                      </Carousel>
                      {userVehilceData &&
                        userVehilceData.data &&
                        userVehilceData.data.data &&
                        userVehilceData.data.data.length === 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <lottie-player
                              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                              background="transparent"
                              speed="1"
                              style={{ width: "300px", height: "300px" }}
                              loop
                              autoplay
                            ></lottie-player>
                          </div>
                        )}

                      {usersVehicleDataLoading && <CustomLoader />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container>
                        {["", "", "", "", ""].map((_) => {
                          return (
                            <Grid item xs={12} md={6}>
                              <CardMedia
                                component="img"
                                width="400px"
                                image={require("./image 5.png")}
                                alt="Sorry No image !"
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </TabPanel>
                    <TabPanel
                      value={value}
                      index={2}
                      style={{ textAlign: "center" }}
                    >
                      <span
                        style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                      >
                        Coming Soon
                      </span>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: isSmall ? "20px" : "40px",
                  marginTop: "30px",
                }}
              >
                <Grid item>
                  <div className={viewStyle.formIcon}>
                    <IconButsUser
                      onClick={handleClickSubmit}
                      ibtname="OK"
                      className={userClass.formButtonAdd}
                      variant="outlined"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={viewStyle.formIcon}>
                    <Link to={link}>
                      <IconButsUser
                        ibtname="Cancel"
                        className={userClass.formButtonAdd}
                        variant="outlined"
                      />
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <CustomLoader />
          )}
        </Paper>
      </div>
     
    </>
  );
};

export default UserEdit;
