import { Box, Grid, IconButton, Modal } from "@material-ui/core";
import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import {
  FORMONE_BUTTON_MANAGEMENT,
  FORM_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const UserStatusChange = ({ open, handleClose, id }) => {
  const customStyle = makeStyles();

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid>
            <div
              className={customStyle.iconStyle}
              style={{ backgroundColor: "#FEDD17" }}
            >
              <Link to="/administrator/usercredential">
                <IconButton onClick={handleClose}>
                  <MdArrowBackIosNew />
                </IconButton>
              </Link>
              <h1>Status change</h1>
              <div></div>
            </div>

            <div className={customStyle.headerParentOne}>
              <Grid container spacing={1}>
                <Grid item xs={4} md={6}>
                  <div className={customStyle.formIcon}>
                    <IconButs
                      ibtname={FORM_BUTTON_MANAGEMENT}
                      className={customStyle.formButtonAdd}
                    />
                    <IconButs
                      onClick={handleClose}
                      ibtname={FORMONE_BUTTON_MANAGEMENT}
                      className={customStyle.formButton}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default UserStatusChange;
