import React, { useState } from "react";
import { Box, Typography, Grid, Paper, IconButton } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import { ImLocation } from "react-icons/im";
import HeadBar from "../../components/HeadBar/HeadBar";
import {
  TXT_SALESPERSONMANAGEMENT,
  DEALER_LIST_DOC,
  ASSIGN_DEALER_TECHNICIAN,
  TXT_SALES_DETAIL,
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import useSalesPersonQuery from "../../data/sales/useSalesPersonQuery";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import salesStyle from "./salesStyle";

import OnboardedTable from "./OnboardedTable";
import { FindLink } from "../../sharedFeatures/FindLink";

const SalesDealerTechnicianDetails = () => {
  const { id } = useParams();

  const viewStyle = makeStyles();
  const inventoryStyle = InventoryManagmentStyle();
  const salesStyles = salesStyle();

  const [onboardedOpen, setOnboardedOpen] = useState(false);

  const { data: salesPerson } = useSalesPersonQuery.salesPerson_By_Id(id);

  const { data: onboardedData, isLoading: onboardedLoading } =
    useSalesPersonQuery.dealerlist_bysalesperson(id);

  const toggleOnboarded = () => {
    setOnboardedOpen(!onboardedOpen);
  };
  
  let link = FindLink("/administrator/sales");
  

  return (
    <>
      <HeadBar title={TXT_SALESPERSONMANAGEMENT} />

      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon className={viewStyle.backButton} />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_SALES_DETAIL} />
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Name of the User
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="Jenny Wilson"
                    variant="outlined"
                    className={salesStyles.detailsInput}
                    value={
                     ` ${salesPerson &&
                      salesPerson.first_name}  ${salesPerson &&
                        salesPerson.last_name}`
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Email address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="sara.cruz@example.com"
                    variant="outlined"
                    className={salesStyles.detailsInput}
                    value={salesPerson && salesPerson.email}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Mobile number
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="9605499898"
                    variant="outlined"
                    className={salesStyles.detailsInput}
                    value={salesPerson && salesPerson.phone}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="Austin"
                    className={salesStyles.detailsInput}
                    icon={<ImLocation />}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.location
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ paddingTop: "10px" }}>
                    <Typography className={salesStyles.detailsLabel}>
                      Address
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controls.TextArea
                        height={'75px'}
                        padding={'10px'}
                        maxRows={4}
                        fontSize={'12px'}
                        className={salesStyles.stepperFormTextBar}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.address_line_one
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.TextArea
                        height={'75px'}
                        padding={'10px'}
                        maxRows={4}
                        fontSize={'12px'}
                        className={salesStyles.stepperFormTextBar}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.address_line_two
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.InputField
                        className={salesStyles.detailsInput2}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.locality
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.InputField
                        className={salesStyles.detailsInput2}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.city
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ paddingTop: "10px" }}>
                    <Typography className={salesStyles.detailsLabel}>
                      Country & Zip Code
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controls.InputField
                        className={salesStyles.detailsInput2}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.country
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.InputField
                        className={salesStyles.detailsInput2}
                        value={
                          salesPerson &&
                          salesPerson.address &&
                          salesPerson.address.pincode
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                  Dealer/Technician OnBoarded
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Link
                    to={`/administrator/salespersondetails/onBordedDealers/${id}`}
                  >
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={salesStyles.cancelButton}
                    />
                  </Link>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Last Follow Up Date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    disabled="true"
                    className={salesStyles.detailsInput}
                  />
                </Grid>
              </Grid> */}

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    disabled="true"
                    className={salesStyles.detailsInput}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.status
                        ? salesPerson.address.status
                        : "Pending"
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={salesStyles.detailsLabel}>
                    Reporting Sales Manager
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    disabled="true"
                    className={salesStyles.detailsInput}
                    value={salesPerson && salesPerson.parent_id.first_name}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: { xs: "inline", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to={link}>
                  <IconButs
                    ibtname={ASSIGN_DEALER_TECHNICIAN}
                    className={inventoryStyle.formButtonAdd}
                    variant="outlined"
                  />
                </Link>
              </Box>
            </Grid>
            {
              <OnboardedTable
                open={onboardedOpen}
                handleClose={toggleOnboarded}
                data={onboardedData}
                loading={onboardedLoading}
              />
            }
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default SalesDealerTechnicianDetails;
