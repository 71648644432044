import React, { useState } from "react";
import { Grid, Paper, IconButton } from "@material-ui/core";
import {
  TXT_SALESPERSONMANAGEMENT,
  ASSIGN_DEALER_TECHNICIAN,
  TXT_SALES_DETAIL,
  DEALER_LIST_DOC,
} from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { Link } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import Controls from "../../common/Controls/Controls";
import { ImLocation } from "react-icons/im";
import HeadBar from "../../components/HeadBar/HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";

const SalesPersonDetails = () => {
  const viewStyle = makeStyles();
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <HeadBar title={TXT_SALESPERSONMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Link to="/administrator/sales">
              <IconButton className={viewStyle.backButtonParent}>
                <BackbuttonIcon className={viewStyle.backButton} />
              </IconButton>
            </Link>
            <Sub data={TXT_SALES_DETAIL} />
          </div>

          <Grid container spacing={1}>
            <Grid item xs={4} md={6}>
              Name of the User
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="Jenny Wilson"
                // label="Jenny Wilson"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Email address
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="sara.cruz@example.com"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Mobile number
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="9605499898"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Location
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="Austin"
                className={viewStyle.inputTextBar}
                icon={<ImLocation />}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Address
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.TextArea
                placeholder="3517 W. Gray St. Utica, Pennsylvania 57867"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Dealers / Technicians Onboarded
            </Grid>
            <Grid item xs={4} md={6}>
              <div className={viewStyle.docStyle}>
                <IconButs
                  ibtname={DEALER_LIST_DOC}
                  className={viewStyle.formButton}
                />
              </div>
            </Grid>

            <Grid item xs={4} md={6}>
              Last Follow up Date
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.DatePicker className={viewStyle.formSelectBox} />
            </Grid>

            <Grid item xs={4} md={6}>
              Status
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="Active"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>

            <Grid item xs={4} md={6}>
              Reporting Sales Manager
            </Grid>
            <Grid item xs={4} md={6}>
              <Controls.InputField
                placeholder="Rakesh Agarwal"
                variant="outlined"
                className={viewStyle.inputTextBar}
              />
            </Grid>
            <Grid item xs={4} md={12}>
              <div className={viewStyle.formIcon}>
                <Link to="/administrator/sales">
                  <IconButs
                    ibtname={ASSIGN_DEALER_TECHNICIAN}
                    className={viewStyle.formButtonAdd}
                    style={{ fontSize: "14px" }}
                    // onClick={handleClose}
                    variant="outlined"
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default SalesPersonDetails;
