import { useMutation, useQueryClient } from "react-query";
import { addSalesPerson, deleteSalesPerson, editSalesPerson } from "./salesUrls";

const add_salesPerson = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addSalesPerson(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("salesPersonList")

      const prev = queryClient.getQueryData("salesPersonList")

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
  
      queryClient.invalidateQueries("salesPersonList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("salesPersonList", context.prev)
    },
  });
};

const edit_SalesPerson = () =>{
  const queryClient = useQueryClient()
  return useMutation((salesPerson)=>editSalesPerson(salesPerson),{
    onMutate:async(newData)=>{
      await queryClient.cancelQueries("salesPersonList")

      const prev = queryClient.getQueryData("salesPersonList")

      return {
        prev,
        newData
      }
    },
    onSettled:()=>{
      queryClient.invalidateQueries("salesPersonList")
    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("salesPersonList",context.prev)
    }
  })
}

const delete_salesperson = () =>{
  const queryClient = useQueryClient()
  return useMutation((todoId)=>deleteSalesPerson(todoId),{
    onMutate:async (todo)=>{
      await queryClient.cancelQueries("salesPersonList")


      const prev = queryClient.getQueryData("salesPersonList")

      queryClient.setQueryData("salesPersonList",(old=[])=> {} )
      
      // old.results.filter((item)=>item.id!==todo.id)
    

      return {
        prev,
        todo
      }

    },
    onError:(err,_,context)=>{
      queryClient.setQueryData("salesPersonList",context.prev)
    },
    onSettled:(todo)=>{
     queryClient.invalidateQueries("salesPersonList")
    }
  })
}

const useAddSalesPerson ={
    add_salesPerson,
    delete_salesperson,
    edit_SalesPerson
}

export default useAddSalesPerson