import React, { useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";

export function useForm(initialValues) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [anchor, setAnchor] = useState();
  const [open, setOpen] = useState();
  const [id, setId] = useState(); //id to edit values
  const [messageOpen, setMessageOpen] = useState();
  const [messageContent, setMessageContent] = useState();
  const [checked, setChecked] = React.useState([]);
  const [dirty, setDirty] = React.useState(false);
  const handleCheckBox = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: e.target.checked,
    });
  };

  const handleInputChange = (e) => {
    setDirty(true);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      [id]: value,
    });
  
  };
  

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // setChecked(newChecked);
    setValues({ productType: newChecked });
  };

  const handleSelectChange = (e) => {
    const { id, value } = e.target;

    setValues({
      ...values,
      [id]: value,
    });
  };
  const handleOpen = (id) => {
    setOpen(!open);
    setErrors("");
    if (id) setId(id);
    resetForm();
  };

  const handleMessageBox = (message) => {
    setMessageOpen(true);
    setMessageContent(message);
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
    setMessageOpen("");
  };

  const resetMessage = () => {
    setMessageOpen();
    setMessageContent("");
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    anchor,
    setAnchor,
    handleInputChange,
    resetForm,
    handleMessageBox,
    messageOpen,
    messageContent,
    handleOpen,
    open,
    setOpen,
    handleCheckBox,
    handleToggle,
    resetMessage,
    id,
    dirty,
    setDirty,
    handleSelectChange,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    // padding: theme.spacing(3),
    // marginTop: "100px",
    // backgroundColor: 'purple',
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
      // padding: theme.spacing(3),
      paddingLeft: "0",
      paddingRight: "0",
      marginLeft: "0",
      marginRight: "0",
      // marginTop: theme.spacing(5),
      // marginBottom: theme.spacing(5)
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;

  return (
    <Paper className={classes.pageContent}>
      <form className={classes.root} autoComplete="off" {...other}>
        {props.children}
      </form>
    </Paper>
  );
}
