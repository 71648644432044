import {
  makeStyles,
  createStyles,

} from "@material-ui/core/styles";


export default makeStyles(() =>
  createStyles({
    alert: {
      backgroundColor: "green !important",
      color: "white !important",
    },
  })
);
