import { useMutation, useQueryClient } from "react-query";
import {
  assignMultipleDealer,
  assignToAdvisor,
  assignToDealer,
  statusChange,
} from "./orderUrls";

const assignAdvisor = () => {
  const queryClient = useQueryClient();
  return useMutation((value) => assignToAdvisor(value), {
    onMutate: async (data) => {
      await queryClient.cancelQueries("getAdvisorList");

      const prev = queryClient.getQueryData("getAdvisorList");
      return {
        prev,
        data,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("getAdvisorList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("getAdvisorList", context.prev); //rollback the cache to the previous state
    },
  });
};
const assignDealer = () => {
  const queryClient = useQueryClient();
  return useMutation((value) => assignToDealer(value), {
    onMutate: async (data) => {
     
      return {
        data,
      };
    },
    onSuccess:(data)=>{
      queryClient.invalidateQueries("getOrdersList");
      return {
        data,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("getAdvisorList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("getAdvisorList", context.prev); //rollback the cache to the previous state
    },
  });
};

const useAssignMultipleDealer = () => {
  const queryClient = useQueryClient();
  return useMutation((value) => assignMultipleDealer(value), {
    onMutate: async (data) => {

  

      return {
       
        data,
      };
    },
    onSuccess:(data)=>{
      queryClient.invalidateQueries("getOrdersList");
      return {
        data,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("getAdvisorList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("getAdvisorList", context.prev); //rollback the cache to the previous state
    },
  });
};
const useChangeStatus = () => {
  const queryClient = useQueryClient();
  return useMutation((value) => statusChange(value), {
    onMutate: async (data) => {
      await queryClient.cancelQueries("getOrdersList");

      const prev = queryClient.getQueryData("getOrdersList");
      return {
        prev,
        data,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("getOrdersList"); //refetch the collection on the background
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("getOrdersList", context.prev); //rollback the cache to the previous state
    },
  });
};

const useAdvisor = {
  assignAdvisor,
  assignDealer,
  useChangeStatus,
  useAssignMultipleDealer,
};

export default useAdvisor;
