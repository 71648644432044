import React, { useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  
  Box,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import { AiOutlineEye, AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import {
  TXT_SALESPERSONMANAGEMENT,
  INVENTORYFORM_ADD_DEALER,
} from "../../utilitis/header";
import HeadBar from "../../components/HeadBar/HeadBar";
import IconButs from "../../common/Controls/IconButs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import SalesDealerForm from "./SalesDealerForm";
import { Link } from "react-router-dom";
import { useForm } from "../../sharedFeatures/useForm";
import Controls from "../../common/Controls/Controls";

const SalesDetails = () => {
  const data = [
    {
      sl_no: 1,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022",
    },
    {
      sl_no: 2,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022",
    },
    {
      sl_no: 3,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022 ",
    },
    {
      sl_no: 4,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022",
    },
    {
      sl_no: 5,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022",
    },
    {
      sl_no: 6,
      name: "Sumini Mani",
      email: "sumini@gmail.com",
      mobile: 960549483,
      location: "Navi Mumbai",
      followUp: "05 - 05 - 2022",
    },
  ];

  const salesManagers = [
    {
      id: "1",
      name: "abc",
    },
    {
      id: "2",
      name: "cde",
    },
    {
      id: "3",
      name: "ghi",
    },
    {
      id: "4",
      name: "lmn",
    },
  ];

  const { TblContainer, TblHead } = useTable(HeadCells.salesHead);
  const styles = makeStyles();

  const [trySearch, setTrySearch] = useState("");
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const {
    handleOpen,
    open,
    setOpen,
    values,
    handleInputChange,
    errors,
    setErrors,
  } = useForm();

  return (
    <>
      <HeadBar title={TXT_SALESPERSONMANAGEMENT} />
      <div className={styles.divStyle}>
        <div className={styles.searchBoxParent}>
          <Controls.StyledSearch
            placeholder="Search"
            className={styles.searchBar}
            size="small"
            name="usersearch"
            value={trySearch}
            onChange={(e) => setTrySearch(e.target.value)}
            icon={<BsSearch />}
          />
          <IconButs
            ibtname={INVENTORYFORM_ADD_DEALER}
            icon={<AiOutlinePlus />}
            className={styles.salesButton}
            onClick={handleOpen}
          />
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {data.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center">{item.sl_no}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.email}</TableCell>
                    <TableCell align="center">{item.mobile}</TableCell>
                    <TableCell align="center">{item.location}</TableCell>
                    <TableCell align="center">
                      <Link to="" className={styles.linkColor}>
                        View
                      </Link>
                    </TableCell>
                    <TableCell>{item.followUp}</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>
                      <div className={styles.iconButton}>
                        {/* <Link to={`/administrator/sales/salesPersonDetails/${item.sl_no}`}> */}
                        <IconButton>
                          <AiOutlineEye className="user-icons" />
                        </IconButton>
                        {/* </Link> */}
                        <IconButton>
                          <FaEdit className="user-icons" />
                        </IconButton>
                        <IconButton>
                          <AiFillDelete className="user-icons" />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </Box>
        {open ? (
          <SalesDealerForm
            open={open}
            handleClose={handleOpen}
            title="ADD DEALER PERSON"
            salesManagersData={salesManagers}
          />
        ) : null}
      </div>
    </>
  );
};

export default SalesDetails;
