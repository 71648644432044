import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    titlStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FEDD17",
      padding: "10px 0px",
    },
    resetRootContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      width: 900,
      backgroundColor: "#ffffff",
      boxShadow: 24,
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    label: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
      display:"flex"
    },
    input: {
      width: "100%",
      margin: "10px 0px",
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        height: "45px",
        fontSize: "14px",
      },
    },
    formButtonContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        gap: "0px",
        marginBottom: "20px",
      },
    },
    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "300px",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "10px 0px",
        maxWidth: "100%",
      },
    },
    cancelButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important", // used in add brand
      width: "100%",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important", // changed from 12 to 18px
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      margin: "30px 0px",

      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
        margin: "30px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    searchBar: {
      width: "100%",
      borderRadius: "20px",
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
    },

    formSelectBox: {
      width: "100%",
      borderRadius: "6px",
      height: "45px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    inputTextBar: {
      width: "100%",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
      },
      
     
    },

    resetContainer: {
      padding:'1rem 1.3rem 1rem 1rem'
    },
    formIcon: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "30px",
      gap: "40px",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        marginBottom: "0px",
        justifyContent: "center",
        gap: "0px",
      },
    },
  })
);
