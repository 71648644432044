import React from "react";
import { Box, Divider } from "@material-ui/core";
import useStyles from "./dashboardStyles";

const ChartDetailHeader = ({ title=0, stat, update }) => {
  const dashStyles = useStyles();
  return (
    <>
      <Box className={dashStyles.chartHeaderParent}>
        <h4 className={dashStyles.chartDetailHeaderTitle}>{title}</h4>
        <h3 className={dashStyles.chartDetailHeaderStat}>{stat}</h3>
        <h4 className={dashStyles.chartDetailHeaderUpdate}>{update}</h4>
      </Box>
      <Divider />
    </>
  );
};

export default ChartDetailHeader;
