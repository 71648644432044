import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Grid } from "@mui/material";
import IconButs from "../../common/Controls/IconButs";
import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
  ADD_USER,
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import { Typography } from "antd";
import { useForm } from "../../sharedFeatures/useForm";
import useAddUserCredential from "../../data/userCredential/useAddUserCredential";
import useAddDealer from "../../data/dealer/useAddDealer";
import CustomLoader from "../../features/CustomLoader";
import UserCredentialStyle from "./userCredentialStyle";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import ScrollToTop from "../../common/ScrollToTop";
import { toast } from "material-react-toastify";
import userCredentailQuery from "../../data/userCredential/useCredentialQuery";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./UserCredential.css";
import salesStyle from "../SalesPersonManagement/salesStyle";

const UserCredentialForm = ({ open, handleClose, setOpen }) => {
  const credentialStyle = UserCredentialStyle();
  const orderStyle = OrderManagmentStyle();
  const salesStyles = salesStyle();
  const initialValues = {
    firstName: "",
    lastName: "",
    userRole: "",
    phone: "",
    // userName: "",
    email: "",
    password: "",
    status: "",
  };

  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [userRoleError, setUserRoleError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  // const [userNameError, setUserNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [userRoleList, setUserRoleList] = React.useState([]);
  const [showPass, setShowPass] = React.useState(false);
  const { values, handleInputChange, setErrors, setValues } =
    useForm(initialValues);

  const {
    mutateAsync: addUser,
    isLoading: addUserLoading,
    isSuccess: addUserSuccess,
  } = useAddUserCredential.add_userCredential();

  const {
    mutate: emailCheck,
    data: emailExistData,
    error: errorData,
    isLoading: emailLoading,
  } = useAddDealer.email_exist_not();

  const {
    mutate: phoneCheck,
    data: phoneExistData,
    error: errorphnData,
    isLoading: mobileLoading,
  } = useAddDealer.phone_exist_not();

  const {
    data: userTypesListforCreate,
    isSuccess: userTypeListSuccess,
    refetch,
  } = userCredentailQuery.userTypesListforCreate();

  const phoneExistOrnot = async (e) => {
    await phoneCheck({
      phone: values.phone,
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    if (
      userTypeListSuccess &&
      userTypesListforCreate &&
      userTypesListforCreate.data &&
      userTypesListforCreate.data.data
    ) {
      let data = [...userTypesListforCreate.data.data];
      data = data.map((item) => ({ id: item.name, name: item.name }));
      setUserRoleList(data);
    }
  }, [userTypeListSuccess]);

  const emailExistOrnot = async (e) => {
    await emailCheck({
      email: values.email,
    });
  };

  React.useEffect(() => {
    if (errorData && errorData.response.data.success === "True") {
      setEmailError(errorData && errorData.response.data.message);
    } else if (errorphnData && errorphnData.response.data.success === "True") {
      setPhoneError(errorphnData && errorphnData.response.data.message);
    } else if (
      emailExistData &&
      emailExistData.success === "False" &&
      phoneExistData &&
      phoneExistData.success === "False"
    ) {
      submitData();
    }
  }, [emailExistData, phoneExistData, errorData, errorphnData]);

  const submitFormData = (event) => {
    let err = undefined;
    event.preventDefault();

    if (!values.firstName) {
      setFirstNameError("First Name is required");
      err = true;
    }
    if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(values.firstName)) {
      setFirstNameError("First name not valid");
      err = true;
    }
    if (!values.lastName) {
      setLastNameError("First Name is required");
      err = true;
    }
    if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(values.lastName)) {
      setLastNameError("Last name not valid");
      err = true;
    }
    if (!values.email) {
      setEmailError("Email is required");
      err = true;
    }
    if (
      !/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i.test(
        values.email
      )
    ) {
      setEmailError("Enter a valid email address");
      err = true;
    }
    if (!values.phone) {
      setPhoneError("Phone number is required");
      err = true;
    }
    if (values.phone) {
      if (values.phone.length > 10 || values.phone.length < 10) {
        setPhoneError("Invalid Phone Number");
        err = true;
      }
    }
    // if (!values.userName) {
    //   setUserNameError("User name is required");
    //   err = true;
    // }
    // if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(values.firstName)) {
    //   setFirstNameError("First name not valid");
    //   err = true;
    // }
    // if (!/^[^\s][a-zA-Z\s]+[^\s]$/i.test(values.lastName)) {
    //   setLastNameError("Last name not valid");
    //   err = true;
    // }
    if (!values.userRole) {
      setUserRoleError("User role is required");
      err = true;
    }
    if (!values.password) {
      setPasswordError("Password is required");
      err = true;
    } else {
      if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(
          values.password
        )
      ) {
        setPasswordError(
          "Password should have minimum 6 character, atleast one number and one special character"
        );
        err = true;
      }
    }

    if (!err) {
      handleValidate();
    }
  };

  const handleValidate = () => {
    if (
      firstNameError === "" &&
      lastNameError === "" &&
      phoneError === "" &&
      emailError === "" &&
      // userNameError === "" &&
      passwordError === "" &&
      userRoleError === "" &&
      passwordError === ""
    ) {
      emailExistOrnot();
      phoneExistOrnot();
    }
  };

  const submitData = () => {
    addUser({
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      phone: values.phone.trim(),
      email: values.email.trim(),
      // username: values.userName,
      password: values.password.trim(),
      user_type: values.userRole,
    })
      .then((res) => {})
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error
        ) {
          toast.error(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.error &&
              err.response.data.error
          );
        }
      });
  };

  const ALPHA_NUMERIC_NO_SPACE = /^[0-9]+$/;

  const BLOCK_SPECIAL_REGEX = /^[0-9a-zA-Z \b]+$/;

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  const handleChange = (event) => {
    setErrors(false);
    let targetName = event.target.name;

    if (targetName === "firstName") {
      if (/^[a-z ,.'-]+$/i.test(event.target.value)) {
        if (event.target.value.length < 46) {
          handleInputChange(event);
          setFirstNameError("");
        }
      } else {
        setValues({
          ...values,
          firstName: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    }
    if (targetName === "lastName") {
      if (/^[a-z ,.'-]+$/i.test(event.target.value)) {
        if (event.target.value.length < 46) {
          handleInputChange(event);
          setLastNameError("");
        }
      } else {
        setValues({
          ...values,
          lastName: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    } else if (targetName === "password") {
      if (event.target.value) {
        handleInputChange(event);
        setPasswordError("");
      } else {
        setValues({
          ...values,
          password: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    } else if (targetName === "userRole") {
      if (event.target.value) {
        handleInputChange(event);
        setUserRoleError("");
      } else {
        setValues({
          ...values,
          userRole: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    } else if (targetName === "email") {
      if (/^[a-zA-Z0-9@._]*$/.test(event.target.value)) {
        if (event.target.value.length < 60) {
          handleInputChange(event);
          setEmailError("");
        }
      } else {
        setValues({
          ...values,
          email: event.target.value.substring(0, event.target.value.length - 1),
        });
      }
    } else if (targetName === "phone") {
      if (/^[0-9]+$/.test(event.target.value)) {
        if (event.target.value.length < 11) {
          handleInputChange(event);
          setPhoneError("");
        }
      } else {
        return setValues({
          ...values,
          phone: event.target.value.substring(0, event.target.value.length - 1),
        });
      }
    }
    //  else if (targetName === "userName") {
    //   if (/^[a-z ,.'-]+$/i.test(event.target.value)) {
    //     if (event.target.value.length < 46) {
    //       handleInputChange(event);
    //       setUserNameError("");
    //     }
    //   } else {
    //     setValues({
    //       ...values,
    //       userName: event.target.value.substring(
    //         0,
    //         event.target.value.length - 1
    //       ),
    //     });
    //   }
    // }
  };

  const preventSpaceAndSpecialWithAlphaNumeric = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_NUMERIC_NO_SPACE.test(value)) {
      event.preventDefault();
    }
    if (event.nativeEvent.code === "Space") {
      event.preventDefault();
    }
  };

  const alphaWithSpace = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  React.useEffect(() => {
    if (addUserSuccess) {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          User added successfully
        </span>
      );
      handleClose();
      setValues({
        nameOfTheUser: "",
        phone: "",
        email: "",
        // userName: "",
        password: "",
        userRole: "",
      });
      setErrors(false);
      setPhoneError("");
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      // setUserNameError("");
      setPasswordError("");
      setUserRoleError("");
      setOpen(false);
    }
  }, [addUserSuccess]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalWrapCred">
        <ScrollToTop />
        <Box className={credentialStyle.titlStyle}>
          <Typography className={orderStyle.assignTitle}>{ADD_USER}</Typography>
        </Box>
        <form style={{ width: "100%", padding: "10px" }}>
          <div style={{ padding: "10px" }}>
            {/* addUserSuccess && */}

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  First name
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controls.InputField
                  name="firstName"
                  placeholder="First name of the User"
                  value={values.firstName}
                  className={credentialStyle.input}
                  onChange={handleChange}
                  error={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  Last name
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controls.InputField
                  name="lastName"
                  placeholder="Last name of the User"
                  value={values.lastName}
                  className={credentialStyle.input}
                  onChange={handleChange}
                  error={lastNameError}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  User Role
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controls.SelectBox
                  name="userRole"
                  label="User Role"
                  className={credentialStyle.formSelectBox}
                  options={userRoleList}
                  value={values.userRole}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleChange(e);
                  }}
                  onKeyPress={alphaWithSpace}
                  error={userRoleError}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  Phone
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <Controls.InputField
                  placeholder="Mobile Number"
                  type="numbers"
                  name="phone"
                  onKeyPress={preventSpaceAndSpecialWithAlphaNumeric}
                  className={credentialStyle.input}
                  value={values.phone}
                  onChange={handleChange}
                  error={phoneError}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  Email
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controls.InputField
                  name="email"
                  placeholder=" example@email.com"
                  value={values.email}
                  className={credentialStyle.input}
                  onChange={handleChange}
                  error={emailError}
                  onInput={(event) => {
                    const value = event.target.value;
                    const regex = /^[a-zA-Z0-9@._]*$/;
                    if (!regex.test(value)) {
                      event.target.value = value.replace(
                        /[^a-zA-Z0-9@._]/g,
                        ""
                      );
                    }
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={5}>
                  <Typography className={credentialStyle.label}>
                    User Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Controls.InputField
                    name="userName"
                    placeholder="Username"
                    className={credentialStyle.input}
                    value={values.userName}
                    onChange={handleChange}
                    error={userNameError}
                  />
                </Grid> */}
              <Grid item xs={12} sm={5}>
                <Typography className={credentialStyle.label}>
                  Password
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} className="password-input">
                <Controls.InputField
                  name="password"
                  placeholder="Password"
                  type={showPass ? "text" : "password"}
                  value={values.password}
                  className={credentialStyle.input}
                  onChange={handleChange}
                  inputProps={{ maxLength: 15 }}
                  error={passwordError}
                  icon={
                    showPass ? (
                      <IconButton onClick={() => setShowPass(false)}>
                        <Visibility />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setShowPass(true)}>
                        <VisibilityOff />
                      </IconButton>
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButs
                  className={credentialStyle.formButtonAdd}
                  onClick={submitFormData}
                  disabled={
                    addUserLoading || mobileLoading || emailLoading
                      ? true
                      : false
                  }
                  ibtname={
                    addUserLoading || mobileLoading || emailLoading ? (
                      <CircularProgress color="black" size={24} />
                    ) : (
                      FORM_BUTTON_MANAGEMENT
                    )
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: { xs: "inline", sm: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButs
                      onClick={handleClose}
                      ibtname={FORMONE_BUTTON_MANAGEMENT}
                      className={salesStyles.formButton}
                      disabled={
                        addUserLoading || mobileLoading || emailLoading
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserCredentialForm;
