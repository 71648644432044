import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
const theme = createTheme();

export default makeStyles((theme) =>
  createStyles({
    parentContainer: {
      maxWidth: "1500px !important",
      minHeight: "auto !important",
      marginLeft: "3vw !important",
      marginRight: "3vw !important",
      padding: "3vw !important",
      marginTop: "50px !important",
    },
    tabBox: {
      height: "300px !important",
      width: "1100px !important",
      border: "1px solid grey !important",
      overflowX: "scroll !important",
    },
    productType: {
      height: "300px !important",
      width: "400px !important",
    },
    divStyle: {
      width: "100% !important",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5 !important",
      padding: "2px 0 !important",
    },
    label: {
      padding: "18px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      ["@media (max-width: 412px)"]: {
        padding: "5px !important",
      },
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        padding: "0 !important",
        marginBottom: "10px !important",
        marginTop: "16px !important",
      },
    },
    ifscLabel: {
      padding: "20px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      ["@media (max-width: 412px)"]: {
        padding: "5px !important",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 !important",
        marginBottom: "10px !important",
        marginTop: "16px !important",
      },
    },
    ifscOverlay: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px !important",
      },
    },
    viewlabel: {
      fontWeight: "600 !important",
      fontStyle: "normal !important",
      fontSize: "12px !important",
      fontFamily: "Montserrat !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      width: "100% !important",
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        textAlign: "start !important",
      },
    },

    addBtn: {
      fontFamily: "Montserrat !important",
      fontSize: "14px !important",
    },

    tradingContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px !important",
      },
    },

    tradingGrid: {
      marginTop: "6px",
      marginInline: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginInline: "0px !important",
        marginRight: "10px !important",
      },
    },

    addRemoveButtonGroup: {
      marginTop: "20px !important",
      marginLeft: "10px !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
        // marginTop: "0 !important",
        // marginLeft: "0 !important",
      },
      addRemoveButtonGroup2: {
        display: "flex !important",
        flexDirection: "row !important",
      },
    },

    productTypeSelectStyle: {
      fontSize: "14px",
      fontFamily: "Montserrat !important",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },

    productTypeListStyle: {
      paddingTop: 0,
      paddingBottom: 0,
      width: "100%",
      "& li": {
        fontWeight: 200,
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: "14px",
        width: "100%",
        fontFamily: "Montserrat !important",
      },
      "& li.Mui-selected": {
        color: "black",
        background: "#ffff",
      },
      "& li.Mui-selected:hover": {
        background: "#ffff",
      },
    },

    fieldvalue: {
      color: "red !important",
    },

    addressBarForm: {
      "& .MuiInputBase-input": {
        height: "0.5rem !important",
        borderRadius: "8px !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `14px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      width: "100% !important",
      
      //hide input arrows
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none !important",
          margin: "0px !important",
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    addressBar2: {
      "& .MuiInputBase-input": {
        height: "0.5rem !important",
        borderRadius: "8px !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `12px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      width: "49% !important",
      ["@media (max-width: 960px) !important"]: {
        width: "49% !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "49% !important",
      },
      ["@media (max-width: 450px)"]: {
        width: "49% !important",
      },
      ["@media (max-width: 450px)"]: {
        width: "49% !important",
      },
      //hide input arrows
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none !important",
          margin: "0px !important",
        },
    },
    listButton: {
      // width: "320px !important",
      width: "100% !important",
      height: "40px !important",
      margin: "10px 0 !important",
      fontFamily: "Montserrat !important",
      fontWeight: "400 !important",
      fontStyle: "normal !important",
      fontSize: "12px !important",
      lineHeight: "24px !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      background: "#ffffff !important",
      border: "1px solid #D9D9D9 !important",
      color: "#000000 !important",
      textTransform: "capitalize !important",
      borderRadius: "6px !important",
      ["@media (max-width: 450px)"]: {
        marginBottom: "-10px !important",
      },
    },

    toast: {
      top: "50% !important",
    },

    facilities: {
      width: "100% !important",
      padding: "10px !important",
      fontFamily: "Montserrat !important",
      fontSize: "14px !important",
      // borderRadius: `10px !important`,
      marginTop: "10px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      // marginBottom: "23px !important",
      ["@media (max-width: 1500px)"]: {
        // width: "300px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 1300px)"]: {
        // width: "450px !important",
        marginBottom: "0px 0px 20px 5px !important",
      },
      ["@media (max-width: 1280px)"]: {
        // width: "355px !important",
        marginBottom: "0px 0px 20px 5px !important",
      },
      ["@media (max-width: 960px) !important"]: {
        width: "100% !important",
        // marginBottom: "20px"
      },
      ["@media (max-width: 450px)"]: {
        marginBottom: "-5px !important",
      },
    },
    facilities2: {
      width: "100% !important",
      padding: "10px !important",
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      // borderRadius: `10px !important`,
      marginTop: "10px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      // marginBottom: "23px !important",
      ["@media (max-width: 1500px)"]: {
        // width: "300px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 1300px)"]: {
        // width: "450px !important",
        marginBottom: "0px 0px 20px 5px !important",
      },
      ["@media (max-width: 1280px)"]: {
        // width: "355px !important",
        marginBottom: "0px 0px 20px 5px !important",
      },
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        // marginBottom: "20px"
      },
      ["@media (max-width: 450px)"]: {
        marginBottom: "-5px !important",
      },
    },
    geolocation: {
      width: "100% !important",
      height: "50px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "black !important",
      padding: "18.5px 14px !important",
      margin: "2px 0 !important",
      borderRadius: "6px !important",
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        margin: "0px  !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        marginBottom: "0px !important",
      },
      ["@media (max-width: 400px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
      },
      ["@media (max-width: 360px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
      },
    },
    tabs: {
      // "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "#3f51b5 !important",
      backgroundColor: "yellow !important",
      // },
    },

    subHeader: {
      position: "relative !important",
      top: "-40px !important",
    },
    backButtonDealer: {
      position: "relative !important",
      top: "32px !important",
      left: "-15px !important",
    },
    backtoDealer: {
      position: "relative !important",
      top: "-12px !important",
      left: "0px !important",
    },
    addDealerHeading: {
      // position: "relative !important",
      // top: "10px !important",
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      lineHeight: "24px !important",
      color: "#252F40 !important",
      // padding: "30px !important",
      width: "265px !important",
      height: "24px !important",
      // marginTop: '17px !important',
      marginLeft: "35px !important",
      // left: "30px !important",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield !important",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none !important",
      margin: "0px !important",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none !important",
      margin: "0px !important",
    },
    backButton: {
      color: "#292D32 !important",
      lineHeight: "45px !important",
      fontWeight: "600 !important",
      fontSize: "20px !important",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      width: "27px !important",
      height: "27px !important",
      top: "10px !important",
      border: "3px solid black !important",
      borderRadius: "20px !important",
    },

    stepper: {
      top: "70px !important",
    },
    docLabel: {
      color: "red !important",
      fontFamily: "Poppins !important",
      fontSize: "20px !important",
      margin: "0px 0px 30px 0px !important",
      fontWeight: "300px !important",
    },
    // error: {
    //   color: 'red !important',
    //    transitionDuration: 'initial !important'
    // },
    mainBox: {
      width: "1300px !important",
    },
    formIcon: {
      width: "100% !important",

      position: "absolute !important",
      // padding: "20px 0px !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      marginTop: "30px !important",
    },
    formButtonAdd: {
      position: "relative !important",
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "300px ",
      height: "40px !important",
      textTransform: "capitalize !important",
      lineHeight: "22px !important",
      letterSpacing: "-0.387234px !important",
      boxShadow:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07) !important",
      border: "0 !important",
      borderRadius: "8px !important",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none !important",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0px",
        width: "90vw !important",
      },
    },
    userButton: {
      position: "relative !important",
      fontFamily: "Montserrat !important",
      fontWeight: "400 !important",
      background: "#FEDD17 !important",
      borderColor: "red !important",
      fontSize: "12px !important",
      border: "1px solid yellow !important",
      color: "#252F40 !important",
      width: "430px !important",
      height: "40px !important",
      textTransform: "capitalize !important",
      lineHeight: "15px !important",
      letterSpacing: "-0.387234px !important",
      boxShadow:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07) !important",
      border: "0 !important",
      borderRadius: "8px !important",
      ["@media (max-width: 412px)"]: {
        width: "300px !important",
      },
    },
    preview: {
      height: "100px !important",
      width: "150px !important",
      backgroundColor: "#C8C8C8 !important",
      padding: "35px !important",
      margin: "0px 0px 10px 15px !important",
    },
    shopInput: {
      marginLeft: "202px !important",
      ["@media (max-width: 1600px)"]: {
        marginLeft: "180px !important",
      },
      ["@media (max-width: 1400px)"]: {
        marginLeft: "130px !important",
      },
      ["@media (max-width: 900px)"]: {
        marginLeft: "0px !important",
      },
    },
    selectbox: {
      width: "350px !important",
      marginBottom: "25px !important",
      margin: "10px 0px 15px 0px !important",
      ["@media (max-width: 1500px)"]: {
        width: "300px !important",
      },
      ["@media (max-width: 1300px)"]: {
        width: "450px !important",
        marginBottom: "0px 0px 20px 5px !important",
      },
    },
    multipleSelect: {
      width: "100% !important",
      minHeight: "50px !important",
      maxHeight: "60% !important",
      marginBottom: "20px !important",
      marginTop: "19px !important",
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        marginBottom: "20px !important",
        [theme.breakpoints.down("sm")]: {
          width: "100% !important",
          marginTop: "15px !important",
        },
      },
      
    },
    basicSelectBox: {
      width: "100% !important",
      height: "50px !important",
      marginTop: "10px !important",
      marginBottom: "15px !important",
      borderRadius: "6px !important",
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        marginBottom: "0px !important",
      },
    },
    textBox: {
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      width: "350px !important",
      ["@media (max-width: 1500px)"]: {
        width: "300px !important",
      },
      ["@media (max-width: 1300px)"]: {
        width: "450px !important",
        margin: "0px 0px 20px 5px !important",
      },
    },
    error: {
      color: "red !important",
      fontWeight: "bold !important",
      fontSize: "16px !important",
    },
    inputBox: {
      display: "flex !important",
      flexWrap: "wrap !important",
      width: "100% !important",
      minHeight: "40px !important",
      maxHeight: "100% !important",
      border: "1px solid #C8C8C8 !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      display: "flex !important",
      flexWrap: "wrap !important",
      alignItems: "center !important",
      padding: "10px 10px !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      ["@media (max-width: 960px)"]: {
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        marginTop: "10px !important",
        marginBottom: "-10px !important",
        padding: "3px 2px !important",
      },
      ["@media (max-width: 400px)"]: {
        marginTop: "10px !important",
        marginBottom: "-10px !important",
      },
      ["@media (max-width: 360px)"]: {
        marginBottom: "-10px !important",
      },
    },
    formTextBar: {
      width: "100% !important",
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      //   margin: "5px 0px !important",
      // width: "361px !important",
      // margin: "20px 0px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",

      // ["@media (max-width: 360px)"]: {
      //   width: "300px !important",
      //   margin: "0px !important",
      //   marginBottom: "20px"
      // },
      ["@media (max-width: 960px)"]: {
        // width: "410px !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        // width: "330px !important",
        margin: "0px !important",
        marginBottom: "20px !important",
        //       width: "200px !important",
      },
      ["@media (max-width: 450px)"]: {
        marginTop: "10px !important",
        marginBottom: "-10px !important",
      },
      ["@media (max-width: 400px)"]: {
        marginTop: "10px !important",
        marginBottom: "-10px !important",
      },
      ["@media (max-width: 360px)"]: {
        marginBottom: "-10px !important",
      },
    },
    stepperFormTextBar: {
      "& ::placeholder": {
        fontSize: "14px !important",
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input": {
        height: "10px !important",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "MuiInputBase-input MuiOutlinedInput-input": {
        height: "10px !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `14px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      width: "100% !important",
      margin: "10px 0 !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      marginBottom: "20px !important",
      ["@media (max-width: 960px)"]: {
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
        "& input": {
          fontSize: "12px !important",
        },
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        marginBottom: "0px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 400px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 360px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
    },
    documentImage: {
      height: "100px",
      width: "150px",
      cursor: "pointer",
    },
    locationfield: {
      // width: "auto !important",
      // margin: "20px 0px !important",
      width: "100% !important",
      backgroundColor: "white !important",
      borderRadius: "20px !important",
      ["@media (max-width: 1500px)"]: {
        // width: "200px !important",
      },
      ["@media (max-width: 960px)"]: {
        // width: "410px !important",
        // margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        // width: "330px !important",
        // margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        marginTop: "10px !important",
        marginBottom: "0px !important",
      },
      ["@media (max-width: 400px)"]: {
        marginBottom: "20px !important",
        marginTop: "10px !important",
      },
      ["@media (max-width: 360px)"]: {
        marginBottom: "0px !important",
      },
    },
    imgDiv: {
      width: "243px !important",
      border: "1px solid green !important",
      padding: "15px !important",
      borderRadius: "5px !important",
      marginBottom: "10px !important",
      position: "relative !important",
      ["@media (max-width: 460px)"]: {
        width: "88vw !important",
      },
    },
    labelImg: {
      fontFamily: "Montserrat !important",
      paddingInline: "5px !important",
      fontStyle: "normal !important",
      fontWeight: "bold !important",
      fontSize: "13px !important",
      lineHeight: "24px !important",
      position: "absolute !important",
      top: "-12px !important",
      left: "20px !important",
      backgroundColor: "white !important",
    },
    brand: {
      maxHeight: "650px !important",
      overflow: "auto !important",
      maxWidth: "100% !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "10px !important",
      padding: "10px !important",
      margin: "10px 0px 15px 0px !important",
      // //mobilr
      // ["@media (max-width: 960px)"]: {
      //   width: "100% !important",
      //   height: "100% !important",
      // },
      // ["@media (max-width: 450px)"]: {
      //   width: "100% !important",
      //   height: "100% !important",
      // },
      // ["@media (max-width: 400px)"]: {
      //   width: "100% !important",
      //   height: "100% !important",
      // },
      // ["@media (max-width: 360px)"]: {
      //   height: "100% !important",
      //   width: "100% !important",
      // },
    },
    addIcon: {
      border: "2px solid black !important",
      // fontSize: "10px !important",
      fontWeight: "bold !important",
      borderRadius: "5px !important",
    },
    shopImage: {
      width: "374px !important",
      height: "50px !important",
      border: "1px solid #F8F8F8 !important",
      marginRight: "10px !important",
      margin: "0px 10px 20px 0px !important",
    },
    addDashedBorder: {
      width: "50px !important",
      height: "53px !important",
      border: "dashed !important",
      borderRadius: "5% !important",
    },
    imageicon: {
      border: "2px solid green !important",
      color: "green !important",
      fontWeight: "bold !important",
      borderRadius: "5px !important",
    },
    imageBox: {
      width: "243px !important",
      height: "40px !important",
      marginRight: "10px !important",
      marginBottom: "40px !important",
      "& input": {
        borderRadius: "6px !important",
      },
      "& fieldset": {
        borderRadius: "6px !important",
      },
      "& input[type=file]": {
        opacity: "0px !important",
        color: "transparent !important",
      },
      // ["@media (max-width: 412px)"]: {
      //   marginBottom: "30px !important",
      // },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px !important",
        width: "88vw !important",
        borderRadius: "8px !important",
        marginBottom: "20px",
      },
      // ["@media (max-width: 450px)"]: {
      //   marginLeft: "14px !important",
      //   width: "330px !important",
      //   borderRadius: "8px !important",
      // },
      // ["@media (max-width: 400px)"]: {
      //   marginLeft: "14px !important",
      //   width: "300px !important",
      //   borderRadius: "8px !important",
      // },

      // ["@media (max-width: 360px)"]: {
      //   marginLeft: "14px !important",
      //   width: "290px !important",
      //   borderRadius: "8px !important",
      // },
    },
    imageBox2: {
      width: "243px !important",
      marginRight: "10px !important",
      marginBottom: "10px !important",
      "& input[type=file]": {
        opacity: "0px !important",
        color: "transparent !important",
      },
      // ["@media (max-width: 412px)"]: {
      //   marginBottom: "30px !important",
      // },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px !important",
        width: "74vw !important",
        borderRadius: "8px !important",
        marginRight: "7px !important",
      },
      // ["@media (max-width: 450px)"]: {
      //   marginLeft: "14px !important",
      //   width: "250px !important",
      //   borderRadius: "8px !important",
      //   marginRight: "21px !important",
      // },
      ["@media (max-width: 400px)"]: {
        width: "73vw !important",
      },

      ["@media (max-width: 375px)"]: {
        width: "72vw !important",
      },
      ["@media (max-width: 320px)"]: {
        width: "70vw !important",
      },
    },
    tradeLabel: {
      fontFamily: "Montserrat !important",
      fontSize: "14px !important",
    },
    required: {
      color: "red",
    },
    gstGridStyle: {
      marginBottom: "2vh !important",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0px !important",
      },
    },
    cardStyle: {
      padding: "0px !important",
      paddingBottom: "0px !important",
      "& .MuiCard-root": {
        paddingBottom: "0px !important",
      },
    },
    boxStyle: {
      [theme.breakpoints.down("sm")]: {
        overflowX: "hidden !important",
      },
    },
  })
);
