import DataService from "../../DataService/DataService";

const getUserlist = async ({ searchQry, page, perPageItems }) => {
  let url = ``;
  if (searchQry && page && perPageItems) {
    url = `auth/getcustomers/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page) {
    url = `auth/getcustomers/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `auth/getcustomers/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (page && perPageItems) {
    url = `auth/getcustomers/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `auth/getcustomers/?page=${page}`;
  } else if (perPageItems) {
    url = `auth/getcustomers/?page_size=${perPageItems}`;
  } else {
    url = `auth/getcustomers/?page=${page}`;
  }
  const data = await DataService.get(url);
  return data.data;
};

const getUserDetail = async (id) => {
  const url = `auth/getcustomers/${id}/`;
  const data = await DataService.get(url);
  return data;
};

const getUserVehicleDetail = async (id) => {
  const url = `auth/customervehicledetails/${id}/`;
  const data = await DataService.get(url);
  return data;
};

const updateUserDetail = async (value) => {
  const { id, ...editData } = value;
  const url = `/auth/updateuserprofile/${id}/`;
  const response = await DataService.patch(url, value);
  return response;
};

const deleteCustomer = async (id) => {
  const url = `auth/customerdelete/${id}/`;
  const data = await DataService.del(url);
  return data;
};

export {
  getUserlist,
  getUserDetail,
  getUserVehicleDetail,
  updateUserDetail,
  deleteCustomer,
};
