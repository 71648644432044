import DataService from "../../DataService/DataService";

const getVehicleList = async ({ searchQry, page, perPageItems }) => {
  let url = "";
  if (searchQry && page && perPageItems) {
    url = `/vehicle/vehicles/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page) {
    url = `/vehicle/vehicles/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems) {
    url = `/vehicle/vehicles/?search=${searchQry}&page_size=${perPageItems}`;
  } else if (page && perPageItems) {
    url = `/vehicle/vehicles/?page_size=${perPageItems}&page=${page}`;
  } else if (page) {
    url = `/vehicle/vehicles/?page=${page}`;
  } else if (perPageItems) {
    url = `/vehicle/vehicles/?page_size=${perPageItems}`;
  } else {
    url = `/vehicle/vehicles/?page=${page}`;
  }
  const data = await DataService.get(url);
  return data.data;
};

const getVehicleData = async (value) => {
  const url = `/vehicle/vehicles/${value}/`;
  const data = await DataService.get(url);
  return data.data;
};

const addVehicleDocuments = async ({ form_data }) => {
  // const url = "/vehicle/vehicle/upload_data/";
  // const { ...state } = value;
  // const data = await DataService.post(url, value);
  // return data;
};

export { 
  getVehicleList, 
  getVehicleData, 
  addVehicleDocuments 
};
