import React from "react";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      position: "absolute",
    },
    "& .Mui-error": {
      position: "absolute",
    },
    "& textarea": {
      padding: "10px", // Adjust the padding value as needed
      fontFamily: "Montserrat !important",
    },
  },
});

const TextArea = ({
  value,
  name,
  className,
  placeholder,
  error = null,
  onChange,
  onKeyPress,
  maxLength,
  maxRows,
  disabled,
  height,
  padding,
  fontSize,
  borderRadius,
  ...others
  
}) => {

  const classes = useStyles();
  return (
    <>
      <TextareaAutosize
        name={name}
        aria-label="empty textarea"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        className={`${className} ${classes.root}`} 
        onChange={onChange}
        maxLength={maxLength}
        maxRows={maxRows}
        onKeyPress={onKeyPress}
        error={error}
        variant="outlined"
        {...others}
        style={{ height,padding ,fontFamily: "Montserrat" , fontSize , borderRadius}}
        {...(error && { error: true, helperText: error })}

        // helperText={text === "" ? 'Empty field!' : ' '}
      />
    </>
  );
};

export default TextArea;
