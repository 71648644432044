import { Box, Grid } from "@material-ui/core";
import React from "react";
import DealersOnboardedChart from "./DealersOnboardedChart";
import OrderValueChart from "./OrderValueChart";
import useStyles from "./dashboardStyles";
import {
  useTopProductData,
  usedealerData,
} from "../../data/dashboard/dashboardQuery";
import { keyConverter } from "./utils/utils";

const Dealers = ({ filter }) => {
  const dashStyles = useStyles();

  const { data: topProduct } = useTopProductData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });
  const { data: dealerData } = usedealerData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });

  const topDealerData =
    dealerData &&
    dealerData.data &&
    dealerData.data.data &&
    dealerData.data.data.map((e) => {
      return {
        name: keyConverter(e, filter),
        uv: e["y-axis"] && e["y-axis"].count,
      };
    });
  const topProductData =
    topProduct &&
    topProduct.data &&
    topProduct.data.data &&
    topProduct.data.data.map((e) => {
      return {
        name: keyConverter(e, filter),
        ...Object.values(e["y-axis"]).filter((e, i) => {
          return {
            ["product" + i]: e,
          };
        }),
      };
    });
  const product =
    topProduct &&
    topProduct.data &&
    topProduct.data.data &&
    topProduct.data.data[0] &&
    topProduct.data.data[0].product_details &&
    topProduct.data.data[0].product_details &&
    Object.values(
      topProduct &&
        topProduct.data &&
        topProduct.data.data &&
        topProduct.data.data[0] &&
        topProduct.data.data[0].product_details &&
        topProduct.data.data[0].product_details
    ).filter((e, i) => {
      return {
        [i]: e,
      };
    })
  return (
    <Box className={dashStyles.dealersSectionParent}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={10}>
          <OrderValueChart data={topProductData} product={product} total = { topProduct &&
        topProduct.data &&
        topProduct.data.total }/>
        </Grid>
        <Grid item md={12}>
          <DealersOnboardedChart  data={topDealerData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dealers;
