import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import routes from "../Routes/Routes";
import Sidebar from "../Sidebar/Sidebar";
import "./Uselayout.css";
import "./AdminStyles.css";
import { Box } from "@material-ui/core";

import useLoginQuery from "../data/auth/useLoginQuery";

const AdminLayout = () => {
  const { isSuccess: permissionsSuccess } = useLoginQuery.permissions_list();

  const [newRoutes, setNewRoutes] = useState([]);

  useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("userPermissions"));
    let filteredRoutes = [];
    if (permissions) {
      permissions = permissions.map((item) => item.id);
      permissions && permissions.push(0);
      filteredRoutes = routes.filter((item) => permissions.includes(item.id));
    }
    setNewRoutes([...filteredRoutes]);
  }, [permissionsSuccess]);
  const [open, setOpen] = useState();
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === "/administrator") {
        return (
        
            <Route path={route.path} key={key +1} element={<route.component />} />
          
        );
      }
    });
  };
  return (
    <>
      <div className="main">
        <Box className="sidebar" sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar togleState={setOpen} />
        </Box>
        <Box style={{ width: "100%" }}>
          <Box style={{ width: "100%", height: "100%" }}>
            <Routes>{getRoutes(newRoutes)}</Routes>
          </Box>
        </Box>
      </div>
    </>
  );
};
export default AdminLayout;
