import React from "react";

const SmallIcon = () => {
  return (
    <>
      <svg
        width="41"
        height="49"
        viewBox="0 0 41 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.6303 6.49448C26.8557 -1.27583 14.2547 -1.27583 6.48434 6.49448C-0.620404 13.5971 -1.21043 24.7277 4.67317 32.5253L7.84729 29.4309C6.09151 26.9205 5.053 23.8667 5.053 20.5694C5.053 12.0049 11.9931 5.06288 20.5576 5.06288C29.122 5.06288 36.0619 12.0049 36.0619 20.5694C36.0619 29.1319 29.1218 36.0739 20.5576 36.0739C17.1642 36.0739 14.0336 34.9697 11.4818 33.1198L8.23905 36.397L20.5576 48.7132L34.6335 34.6402C42.4038 26.8702 42.4038 14.267 34.6303 6.49448Z"
          fill="#E94141"
        />
        <path
          d="M28.863 28.9297C33.5029 24.29 33.5029 16.767 28.863 12.126C24.2252 7.48633 16.7024 7.48633 12.0614 12.126C8.04892 16.1375 7.51349 22.3047 10.4411 26.9011L14.5475 22.8976C13.6333 20.6035 14.1 17.88 15.9565 16.0232C17.7698 14.2099 20.4064 13.723 22.6634 14.5553L19.6035 17.612C18.5629 18.6527 18.5629 20.3427 19.6035 21.3833C20.6439 22.4237 22.3339 22.4237 23.3746 21.3833L26.4366 18.3264C27.267 20.585 26.7822 23.2178 24.9687 25.0351C23.11 26.8929 20.3884 27.3634 18.0924 26.4423L14.0497 30.5235C18.6501 33.4808 24.8406 32.9554 28.863 28.9297Z"
          fill="#E94141"
        />
      </svg>
    </>
  );
};

export default SmallIcon;
