import React, { useState } from "react";
import {  Paper, IconButton, Box, Grid } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { Link } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import { AiOutlinePlus } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import vehicleStyles from "./VehicleStyle";
import {
  TXT_VEHICLEMANAGEMENT,
  ADD_NEW,
  TXT_VEHICLE_ADD,
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import { FindLink } from "../../sharedFeatures/FindLink";

const VehicleAdd = () => {
  const viewStyle = makeStyles();
  const vehicleStyle = vehicleStyles();
  const [search, setSearch] = useState();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };
  let link = FindLink("/administrator/vehicle")

  return (
    <>
      <HeadBar title={TXT_VEHICLEMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <div className={viewStyle.searchBoxParent}></div>

        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_VEHICLE_ADD} />
          </div>

          <Grid
            container
            alignItems="start"
            spacing={2}
            className={vehicleStyle.formContainer}
          >
            <Grid item xs={12} md={6}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Vehicle Brand
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    className={vehicleStyle.inputTextBar}
                    name="userRoleManagers"
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Vehicle Model
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    className={vehicleStyle.inputTextBar}
                    name="userRoleManagers"
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Vehicle Sub Model
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    name="Search Varient"
                    className={vehicleStyle.inputTextBar}
                    // onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Vehicle Tyre Size
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={3} md={3} className={vehicleStyle.viewlabel}>
                      Front
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Controls.InputField
                        className={vehicleStyle.inputTextBarSmall}
                        name="Tyre"
                        label=""
                      />
                    </Grid>
                    <Grid item xs={3} md={3} className={vehicleStyle.viewlabel}>
                      Rear
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Controls.InputField
                        className={vehicleStyle.inputTextBarSmall}
                        name="Tyre"
                        label=""
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Tyre Aire Pressure
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={3} md={3} className={vehicleStyle.viewlabel}>
                      Front
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Controls.InputField
                        className={vehicleStyle.inputTextBarSmall}
                        name="Tyre"
                        placeholder="PSI"
                        // options={selectData}
                      />
                    </Grid>
                    <Grid item xs={3} md={3} className={vehicleStyle.viewlabel}>
                      Rear
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Controls.InputField
                        className={vehicleStyle.inputTextBarSmall}
                        name="Tyre"
                        placeholder="PSI"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Battery Model
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    name="Battery"
                    placeholder="Amaron"
                    className={vehicleStyle.inputTextBar}
                    // onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Engine Oil Grade
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputField
                    name="Engine"
                    placeholder="10 w 40 4T"
                    className={vehicleStyle.inputTextBar}
                    // onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={6}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Vehicle Image
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.DocumentInputField
                    id="file-upload"
                    type="file"
                    name="Upload Image"
                    className={vehicleStyle.formsUpload}
                    icon={<FiUpload />}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Related Services
                </Grid>
                <Grid item xs={12} md={6}>
                  <IconButs
                    ibtname={ADD_NEW}
                    className={vehicleStyle.forms}
                    icon={<AiOutlinePlus />}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={vehicleStyle.formLabel}>
                  Related Products
                </Grid>
                <Grid item xs={12} md={6}>
                  <IconButs
                    ibtname={ADD_NEW}
                    className={vehicleStyle.forms}
                    icon={<AiOutlinePlus />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "40px",
              marginTop: "20px",
            }}
          >
            <Grid item>
              <div className={vehicleStyle.formIcon}>
                <IconButs
                  ibtname="OK"
                  className={vehicleStyle.formButtonAdd}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={vehicleStyle.formIcon}>
                <Link to={link}>
                  <IconButs
                    ibtname="Cancel"
                    className={vehicleStyle.formButtonAdd}
                    variant="outlined"
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default VehicleAdd;
