import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const FindLink = (link) => {
    //aimee
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page");
    const search = searchParams.get("search");
    const itemsno = searchParams.get("itemsno");
    const selected = searchParams.get("selected");
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    
    link = page !== null ? link + `?page=${page}` : link;
    link = search !== null ? link + `&search=${search}` : link;
    link = itemsno !== null ? link + `&itemsno=${itemsno}` : link;
    link = (selected !== null && selected !== undefined)  ? link + `&selected=${selected}` : link;
    link = from !== null ? link + `&from=${from}` : link;
    link = to !== null ? link + `&to=${to}` : link;


    return link;
}
 



 


