import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
const theme = createTheme();

export default makeStyles((theme) =>
  createStyles({
    listMain: {
      // position: "relative",
      backgroundColor: "#F5F5F5",
      width: "98%",
      minHeight: "100vh",
    },
    searchList: {
      position: "relative",
      display: "flex",
      top: "20px",
      left: "58px",
      right: "20px",
      width: "97%",
      height: "70px",
    },
    formSelectBox: {
      width: "17vw",
      marginBottom: "20px",
      marginLeft: "50px",
      backgroundColor: "white",
      borderRadius: "20px",
    },
    dmSalesButtonOne: {
      fontFamily: "Montserrat",
      fontWeight: "700",
      background: "#FEDD17",
      // lineHeight: '22px',
      letterSpacing: "1px",
      display: "flex",
      justifyContent: "space-evenly",
      // padding: "10px 8px",
      // width: '361px',
      // height: "40px",
      fontSize: "14px",
      color: "#252F40",
      textTransform: "capitalize",
      borderRadius: "8px",
      // justifyContent: 'space-evenly',dmSalesButtonOne
      "&:hover": {
        background: "#FEDD17 !important",
      },
      [theme.breakpoints.down("md")]: {
        height: "41px",
        fontSize: "10px",
      },
      // [theme.breakpoints.down("sm")]:{
      //   height:"36px",
      //   fontSize:"10px",
      //   letterSpacing:"0px"

      // },
      ["@media (max-width: 450px)"]: {
        height: "36px !important",
        fontSize: "9px !important",
        letterSpacing: "0px",
      },
    },
    tableList: {
      position: "relative",
      left: "20px",
      top: "80px",
      width: "97%",
      height: "600px",
    },
    tablePagination: {
      position: "relative",
      left: "20px",
      top: "auto",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    searchBar: {
      width: "379px",
      backgroundColor: "white",
      borderRadius: "8px",
      fontSize: "14px",
      ["@media (max-width: 1500px)"]: {
        width: "200px",
      },
    },
    filter: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      paddingLeft: "10px",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.75px",
      color: "#000000",
    },
  })
);
